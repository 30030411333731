import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Table } from 'reactstrap';
import { Link } from 'react-router';
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import kcLogo from '../images/kc1.png';
import paperLCA from '../images/PaperLCA.jpg';
import Loader from 'react-loader-spinner'
import '../index.css';

class Paper extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      kwData: {},
      mmData: {},
      wpData: {},
      buttonValue: "Volume",
      kwPrice: {},
      mmPrice: {},
      wpPrice: {},
      kwRevenue: {},
      mmRevenue: {},
      wpRevenue: {},
      loadingVolume: true,
      loadingPrice: true,
      loadingRevenue: true
    };
    this.setState({ buttonValue: "Volume" })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //API call - VOLUME
    this.setState({ loadingVolume: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/volume/', {
      params: {
        material: 'Paper',
        out: (this.state.buttonValue).toLowerCase()
      }
    }).then(res => {
      this.setState({
        loadingVolume: false,
        kwData: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmData: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpData: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));

    //API call - PRICE
    this.setState({ loadingPrice: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/buy_sell/', {
      params: {
        material: 'Paper'
      }
    }).then(res => {
      this.setState({
        loadingPrice: false,
        kwPrice: {
          labels: res.data.data.kw.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.kw.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.kw.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        mmPrice: {
          labels: res.data.data.mm.keys,
          datasets: [
            {
              label: 'Buying Pricing',
              data: res.data.data.mm.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.mm.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        wpPrice: {
          labels: res.data.data.wp.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.wp.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.wp.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        }
      })
    }).catch(err => console.log(err));

    //API call - REVENUE
    this.setState({ loadingRevenue: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/revenue/', {
      params: {
        material: 'Paper'
      }
    }).then(res => {
      this.setState({
        loadingRevenue: false,
        kwRevenue: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmRevenue: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpRevenue: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));
  }

  //Navbar Toggle
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  //Dropdown button toggle
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  
  //View port width calculator
  calculateWidth() {
    if (window.innerWidth <= 600) { /* mobile */
      return 300;
    } else if (window.innerWidth >= 1100) { /* Desktop */
      return 150;
    } else {
      return 250; /* Tablet */
    }
  }

  //Render Waste Picker data
  renderWastePickerData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.wpData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L0 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: '600'
                        },
                          ticks: {
                            fontSize: 12,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: 'Source Sans Pro',
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if(this.state.buttonValue === "Price") {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.wpPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                  tooltips: {
                    mode: 'x-axis'
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Price',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true,
                      ticks: {
                        fontSize: 12,
                        beginAtZero: true,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true
                    }]
                  }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.wpRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L0 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: '600'
                      },
                        ticks: {
                          fontSize: 12,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: 'Source Sans Pro',
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  // Render Kabadiwalla data
  renderKabadiwallaData() {
    if(this.state.buttonValue === "Volume") {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.kwData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L1 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        },
                        tooltips: {
                          enabled: true,
                          fontSize: 12,
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif",
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === "Price") {
      if(this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.kwPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.wpRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L1 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: '600'
                      },
                        ticks: {
                          fontSize: 12,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: 'Source Sans Pro',
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  //Render Middleman Data
  renderMiddlemanData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.mmData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L2 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Volume',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                          fontSize: 8,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          beginAtZero:true,
                          min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === 'Price') {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.mmPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.mmRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L2 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif",
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  renderDropdownButton() {
    if(this.state.loadingVolume == false && this.state.loadingPrice == false) {
      return (
        <ButtonDropdown className="btn center" style={{ marginTop: 20, marginBottom: 20 }} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle className="dropDownButtonStyle" caret>
            {this.state.buttonValue}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Volume"})}>Volume</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Price"})}>Price</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Revenue"})}>Revenue</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loader
             type="TailSpin"
             color="#39A300"
             height="40"
             width="40"
          />
        </div>
      )
    }
  }

  renderMaterialTaxonomy() {
      return (
        <div className="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Material Taxonomy</h3>
          </div>
          <p className="descriptionStyle">The classification of paper scrap materials within the Chennai informal waste ecosystem, as revealed by our survey, is outlined in the table below. At the L0 level, paper is collected from post-consumer sources under the broad categories of White (printed paper from periodicals), Attai (cardboard), newspaper, note paper (loose writing paper), and Paper (magazines, periodicals & other bounded paper products). This eventually undergoes segregation and refinement as the scrap materials move downstream towards final processing.</p>
          <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10 }}>
            <Table bordered className="tabelTextStyle">
            <thead>
              <tr>
                <th colspan="2">Waste-picker</th>
                <th colspan="2">Kabadiwalla</th>
                <th colspan="2">Middleman</th>
                <th colspan="2">Processor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
              </tr>
              <tr>
                <td rowspan="4">White</td>
                <td rowspan="4">11</td>
                <td rowspan="4">White Paper</td>
                <td rowspan="4">12</td>
                <td>Book</td>
                <td>16</td>
                <td>Book Paper Scrap</td>
                <td>17-19</td>
              </tr>
              <tr>
                <td>Magzine</td>
                <td>11</td>
                <td>Magzine Scrap</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Notebook</td>
                <td>14</td>
                <td>Notebook Scrap</td>
                <td>15-18</td>
              </tr>
              <tr>
                <td>Newspaper</td>
                <td>11.8</td>
                <td>Newspaper Scrap</td>
                <td>12-14</td>
              </tr>
              <tr>
                <td>Attai</td>
                <td>6-12</td>
                <td>Attai</td>
                <td>8-14</td>
                <td>Cardboard</td>
                <td>16</td>
                <td>Cardboard Scrap</td>
                <td>18</td>
              </tr>
              <tr>
                <td rowspan="3">Newspaper</td>
                <td rowspan="3">9</td>
                <td rowspan="3">Newspaper</td>
                <td rowspan="3">10-12</td>
              </tr>
              <tr>
                <td>English Newspaper</td>
                <td>14</td>
                <td>Newspaper Scrap</td>
                <td>15-16</td>
              </tr>
              <tr>
                <td>Tamil Newspaper</td>
                <td>12</td>
                <td>Newspaper Scrap</td>
                <td>13-14</td>
              </tr>
              <tr>
                <td>Note Paper</td>
                <td>13</td>
                <td>Note Paper</td>
                <td>16</td>
                <td>Note Paper</td>
                <td>18</td>
                <td>White Paper Scrap</td>
                <td>20-22</td>
              </tr>
              <tr>
                <td rowspan="5">Paper</td>
                <td rowspan="5">6.5</td>
              </tr>
              <tr>
                <td>Bill Book</td>
                <td>3-6</td>
                <td>Bill Book</td>
                <td>-</td>
                <td>Waste Paper</td>
                <td>8-12</td>
              </tr>
              <tr>
                <td>White Record</td>
                <td>-</td>
                <td>White Record</td>
                <td>-</td>
                <td>White Record</td>
                <td>8-12</td>
              </tr>
              <tr>
                <td>Color Record</td>
                <td>3.5-12</td>
                <td>Color Record</td>
                <td>11</td>
                <td>Color Record</td>
                <td>14-16</td>
              </tr>
              <tr>
                <td>Rani</td>
                <td>9</td>
                <td>Tamil Magzine</td>
                <td>-</td>
                <td>Newspaper Scrap</td>
                <td>12-14</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      )
  }

  renderDataAnalyticsContent() {
    if(this.state.loadingVolume == false && this.state.buttonValue === 'Volume') {
      return (
        <div>
          <h5>Volume</h5>
          <p className="descriptionStyleMaterialTaxonomy">Cardboard makes up the overwhelming majority of paper materials recovered within the Chennai waste ecosystem. Newspaper is the highest in terms of volume among paper subcategory material after cardboard, with English newspapers fetching a higher price compared to regional language newspapers.  Writing paper and craft paper, usually sourced from various school stationery, have a seasonal volume spiking at the end of each school year in May - July.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Price') {
      return (
        <div>
          <h5>Price</h5>
          <p className="descriptionStyleMaterialTaxonomy">At the L0 and L1 enterprise level, given the lack of segregation, sale price of the mixed aggregate is determined by the relative content of various types of material. Beginning at the L2 level, various grades of paper are segregated and fetch a price according to market demand and the proportion of high quality white paper in the material. </p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Revenue') {
      return (
        <div>
          <h5>Revenue</h5>
          <p className="descriptionStyleMaterialTaxonomy">Cardboard generates the highest revenue across all stakeholder levels due to its high volume. However, materials such as “white”, english newspaper, and notebooks, which have a higher sale price also see significant recovery and revenue generation due to the relative percentage of high quality white paper in them. </p>
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronPaper">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Paper</h1>
            The paper market is characterized by a highly consolidated structure where stakeholders typically handle most subcategories of paper at one location. Given that paper is a low-value material, minimal processing is carried out in the city, with most stakeholders focussing on aggregation. Paper is labor-intensive to manage and enterprises require large storage areas and baling machinery to be able to handle large volumes.
          </div>
        </div>
        {/* LCA */}
        <div class="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Life Cycle Analysis</h3>
          </div>
          <p className="descriptionStyle">Within the paper value chain, shown in the image, newspaper is the largest material by volume and it sells for 9 INR per kilogram at L0 level. Cardboard is the second highest in terms of volume and sells at around 7.5 INR per kilogram. Most paper sector stakeholders in the city play the role of aggregators. Processing is rarely done at the middleman level when it comes to paper, and it is reserved for paper milling operations which are located outside Chennai. Paper processors in the city are usually involved only in the shredding and baling of different categories of paper.</p>
          <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
            <img src={paperLCA} alt="Plastic LCA" class="img-responsive" height={'auto'} width={'100%'} className="center" />
          </div>
        </div>
        {/* Material Taxnomy */}
        {this.renderMaterialTaxonomy()}
        {/* Data Analytics */}
        <div className="descriptionViewStyle" style={{ paddingLeft: 15, paddingRight: 15 }}>
          {
            this.state.loadingVolume == false ? (
              <div>
                <h3>Data Analytics</h3> 
                {this.renderDataAnalyticsContent()}
              </div>
            ) : null
          }
          {/* Dropdown Button */}
          {this.renderDropdownButton()}

          {this.renderWastePickerData()}
          {this.renderKabadiwallaData()}
          {this.renderMiddlemanData()}
        </div>
      </div>
    )
  }
}

export default Paper;
