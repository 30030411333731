import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Table } from 'reactstrap';
import { Link } from 'react-router';
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import kcLogo from '../images/kc1.png';
import Loader from 'react-loader-spinner'
import '../index.css';

class Glass extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      kwData: {},
      mmData: {},
      wpData: {},
      buttonValue: "Volume",
      kwPrice: {},
      mmPrice: {},
      wpPrice: {},
      kwRevenue: {},
      mmRevenue: {},
      wpRevenue: {},
      loadingVolume: true,
      loadingPrice: true,
      loadingRevenue: true
    };
    this.setState({ buttonValue: "Volume" })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //API call - VOLUME
    this.setState({ loadingVolume: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/volume/', {
      params: {
        material: 'Glass',
        out: (this.state.buttonValue).toLowerCase()
      }
    }).then(res => {
      this.setState({
        loadingVolume: false,
        kwData: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmData: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpData: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));

    //API call - PRICE
    this.setState({ loadingPrice: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/buy_sell/', {
      params: {
        material: 'Glass'
      }
    }).then(res => {
      this.setState({
        loadingPrice: false,
        kwPrice: {
          labels: res.data.data.kw.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.kw.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.kw.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        mmPrice: {
          labels: res.data.data.mm.keys,
          datasets: [
            {
              label: 'Buying Pricing',
              data: res.data.data.mm.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.mm.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        wpPrice: {
          labels: res.data.data.wp.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.wp.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.wp.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        }
      })
    }).catch(err => console.log(err));

    //API call - REVENUE
    this.setState({ loadingRevenue: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/revenue/', {
      params: {
        material: 'Glass'
      }
    }).then(res => {
      this.setState({
        loadingRevenue: false,
        kwRevenue: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmRevenue: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpRevenue: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));
  }

  //Navbar Toggle
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  //Dropdown button toggle
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  //View port width calculator
  calculateWidth() {
    if (window.innerWidth <= 600) { /* mobile */
      return 300;
    } else if (window.innerWidth >= 1100) { /* Desktop */
      return 150;
    } else {
      return 250; /* Tablet */
    }
  }

  //Render Waste Picker data
  renderWastePickerData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.wpData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L0 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: '600'
                        },
                          ticks: {
                            fontSize: 12,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: 'Source Sans Pro',
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if(this.state.buttonValue === 'Price') {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.wpPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                  tooltips: {
                    mode: 'x-axis'
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Price',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true,
                      ticks: {
                        fontSize: 12,
                        beginAtZero: true,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true
                    }]
                  }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.wpRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L0 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: '600'
                      },
                        ticks: {
                          fontSize: 12,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: 'Source Sans Pro',
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  // Render Kabadiwalla data
  renderKabadiwallaData() {
    if(this.state.buttonValue === "Volume") {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.kwData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L1 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        },
                        tooltips: {
                          enabled: true,
                          fontSize: 12,
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif",
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === "Price") {
      if(this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.kwPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.kwRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L1 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif",
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  //Render Middleman Data
  renderMiddlemanData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.mmData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L2 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Volume',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                          fontSize: 8,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          beginAtZero:true,
                          min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === 'Price') {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.mmPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.mmRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L2 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        beginAtZero:true,
                        min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  renderDropdownButton() {
    if(this.state.loadingVolume == false && this.state.loadingPrice == false) {
      return (
        <ButtonDropdown className="btn center" style={{ marginTop: 20, marginBottom: 20 }} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle className="dropDownButtonStyle" caret>
            {this.state.buttonValue}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Volume"})}>Volume</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Price"})}>Price</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Revenue"})}>Revenue</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loader
             type="TailSpin"
             color="#39A300"
             height="40"
             width="40"
          />
        </div>
      )
    }
  }

  renderMaterialTaxonomy() {
      return (
        <div className="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Material Taxonomy</h3>
          </div>
          <p className="descriptionStyle">Glass is the simplest of materials because of its straightforward identification and limited processing requirement. There are mainly two kinds of bottles: beer bottles (650ml) and quarter bottles (180ml). The quarter bottles are the most popular size of container for the sale of hard spirits like whiskey, rum or brandy. Because of their small size, they are cheap and mainly patronized by working class people. While L1 enterprise tend not to segregate further than the above mentioned categories, L2 enterprises undertake the role of segregating the bottles further according to the brand/manufacturer and also colour, and then passing them on to rebottling operations for cleaning and washing. The various categories of glass products, as revealed by our survey, is outlined in the table below. </p>
          <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10 }}>
            <Table bordered className="tabelTextStyle">
            <thead>
              <tr>
                <th colspan="2">Waste-picker</th>
                <th colspan="2">Kabadiwalla</th>
                <th colspan="2">Middleman</th>
                <th colspan="2">Processor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
              </tr>
              <tr>
                <td rowspan="8">Beer Bottle</td>
                <td rowspan="8">1.5-2</td>
              </tr>
              <tr>
                <td rowspan="3">Green Beer Bottle</td>
                <td rowspan="3">2.5-3</td>
              </tr>
              <tr>
                <td>kF</td>
                <td>3 - 4</td>
                <td>kF</td>
                <td>3.5 - 4.5</td>
              </tr>
              <tr>
                <td>British Empire</td>
                <td>2.5-3</td>
                <td>British Empire</td>
                <td>3-4</td>
              </tr>

              <tr>
                <td rowspan="4">Brown Beer Bottle</td>
                <td rowspan="4">2.5-3</td>
              </tr>
              <tr>
                <td>kals</td>
                <td>2.5-3</td>
                <td>kals</td>
                <td>3-4</td>
              </tr>
              <tr>
                <td>Zingaro</td>
                <td>3-4</td>
                <td>Zingaro</td>
                <td>3.5-4.5</td>
              </tr>
              <tr>
                <td>SNJ</td>
                <td>2.5-3</td>
                <td>SNJ</td>
                <td>3-4</td>
              </tr>

              <tr>
                <td rowspan="9">Quarter Bottle</td>
                <td rowspan="9">0.25-0.33</td>
              </tr>
              <tr>
                <td rowspan="9">Quarter Bottle</td>
                <td rowspan="9">0.5</td>
              </tr>
              <tr>
                <td>Midas</td>
                <td>0.75 - 1</td>
                <td>Midas</td>
                <td>1-1.25</td>
              </tr>
              <tr>
                <td>Elite</td>
                <td>0.75-1</td>
                <td>Elite</td>
                <td>1-1.25</td>
              </tr>
              <tr>
                <td>1848</td>
                <td>0.75-1</td>
                <td>1848</td>
                <td>1-1.25</td>
              </tr>
              <tr>
                <td>SDL</td>
                <td>0.75-1</td>
                <td>SDL</td>
                <td>1-1.25</td>
              </tr>
              <tr>
                <td>MGM (POLI)</td>
                <td>0.9-1.1</td>
                <td>Poli MGM</td>
                <td>1.3-1.5</td>
              </tr>
              <tr>
                <td>New Kamana</td>
                <td>0.75-1</td>
                <td>New Kamana</td>
                <td>1-1.25</td>
              </tr>
              <tr>
                <td>MGM</td>
                <td>.9-1.1</td>
                <td>MGM</td>
                <td>1.3-1.5</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
      )
  }

  renderDataAnalyticsContent() {
    if(this.state.loadingVolume == false && this.state.buttonValue === 'Volume') {
      return (
        <div>
          <h5>Volume</h5>
          <p className="descriptionStyleMaterialTaxonomy">The collection process at the L0 level is fairly consolidated. Wine bottles, which are 375 ml containers for spirits, are the most recovered material, followed by beer bottles, and finally quarter bottles (180 ml). At the L1 level, the market begins consolidating as wine and quarter bottles get grouped into one category - liquor bottle. L2 enterprises practice direct collection of beer bottles from commercial source such as bars and clubs, pushing up beer bottle recovery significantly at the L2 level.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Price') {
      return (
        <div>
          <h5>Price</h5>
          <p className="descriptionStyleMaterialTaxonomy">At the L0 level, given the lack of segregation, spirit and beer bottles fetch merely 40 paise (INR 0.40) and INR 1.20 respectively. As a result, unless they are able to recover large quantities, L0s prefer not to collect bottles due to its weight and unwieldy nature. Among all glass materials, high quality unstained ornamental glass fetches the L1s the highest margins. Spirit bottles offer the highest margins at the L2 level. Beer bottles of certain brands such as Kingfisher and Carlsberg fetch a higher margin as a result of high demand and premium buying price.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Revenue') {
      return (
        <div>
          <h5>Revenue</h5>
          <p className="descriptionStyleMaterialTaxonomy">Across different stakeholder levels, beer bottles generate the highest revenue due to their high volumes. Liquor bottles also generate high revenue at the L0 and L1 levels due to their high sale price, but the absolute numbers are diluted at the L2 level since liquor bottles begin being segregated into various brands.</p>
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronGlass">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Glass</h1>
            The glass market is characterized by a simplistic structure with all stakeholders carrying out aggregation of all types of bottles. Glass segregation and processing is labor-intensive and requires specialized training for the workers to ensure correct identification of the various brands. Stakeholders who are able to provide higher volumes, uniform quality and  uniform grade of material receive better prices at all stakeholder levels.
          </div>
        </div>
        {/* LCA */}
        <div class="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Life Cycle Analysis</h3>
          </div>
          <p className="descriptionStyle">Discarded glass bottles are picked up by L0 workers from generation hotspots such as bars, clubs, garbage bins in certain neighborhoods, and parks. The collected material is sold to L1 enterprises on a per unit basis, priced differently for different types of glass bottles. The L1 enterprises primarily play the role of an aggregator and perform basic segregation of the material into categories of spirit bottles, beer bottles, and other bottles. L2 enterprises pick-up material from multiple L1s and undertake the role of segregating the bottles according to the brand/manufacturer and also colour, and then passing them on to rebottling operations for cleaning and washing.</p>
        </div>
        {/* Material Taxnomy */}
        {this.renderMaterialTaxonomy()}

        <div className="descriptionViewStyle" style={{ paddingLeft: 15, paddingRight: 15 }}>
          {
            this.state.loadingVolume == false ? (
              <div>
                <h3>Data Analytics</h3> 
                {this.renderDataAnalyticsContent()}
            </div>
            ) : null
          }
          {/* Dropdown Button */}
          {this.renderDropdownButton()}

          {this.renderWastePickerData()}
          {this.renderKabadiwallaData()}
          {this.renderMiddlemanData()}
        </div>
      </div>
    )
  }
}

export default Glass;
