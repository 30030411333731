import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import kcLogo from '../images/kc1.png';
import '../index.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="md">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" target="_blank" href="https://forms.gle/py6ZZNDn6a1v7MkTA">Download Report</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronHome">
          <div class="imgContainer" />
          <div class="centered descriptionHeaderViewStyle">
            <h1 className="jumbotronHeader">A spatial audit of Chennai’s informal waste supply chain: Rethinking integration strategies in the Global South</h1>
            <p className="jumbotronSubs">Kabadiwalla Connect, a waste management startup based in India  completed a survey of over 2500 stakeholders in Chennai’s informal waste supply chain in 2018. The survey was made possible through a grant from the <a target="_blank" href="http://blogs.worldbank.org/opendata/announcing-funding-10-development-data-innovation-projects">Global Partnership for Sustainable Development Data</a>. Certain key insights from the survey are presented in this website.</p>
            <div className="descriptionStyle headerSubs">
              <p style={{ fontWeight: '100', color: 'white' }}>Last updated on: May, 2020</p>
              <p style={{ fontWeight: '100', color: 'white' }}>Authors: Siddharth Hande, Ganesh Kumar, Sonaal Bangera, Tharaka Abilash P</p>
              <p style={{ fontWeight: '100', color: 'white' }}>With writing and editing support from: Thomas Manuel, Hari, Prajeeth</p>
              <p style={{ fontWeight: '100', color: 'white' }}>Thanks to Arun Balaji, Balakrishnan, Arunkumar &amp; Murugan for their tireless work in the collection of data on the field.</p>
            </div>
          </div>
        </div>
        {/* IS Description */}
        <div class="descriptionViewStyle">
          <div style={{ paddingBottom: 10 }}>
            <p className="descriptionStyle">
              <h3>Introduction to the Informal Sector</h3>
              Waste collection is a merit good - a service or product deemed essential, by law, for the well-being of all citizens - in most nations of the world. However, even in the most developed nations of the world, waste collection coverage is hardly complete. In its 2012 report Global Review of Solid Waste Management, the World Bank puts the average waste collection coverage at 86% in upper-middle income, 68% in lower-middle income and 41% in low income countries. Many lower-middle and low income countries have made significant strides in improving collection coverage in the last 2 decades, but the burgeoning urban population in these countries, and the associated increase in waste generation have outpaced the growth and funding in waste management systems. The collection coverage is especially poor among countries located in sub-Saharan Africa and South Asia, where rapid population and industrial growth has lead to a swelling in waste generation. 
            </p>
            <p className="descriptionStyle">
              Typically, low-income countries spend a majority of their SWM budgets on waste collection, with only a fraction going toward disposal (Hoornweg and Bhada-Tata, 2012). As a result, most disposal sites in developing nations are open dumps (Guerrero et al., 2013), leading to concerns over leachates and landfill gases. Existing collection, transfer and transport practices in developing nations are also affected by deficiencies in bin collection systems, poor route planning, lack of information about collection schedule (Hazra and Goel, 2009), insufficient infrastructure (Moghadam et al., 2009), poor roads and lack of vehicles for waste collection (Henry et al., 2006). 
            </p>
            <p className="descriptionStyle">
              Overall, this has resulted in a major waste crisis in cities in developing nations where open disposal is leading to a number of environmental and health concerns. Uncollected waste results in a higher incidence of serious illnesses, including diarrhoea, acute respiratory infections, and plague-like diseases, in children and compromised individuals living in households in the vicinity of this waste, as compared with households receiving a regular waste collection service (UN-Habitat, 2009). Uncollected waste, especially plastic waste, has also been shown to cause severe floods and increase water-borne illnesses (Gupta, 2010).
            </p>
            <p className="descriptionStyle">
              These deficiencies in waste management in the developing world are largely a result of the high cost of waste management. Organizing the informal sector and promoting micro-enterprises are some methods mentioned by Sharholy et al. (2008) as ways of creating affordable waste collection services. Wilson et al. (2012) also suggest that there is a great opportunity to improve waste management in developing cities through partnerships between government-run waste collection systems and the informal waste sector, given their already crucial role in boosting resource recovery in the city.
            </p>
          </div>
          <div style={{ paddingBottom: 10 }}>
            <p className="descriptionStyle">
              <h3>Understanding Informality in Solid Waste Management</h3>
              With regards to solid waste management, the informal sector refers to the people who depend on recycling materials from waste for their livelihoods (David et al., 2006). In the developing world, informal waste recycling is often carried out by poor and marginalised communities who resort to waste picking for everyday survival (Haan et al., 1998). Research shows that 2% of the urban population in Asian and Latin American cities depend on waste picking to earn their livelihood (Medina, 2000). The informal waste sector often includes discrete social groups and goes by different names in different countries (Medina and Dows, 2000). They are known are Zabbaleen in Egypt; Pepenadores in Mexico; Basuriegos in Colombia; Chamberos in Ecuador; Buzos in Costa Rica; Cirujas in Argentina; Lapak in Indonesia, and Kabadiwallas in India.            
            </p>
            <p className="descriptionStyle">
              Their primary incentive is financial profit — wastepickers and itinerant buyers collect materials from various waste generation and disposal hotspots, which they then sell to aggregators. These aggregators segregate the material at a coarse level into paper, plastic, glass, and metal, and sell to specialised wholesalers, who in turn sell these materials to processors who recycle this material into secondary raw materials. Together, these stakeholders form an important ecosystem of handling recyclable waste, and their network is connected to many residences, commercial establishments and industries in an urban ecosystem. 
            </p>
            <p className="descriptionStyle">
              Many studies have shown that informal recycling activities have positive effects on the environment, reduce costs of waste management for the local municipality, and provide income opportunities for large numbers of poor people. However, Gerdes and Gunsilius (2010) comment that as waste management in the developing world becomes more modernised, privatisation and mechanisation of waste management services are common strategies, and the focus is often on efficient collection and disposal rather than on recovery and recycling of waste. As a result of this de-facto waste management strategy, the integration of the informal sector remains largely ignored in mainstream discussions on waste management. 
            </p>

          </div>

          <div style={{ paddingBottom: 10 }}>
            <p className="descriptionStyle">
              <h3>Global Perspectives and Classification</h3>
              Resource recovery by informal waste sector workers occurs in cities throughout the economically developing world (Bernache, 2003). In cities with a formal, municipal waste collection and disposal system, there are at least four main categories of informal waste collection that can be identified, depending on where and how material recovery takes place (Wilson et al., 2001):
            </p>
            <ol className="listStyle">
              <li>Itinerant waste buyers: People from that informal sector that use a vehicle to collect sorted recyclable materials from householders or domestic servants. They often use ‘3-wheelers’, or tricycles (Medina and Dows, 2000).</li>
              <li>Street waste picking: This is when the informal sector recover recyclable material from mixed waste that are discarded in communal bins before collection.</li>
              <li>Municipal waste collection crew: Recyclable material are recovered from vehicles transporting municipal solid waste to landfills. This practice is widespread, e.g in Mexico, Colombia, Thailand and Philippines (Wilson et al., 2001).</li>
              <li>Waste picking from dumps: Waste pickers often rummage through waste from dumpyards before they are treated. This is often associated with communities that live in shacks built from waste construction materials on or near the dump. </li>
            </ol>
            <p className="descriptionStyle">
              It is important to understand that the way in which informal recycling activities are organised, has important consequences for income generation, working conditions and social status (Wilson et al., 2001). Wilson et al. (2006), as a general rule, conclude that “the less organised the informal recycling sector is, the less the people involved are capable of adding value to the secondary raw materials they collect, and the more vulnerable they are to exploitation from intermediate dealers”. To understand this informal sector network, they propose the following hierarchy, with stakeholders listed in descending order of wealth accumulation:
            </p>
            <ol className="listStyle">
              <li>Manufacturing Industries</li>
              <li>Brokers, Wholesalers, and other processors</li>
              <li>Craftsmen, Middlemen</li>
              <li>Recycling MSEs and scavenging co-operatives</li>
              <li>Family type units involved in waste collection</li>
              <li>Individual waste pickers</li>
            </ol>
            <p className="descriptionStyle">
              In this current project, we have attempted to provide a new classification of the informal waste sector based on one of the most comprehensive waste management sector surveys conducted in a single developing world city. The classification aims to categorize stakeholders based on their functional roles, since such a classification method will be applicable across geographies. 
            </p>

            <div>
              <p className="descriptionStyle">
                <h3>New System of Classification</h3>
                Within Chennai’s waste ecosystem, each type of stakeholder is known by various terms in the local Tamil language. For the purpose of our study, we have defined stakeholder groups, based on their distinct functional roles, on the following basis:
              </p>
              <ol className="listStyle">
                <li><b>Level 0 (L0 aggregators):</b> These are informal sector workers who may or may not have a means of transportation and incur zero or minimal input cost. These stakeholders primarily collect from roadside dustbins, landfills, and, in the case of those owning a vehicle, from households.</li>
                <li><b>Level 1 (L1 aggregators):</b> These are informal sector workers who have a storage space and aggregate material from L0 stakeholders and residents. They do minimal or no processing of the material.</li>
                <li><b>Level 2 (L2 aggregators):</b> These are informal sector workers who have a storage space which is many times larger when compared to a L1 stakeholder and aggregate material directly from both L1 and other commercial sources in bulk. They typically specialise in a single super category of material and might process it to produce secondary raw materials as well.</li>
                <li><b>Processors:</b> These are stakeholders who buy specific grades of post-consumer scrap material from L1s and L2s and convert them to usable secondary raw material for the manufacturing industry.</li>
              </ol>
              <p className="descriptionStyle">
                Arfvidsson et al. (2016) contend that there is a pressing need to create reliable and robust data on urban informality as the current SDG targets and indicators currently rely on official data, which is frequently unreliable, but more importantly—does not include unregulated activities. Our objective with the informal sector survey in Chennai is to directly contribute to this discussion by building a comprehensive dataset on the informal waste ecosystem in Chennai.
              </p>
              <p className="descriptionStyle">
                The primary instruments of our survey are comprehensive questionnaires for L0 (wastepicker), L1 (kabadiwallas), and L2 (middlemen), and processor stakeholders. All questionnaires are divided into four sections covering  demographics, operations, financials, and health & safety. The first section is aimed at gathering information from the respondents about the enterprise, their organisational details and the respondent’s demographic details. The section on business operations deals with the waste sourced by the stakeholder - how and where they source from including the details of the various types, buying cost, quantities, and selling costs. Additionally, this section also captures information on operational requirements of the business such as labour and equipment for processing. In the financials section, details of financial expenditure for various aspects of business operations, payment modes, and schedules have been explored. In the final section, health & safety hazards arising from operation of the business and from exposure to scrap material have been captured.
              </p>
              <p className="descriptionStyle">
                Kabadiwalla Connect identified four neighbourhoods in Chennai city to be a part of the in-depth surveys. To arrive at these four neighbourhoods, the past research by Kabadiwalla Connect of mapping aggregators in the city was considered. A clustering of the locations of these aggregators were performed using K-Means clustering for 8 clusters in the city. The neighbourhoods selected were Mylapore, Koyambedu-Choolaimedu, Velachery, and Old Mahabalipuram Road (OMR). In these neighbourhoods, a detailed survey of all levels of stakeholders was performed, while only aggregators and processors were surveyed in other parts of the city. 
              </p>
              <p className="descriptionStyle">
                Kabadiwalla Connect had totally collected data on 314 wastepickers, 1970 kabadiwallas, 280 middlemen and 46 processors. This includes stakeholders who did not answer the entire survey for whom only name and location was recorded.
              </p>
              <p className="descriptionStyle">
                The video below showcases the different stakeholders within the informal waste sector, based on the data collected in Chennai, India.
                <br/>
                <br/>
                <div
                  className="video"
                  style={{
                    position: "relative",
                    paddingBottom: "50.25%" /* 16:9 */,
                    paddingTop: 60,
                    height: 0
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%"
                    }}
                    src={`https://www.youtube.com/embed/2ZlvUwibwZU`}
                    frameBorder="0"
                  />
                </div>
              </p>
            </div>

            <div>
              <p className="descriptionStyle">
                <h3>Kabadiwalla Connect aims to develop market based solutions that support informal sector integration in the Global South</h3>
              </p>

              <p className="descriptionStyle">
                Kabadiwalla Connect (KC) has determined that leveraging the informal ecosystem of urban waste aggregators has the potential to decrease the amount of material sent to landfills in Indian cities by 70%. In the current scenario, municipalities, multinational brands, and waste management companies struggle to work effectively with informal stakeholders – despite increasing evidence of the commercial, environmental, and social benefits of forming mutually beneficial partnerships. 
              </p>

              <p className="descriptionStyle">
                Through a unique business process and award winning technology, Kabadiwalla Connect integrates the informal ecosystem into the reverse logistics supply chain, helping municipalities, brands, and waste management companies recover post-consumer waste efficiently and more inclusively in the developing world.
              </p>

              <p className="descriptionStyle">
                Rather than approaching informality as a problem in cities in the developing world, Kabadiwalla Connect has developed technologies that leverage the existing informal infrastructure towards a more efficient waste management system. 
              </p>

              <p className="descriptionStyle">
                At maturity, Kabadiwalla Connect aims to position itself as a technology provider for informal sector integration in SE Asia — working with municipalities, processors, waste collection companies and Producer Responsibility Organisations (PROs) 
              </p>

              <p className="descriptionStyle">
                KCs solutions can be broadly categorised into – Waste Management Infrastructure Mapping and Assessments, Waste Supply-chain Digitalisation, Post-consumer Material Sourcing & Municipal Waste Collection. 
              </p>

              <p className="descriptionStyle">
                KC is currently working on developing a municipal collection service that utilises neighbourhood informal scrap shops and their waste-pickers in the last mile collection of municipal waste on behalf of the municipality and/or waste management company. KC has an active pilot of this collection service deployed in Mylapore, Chennai. 
              </p>

             
            </div>  
          </div>
          <hr/>
          <p className="descriptionStyle">
            <span style={{ fontSize: 16 }}><b>References</b></span><br></br>
            <ol className="numberList">
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.sciencedirect.com/science/article/pii/S0197397505000482">David C., Wilson, Costas Velis, Chris Cheeseman (2006). Role of informal sector recycling in waste management in developing countries. Habitat International 30(4), 797-808.</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://www.hia21.eu/dwnld/20131229_The%20environmental%20impact%20of%20municipal%20waste.pdf">Bernache, G. (2003). The environmental impact of solid waste management: The case of Guadalajara metro area. Resources Conservation and Recycling, 39(3), 223–237.</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.sciencedirect.com/science/article/pii/S0197397505000482">Haan, H. C., Coad, A., Lardinois, I. (1998). Municipal waste management: Involving micro-and-small enterprises. Guidelines for municipal managers.</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="http://www.inclusivecities.org/wp-content/uploads/2012/07/Medina_Scavenger_Cooperatives_in_Developing_Countries.pdf">Medina, M. (2000). Scavenger cooperatives in Asia and Latin America. Resources, Conservation and Recycling.</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://scholarsarchive.byu.edu/cgi/viewcontent.cgi?article=1476&context=ccr">Medina, M., & Dows, M. (2000). A short history of scavenging. Comparative Civilizations Review(42).</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://www.tandfonline.com/doi/full/10.1080/19376812.2015.1130636">Arfvidsson, H., Simon, P., Oloko, M. and Moodley, N., (2016). Engaging with and measuring informality in the proposed Urban Sustainable Development Goal. African Geographical Review.</a></li>
            </ol>
          </p>
        </div>
        {/* Footer */}
        <div class="footerTextStyle" style={{ height: 100, width: null, backgroundColor: '#575757', paddingTop: 35 }}>
          <a class ="iconStyle" target="_blank" rel="noopener noreferrer" href="https://twitter.com/kabadiconnect">
            <i class="fab fa-twitter"></i>
          </a>
          <a class ="iconStyle" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/KabadiwallaConnect/">
            <i class="fab fa-facebook-f"></i>
          </a>
          <br></br>
          Kabadiwalla Connect 2020
        </div>
      </div>
    )
  }
}

export default Home;
