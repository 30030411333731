import React, { Component } from 'react';
import { Router, Route, hashHistory } from 'react-router';
import Home from './components/Home';
import Stakeholders from './components/Stakeholders';
import Materials from './components/Materials';
import Plastic from './components/Plastic';
import Paper from './components/Paper';
import Metal from './components/Metal';
import Glass from './components/Glass';

export default class Routes extends Component {
    render() {
        return (
            <Router history={hashHistory}>
              <Route path="/" component={Home} />
              <Route path="/Stakeholders" component={Stakeholders} />
              <Route path="/Materials" component={Materials} />
              <Route path="/Plastic" component={Plastic} />
              <Route path="/Paper" component={Paper} />
              <Route path="/Metal" component={Metal} />
              <Route path="/Glass" component={Glass} />
            </Router>
        );
    }
}
