var wastepicker = {
   "type": "FeatureCollection",
   "features": [
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2769933333,13.0225950000 ]
    },
    "properties": {
    "waste_picker_name":"Elumalai"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2181883333,12.9805783333 ]
    },
    "properties": {
    "waste_picker_name":"Rangaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2181816667,12.9806166667 ]
    },
    "properties": {
    "waste_picker_name":"S.murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2099966667,12.9971000000 ]
    },
    "properties": {
    "waste_picker_name":"Ravi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2183366667,12.9872700000 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2230133333,12.9861300000 ]
    },
    "properties": {
    "waste_picker_name":"Dhash"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2196583333,12.9717566667 ]
    },
    "properties": {
    "waste_picker_name":"Amulraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2229151900,12.9860014700 ]
    },
    "properties": {
    "waste_picker_name":"Sedharaman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533950000,13.0011733333 ]
    },
    "properties": {
    "waste_picker_name":"Vallarasu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2538666667,12.9961150000 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2538366667,12.9961033333 ]
    },
    "properties": {
    "waste_picker_name":"Kuppamma"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2541116667,12.9962150000 ]
    },
    "properties": {
    "waste_picker_name":"Gobikrishnan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2628100000,13.0243816667 ]
    },
    "properties": {
    "waste_picker_name":"Jhon"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613318500,13.0009290700 ]
    },
    "properties": {
    "waste_picker_name":"Venkesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2609828900,13.0010076500 ]
    },
    "properties": {
    "waste_picker_name":"Velangani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2535441200,13.0012021500 ]
    },
    "properties": {
    "waste_picker_name":"Suriya"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2744751500,13.0412578700 ]
    },
    "properties": {
    "waste_picker_name":"Suresh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2633150000,13.0355883333 ]
    },
    "properties": {
    "waste_picker_name":"Kanagaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2662533333,13.0294433333 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2719600000,13.0253350000 ]
    },
    "properties": {
    "waste_picker_name":"Sivaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2643950000,13.0331933333 ]
    },
    "properties": {
    "waste_picker_name":"Lingam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2611350000,13.0418150000 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2655850000,13.0424433333 ]
    },
    "properties": {
    "waste_picker_name":"Dharchanamoorthy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2721373900,13.0314626000 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2581454100,13.0334480000 ]
    },
    "properties": {
    "waste_picker_name":"Vekatraman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613514800,13.0330837500 ]
    },
    "properties": {
    "waste_picker_name":"Kasinadhan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2402032300,12.8264777900 ]
    },
    "properties": {
    "waste_picker_name":"Joseph"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625700000,13.0811050000 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2672283333,13.0255333333 ]
    },
    "properties": {
    "waste_picker_name":"Jesuraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2734533333,13.0608600000 ]
    },
    "properties": {
    "waste_picker_name":"Ram"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2414559600,12.8539458700 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2457162000,12.8872956600 ]
    },
    "properties": {
    "waste_picker_name":"Ramaswamy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708735700,13.0250624000 ]
    },
    "properties": {
    "waste_picker_name":"Karthik"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2691700000,13.0292966667 ]
    },
    "properties": {
    "waste_picker_name":"Segar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2723500600,13.0253056100 ]
    },
    "properties": {
    "waste_picker_name":"chandhiran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708556100,13.0249534800 ]
    },
    "properties": {
    "waste_picker_name":"Babu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2778836500,13.0573949500 ]
    },
    "properties": {
    "waste_picker_name":"Yesuraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737996600,13.0387328500 ]
    },
    "properties": {
    "waste_picker_name":"Soundraraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2684600000,13.0253566667 ]
    },
    "properties": {
    "waste_picker_name":"Moorthy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2771233333,13.0378150000 ]
    },
    "properties": {
    "waste_picker_name":"Kalimuthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741650000,13.0290483333 ]
    },
    "properties": {
    "waste_picker_name":"Pandiyan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737033333,13.0662700000 ]
    },
    "properties": {
    "waste_picker_name":"Palani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2716966667,13.0244816667 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2720450000,13.0244166667 ]
    },
    "properties": {
    "waste_picker_name":"Kannan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2147916667,13.0981200000 ]
    },
    "properties": {
    "waste_picker_name":"Chinnadurai "
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2764416800,13.0235840000 ]
    },
    "properties": {
    "waste_picker_name":"Balpandi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737134900,13.0337870000 ]
    },
    "properties": {
    "waste_picker_name":"Ceena"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2723317000,13.0253482700 ]
    },
    "properties": {
    "waste_picker_name":"Kalaivani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2728639400,13.0287285500 ]
    },
    "properties": {
    "waste_picker_name":"Gayadhiri"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2666501400,13.0347821100 ]
    },
    "properties": {
    "waste_picker_name":"Anand"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2323366667,13.0641266667 ]
    },
    "properties": {
    "waste_picker_name":"Antony"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2294650000,13.0567966667 ]
    },
    "properties": {
    "waste_picker_name":"Hari"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2190050000,12.9784900000 ]
    },
    "properties": {
    "waste_picker_name":"Suresh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2189216667,12.9784950000 ]
    },
    "properties": {
    "waste_picker_name":"Mathivanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2231633333,12.9856100000 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2231100000,12.9855566667 ]
    },
    "properties": {
    "waste_picker_name":"M.chatharaman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2167983333,13.0722266667 ]
    },
    "properties": {
    "waste_picker_name":"Gopi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2288716667,13.0657716667 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2253633333,13.0657683333 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2286783333,13.0657483333 ]
    },
    "properties": {
    "waste_picker_name":"Said ali"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2241783333,13.0557533333 ]
    },
    "properties": {
    "waste_picker_name":"Abbas"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2231783333,13.0677616667 ]
    },
    "properties": {
    "waste_picker_name":"Murali"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2175650000,12.9876650000 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2151316667,12.9889366667 ]
    },
    "properties": {
    "waste_picker_name":"Saroja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2209266667,12.9842550000 ]
    },
    "properties": {
    "waste_picker_name":"John"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2189050000,12.9712916667 ]
    },
    "properties": {
    "waste_picker_name":"Prabhu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2184550000,12.9882600000 ]
    },
    "properties": {
    "waste_picker_name":"Annamalai"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2185366667,12.9883950000 ]
    },
    "properties": {
    "waste_picker_name":"Hari"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2218516667,12.9842916667 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2177116667,12.9847250000 ]
    },
    "properties": {
    "waste_picker_name":"Hussain"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2225816667,12.9833716667 ]
    },
    "properties": {
    "waste_picker_name":"Thurai"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2178100000,12.9838883333 ]
    },
    "properties": {
    "waste_picker_name":"Lingam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2186500000,12.9891083333 ]
    },
    "properties": {
    "waste_picker_name":"Saravanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2235450000,13.0707050000 ]
    },
    "properties": {
    "waste_picker_name":"Munusamy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2235816667,13.0708266667 ]
    },
    "properties": {
    "waste_picker_name":"Sahayam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2240650000,13.0668666667 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2158550000,13.0750933333 ]
    },
    "properties": {
    "waste_picker_name":"Beeman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2162516667,13.0725500000 ]
    },
    "properties": {
    "waste_picker_name":"Basha"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2349600000,12.9266800000 ]
    },
    "properties": {
    "waste_picker_name":"Mogan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2337883333,12.9329633333 ]
    },
    "properties": {
    "waste_picker_name":"Ravi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321250000,12.9323216667 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2392783333,12.9548166667 ]
    },
    "properties": {
    "waste_picker_name":"Mariyappan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2393383333,12.9548333333 ]
    },
    "properties": {
    "waste_picker_name":"Balaji"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2390200000,12.9535383333 ]
    },
    "properties": {
    "waste_picker_name":"Bhavani balaji"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2389000000,12.9536116667 ]
    },
    "properties": {
    "waste_picker_name":"Geevan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2463166667,12.9648983333 ]
    },
    "properties": {
    "waste_picker_name":"Suguna"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2377333333,12.9327866667 ]
    },
    "properties": {
    "waste_picker_name":"Vignesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2376466667,12.9327883333 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2376950000,12.9327100000 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2378683333,12.9327650000 ]
    },
    "properties": {
    "waste_picker_name":"Chidambaram"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2347716667,12.9336866667 ]
    },
    "properties": {
    "waste_picker_name":"Rahim"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2486250000,12.9499133333 ]
    },
    "properties": {
    "waste_picker_name":"Palani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2409366667,12.9619466667 ]
    },
    "properties": {
    "waste_picker_name":"Lakshmi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2407683333,12.9609600000 ]
    },
    "properties": {
    "waste_picker_name":"Delhi babu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2485500000,12.9498450000 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2486050000,12.9498883333 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2485883333,12.9498000000 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2403000000,12.9484283333 ]
    },
    "properties": {
    "waste_picker_name":"Pannerselvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2403416667,12.9482450000 ]
    },
    "properties": {
    "waste_picker_name":"Ranjith Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2456750000,12.9464633333 ]
    },
    "properties": {
    "waste_picker_name":"Ramasamy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1949083333,13.0723633333 ]
    },
    "properties": {
    "waste_picker_name":"Ramesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2396500000,12.9614100000 ]
    },
    "properties": {
    "waste_picker_name":"Dhanaball"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2265216667,12.9790900000 ]
    },
    "properties": {
    "waste_picker_name":"Murukesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2436983333,12.9620966667 ]
    },
    "properties": {
    "waste_picker_name":"Sundaram"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2337750000,12.9334716667 ]
    },
    "properties": {
    "waste_picker_name":"Raman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2451950000,13.0617116667 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2487066667,13.0588133333 ]
    },
    "properties": {
    "waste_picker_name":"Jayaraman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2551733333,13.0565650000 ]
    },
    "properties": {
    "waste_picker_name":"Balan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1947566667,13.0723083333 ]
    },
    "properties": {
    "waste_picker_name":"Pachaiyappan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1948933333,13.0714550000 ]
    },
    "properties": {
    "waste_picker_name":"Kalliyappan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1949466667,13.0698316667 ]
    },
    "properties": {
    "waste_picker_name":"Sakthi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1985150000,13.0723200000 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1980383333,13.0694533333 ]
    },
    "properties": {
    "waste_picker_name":"Mogan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974000000,13.0672866667 ]
    },
    "properties": {
    "waste_picker_name":"Sasikumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1947700000,13.0705583333 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974877300,13.0700484900 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1906341200,13.0639913000 ]
    },
    "properties": {
    "waste_picker_name":"Kathiresan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1970850000,13.0680466667 ]
    },
    "properties": {
    "waste_picker_name":"Mogan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974183333,13.0690933333 ]
    },
    "properties": {
    "waste_picker_name":"Kasiyamma"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973300000,13.0692783333 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973266667,13.0692383333 ]
    },
    "properties": {
    "waste_picker_name":"Subramani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1972616667,13.0676450000 ]
    },
    "properties": {
    "waste_picker_name":"Muniyandi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973583333,13.0676166667 ]
    },
    "properties": {
    "waste_picker_name":"Rajalingam "
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973366667,13.0677300000 ]
    },
    "properties": {
    "waste_picker_name":"Muniyamma"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1982016667,13.0694783333 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1981733333,13.0694383333 ]
    },
    "properties": {
    "waste_picker_name":"Dharman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1971633333,13.0729916667 ]
    },
    "properties": {
    "waste_picker_name":"Muthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1972183333,13.0728983333 ]
    },
    "properties": {
    "waste_picker_name":"Selvaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2448633333,13.0970533333 ]
    },
    "properties": {
    "waste_picker_name":"Karunagaran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2447266667,13.0970916667 ]
    },
    "properties": {
    "waste_picker_name":"B.siva"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2448916667,13.0969516667 ]
    },
    "properties": {
    "waste_picker_name":"Andal"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2471066667,13.1016866667 ]
    },
    "properties": {
    "waste_picker_name":"Ragu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2509866667,13.1062900000 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1948845300,13.0716838300 ]
    },
    "properties": {
    "waste_picker_name":"Ramakrishnan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974146400,13.0696880400 ]
    },
    "properties": {
    "waste_picker_name":"Anga Muthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1975745100,13.0697224000 ]
    },
    "properties": {
    "waste_picker_name":"Kannan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2302008000,13.0598598900 ]
    },
    "properties": {
    "waste_picker_name":"Durai"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2300972600,13.0592878300 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2243085800,13.0639474000 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2259325500,13.0674825500 ]
    },
    "properties": {
    "waste_picker_name":"Devaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2128288000,13.0633132400 ]
    },
    "properties": {
    "waste_picker_name":"Annamalai"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2261217300,13.0677636500 ]
    },
    "properties": {
    "waste_picker_name":"Selva"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2202254600,12.9739568300 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2165858100,12.9647232600 ]
    },
    "properties": {
    "waste_picker_name":"Permal"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2168900300,12.9659690600 ]
    },
    "properties": {
    "waste_picker_name":"Sasi "
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2079316667,13.0771950000 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2199542600,13.0038110100 ]
    },
    "properties": {
    "waste_picker_name":"Raman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2115914000,13.0024331400 ]
    },
    "properties": {
    "waste_picker_name":"Elanthamilan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2118420400,13.0036317200 ]
    },
    "properties": {
    "waste_picker_name":"Nagaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2118538000,13.0036340600 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2074681200,12.9985821900 ]
    },
    "properties": {
    "waste_picker_name":"Siluvai yesu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2131974200,13.0077233700 ]
    },
    "properties": {
    "waste_picker_name":"Ramu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2240712500,12.9778541200 ]
    },
    "properties": {
    "waste_picker_name":"Peter"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2291300700,12.9808261300 ]
    },
    "properties": {
    "waste_picker_name":"Rama"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2338775300,12.9814951300 ]
    },
    "properties": {
    "waste_picker_name":"Sekar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2212152600,12.9767275700 ]
    },
    "properties": {
    "waste_picker_name":"Guna"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2211640700,12.9783208700 ]
    },
    "properties": {
    "waste_picker_name":"Sundar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2210165800,12.9793500200 ]
    },
    "properties": {
    "waste_picker_name":"Bose"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2202376600,12.9822209400 ]
    },
    "properties": {
    "waste_picker_name":"Sankar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2194161600,12.9822721700 ]
    },
    "properties": {
    "waste_picker_name":"Nagaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2191320000,12.9909690000 ]
    },
    "properties": {
    "waste_picker_name":"Rajan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2212943000,13.0068584000 ]
    },
    "properties": {
    "waste_picker_name":"Sekhar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2232645000,13.0106127000 ]
    },
    "properties": {
    "waste_picker_name":"Vadivelu."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2190600000,12.9924620000 ]
    },
    "properties": {
    "waste_picker_name":"Raja."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2212431000,12.9894915000 ]
    },
    "properties": {
    "waste_picker_name":"Suresh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2212458000,12.9807098000 ]
    },
    "properties": {
    "waste_picker_name":"Antony"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2200920000,12.9822549000 ]
    },
    "properties": {
    "waste_picker_name":"Ramu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2237338000,12.9825709000 ]
    },
    "properties": {
    "waste_picker_name":"Babu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2219857000,12.9831275000 ]
    },
    "properties": {
    "waste_picker_name":"Perumal"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2218811000,12.9826564000 ]
    },
    "properties": {
    "waste_picker_name":"Anbu."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2184764200,12.9877793300 ]
    },
    "properties": {
    "waste_picker_name":"Devarajan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2180389700,12.9849901900 ]
    },
    "properties": {
    "waste_picker_name":"Barath"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2198944200,12.9850632100 ]
    },
    "properties": {
    "waste_picker_name":"Babu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2181566900,12.9850717900 ]
    },
    "properties": {
    "waste_picker_name":"Ravi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2180661700,12.9823396900 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2072994600,12.9992013400 ]
    },
    "properties": {
    "waste_picker_name":"Raj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2220296600,13.0092959900 ]
    },
    "properties": {
    "waste_picker_name":"Rajendiren"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2073202800,12.9992524100 ]
    },
    "properties": {
    "waste_picker_name":"Hariharan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2108049200,13.0035344700 ]
    },
    "properties": {
    "waste_picker_name":"Venkatesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104896100,13.0038421800 ]
    },
    "properties": {
    "waste_picker_name":"Nagu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2155856700,13.0096229100 ]
    },
    "properties": {
    "waste_picker_name":"Deva"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2097050000,13.0747800000 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2102016667,13.0756800000 ]
    },
    "properties": {
    "waste_picker_name":"Babu "
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2128150000,13.0763166667 ]
    },
    "properties": {
    "waste_picker_name":"Muthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2136316667,13.0748566667 ]
    },
    "properties": {
    "waste_picker_name":"Murthy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2350574000,12.9338834000 ]
    },
    "properties": {
    "waste_picker_name":"Moorthy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2344121000,12.9357744000 ]
    },
    "properties": {
    "waste_picker_name":"Selvam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2333929000,12.9368675000 ]
    },
    "properties": {
    "waste_picker_name":"Ganesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2349097000,12.9402624000 ]
    },
    "properties": {
    "waste_picker_name":"Moses"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2325205000,12.9331022000 ]
    },
    "properties": {
    "waste_picker_name":"Srinivasan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2335197000,12.9335260000 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2308572400,12.9285348400 ]
    },
    "properties": {
    "waste_picker_name":"Siva"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2307046100,12.9282445600 ]
    },
    "properties": {
    "waste_picker_name":"Sevaraji"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2270950000,12.8782376000 ]
    },
    "properties": {
    "waste_picker_name":"Sundar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2271008000,12.8810666000 ]
    },
    "properties": {
    "waste_picker_name":"Sadam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2287790000,12.8888543000 ]
    },
    "properties": {
    "waste_picker_name":"Ramesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2278809000,12.9005502000 ]
    },
    "properties": {
    "waste_picker_name":"Dayalan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2277982000,12.8994413000 ]
    },
    "properties": {
    "waste_picker_name":"Selvaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2308533333,12.9130400000 ]
    },
    "properties": {
    "waste_picker_name":"Sangar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2306847900,12.9228497100 ]
    },
    "properties": {
    "waste_picker_name":"Rajesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2309488300,12.9248237600 ]
    },
    "properties": {
    "waste_picker_name":"Sundar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2470153100,12.9406234600 ]
    },
    "properties": {
    "waste_picker_name":"Sureshkumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2470987000,12.9407520000 ]
    },
    "properties": {
    "waste_picker_name":"Anbalagan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2284033333,12.9065516667 ]
    },
    "properties": {
    "waste_picker_name":"Guna"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2417661600,12.9541192300 ]
    },
    "properties": {
    "waste_picker_name":"Rajasekar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2289566667,12.9039716667 ]
    },
    "properties": {
    "waste_picker_name":"Karthik"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2367863900,12.9455699900 ]
    },
    "properties": {
    "waste_picker_name":"Durga Rao"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2416857400,12.9542340400 ]
    },
    "properties": {
    "waste_picker_name":"Elumalai( already data given)"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2297350000,12.9176533333 ]
    },
    "properties": {
    "waste_picker_name":"Ravi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2302933300,12.9199338400 ]
    },
    "properties": {
    "waste_picker_name":"Narayana"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2299756300,12.9175379800 ]
    },
    "properties": {
    "waste_picker_name":"Suresh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321412300,12.9300189600 ]
    },
    "properties": {
    "waste_picker_name":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2331244100,12.9316088600 ]
    },
    "properties": {
    "waste_picker_name":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2317744500,12.9310419700 ]
    },
    "properties": {
    "waste_picker_name":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2313755600,12.9297035100 ]
    },
    "properties": {
    "waste_picker_name":"Senu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2673750000,13.0355150000 ]
    },
    "properties": {
    "waste_picker_name":"Arumugam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2638816667,13.0283650000 ]
    },
    "properties": {
    "waste_picker_name":"Sangar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2596416667,13.0271466667 ]
    },
    "properties": {
    "waste_picker_name":"Sangar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2658915700,13.0263381100 ]
    },
    "properties": {
    "waste_picker_name":"Marimuthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2668542100,13.0349603100 ]
    },
    "properties": {
    "waste_picker_name":"Kanda swami"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2676516200,13.0359430300 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2665291500,13.0295150900 ]
    },
    "properties": {
    "waste_picker_name":"Magendiran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2665453000,13.0297169700 ]
    },
    "properties": {
    "waste_picker_name":"Bala murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2672375800,13.0326772200 ]
    },
    "properties": {
    "waste_picker_name":"Nagaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2671026200,13.0353170000 ]
    },
    "properties": {
    "waste_picker_name":"Balu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2671145200,13.0353145200 ]
    },
    "properties": {
    "waste_picker_name":"Velu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2679149300,13.0356432300 ]
    },
    "properties": {
    "waste_picker_name":"Murali"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2678064000,13.0380815200 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2670155600,13.0350618600 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2672568700,13.0356996800 ]
    },
    "properties": {
    "waste_picker_name":"Suriya"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2639232700,13.0517583200 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2681597100,13.0445748200 ]
    },
    "properties": {
    "waste_picker_name":"Saravanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2775330700,13.0326679300 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2772232400,13.0222388800 ]
    },
    "properties": {
    "waste_picker_name":"Vijakumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2679437500,13.0342308100 ]
    },
    "properties": {
    "waste_picker_name":"Sivakumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2655621400,13.0262002400 ]
    },
    "properties": {
    "waste_picker_name":"Mari"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2678343800,13.0362824700 ]
    },
    "properties": {
    "waste_picker_name":"Jaya"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2645679300,13.0337815100 ]
    },
    "properties": {
    "waste_picker_name":"Shanmugam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2662740000,13.0340566500 ]
    },
    "properties": {
    "waste_picker_name":"Velmurugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2792472900,13.0290335400 ]
    },
    "properties": {
    "waste_picker_name":"Kannan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2679251300,13.0429689500 ]
    },
    "properties": {
    "waste_picker_name":"Arumugam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2679748700,13.0429035500 ]
    },
    "properties": {
    "waste_picker_name":"Vetrivel"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2797565700,13.0396223300 ]
    },
    "properties": {
    "waste_picker_name":"Selva"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2709510800,13.0250613400 ]
    },
    "properties": {
    "waste_picker_name":"Shanmugam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2724316100,13.0278528400 ]
    },
    "properties": {
    "waste_picker_name":"Muthu kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2742816667,13.0341216667 ]
    },
    "properties": {
    "waste_picker_name":"Raman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2684940800,13.0397604800 ]
    },
    "properties": {
    "waste_picker_name":"Munivel"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708524300,13.0350436300 ]
    },
    "properties": {
    "waste_picker_name":"Murugesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2698078300,13.0358162400 ]
    },
    "properties": {
    "waste_picker_name":"Arumugam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737417700,13.0354151000 ]
    },
    "properties": {
    "waste_picker_name":"Vijayan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2692900000,13.0390300000 ]
    },
    "properties": {
    "waste_picker_name":"Murugasan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2742633333,13.0301116667 ]
    },
    "properties": {
    "waste_picker_name":"Sivaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2681575300,13.0406336900 ]
    },
    "properties": {
    "waste_picker_name":"Nagendran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2659112100,13.0327630200 ]
    },
    "properties": {
    "waste_picker_name":"Geja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2578636100,13.0276636800 ]
    },
    "properties": {
    "waste_picker_name":"Othuran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2617534200,13.0218739500 ]
    },
    "properties": {
    "waste_picker_name":"N.mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2634316667,13.0328766667 ]
    },
    "properties": {
    "waste_picker_name":"Ramanadan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2603333333,13.0293266667 ]
    },
    "properties": {
    "waste_picker_name":"Lakshman"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2579116667,13.0282550000 ]
    },
    "properties": {
    "waste_picker_name":"Vengadesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2593066667,13.0222566667 ]
    },
    "properties": {
    "waste_picker_name":"Kuppan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2677724400,13.0429353000 ]
    },
    "properties": {
    "waste_picker_name":"Saravanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2745111100,13.0422442900 ]
    },
    "properties": {
    "waste_picker_name":"Ramu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2783377900,13.0373519900 ]
    },
    "properties": {
    "waste_picker_name":"Muthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2672350000,13.0412000000 ]
    },
    "properties": {
    "waste_picker_name":"Ramesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2717550000,13.0438283333 ]
    },
    "properties": {
    "waste_picker_name":"R.raji"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2795066667,13.0369550000 ]
    },
    "properties": {
    "waste_picker_name":"Sankar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2770749200,13.0223480600 ]
    },
    "properties": {
    "waste_picker_name":"Loganathan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708671100,13.0250049400 ]
    },
    "properties": {
    "waste_picker_name":"Murugan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708020000,13.0250393500 ]
    },
    "properties": {
    "waste_picker_name":"Kumar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2547011200,13.0354137800 ]
    },
    "properties": {
    "waste_picker_name":"Kuppu samy"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2752200000,13.0257350000 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708333333,13.0249416667 ]
    },
    "properties": {
    "waste_picker_name":"Selvaraj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2706466667,13.0250100000 ]
    },
    "properties": {
    "waste_picker_name":"Easu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2565933333,13.0266950000 ]
    },
    "properties": {
    "waste_picker_name":"Babu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2563850000,13.0381266667 ]
    },
    "properties": {
    "waste_picker_name":"Venkatraman.n"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2678763300,13.0194438300 ]
    },
    "properties": {
    "waste_picker_name":"Pandian"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2798395600,13.0314340200 ]
    },
    "properties": {
    "waste_picker_name":"Jemini"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2775920600,13.0217507600 ]
    },
    "properties": {
    "waste_picker_name":"Mahendran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2604658700,13.0230475300 ]
    },
    "properties": {
    "waste_picker_name":"Ramesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2793766667,13.0270433333 ]
    },
    "properties": {
    "waste_picker_name":"Muthukrishnan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2589933333,13.0236000000 ]
    },
    "properties": {
    "waste_picker_name":"Raja"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2651733333,13.0250183333 ]
    },
    "properties": {
    "waste_picker_name":"Kaleesh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2742194500,13.0258701100 ]
    },
    "properties": {
    "waste_picker_name":"Sarasu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2739049300,13.0260463400 ]
    },
    "properties": {
    "waste_picker_name":"Mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2724326000,13.0263985700 ]
    },
    "properties": {
    "waste_picker_name":"Sami kannu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2748274700,13.0236175800 ]
    },
    "properties": {
    "waste_picker_name":"Rajendran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2765801100,13.0290562600 ]
    },
    "properties": {
    "waste_picker_name":"Masila mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2743973300,13.0243614500 ]
    },
    "properties": {
    "waste_picker_name":"Saravanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2735902700,13.0343952400 ]
    },
    "properties": {
    "waste_picker_name":".mani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2676515500,13.0358213700 ]
    },
    "properties": {
    "waste_picker_name":"Deena dayalan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2671650700,13.0355327100 ]
    },
    "properties": {
    "waste_picker_name":"Santhi"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2743610300,13.0305623500 ]
    },
    "properties": {
    "waste_picker_name":"Iyappan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2729738600,13.0343956100 ]
    },
    "properties": {
    "waste_picker_name":"Eswaran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2783806800,13.0232386200 ]
    },
    "properties": {
    "waste_picker_name":"Murugesan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2738116667,13.0329983333 ]
    },
    "properties": {
    "waste_picker_name":"Raj"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2710866667,13.0285800000 ]
    },
    "properties": {
    "waste_picker_name":"Sambath"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2710666667,13.0288400000 ]
    },
    "properties": {
    "waste_picker_name":"Rani"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2635074400,13.0270831400 ]
    },
    "properties": {
    "waste_picker_name":"Nagalingam"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2795774800,13.0318653000 ]
    },
    "properties": {
    "waste_picker_name":"Chnadran"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2631967200,13.0218624900 ]
    },
    "properties": {
    "waste_picker_name":"Gunasekar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2600633333,13.0186933333 ]
    },
    "properties": {
    "waste_picker_name":"Kalimuthu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657533333,13.0266800000 ]
    },
    "properties": {
    "waste_picker_name":"K.sangar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2707342700,13.0414980200 ]
    },
    "properties": {
    "waste_picker_name":"Narayanan"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737050300,13.0376235800 ]
    },
    "properties": {
    "waste_picker_name":"Senthil"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737450000,13.0375783333 ]
    },
    "properties": {
    "waste_picker_name":"Sathaiyya"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2267058100,13.0803287200 ]
    },
    "properties": {
    "waste_picker_name":"Murali"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2274729800,13.0755594100 ]
    },
    "properties": {
    "waste_picker_name":"Rajini"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2260050000,13.0730816667 ]
    },
    "properties": {
    "waste_picker_name":"Suresh"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2273033333,13.0705583333 ]
    },
    "properties": {
    "waste_picker_name":"Sundar"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2290348600,13.0738251800 ]
    },
    "properties": {
    "waste_picker_name":"Raji"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2233636400,13.0739710600 ]
    },
    "properties": {
    "waste_picker_name":"Hari"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2236277900,13.0707813900 ]
    },
    "properties": {
    "waste_picker_name":"Munusami"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2313016667,13.0722133333 ]
    },
    "properties": {
    "waste_picker_name":"Velu"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2233400000,13.0708000000 ]
    },
    "properties": {
    "waste_picker_name":"Sagayam"
    }
  }
]
}

export default wastepicker;