import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardImg,
  CardTitle,
  CardText,
  CardDeck,
  CardBody,
  Button } from 'reactstrap';
import { Link } from 'react-router';
import '../index.css';
import kcLogo from '../images/kc1.png';
import plastic from '../images/plasticMaterialImage.jpg';
import paper from '../images/paperMaterialImage.jpg';
import glass from '../images/glassMaterialImage.jpg';
import metal from '../images/metalMaterialImage.png';
import { hashHistory } from 'react-router';

class Materials extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron */}
        <div class="containerJumbotronMaterials">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Materials</h1>
            Paper and metal are the foundation of the recycling sector in Chennai, and are the most traded materials in the market. Plastic is a fairly new addition to the recycling landscape with collection of plastics having begun only about 20 years ago.
          </div>
        </div>
        {/* Cards */}
        <div style={{ margin: 30 }}>
          <CardDeck>
            <Card className="cardStyle">
              <CardImg top width="100%" src={plastic} alt="Card image cap" />
              <CardBody>
                <CardTitle className="cardHeadingStyle">Plastic</CardTitle>
                <CardText className="cardTextStyle">Recent addition to the recycling landscape in the city, it is seeing increasing recovery due to the widespread use of plastic in consumer products.</CardText>
                <Button
                  onClick={() => {
                    hashHistory.push("/Plastic");
                  }}
                  className="buttonTextStyle">
                  Read More
                </Button>
              </CardBody>
            </Card>
            <Card className="cardStyle">
              <CardImg top width="100%" src={paper} alt="Card image cap" />
              <CardBody>
                <CardTitle className="cardHeadingStyle">Paper</CardTitle>
                <CardText className="cardTextStyle">Foundational material that is the highest traded within Chennai’s recycling sector; it is a high-volume material handled by a majority of informal sector stakeholders.</CardText>
                <Button
                  className="buttonTextStyle"
                  onClick={() => {
                    hashHistory.push("/Paper");
                  }}
                >
                  Read More
                </Button>
              </CardBody>
            </Card>
            <Card className="cardStyle">
              <CardImg top width="100%" src={glass} alt="Card image cap" />
              <CardBody>
                <CardTitle className="cardHeadingStyle">Glass</CardTitle>
                <CardText className="cardTextStyle">Low-margin material primarily procured by the robust alcoholic beverages industry in nearby Pondicherry and elsewhere in the state of Tamil Nadu.</CardText>
                <Button
                  className="buttonTextStyle"
                  onClick={() => {
                    hashHistory.push("/Glass");
                  }}
                >
                    Read More
                  </Button>
              </CardBody>
            </Card>
            <Card className="cardStyle">
              <CardImg top width="100%" src={metal} alt="Card image cap" />
              <CardBody>
                <CardTitle className="cardHeadingStyle">Metal</CardTitle>
                <CardText className="cardTextStyle">Long-standing market with high profits margins and high recovery rates; it is on the down trend as the consumer market for metals is eroded by plastic and composite materials.</CardText>
                <Button
                  className="buttonTextStyle"
                  onClick={() => {
                    hashHistory.push("/Metal");
                  }}
                >
                  Read More
                </Button>
              </CardBody>
            </Card>
          </CardDeck>
        </div>
      </div>
    )
  }
}

export default Materials;
