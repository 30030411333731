import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';
import { Link } from 'react-router';
import kcLogo from '../images/kc1.png';
import marker from '../images/marker.svg';
import wastepicker from '../images/wastepicker.jpg';
import kabadiwalla from '../images/kabadiwalla.jpg';
import middleman from '../images/middleman.jpg';
import processor from '../images/processor.jpg';
import googleMapStyle from './common/googleMapStyle';
import {Map, InfoWindow, Marker, GoogleApiWrapper, google} from 'google-maps-react';
import '../index.css';
import L1points from './common/L1points';
import L2points from './common/L2points';
import L0points from './common/L0points';


class Stakeholders extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false, selectedPlaceL0: {}, selectedPlaceL1: {}, selectedPlaceL2: {}, activeMarker: {}, showingInfoWindow: false
    };
  }
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  
  onMarkerClickL0 = (props, marker, e) => {
    this.setState({
      selectedPlaceL0: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  
  onMarkerClickL1 = (props, marker, e) => {
    this.setState({
      selectedPlaceL1: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
  
  onMarkerClickL2 = (props, marker, e) => {
    this.setState({
      selectedPlaceL2: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
  }
    
    
  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
  };
  
  mapMarkerRenderL0() {
    if (L0points.features.length !== 0) {
      return (
        L0points.features.map((point,index) => (
          <Marker
            key={index}
            title={point.properties.waste_picker_name}
            name={point.properties.waste_picker_name}
            position={{lat: Number(point.geometry.coordinates[1]), lng: Number(point.geometry.coordinates[0])}} 
            options={{icon: marker }}
            onClick={this.onMarkerClickL0}
          />
        ))
      )
    } 
  }
  
  mapMarkerRenderL1() {
    if (L1points.features.length !== 0) {
      return (
        L1points.features.map((point,index) => (
          <Marker
            key={index}
            title={point.properties.enterprise_name}
            name={point.properties.enterprise_name}
            position={{lat: Number(point.geometry.coordinates[1]), lng: Number(point.geometry.coordinates[0])}} 
            options={{icon: marker }}
            onClick={this.onMarkerClickL1}
          />
        ))
      )
    } 
  }
  
  mapMarkerRenderL2() {
    if (L2points.features.length !== 0) {
      return (
        L2points.features.map((point,index) => (
          <Marker
            key={index}
            title={point.properties.shop_name}
            name={point.properties.shop_name}
            position={{lat: Number(point.geometry.coordinates[1]), lng: Number(point.geometry.coordinates[0])}} 
            options={{icon: marker }}
            onClick={this.onMarkerClickL2}
          />
        ))
      )
    } 
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronStakeholder">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Stakeholders</h1>
            The informal waste sector in Chennai consists of a large array of stakeholders that are involved in the recovery and recycling of scrap material. Kabadiwalla Connect has created a classification of these stakeholders based on their functional role within the ecosystem.
          </div>
        </div>
        {/* Stakeholders Description */}
        <div class="descriptionViewStyle">
          {/* L0 */}
          <div style={{ marginBottom: 70 }}>
            <p className="descriptionStyle">
              <h3>L0 Aggregator</h3> 
              Level 0 or L0 stakeholders are informal sector workers who, typically, do not have a means of transportation and operate with zero or minimal input cost. These stakeholders primarily collect scrap material from roadsides, landfills, and, in the case of those owning a transport vehicle, from households.
            </p>
            {/* L0 Image and Map */}
            <div className="descriptionStyle" style={{ marginTop: 35, marginBottom: 35 }}>
              <img src={wastepicker} alt="Informal Sector - Waste Picker" class="img-responsive" height={'auto'} width={'100%'} className="center"  />
              <div style={{ height: 450, width: 'auto', position: 'relative', marginTop: 35 }}>
                <Map 
                  google={this.props.google}
                  streetViewControl= {false}
                  scrollwheel={true}
                  zoom={11} 
                  minZoom={11}
                  onClick={this.onMapClicked}
                  styles={googleMapStyle}
                  style={{ height: 450, width: '100%' }}
                  mapTypeControl={false} 
                  initialCenter={{
                    lat: 13.061630,
                    lng: 80.246602
                  }}
                >
                  {this.mapMarkerRenderL0()}
                  <InfoWindow 
                    visible={this.state.showingInfoWindow}
                    marker={this.state.activeMarker}
                  >
                    <div>
                      <span class="mapInfoWindowTextStyle">{this.state.selectedPlaceL0.title}</span>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </div>
          </div>
          {/* L1 */}
          <div style={{ marginBottom: 70 }}>
            <p className="descriptionStyle">
              <h3>L1 Aggregator</h3> 
              Level 1 or L1 stakeholders are informal sector workers who have access to storage space and aggregate material from L0 stakeholders and residents. They do minimal or no processing of the material and high volume trading is their primary means of value creation and income generation.
            </p>
            {/* L1 Image and Map */}
            <div className="descriptionStyle" style={{ marginTop: 35, marginBottom: 35 }}>
              <img src={kabadiwalla} alt="Informal Sector - Kabadiwalla" class="img-responsive" height={'auto'} width={'100%'} className="center"  />
              <div style={{ height: 450, width: 'auto', position: 'relative', marginTop: 35 }}>
                <Map 
                  google={this.props.google}
                  streetViewControl= {false}
                  scrollwheel={true}
                  zoom={11} 
                  minZoom={11}
                  onClick={this.onMapClicked}
                  styles={googleMapStyle}
                  style={{ height: 450, width: '100%' }}
                  mapTypeControl={false} 
                  initialCenter={{
                    lat: 13.061630,
                    lng: 80.246602
                  }}
                >
                  {this.mapMarkerRenderL1()}
                  <InfoWindow 
                    visible={this.state.showingInfoWindow}
                    marker={this.state.activeMarker}
                  >
                    <div>
                      <span class="mapInfoWindowTextStyle">{this.state.selectedPlaceL1.title}</span>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </div>
          </div>
          {/* L2 */}
          <div style={{ marginBottom: 70 }}>
            <p className="descriptionStyle">
              <h3>L2 Aggregator</h3> 
              Level 2 or L2 stakeholders are informal sector workers who have access to storage space which is many times larger when compared to an L1 stakeholder and aggregate material directly from L1s and other commercial sources. They typically specialize in a single super category of material, either paper, plastic, glass, or metal, and employ a large number of people to undertake segregation and rudimentary processing. Some L2 stakeholders also undertake further processing such as pelletization to produce secondary raw materials as well.
            </p>
            {/* L2 Image and Map  */}
            <div className="descriptionStyle" style={{ marginTop: 35, marginBottom: 35 }}>
              <img src={middleman} alt="Informal Sector - Middleman" class="img-responsive" height={'auto'} width={'100%'} className="center"  />
              <div style={{ height: 450, width: 'auto', position: 'relative', marginTop: 35 }}>
                <Map 
                  google={this.props.google}
                  streetViewControl= {false}
                  scrollwheel={true}
                  zoom={11} 
                  minZoom={11}
                  onClick={this.onMapClicked}
                  styles={googleMapStyle}
                  style={{ height: 450, width: '100%' }}
                  mapTypeControl={false} 
                  initialCenter={{
                    lat: 13.061630,
                    lng: 80.246602
                  }}
                >
                  {this.mapMarkerRenderL2()}
                  <InfoWindow 
                    visible={this.state.showingInfoWindow}
                    marker={this.state.activeMarker}
                  >
                    <div>
                      <span class="mapInfoWindowTextStyle">{this.state.selectedPlaceL2.title}</span>
                    </div>
                  </InfoWindow>
                </Map>
              </div>
            </div>
          </div>
          {/* Processor */}
          <p className="descriptionStyle">
            <h3>Processor</h3> 
            Processors are stakeholders with the primary function of producing secondary raw material for the manufacturing industry. Processors can be of varying sizes but are largely unorganized businesses that buy specific grades and types of post-consumer scrap material from L1s and L2s.
          </p>
          <div className="descriptionStyle" style={{ marginTop: 35, marginBottom: 35 }}>
            <img src={processor} alt="Informal Sector - Processor" class="img-responsive" height={'auto'} width={'100%'} className="center"  />
          </div>
        </div>    
        {/* Footer */}
        <div class="footerTextStyle" style={{ height: 100, width: null, backgroundColor: '#575757', paddingTop: 35 }}>
          <a class ="iconStyle" target="_blank" rel="noopener noreferrer" href="https://twitter.com/kabadiconnect">
            <i class="fab fa-twitter"></i>
          </a>
          <a class ="iconStyle" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/KabadiwallaConnect/">
            <i class="fab fa-facebook-f"></i>
          </a>
          <br></br>
          Kabadiwalla Connect 2018
        </div>
      </div>
    )
  }
}

export default GoogleApiWrapper({
  apiKey: ('AIzaSyAg7vS8VhWiqP_wZoC2-SLRZyinttgjzaY')
})(Stakeholders)
