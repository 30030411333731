var kabadiwallas = {
   "type": "FeatureCollection",
   "features": [
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2512055600,12.7913694100 ]
    },
    "properties": {
    "enterprise_name":"K.M.Jeeva",
    "owner_name":"Kumar",
    "contact_number":"9962456250"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2409956000,12.8494196000 ]
    },
    "properties": {
    "enterprise_name":"Anantha vinyagam waste Papper mart",
    "owner_name":"Senthil bopathy",
    "contact_number":"9080188808"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2410726000,12.8496058000 ]
    },
    "properties": {
    "enterprise_name":"Sri Sherman Papper mart",
    "owner_name":"Balan",
    "contact_number":"9894067790"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2428268000,12.8511407000 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper",
    "owner_name":"Koilraj",
    "contact_number":"9444167189"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2414086000,12.8535766000 ]
    },
    "properties": {
    "enterprise_name":"Punitha inaciyar traders",
    "owner_name":"Michal",
    "contact_number":"9940305402"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2420641000,12.8550079000 ]
    },
    "properties": {
    "enterprise_name":"Vas cleandan Papper mart",
    "owner_name":"Durai",
    "contact_number":"9840128793"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432298500,12.8678488700 ]
    },
    "properties": {
    "enterprise_name":"Menaka waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9003546310"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2438669000,12.8773116900 ]
    },
    "properties": {
    "enterprise_name":"Bn waste",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2438684300,12.8773131600 ]
    },
    "properties": {
    "enterprise_name":"Nathiya irumbubu kadi",
    "owner_name":"Kaliya perumal",
    "contact_number":"9962468031"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2451660900,12.8839711100 ]
    },
    "properties": {
    "enterprise_name":"Manika raj",
    "owner_name":"Manik raj",
    "contact_number":"9962645032"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2454775100,12.8845298900 ]
    },
    "properties": {
    "enterprise_name":"Sathya waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9962772838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2455596100,12.8906826600 ]
    },
    "properties": {
    "enterprise_name":"Poovi kumar",
    "owner_name":"Kumar",
    "contact_number":"9841600479"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2457751600,12.8913391500 ]
    },
    "properties": {
    "enterprise_name":"Selvam",
    "owner_name":"Selvam",
    "contact_number":"9087867595"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2430605000,12.8983699700 ]
    },
    "properties": {
    "enterprise_name":"Selvam",
    "owner_name":"Selvam",
    "contact_number":"8680087967"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2329448800,12.9031514000 ]
    },
    "properties": {
    "enterprise_name":"Isakki Amman waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9840344059"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2328740300,12.9044274100 ]
    },
    "properties": {
    "enterprise_name":"Suambu waste paper mart",
    "owner_name":"S. Parthiban",
    "contact_number":"9962124411"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2293506900,12.9120528900 ]
    },
    "properties": {
    "enterprise_name":"Gk waste paper mart",
    "owner_name":"Yuvraj",
    "contact_number":"8122770878"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321865700,12.9127578500 ]
    },
    "properties": {
    "enterprise_name":"Mrs waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9444804425"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2289569300,12.9136772500 ]
    },
    "properties": {
    "enterprise_name":"Sri selva vinayaga traders",
    "owner_name":"Jaya prathap",
    "contact_number":"9884162935"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2289569300,12.9136772500 ]
    },
    "properties": {
    "enterprise_name":"Sri selva vinayaga traders",
    "owner_name":"Jaya prathap",
    "contact_number":"9884162935"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321231200,12.9137041700 ]
    },
    "properties": {
    "enterprise_name":"PMS waste paper mart",
    "owner_name":"Suambu lingam",
    "contact_number":"994593386"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2292996700,12.9150535100 ]
    },
    "properties": {
    "enterprise_name":"Jesu waste paper mart",
    "owner_name":"Jesu",
    "contact_number":"6380995952"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2292996700,12.9150535100 ]
    },
    "properties": {
    "enterprise_name":"Jesu waste paper mart",
    "owner_name":"Jesu",
    "contact_number":"6380995952"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321196600,12.9152472600 ]
    },
    "properties": {
    "enterprise_name":"Balan waste paper mart",
    "owner_name":"Balan",
    "contact_number":"9444607910"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2321196600,12.9152472600 ]
    },
    "properties": {
    "enterprise_name":"Balan waste paper mart",
    "owner_name":"Balan",
    "contact_number":"9444607910"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2294063500,12.9157829400 ]
    },
    "properties": {
    "enterprise_name":"Balan traders",
    "owner_name":"A. Arul raj",
    "contact_number":"9788969788"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2333545400,12.9183337700 ]
    },
    "properties": {
    "enterprise_name":"Sp waste paper mart",
    "owner_name":"Susai ponraj",
    "contact_number":"9884122982"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2505274600,12.9185044800 ]
    },
    "properties": {
    "enterprise_name":"Ward no 196\nKayalan kadi",
    "owner_name":"",
    "contact_number":"9790953890"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511460400,12.9216788600 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"Sarvanan",
    "contact_number":"9840486603"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511529700,12.9221464700 ]
    },
    "properties": {
    "enterprise_name":"Siva sakrhi waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9943147075"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2510667200,12.9227969200 ]
    },
    "properties": {
    "enterprise_name":"N. Iyyappan waste paper mart\nw no 196",
    "owner_name":"N.iyyapan",
    "contact_number":"9176766089"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974476900,12.9253624500 ]
    },
    "properties": {
    "enterprise_name":"Kr waste mart",
    "owner_name":"Kangaraj",
    "contact_number":"9597311898"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2037312200,12.9253636700 ]
    },
    "properties": {
    "enterprise_name":"Rajan waster paper mart",
    "owner_name":"Rajan",
    "contact_number":"9841856604"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2050999300,12.9255643700 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper",
    "owner_name":"Mani",
    "contact_number":"9710643574"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2355683333,12.9258816667 ]
    },
    "properties": {
    "enterprise_name":"Balan treaters",
    "owner_name":"Simson",
    "contact_number":"9884931419"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2345233333,12.9259350000 ]
    },
    "properties": {
    "enterprise_name":"S M paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2353444000,12.9259546100 ]
    },
    "properties": {
    "enterprise_name":"Balan waster mart",
    "owner_name":"Raja",
    "contact_number":"7395901544"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2353444000,12.9259546100 ]
    },
    "properties": {
    "enterprise_name":"Balan waster mart",
    "owner_name":"Raja",
    "contact_number":"7395901544"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2029420500,12.9260864200 ]
    },
    "properties": {
    "enterprise_name":"Jrs waste paper mart",
    "owner_name":"Rajendra prasad",
    "contact_number":"9884830449"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2345433333,12.9261000000 ]
    },
    "properties": {
    "enterprise_name":"SM erumpu katai",
    "owner_name":"Jestin segar",
    "contact_number":"9791174481"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2332950000,12.9261783333 ]
    },
    "properties": {
    "enterprise_name":"Andhoni raj",
    "owner_name":"",
    "contact_number":"7397283398"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1948936800,12.9268255800 ]
    },
    "properties": {
    "enterprise_name":"Psr waste mart",
    "owner_name":"",
    "contact_number":"9094670080"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1964604300,12.9273358500 ]
    },
    "properties": {
    "enterprise_name":"Jothi",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2076133900,12.9278298300 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste mart,Parasuram",
    "owner_name":"Parasuram",
    "contact_number":"8190913461"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2495056100,12.9293688500 ]
    },
    "properties": {
    "enterprise_name":"Subiksha waste paper mart",
    "owner_name":"Mani raj",
    "contact_number":"9941633578"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2047739100,12.9294799500 ]
    },
    "properties": {
    "enterprise_name":"N balashivanesan waste paper mart",
    "owner_name":"",
    "contact_number":"9940435750"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2047763400,12.9295444400 ]
    },
    "properties": {
    "enterprise_name":"Bala sivanesh waste paper mart",
    "owner_name":"",
    "contact_number":"9940435750"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2366533333,12.9297900000 ]
    },
    "properties": {
    "enterprise_name":"J.J.J mettal",
    "owner_name":"Ravi",
    "contact_number":"9941440525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2009961900,12.9302243900 ]
    },
    "properties": {
    "enterprise_name":"Thirumurugan traders",
    "owner_name":"Arumugam",
    "contact_number":"9094997932"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2079616667,12.9307633333 ]
    },
    "properties": {
    "enterprise_name":"Ayyanar waste paper mart",
    "owner_name":"",
    "contact_number":"7448642247"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2352150000,12.9310083333 ]
    },
    "properties": {
    "enterprise_name":"Vijalakshmi",
    "owner_name":"Vijalakshmi",
    "contact_number":"9094673471"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2352713700,12.9310560900 ]
    },
    "properties": {
    "enterprise_name":"Sri Lakshmi waste paper mart",
    "owner_name":"Muthu kumar",
    "contact_number":"9941266301"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2351366667,12.9311533333 ]
    },
    "properties": {
    "enterprise_name":"Muthuthamman west paper mart",
    "owner_name":"",
    "contact_number":"8939057336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2332800700,12.9312133600 ]
    },
    "properties": {
    "enterprise_name":"Manikam waste mart",
    "owner_name":"Manikam",
    "contact_number":"9840229748"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2350563500,12.9312401600 ]
    },
    "properties": {
    "enterprise_name":"Sri mutharamman waste paper mart\n5/57, tiruvallur street\nokkiyampet",
    "owner_name":"Saravanan",
    "contact_number":"7550004553"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2334183333,12.9312466667 ]
    },
    "properties": {
    "enterprise_name":"Manickam,",
    "owner_name":"",
    "contact_number":"9840229748"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2346033333,12.9313733333 ]
    },
    "properties": {
    "enterprise_name":"Muthammal West paper mart",
    "owner_name":"",
    "contact_number":"9094673471"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2346101000,12.9316018000 ]
    },
    "properties": {
    "enterprise_name":"Muthu Kumar waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9094673471"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2117283333,12.9323116667 ]
    },
    "properties": {
    "enterprise_name":"Sree muthuraman waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2377900000,12.9325233333 ]
    },
    "properties": {
    "enterprise_name":"Guru waste paper",
    "owner_name":"Raja",
    "contact_number":"7373131810"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2377757100,12.9325694400 ]
    },
    "properties": {
    "enterprise_name":"Guru raja",
    "owner_name":"Guru raja",
    "contact_number":"7401719914"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2091016667,12.9330883333 ]
    },
    "properties": {
    "enterprise_name":"Sree mutharamman waste paper mart",
    "owner_name":"",
    "contact_number":"9380771293"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2477466667,12.9345350000 ]
    },
    "properties": {
    "enterprise_name":"Maranthalai madassmy west paper mart",
    "owner_name":"Sundaraval",
    "contact_number":"7299322941"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2380066667,12.9352633333 ]
    },
    "properties": {
    "enterprise_name":"Maheshwari/gunaselan paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2333882800,12.9352730500 ]
    },
    "properties": {
    "enterprise_name":"Mageswari traders",
    "owner_name":"Madha muthu",
    "contact_number":"9841358744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2334637000,12.9354005300 ]
    },
    "properties": {
    "enterprise_name":"Mageswari waste paper mart",
    "owner_name":"Ponnama",
    "contact_number":"9741358744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2005650000,12.9356983333 ]
    },
    "properties": {
    "enterprise_name":"Pavithra waste paper mart",
    "owner_name":"S ashok",
    "contact_number":"7401545872"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2115735000,12.9362653000 ]
    },
    "properties": {
    "enterprise_name":"Pavithra waste paper mart",
    "owner_name":"Sakthivel",
    "contact_number":"9444844739"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2358883333,12.9363266667 ]
    },
    "properties": {
    "enterprise_name":"Tharani wast paper mart/vinayakar wast paper mart",
    "owner_name":"Siva",
    "contact_number":"9884636724"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2394000000,12.9365150000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi treaters",
    "owner_name":"Mani",
    "contact_number":"8438257664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2523698100,12.9367474500 ]
    },
    "properties": {
    "enterprise_name":"Raja",
    "owner_name":"Raja",
    "contact_number":"99415678901"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2045238600,12.9369304900 ]
    },
    "properties": {
    "enterprise_name":"Sumathi waste mart",
    "owner_name":"Sivakumar",
    "contact_number":"22461645"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2482533333,12.9373033333 ]
    },
    "properties": {
    "enterprise_name":"Bathmanathan west paper Mart",
    "owner_name":"",
    "contact_number":"9094774189"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2363366667,12.9373616667 ]
    },
    "properties": {
    "enterprise_name":"Jeeya waste paper mart",
    "owner_name":"Mujub rahman",
    "contact_number":"9176715870"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2356400000,12.9377866667 ]
    },
    "properties": {
    "enterprise_name":"Visithran west paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2407833333,12.9381166667 ]
    },
    "properties": {
    "enterprise_name":"Veni west paper",
    "owner_name":"Krishnaveni",
    "contact_number":"9962129273"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2407472300,12.9381795900 ]
    },
    "properties": {
    "enterprise_name":"Somu",
    "owner_name":"Somu",
    "contact_number":"9176185879"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2513640600,12.9389173700 ]
    },
    "properties": {
    "enterprise_name":"Velan",
    "owner_name":"",
    "contact_number":"8124406485"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2359650000,12.9390433333 ]
    },
    "properties": {
    "enterprise_name":"Pandiyan west paper mart",
    "owner_name":"",
    "contact_number":"9444018389"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2492000000,12.9395716667 ]
    },
    "properties": {
    "enterprise_name":"Steffen west paper mart",
    "owner_name":"",
    "contact_number":"9962834142"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2483516667,12.9398183333 ]
    },
    "properties": {
    "enterprise_name":"Sree nattar Devi west paper mart",
    "owner_name":"Sathivel",
    "contact_number":"7845298318"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2469170800,12.9405653600 ]
    },
    "properties": {
    "enterprise_name":"Mkr store",
    "owner_name":"",
    "contact_number":"9940538123"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2468983333,12.9406016667 ]
    },
    "properties": {
    "enterprise_name":"MSR west paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2478183333,12.9407016667 ]
    },
    "properties": {
    "enterprise_name":"Muthalamman west paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2477597900,12.9407323700 ]
    },
    "properties": {
    "enterprise_name":"Mari",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2071883333,12.9409166667 ]
    },
    "properties": {
    "enterprise_name":"Sree suyambu lingam traders",
    "owner_name":"Ilamkathir",
    "contact_number":"9941085185"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2361728500,12.9417393900 ]
    },
    "properties": {
    "enterprise_name":"Ramesh",
    "owner_name":"Ramesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2100600000,12.9418466667 ]
    },
    "properties": {
    "enterprise_name":"Punitha anthoniyar waste paper mart",
    "owner_name":"",
    "contact_number":"9841369158"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533501200,12.9420645700 ]
    },
    "properties": {
    "enterprise_name":"Rathna traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2084033333,12.9425366667 ]
    },
    "properties": {
    "enterprise_name":"Marry waste paper mart",
    "owner_name":"James",
    "contact_number":"7667701621"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2368461100,12.9432674500 ]
    },
    "properties": {
    "enterprise_name":"Punitha innaciar traders",
    "owner_name":"G. Chinraj",
    "contact_number":"9940355057"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2102566667,12.9433300000 ]
    },
    "properties": {
    "enterprise_name":"Darshini waste paper mart",
    "owner_name":"C dhanashign",
    "contact_number":"9842983105"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2365001700,12.9434445500 ]
    },
    "properties": {
    "enterprise_name":"Sebastian",
    "owner_name":"Sebastian",
    "contact_number":"9003187224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2365016667,12.9435066667 ]
    },
    "properties": {
    "enterprise_name":"Sps paper mart",
    "owner_name":"",
    "contact_number":"9444487224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2488151700,12.9449394000 ]
    },
    "properties": {
    "enterprise_name":"Siva subramaniyan",
    "owner_name":"Siva subramaniyan",
    "contact_number":"9380820613"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2470333333,12.9461783333 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Balakrishnan",
    "contact_number":"9710499857"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2471813400,12.9462112000 ]
    },
    "properties": {
    "enterprise_name":"Kumar",
    "owner_name":"Kumar",
    "contact_number":"9710499857"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2341736500,12.9482618200 ]
    },
    "properties": {
    "enterprise_name":"Vinayagar traders",
    "owner_name":"S. Kumar",
    "contact_number":"9962920579"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2486165900,12.9484212200 ]
    },
    "properties": {
    "enterprise_name":"Laxmi",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2341500000,12.9484433333 ]
    },
    "properties": {
    "enterprise_name":"Sivakami treads",
    "owner_name":"Masanam",
    "contact_number":"9962820579"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533174600,12.9493161100 ]
    },
    "properties": {
    "enterprise_name":"Papa waste paper mart",
    "owner_name":"Anandhabalan",
    "contact_number":"8526252155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2531311100,12.9493801000 ]
    },
    "properties": {
    "enterprise_name":"Papa waste paper mart",
    "owner_name":"Arjun ",
    "contact_number":"8526252155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2375768100,12.9494148600 ]
    },
    "properties": {
    "enterprise_name":"Sebastian",
    "owner_name":"Sebastian",
    "contact_number":"9003187224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2375566667,12.9494466667 ]
    },
    "properties": {
    "enterprise_name":"S.m ganammal traders",
    "owner_name":"S.p.sebastin",
    "contact_number":"9444487224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2480283333,12.9495133333 ]
    },
    "properties": {
    "enterprise_name":"Om sakthi west paper mart",
    "owner_name":"Sakthi vinayagam",
    "contact_number":"9444771930"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2544880000,12.9496252100 ]
    },
    "properties": {
    "enterprise_name":"Raja waste paper mart",
    "owner_name":"Anthony",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2488279500,12.9496650600 ]
    },
    "properties": {
    "enterprise_name":"Madha traders",
    "owner_name":"Mical selvan",
    "contact_number":"9884883838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2480716667,12.9497133333 ]
    },
    "properties": {
    "enterprise_name":"M.poovai Steffen west paper mart",
    "owner_name":"Steffen",
    "contact_number":"9962834142"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2542404200,12.9497845600 ]
    },
    "properties": {
    "enterprise_name":"Raja waste Paper mart",
    "owner_name":"Raja",
    "contact_number":"9176133280"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2488933333,12.9498866667 ]
    },
    "properties": {
    "enterprise_name":"Malai mazhai madha treaders",
    "owner_name":"",
    "contact_number":"9884883838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559346300,12.9500215100 ]
    },
    "properties": {
    "enterprise_name":"Iyya waste paper mart",
    "owner_name":"",
    "contact_number":"9962675171"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2456566667,12.9500466667 ]
    },
    "properties": {
    "enterprise_name":"Punitha andhoniyar erumpu katai",
    "owner_name":"Thangaraj",
    "contact_number":"9444811551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559448500,12.9500673900 ]
    },
    "properties": {
    "enterprise_name":"Ayya waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9962675171"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1989952400,12.9501529100 ]
    },
    "properties": {
    "enterprise_name":"Anthony waste paper mart",
    "owner_name":"",
    "contact_number":"9941802584"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2341466667,12.9502850000 ]
    },
    "properties": {
    "enterprise_name":"Myckel west paper mart",
    "owner_name":"",
    "contact_number":"9962305516"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2342185400,12.9502868700 ]
    },
    "properties": {
    "enterprise_name":"Michel irumbu kadai",
    "owner_name":"Michel srinivasan",
    "contact_number":"9962305516"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2449366667,12.9503183333 ]
    },
    "properties": {
    "enterprise_name":"Mahalakshmi wast Paper mart",
    "owner_name":"Ravi",
    "contact_number":"9176477150"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2525389000,12.9510707000 ]
    },
    "properties": {
    "enterprise_name":"Laldhurai waste paper mart",
    "owner_name":"Rajadhurai",
    "contact_number":"8939066773"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2410404200,12.9517235000 ]
    },
    "properties": {
    "enterprise_name":"Dharman",
    "owner_name":"Dharman",
    "contact_number":"9444426606"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2408700000,12.9518550000 ]
    },
    "properties": {
    "enterprise_name":"Dharman",
    "owner_name":"Dharman",
    "contact_number":"9444426606"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2386904400,12.9518708300 ]
    },
    "properties": {
    "enterprise_name":"Pechiammal",
    "owner_name":"Pechiammal",
    "contact_number":"9176178073"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2406900000,12.9529266667 ]
    },
    "properties": {
    "enterprise_name":"Santhosh wast paper mart",
    "owner_name":"",
    "contact_number":"9884582200"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1995177800,12.9532910500 ]
    },
    "properties": {
    "enterprise_name":"Venkeshvara waste paper mart",
    "owner_name":"Gunasekar",
    "contact_number":"8939098400"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2116165200,12.9540784800 ]
    },
    "properties": {
    "enterprise_name":"Maladhi waste paper mart",
    "owner_name":"",
    "contact_number":"9710488511"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2607581600,12.9541044600 ]
    },
    "properties": {
    "enterprise_name":"New waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2414326300,12.9542230200 ]
    },
    "properties": {
    "enterprise_name":"Magesh traders",
    "owner_name":"Magesh kumar",
    "contact_number":"9941068272"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2416016667,12.9543483333 ]
    },
    "properties": {
    "enterprise_name":"Smk treaters",
    "owner_name":"",
    "contact_number":"9941008272"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2380983333,12.9545800000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman west paper mart",
    "owner_name":"Krishnasamy",
    "contact_number":"9962188518"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2552855000,12.9546638700 ]
    },
    "properties": {
    "enterprise_name":"Papathiammal traders",
    "owner_name":"Kumar",
    "contact_number":"9831649318"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2357366667,12.9547483333 ]
    },
    "properties": {
    "enterprise_name":"S.M.A Enterprise",
    "owner_name":"",
    "contact_number":"9884060730"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2552193300,12.9547881000 ]
    },
    "properties": {
    "enterprise_name":"Papadhi Amman traders",
    "owner_name":"Thirumalai babu",
    "contact_number":"9841649518"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2347733333,12.9548666667 ]
    },
    "properties": {
    "enterprise_name":"AS treaders",
    "owner_name":"Arokiyasamy",
    "contact_number":"8124885599"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2551379200,12.9549865600 ]
    },
    "properties": {
    "enterprise_name":"Laldhurai",
    "owner_name":"Rajadhurai",
    "contact_number":"8939066773"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2552036400,12.9550377600 ]
    },
    "properties": {
    "enterprise_name":"Lal durai",
    "owner_name":"Durai",
    "contact_number":"8438224650"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2365700000,12.9552983333 ]
    },
    "properties": {
    "enterprise_name":"Sri Murugan west paper",
    "owner_name":"Senguttuvan",
    "contact_number":"9444565961"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1957566400,12.9557503900 ]
    },
    "properties": {
    "enterprise_name":"Sri Gulasai mutharamman waste paper mart",
    "owner_name":"Ramanadhan",
    "contact_number":"9941572922"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2397283333,12.9559033333 ]
    },
    "properties": {
    "enterprise_name":"Thru kani west paper mart",
    "owner_name":"",
    "contact_number":"9444411828"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1961709200,12.9565014700 ]
    },
    "properties": {
    "enterprise_name":"Vinidha waste paper mart",
    "owner_name":"Arulraj",
    "contact_number":"8124397714"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1942651100,12.9570513200 ]
    },
    "properties": {
    "enterprise_name":"Jarji",
    "owner_name":"Jorji",
    "contact_number":"8807495948"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1991445000,12.9574939400 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Jayakumar",
    "contact_number":"9176495641"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1925334600,12.9577297000 ]
    },
    "properties": {
    "enterprise_name":"Santhi waste paper mart",
    "owner_name":"Sripan",
    "contact_number":"9841254572"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1993552800,12.9585390700 ]
    },
    "properties": {
    "enterprise_name":"Reepin waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1986345100,12.9590091100 ]
    },
    "properties": {
    "enterprise_name":"Sudalamani waste paper",
    "owner_name":"Sudalamani",
    "contact_number":"9442374284"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2360366667,12.9590200000 ]
    },
    "properties": {
    "enterprise_name":"Madhu midha waste paper Mart",
    "owner_name":"",
    "contact_number":"8056142249"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2377333333,12.9592883333 ]
    },
    "properties": {
    "enterprise_name":"Gnanapragas waste paper mart\nKishor waste paper Mart",
    "owner_name":"Anthony pichayamma",
    "contact_number":"9655882673"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2508879000,12.9606057800 ]
    },
    "properties": {
    "enterprise_name":"Oom sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"9444769105"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1836245700,12.9606702300 ]
    },
    "properties": {
    "enterprise_name":"M I  STEELS",
    "owner_name":"",
    "contact_number":"984060223"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1978884600,12.9614881400 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"Indragandhi",
    "contact_number":"9094131882"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2581666667,12.9614900000 ]
    },
    "properties": {
    "enterprise_name":"Parvathy traders",
    "owner_name":"Manna",
    "contact_number":"24492619"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511201500,12.9619882400 ]
    },
    "properties": {
    "enterprise_name":"Sri Siva sakthi waste paper mart",
    "owner_name":"Thirumaniselvan",
    "contact_number":"9884028551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1987287600,12.9621731700 ]
    },
    "properties": {
    "enterprise_name":"Kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"9841965989"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2565266667,12.9626266667 ]
    },
    "properties": {
    "enterprise_name":"Mr waste paper mart",
    "owner_name":"Rhaguman",
    "contact_number":"9962270375"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2409866667,12.9630700000 ]
    },
    "properties": {
    "enterprise_name":"Muthuvel waste paper mart",
    "owner_name":"",
    "contact_number":"24962212"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2155743300,12.9640362800 ]
    },
    "properties": {
    "enterprise_name":"Sharini waste paper mart",
    "owner_name":"Sivalingam",
    "contact_number":"9543832632"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2545057300,12.9642439200 ]
    },
    "properties": {
    "enterprise_name":"Stallin waste paper mart",
    "owner_name":"",
    "contact_number":"9884436367"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2534345200,12.9645583700 ]
    },
    "properties": {
    "enterprise_name":"Rajalaxmi waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"9884188524"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2114010600,12.9647665500 ]
    },
    "properties": {
    "enterprise_name":"Anthony waste paper mart",
    "owner_name":"Anthony",
    "contact_number":"9942897971"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2517825100,12.9647687100 ]
    },
    "properties": {
    "enterprise_name":"Guna Sri waste paper mart",
    "owner_name":"",
    "contact_number":"7373228836"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2462664000,12.9648156000 ]
    },
    "properties": {
    "enterprise_name":"Selvakumar traders",
    "owner_name":"",
    "contact_number":"9941235384"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2518002700,12.9648510600 ]
    },
    "properties": {
    "enterprise_name":"Chithirai kani waste paper mart",
    "owner_name":"Kandipan",
    "contact_number":"9444299290"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1991077400,12.9648534300 ]
    },
    "properties": {
    "enterprise_name":"Varkis waste paper mart",
    "owner_name":"Selvan",
    "contact_number":"9841791702"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1998565200,12.9649786700 ]
    },
    "properties": {
    "enterprise_name":"Kalaiarasi waste paper mart",
    "owner_name":"Dhanabal",
    "contact_number":"9444422304"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1937130400,12.9650347000 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9444215168"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2565931600,12.9655552800 ]
    },
    "properties": {
    "enterprise_name":"Raja lakshmi Waste mart",
    "owner_name":"Raja",
    "contact_number":"9962160006"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2382305000,12.9661486000 ]
    },
    "properties": {
    "enterprise_name":"Muthuselvi traders",
    "owner_name":"Hariyanayagam",
    "contact_number":"9884424706"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2334850000,12.9671416667 ]
    },
    "properties": {
    "enterprise_name":"Old waste paper mart",
    "owner_name":"",
    "contact_number":"9944753588"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2532183333,12.9673516667 ]
    },
    "properties": {
    "enterprise_name":"Vanitha waste paper",
    "owner_name":"Jayakumar",
    "contact_number":"7358561898"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1961335600,12.9674151700 ]
    },
    "properties": {
    "enterprise_name":"Poovai John",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2521303400,12.9678141200 ]
    },
    "properties": {
    "enterprise_name":"Sokkalingam waste mart",
    "owner_name":"Chokkalingam",
    "contact_number":"9047890454"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1984256700,12.9678758500 ]
    },
    "properties": {
    "enterprise_name":"Iyya waste paper mart",
    "owner_name":"Mateshan",
    "contact_number":"87660087997"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2378820000,12.9679140000 ]
    },
    "properties": {
    "enterprise_name":"Mugesh waste paper mart",
    "owner_name":"Ramakrishnan",
    "contact_number":"9840261444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2373950000,12.9679250000 ]
    },
    "properties": {
    "enterprise_name":"Karthika waste paper mart",
    "owner_name":"Chinnadurai",
    "contact_number":"9444229276"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1810409400,12.9679613200 ]
    },
    "properties": {
    "enterprise_name":"MKM WASTE PAPER MART",
    "owner_name":"Ganapathy",
    "contact_number":"9566068781"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1839941900,12.9684915600 ]
    },
    "properties": {
    "enterprise_name":"Napoleon waste Paper mart",
    "owner_name":"Kingston",
    "contact_number":"9710106109"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1962372000,12.9687308700 ]
    },
    "properties": {
    "enterprise_name":"Thirumal waste paper mart",
    "owner_name":"Aadhiligam",
    "contact_number":"9941633030"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1851195800,12.9688555600 ]
    },
    "properties": {
    "enterprise_name":"The Hindu waste Paper mart",
    "owner_name":"",
    "contact_number":"9962448899"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2608439200,12.9691017600 ]
    },
    "properties": {
    "enterprise_name":"Siva Murugan mart",
    "owner_name":"Velmurugan",
    "contact_number":"9884380577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2575876000,12.9692692000 ]
    },
    "properties": {
    "enterprise_name":"Parthima steel scraps",
    "owner_name":"",
    "contact_number":"9445483165"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1885037100,12.9693231400 ]
    },
    "properties": {
    "enterprise_name":"E S R WASTE PAPER MART",
    "owner_name":"Sakthi",
    "contact_number":"7401091823"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2603977400,12.9694971100 ]
    },
    "properties": {
    "enterprise_name":"St Thomas waste paper mart",
    "owner_name":"J amalraj",
    "contact_number":"8144922090"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559875600,12.9695718200 ]
    },
    "properties": {
    "enterprise_name":"JM waste paper mart",
    "owner_name":"Natarajan",
    "contact_number":"9600143909"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2534366667,12.9696883333 ]
    },
    "properties": {
    "enterprise_name":"Thangam waste paper mart",
    "owner_name":"Durairaj",
    "contact_number":"9444314740"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2074622500,12.9697750100 ]
    },
    "properties": {
    "enterprise_name":"Slevam waste paper mart",
    "owner_name":"Slevam",
    "contact_number":"9965811624"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1883044600,12.9697797700 ]
    },
    "properties": {
    "enterprise_name":"Kannan Raja waste paper mart",
    "owner_name":"Sharma",
    "contact_number":"9840589074"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2467600000,12.9699066667 ]
    },
    "properties": {
    "enterprise_name":"Mjm traders",
    "owner_name":"Mani durai",
    "contact_number":"9444487123"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1818730200,12.9700284100 ]
    },
    "properties": {
    "enterprise_name":"Jagan waste paper mart",
    "owner_name":"Jagadeesh",
    "contact_number":"9841101188"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2182116667,12.9706883333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2450383333,12.9707666667 ]
    },
    "properties": {
    "enterprise_name":"SR traders",
    "owner_name":"S lingam",
    "contact_number":"9791020798"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1870766500,12.9709720900 ]
    },
    "properties": {
    "enterprise_name":"Selvan waste paper mart",
    "owner_name":"Selvan",
    "contact_number":"9500739634"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1827930700,12.9712885000 ]
    },
    "properties": {
    "enterprise_name":"dharshan waste paper mart",
    "owner_name":"Vasanth Kumar",
    "contact_number":"8939135676"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2076286400,12.9713684100 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"9841875501"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1813496500,12.9713964800 ]
    },
    "properties": {
    "enterprise_name":"Sevam waste paper mart",
    "owner_name":"Selvan",
    "contact_number":"9092559491"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2531264300,12.9715566000 ]
    },
    "properties": {
    "enterprise_name":"Sri mutharamman traders, durai",
    "owner_name":"Murali",
    "contact_number":"9094744971"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2035319500,12.9720917500 ]
    },
    "properties": {
    "enterprise_name":"SP waste paper mart",
    "owner_name":"Sekar",
    "contact_number":"9884121018"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2007699200,12.9722074900 ]
    },
    "properties": {
    "enterprise_name":"Iyya waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2399731000,12.9722613000 ]
    },
    "properties": {
    "enterprise_name":"Shanthi waste paper",
    "owner_name":"Prince",
    "contact_number":"9080662092"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2399731000,12.9722613000 ]
    },
    "properties": {
    "enterprise_name":"S.santhi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2532358800,12.9724494600 ]
    },
    "properties": {
    "enterprise_name":"Bakiyaraj waste mart",
    "owner_name":"Bakiyaraj",
    "contact_number":"9941367887"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2396250000,12.9729383333 ]
    },
    "properties": {
    "enterprise_name":"Jagan waste mart",
    "owner_name":"C jagan",
    "contact_number":"9841352954"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2019888500,12.9733302300 ]
    },
    "properties": {
    "enterprise_name":"Raja waste paper mart",
    "owner_name":"Raja",
    "contact_number":"9791192214"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2324583333,12.9735050000 ]
    },
    "properties": {
    "enterprise_name":"Manikandan waste paper mart",
    "owner_name":"Manikandan",
    "contact_number":"9842362854"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2586316667,12.9735566667 ]
    },
    "properties": {
    "enterprise_name":"S n waste paper mart",
    "owner_name":"",
    "contact_number":"8220272712"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613565000,12.9739156000 ]
    },
    "properties": {
    "enterprise_name":"Nambi waste mart",
    "owner_name":"Raju",
    "contact_number":"9444217947"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2193116667,12.9739183333 ]
    },
    "properties": {
    "enterprise_name":"Kattarkani waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9444424021"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1880045600,12.9739275600 ]
    },
    "properties": {
    "enterprise_name":"Sri Ram waste paper mart",
    "owner_name":"Rammar",
    "contact_number":"9962258348"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1893804900,12.9739493900 ]
    },
    "properties": {
    "enterprise_name":"Sri Ram waste paper mart",
    "owner_name":"Pooja",
    "contact_number":"9962258348"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2009054100,12.9746629300 ]
    },
    "properties": {
    "enterprise_name":"P.S Rajapriya waste paper mart",
    "owner_name":"Sharman",
    "contact_number":"9841482483"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2047777000,12.9749027600 ]
    },
    "properties": {
    "enterprise_name":"Muthuramman waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9841923050"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2250783333,12.9751766667 ]
    },
    "properties": {
    "enterprise_name":"Nithvin waste paper mart",
    "owner_name":"Krishnan",
    "contact_number":"9940534383"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1975294000,12.9753328300 ]
    },
    "properties": {
    "enterprise_name":"Sri Venkateswara waste paper mart",
    "owner_name":"",
    "contact_number":"9444981670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2537061100,12.9754715800 ]
    },
    "properties": {
    "enterprise_name":"Chelladurai waste mart",
    "owner_name":"",
    "contact_number":"8122724225"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1989750500,12.9755822900 ]
    },
    "properties": {
    "enterprise_name":"Raj",
    "owner_name":"Raj",
    "contact_number":"9884581205"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1861858600,12.9760836000 ]
    },
    "properties": {
    "enterprise_name":"Parvathi Amman waste paper mart",
    "owner_name":"Saravana",
    "contact_number":"9094613637"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2539466667,12.9761266667 ]
    },
    "properties": {
    "enterprise_name":"Solai waste paper mart",
    "owner_name":"Solai pandi chettiyar",
    "contact_number":"9841204768"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2566998000,12.9768034000 ]
    },
    "properties": {
    "enterprise_name":"Jagadesh waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1937163200,12.9769912000 ]
    },
    "properties": {
    "enterprise_name":"Ammu waste paper mart",
    "owner_name":"Sivanandham",
    "contact_number":"9003039577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1975003800,12.9769998000 ]
    },
    "properties": {
    "enterprise_name":"Vignesh waste paper mart",
    "owner_name":"Vengaipuli",
    "contact_number":"9094946086"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2525766667,12.9770250000 ]
    },
    "properties": {
    "enterprise_name":"MMR waste paper mart",
    "owner_name":"",
    "contact_number":"9840215653"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1941540000,12.9771880000 ]
    },
    "properties": {
    "enterprise_name":"Shop closed",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2045132200,12.9774686900 ]
    },
    "properties": {
    "enterprise_name":"Pandiyan waste paper mart",
    "owner_name":"",
    "contact_number":"9884894155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1932506500,12.9776079900 ]
    },
    "properties": {
    "enterprise_name":"Serina waste paper mart",
    "owner_name":"Pandiyan",
    "contact_number":"9600085282"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2620924100,12.9777747300 ]
    },
    "properties": {
    "enterprise_name":"Thirumani waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"4424570031"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2541091600,12.9780140100 ]
    },
    "properties": {
    "enterprise_name":"Poovi arasan waste paper mart",
    "owner_name":"K. Rajendiran",
    "contact_number":"9842510767"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2245300000,12.9781483333 ]
    },
    "properties": {
    "enterprise_name":"Vanaparvathy waste paper mart",
    "owner_name":"Rajalingam",
    "contact_number":"9380509393"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2638274800,12.9783926900 ]
    },
    "properties": {
    "enterprise_name":"Manikandan waste paper mart",
    "owner_name":"Manikandan",
    "contact_number":"9841455926"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2012505800,12.9785150500 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper Mart",
    "owner_name":"Saravana",
    "contact_number":"9566208883"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2144633333,12.9787500000 ]
    },
    "properties": {
    "enterprise_name":"SMB WASTE PAPER MART",
    "owner_name":"Balakrisnan",
    "contact_number":"9841136373"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2248050000,12.9788150000 ]
    },
    "properties": {
    "enterprise_name":"Vengadeshvara waste paper mart",
    "owner_name":"Janakiraman",
    "contact_number":"9445319820"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2590044500,12.9788320600 ]
    },
    "properties": {
    "enterprise_name":"Parvathi waste paper mart",
    "owner_name":"Bala",
    "contact_number":"4424410078"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2538817300,12.9788476700 ]
    },
    "properties": {
    "enterprise_name":"Iyyadurai nadar waste paper mart",
    "owner_name":"Iyya durai",
    "contact_number":"9551004801"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2430466667,12.9789350000 ]
    },
    "properties": {
    "enterprise_name":"Balu",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2072000000,12.9791416667 ]
    },
    "properties": {
    "enterprise_name":"Baba waste paper mart",
    "owner_name":"Mohamad",
    "contact_number":"9962306629"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1972233100,12.9791927700 ]
    },
    "properties": {
    "enterprise_name":"Venkateshwara waste paper mart",
    "owner_name":"Ramthilagar",
    "contact_number":"9940313388"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973431300,12.9793112400 ]
    },
    "properties": {
    "enterprise_name":"Venkateshwara waste paper mart",
    "owner_name":"",
    "contact_number":"9940313388"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2181133333,12.9793766667 ]
    },
    "properties": {
    "enterprise_name":"MAS waste paper mart",
    "owner_name":"Shagul amethu",
    "contact_number":"9841591876"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2160100000,12.9795366667 ]
    },
    "properties": {
    "enterprise_name":"Dhanasegar waste paper mart",
    "owner_name":"Mariyamma",
    "contact_number":"8056023308"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2088316667,12.9795433333 ]
    },
    "properties": {
    "enterprise_name":"SR waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2431716667,12.9799866667 ]
    },
    "properties": {
    "enterprise_name":"Amr treaders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2044376500,12.9806373300 ]
    },
    "properties": {
    "enterprise_name":"Nanjil waste paper Mart",
    "owner_name":"Kalai selvan",
    "contact_number":"9710565943"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1866705000,12.9807615400 ]
    },
    "properties": {
    "enterprise_name":"Sri Ram waste paper mart",
    "owner_name":"Ram",
    "contact_number":"9381173668"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2366166667,12.9809150000 ]
    },
    "properties": {
    "enterprise_name":"Divya metals",
    "owner_name":"Thanraj",
    "contact_number":"9840314807"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1768206800,12.9809420400 ]
    },
    "properties": {
    "enterprise_name":"Zum zum waste paper mart",
    "owner_name":"",
    "contact_number":"7299646319"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1830011400,12.9809430700 ]
    },
    "properties": {
    "enterprise_name":"Venkatesh",
    "owner_name":"Shankar",
    "contact_number":"9962043085"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2293100000,12.9809750000 ]
    },
    "properties": {
    "enterprise_name":"Mahesh waste paper",
    "owner_name":"Mahesh",
    "contact_number":"9976767587"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2376116667,12.9811633333 ]
    },
    "properties": {
    "enterprise_name":"Seviyar",
    "owner_name":"Seviyar",
    "contact_number":"9841228599"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1853002500,12.9811812200 ]
    },
    "properties": {
    "enterprise_name":"Poovai Kumar waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"99405380151"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2003261500,12.9813229600 ]
    },
    "properties": {
    "enterprise_name":"R s k waste paper Mart",
    "owner_name":"Kishore",
    "contact_number":"9941831077"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2387983333,12.9813750000 ]
    },
    "properties": {
    "enterprise_name":"brama sakthi waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9688645599"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2594314800,12.9814193100 ]
    },
    "properties": {
    "enterprise_name":"Nathan traders",
    "owner_name":"Edward",
    "contact_number":"9444080386"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1954185100,12.9815257300 ]
    },
    "properties": {
    "enterprise_name":"Meenakshi waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"9600501506"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2293466667,12.9816450000 ]
    },
    "properties": {
    "enterprise_name":"Thirumani waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9841155351"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2433616667,12.9816533333 ]
    },
    "properties": {
    "enterprise_name":"Iyannar waste paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1945268500,12.9818086200 ]
    },
    "properties": {
    "enterprise_name":"Poovai rajan waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"9383143307"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2190950000,12.9818183333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1959078800,12.9819602200 ]
    },
    "properties": {
    "enterprise_name":"TNS WASTE PAPER mart",
    "owner_name":"Nagarajan",
    "contact_number":"9841356305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068983333,12.9819783333 ]
    },
    "properties": {
    "enterprise_name":"Sri Murugan waste paper",
    "owner_name":"Bashkar",
    "contact_number":"9444786549"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2324800000,12.9820200000 ]
    },
    "properties": {
    "enterprise_name":"SBA waste paper mart",
    "owner_name":"S.pachaimal",
    "contact_number":"9841645681"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1839046300,12.9821382000 ]
    },
    "properties": {
    "enterprise_name":"New babuwaste paper mart",
    "owner_name":"Santhosh",
    "contact_number":"8667875772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2120166667,12.9824983333 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Jestin",
    "contact_number":"984182689"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2106933333,12.9825400000 ]
    },
    "properties": {
    "enterprise_name":"MAM waste paper mart",
    "owner_name":"Peter",
    "contact_number":"9710962318"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2069433333,12.9825666667 ]
    },
    "properties": {
    "enterprise_name":"Sir Siva sakthi waste paper mart",
    "owner_name":"Perarasu",
    "contact_number":"9710708263"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2623428400,12.9827366700 ]
    },
    "properties": {
    "enterprise_name":"Madasamy waste paper mart",
    "owner_name":"Balakrishnan",
    "contact_number":"9841870044"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1963303500,12.9827369600 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Sakthivel",
    "contact_number":"9941071497"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1910563900,12.9828894100 ]
    },
    "properties": {
    "enterprise_name":"Ruban waste paper mart",
    "owner_name":"Ruban",
    "contact_number":"9884732544"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2616751700,12.9830046800 ]
    },
    "properties": {
    "enterprise_name":"Anitha waste paperart",
    "owner_name":"S.raja gopal",
    "contact_number":"9445714613"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2368200000,12.9831750000 ]
    },
    "properties": {
    "enterprise_name":"Paraloga madha waste paper mart",
    "owner_name":"",
    "contact_number":"9791030434"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2399333333,12.9835700000 ]
    },
    "properties": {
    "enterprise_name":"Basheer",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2383950000,12.9840400000 ]
    },
    "properties": {
    "enterprise_name":"Dileep paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1798166667,12.9840600000 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"9940308577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2656358600,12.9841245000 ]
    },
    "properties": {
    "enterprise_name":"Narayanasamy waste mart",
    "owner_name":"Selvam",
    "contact_number":"9841309417"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533750000,12.9841900000 ]
    },
    "properties": {
    "enterprise_name":"Arumugam waste paper",
    "owner_name":"",
    "contact_number":"7299780705"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2219650000,12.9842000000 ]
    },
    "properties": {
    "enterprise_name":"Sri Asiya waste paper",
    "owner_name":"Muthuvel",
    "contact_number":"9884438810"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2381983333,12.9842083333 ]
    },
    "properties": {
    "enterprise_name":"Thurai waste paper",
    "owner_name":"",
    "contact_number":"9710651508"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2641775500,12.9843124000 ]
    },
    "properties": {
    "enterprise_name":"Janaki waste paper mart",
    "owner_name":"Gnana doss",
    "contact_number":"7299579163"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2676333333,12.9845716667 ]
    },
    "properties": {
    "enterprise_name":"Jeyam waest paper mart",
    "owner_name":"Mohandhas",
    "contact_number":"9841177205"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2596803700,12.9846365800 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Babu",
    "contact_number":"9444971570"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2629280400,12.9847291300 ]
    },
    "properties": {
    "enterprise_name":"Kmk sivasakthi waste paper mart",
    "owner_name":"M inbaraj",
    "contact_number":"9940140023"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1915083333,12.9850516667 ]
    },
    "properties": {
    "enterprise_name":"Rajagopal waste paper mart",
    "owner_name":"",
    "contact_number":"8189813573"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2531783333,12.9852566667 ]
    },
    "properties": {
    "enterprise_name":"Parvathi waste paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2009775100,12.9855329400 ]
    },
    "properties": {
    "enterprise_name":"Dinakaran waste paper mart",
    "owner_name":"Dhinakaran",
    "contact_number":"9444820370"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2048900000,12.9855333333 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Ponmani",
    "contact_number":"9941030650"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432800000,12.9856300000 ]
    },
    "properties": {
    "enterprise_name":"Kali",
    "owner_name":"Kali",
    "contact_number":"9791174362"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1924616667,12.9856500000 ]
    },
    "properties": {
    "enterprise_name":"Sree maharaja traders",
    "owner_name":"",
    "contact_number":"9444015781"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1999790400,12.9858883200 ]
    },
    "properties": {
    "enterprise_name":"V m waste paper Mart",
    "owner_name":"George",
    "contact_number":"9176198969"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2290383333,12.9859966667 ]
    },
    "properties": {
    "enterprise_name":"Anandha metals",
    "owner_name":"Balasubramani",
    "contact_number":"9841480479"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2553833333,12.9860683333 ]
    },
    "properties": {
    "enterprise_name":"T.kumar waste paper mart",
    "owner_name":"Senthil Kumar",
    "contact_number":"9840294522"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1821016667,12.9862800000 ]
    },
    "properties": {
    "enterprise_name":"Sree muthu waste paper mart",
    "owner_name":"Muthu lingam",
    "contact_number":"9710648913"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2416433333,12.9862950000 ]
    },
    "properties": {
    "enterprise_name":"Loganathan waste paper mart",
    "owner_name":"Loganathan",
    "contact_number":"9486767685"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2255950000,12.9863300000 ]
    },
    "properties": {
    "enterprise_name":"MAM waste paper mart",
    "owner_name":"Rasu",
    "contact_number":"9710962318"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1898983333,12.9863666667 ]
    },
    "properties": {
    "enterprise_name":"Sekar waste paper mart",
    "owner_name":"",
    "contact_number":"22329008"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2246350000,12.9864466667 ]
    },
    "properties": {
    "enterprise_name":"Kala waste paper mart",
    "owner_name":"Pandiyan",
    "contact_number":"9791156523"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1743174300,12.9864721300 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9789977325"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2610195000,12.9865199000 ]
    },
    "properties": {
    "enterprise_name":"Muthukani waste paper mart",
    "owner_name":"",
    "contact_number":"24414567"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2608561600,12.9865922400 ]
    },
    "properties": {
    "enterprise_name":"Muthu kani waste paper mart",
    "owner_name":"Vanesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2408166667,12.9866316667 ]
    },
    "properties": {
    "enterprise_name":"Mercy waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"9840484405"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2054800000,12.9867450000 ]
    },
    "properties": {
    "enterprise_name":"Nil",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1972473000,12.9867640100 ]
    },
    "properties": {
    "enterprise_name":"Peter waste paper Mart",
    "owner_name":"",
    "contact_number":"7299666437"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2182483333,12.9870400000 ]
    },
    "properties": {
    "enterprise_name":"Jms waste paper mart",
    "owner_name":"Anthony",
    "contact_number":"9445787925"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890757000,12.9870592000 ]
    },
    "properties": {
    "enterprise_name":"Sree samkatha waste paper mart",
    "owner_name":"",
    "contact_number":"9941982333"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2228333333,12.9871250000 ]
    },
    "properties": {
    "enterprise_name":"Devid waste paper mart",
    "owner_name":"Iyyadurai",
    "contact_number":"9840758001"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2224733333,12.9874166667 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Manokaran",
    "contact_number":"9840324244"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2552976700,12.9876950700 ]
    },
    "properties": {
    "enterprise_name":"Gomathi waste paper mart",
    "owner_name":"Paul raj",
    "contact_number":"9444387522"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2169850000,12.9879533333 ]
    },
    "properties": {
    "enterprise_name":"MBD waste paper",
    "owner_name":"Jeyaselan",
    "contact_number":"9940448973"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1975814000,12.9881759200 ]
    },
    "properties": {
    "enterprise_name":"Sathiybamma dealers",
    "owner_name":"Sathya",
    "contact_number":"9444045625"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2534616667,12.9884516667 ]
    },
    "properties": {
    "enterprise_name":"Maharaja waste paper",
    "owner_name":"",
    "contact_number":"7299505198"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2158216667,12.9884650000 ]
    },
    "properties": {
    "enterprise_name":"Hari Krishnan waste paper mart",
    "owner_name":"Hari",
    "contact_number":"9884794983"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2622500000,12.9884833333 ]
    },
    "properties": {
    "enterprise_name":"ASR TRADERS",
    "owner_name":"",
    "contact_number":"9884853607"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032829300,12.9884843100 ]
    },
    "properties": {
    "enterprise_name":"BSK Trades",
    "owner_name":"Baskar",
    "contact_number":"9444102871"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2413716667,12.9886066667 ]
    },
    "properties": {
    "enterprise_name":"Makalakshmy waste paper",
    "owner_name":"Saranraj",
    "contact_number":"8098385632"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2046650000,12.9886666667 ]
    },
    "properties": {
    "enterprise_name":"Sri Mutharamman treaders",
    "owner_name":"Srimurugan",
    "contact_number":"9962282911"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2044960000,12.9889983000 ]
    },
    "properties": {
    "enterprise_name":"Sri kanth waste paper mart",
    "owner_name":"Ithiko devadas",
    "contact_number":"9862389244"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533000000,12.9891183333 ]
    },
    "properties": {
    "enterprise_name":"R.kandasamy waste paper",
    "owner_name":"Kandasamy",
    "contact_number":"9500174648"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1858066667,12.9891266667 ]
    },
    "properties": {
    "enterprise_name":"Riyaz waste paper mart",
    "owner_name":"Riyaz",
    "contact_number":"9554309755"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533333333,12.9895633333 ]
    },
    "properties": {
    "enterprise_name":"sagundhala waste paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2019437000,12.9896807000 ]
    },
    "properties": {
    "enterprise_name":"New balaji waste paper Mart ",
    "owner_name":"Balaji",
    "contact_number":"9840385563"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2093200000,12.9898566667 ]
    },
    "properties": {
    "enterprise_name":"Mohanasundaram treaders",
    "owner_name":"Chandrasekar",
    "contact_number":"9791971286"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2119066667,12.9899650000 ]
    },
    "properties": {
    "enterprise_name":"SJ waste paper mart",
    "owner_name":"Jegathish",
    "contact_number":"9840588858"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2095483333,12.9901733333 ]
    },
    "properties": {
    "enterprise_name":"Thirupathi waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9551064899"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2051650000,12.9902300000 ]
    },
    "properties": {
    "enterprise_name":"Rajeshkumar",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2156616667,12.9903783333 ]
    },
    "properties": {
    "enterprise_name":"J kathar",
    "owner_name":"Kaathar",
    "contact_number":"8946098812"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1907234000,12.9905089000 ]
    },
    "properties": {
    "enterprise_name":"Sorajan waste paper mart",
    "owner_name":"",
    "contact_number":"9840856306"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613766667,12.9908983333 ]
    },
    "properties": {
    "enterprise_name":"Mathuraman waste paper mart",
    "owner_name":"Selvakumar",
    "contact_number":"9841787284"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2086766667,12.9909366667 ]
    },
    "properties": {
    "enterprise_name":"Nejam",
    "owner_name":"Nejam",
    "contact_number":"9791171035"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2129116667,12.9909716667 ]
    },
    "properties": {
    "enterprise_name":"Palraj",
    "owner_name":"Palraj",
    "contact_number":"8682040458"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1937766667,12.9910916667 ]
    },
    "properties": {
    "enterprise_name":"Eswari waste paper mart",
    "owner_name":"",
    "contact_number":"9444531012"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2097866667,12.9913616667 ]
    },
    "properties": {
    "enterprise_name":"Jeyaseelan waste paper mart",
    "owner_name":"Jeyaseelan",
    "contact_number":"9710275883"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2071200000,12.9913800000 ]
    },
    "properties": {
    "enterprise_name":"Thirupathi waste paper mart",
    "owner_name":"Thirupathi",
    "contact_number":"9677110147"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2698882000,12.9914618000 ]
    },
    "properties": {
    "enterprise_name":"Krishnamurthy waste paper mart",
    "owner_name":"",
    "contact_number":"8220817408"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533400000,12.9915316667 ]
    },
    "properties": {
    "enterprise_name":"Ponrani waste paper mart",
    "owner_name":"Shankar",
    "contact_number":"9962918005"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2588616667,12.9919100000 ]
    },
    "properties": {
    "enterprise_name":"Petchiammal waste paper mart",
    "owner_name":"Padaiselavan",
    "contact_number":"9444145058"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2114116667,12.9920050000 ]
    },
    "properties": {
    "enterprise_name":"KRN waste paper mart",
    "owner_name":"Nithyanandam",
    "contact_number":"9884892685"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2513833333,12.9921700000 ]
    },
    "properties": {
    "enterprise_name":"Marson waste paper mart",
    "owner_name":"Perakmans",
    "contact_number":"7200141410"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1935600000,12.9921783333 ]
    },
    "properties": {
    "enterprise_name":"Thangaraj waste paper mart",
    "owner_name":"M thanga saisaran",
    "contact_number":"7550177328"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2016286000,12.9925147000 ]
    },
    "properties": {
    "enterprise_name":"Mutharaman waste paper mart",
    "owner_name":"P vanaraj",
    "contact_number":"9962443485"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2058466667,12.9925316667 ]
    },
    "properties": {
    "enterprise_name":"Nellai waste paper mart",
    "owner_name":"",
    "contact_number":"9790820636"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2153300000,12.9927883333 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Rani",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2078833333,12.9930966667 ]
    },
    "properties": {
    "enterprise_name":"Sri sakthi metals",
    "owner_name":"Sowthar raj",
    "contact_number":"7502525797"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2528866667,12.9931716667 ]
    },
    "properties": {
    "enterprise_name":"Sathis waste paper mart",
    "owner_name":"",
    "contact_number":"9841488195"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2079100000,12.9931766667 ]
    },
    "properties": {
    "enterprise_name":"V.KaruppuSamy waste paper Mart",
    "owner_name":"Vedrivel",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2164500000,12.9932183333 ]
    },
    "properties": {
    "enterprise_name":"Ramya waste paper Mart",
    "owner_name":"Chinnadurai",
    "contact_number":"9840478425"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2505550000,12.9935183333 ]
    },
    "properties": {
    "enterprise_name":"Thilagavathy waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2701250000,12.9935750000 ]
    },
    "properties": {
    "enterprise_name":"Chellathai traders",
    "owner_name":"Arul",
    "contact_number":"9884021018"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2425283333,12.9936066667 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2626706000,12.9936475000 ]
    },
    "properties": {
    "enterprise_name":"Mahalakshmi waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9884295531"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2100516667,12.9936700000 ]
    },
    "properties": {
    "enterprise_name":"Madavan waste paper mart",
    "owner_name":"Madavan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2509116667,12.9937583333 ]
    },
    "properties": {
    "enterprise_name":"Sivakumar",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2521383333,12.9938816667 ]
    },
    "properties": {
    "enterprise_name":"Vana parvathi waste paper mart",
    "owner_name":"",
    "contact_number":"9962303588"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2691751000,12.9939094000 ]
    },
    "properties": {
    "enterprise_name":"Michael waste paper mart",
    "owner_name":"M Arokiyadhas",
    "contact_number":"909215677"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2172583333,12.9942566667 ]
    },
    "properties": {
    "enterprise_name":"Syedali",
    "owner_name":"Syedali",
    "contact_number":"9841112375"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2127500000,12.9943416667 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2141900000,12.9943816667 ]
    },
    "properties": {
    "enterprise_name":"Arumugam waste paper mart",
    "owner_name":"",
    "contact_number":"9884235721"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2704316667,12.9944733333 ]
    },
    "properties": {
    "enterprise_name":"Sree Lakshmi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2172333333,12.9946233333 ]
    },
    "properties": {
    "enterprise_name":"Sm waste paper mart",
    "owner_name":"Anthony jegan",
    "contact_number":"9790099258"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2047716667,12.9946433333 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Joseph britto",
    "contact_number":"7092735254"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2149066667,12.9946700000 ]
    },
    "properties": {
    "enterprise_name":"Jayalakshmi",
    "owner_name":"Selva",
    "contact_number":"9176104129"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2046300000,12.9947633333 ]
    },
    "properties": {
    "enterprise_name":"MGM waste paper mart",
    "owner_name":"",
    "contact_number":"9444444917"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2568700000,12.9949383333 ]
    },
    "properties": {
    "enterprise_name":"Santhos waste paper mart",
    "owner_name":"Santhos",
    "contact_number":"9884537200"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1952517000,12.9950003000 ]
    },
    "properties": {
    "enterprise_name":"JJ traders",
    "owner_name":"Jalal",
    "contact_number":"9841112248"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068466667,12.9950550000 ]
    },
    "properties": {
    "enterprise_name":"Srikanth waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2033983333,12.9951266667 ]
    },
    "properties": {
    "enterprise_name":"Matha waste paper mart",
    "owner_name":"Britto",
    "contact_number":"7401666377"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1934316667,12.9951716667 ]
    },
    "properties": {
    "enterprise_name":"A k waste paper mart",
    "owner_name":"",
    "contact_number":"8428089280"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2617309000,12.9953640000 ]
    },
    "properties": {
    "enterprise_name":"Vijayan waste paper mart",
    "owner_name":"P.thangamariyappan",
    "contact_number":"9962764140"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104083333,12.9958700000 ]
    },
    "properties": {
    "enterprise_name":"Ariyan",
    "owner_name":"Ariyan",
    "contact_number":"9444343513"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2149400000,12.9964383333 ]
    },
    "properties": {
    "enterprise_name":"Smr waste paper mart",
    "owner_name":"Sagadevan",
    "contact_number":"944422769"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2496816667,12.9979383333 ]
    },
    "properties": {
    "enterprise_name":"Bismila waste paper mart",
    "owner_name":"Shakedowel",
    "contact_number":"9940116893"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2088800000,12.9981800000 ]
    },
    "properties": {
    "enterprise_name":"Vinayaka waste paper mart",
    "owner_name":"Simon",
    "contact_number":"9940238311"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104316667,12.9989700000 ]
    },
    "properties": {
    "enterprise_name":"R.K treaders",
    "owner_name":"Munusamy",
    "contact_number":"9677216361"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2578333333,12.9993583333 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2103933333,12.9993950000 ]
    },
    "properties": {
    "enterprise_name":"Shagul waste paper",
    "owner_name":"Shagul",
    "contact_number":"9841464093"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2596166667,12.9994066667 ]
    },
    "properties": {
    "enterprise_name":"Jayakumar traders",
    "owner_name":"Duraipandiyan",
    "contact_number":"42510942"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2486853000,12.9996278000 ]
    },
    "properties": {
    "enterprise_name":"Samuvel waste paper mart",
    "owner_name":"Samuvel",
    "contact_number":"9884096140"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2048009000,12.9997108000 ]
    },
    "properties": {
    "enterprise_name":"Arunachalam waste paper mart",
    "owner_name":"Arunachalam",
    "contact_number":"9884547664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2118933333,12.9997266667 ]
    },
    "properties": {
    "enterprise_name":"Sathier waste paper",
    "owner_name":"",
    "contact_number":"9952977839"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2493516667,12.9999183333 ]
    },
    "properties": {
    "enterprise_name":"Porselvi waste paper mart",
    "owner_name":"Selvan",
    "contact_number":"8754633608"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2581100000,12.9999316667 ]
    },
    "properties": {
    "enterprise_name":"Cellathai traders",
    "owner_name":"Muthu",
    "contact_number":"9884021018"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2108333333,13.0001466667 ]
    },
    "properties": {
    "enterprise_name":"Mathina waste paper",
    "owner_name":"Allavudin",
    "contact_number":"9940246839"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625500000,13.0003083333 ]
    },
    "properties": {
    "enterprise_name":"Sri sai balaji waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9500140806"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2582383333,13.0007583333 ]
    },
    "properties": {
    "enterprise_name":"Vanaparvathi waste paper mart",
    "owner_name":"",
    "contact_number":"9962303588"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2014131000,13.0008438000 ]
    },
    "properties": {
    "enterprise_name":"Svj traders asarkana st",
    "owner_name":"L v vasikaran",
    "contact_number":"9380509822"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2536416667,13.0013666667 ]
    },
    "properties": {
    "enterprise_name":"Vasanthi waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"24420652"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2042187000,13.0014142000 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste paper mart",
    "owner_name":"Ganesh",
    "contact_number":"9941513282"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2185083333,13.0014750000 ]
    },
    "properties": {
    "enterprise_name":"KVS waste paper Mart",
    "owner_name":"Muthuraaja",
    "contact_number":"9841109976"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2188200000,13.0014833333 ]
    },
    "properties": {
    "enterprise_name":"Jacklen waste paper marr",
    "owner_name":"Selvam",
    "contact_number":"9865348635"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2124483333,13.0017333333 ]
    },
    "properties": {
    "enterprise_name":"Nellai waste paper mart",
    "owner_name":"Niuas",
    "contact_number":"9840144305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2100366667,13.0017666667 ]
    },
    "properties": {
    "enterprise_name":"Jafar",
    "owner_name":"Jafar",
    "contact_number":"9940633761"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2063783333,13.0017716667 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2640167000,13.0019840000 ]
    },
    "properties": {
    "enterprise_name":"Kajakanth perumal waste paper mart",
    "owner_name":"Aarumugam",
    "contact_number":"24916087"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2050583000,13.0020365000 ]
    },
    "properties": {
    "enterprise_name":"Svj traders",
    "owner_name":"L v vasikaran",
    "contact_number":"9380509822"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2193050000,13.0021716667 ]
    },
    "properties": {
    "enterprise_name":"Star treaders",
    "owner_name":"Sahul ameethu",
    "contact_number":"9841414148"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2602983333,13.0024033333 ]
    },
    "properties": {
    "enterprise_name":"Vasudevan waste paper mart",
    "owner_name":"Vasudevan",
    "contact_number":"9445264799"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2122466667,13.0024400000 ]
    },
    "properties": {
    "enterprise_name":"T.C waste paper",
    "owner_name":"Chandran",
    "contact_number":"9840637437"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2595100000,13.0028916667 ]
    },
    "properties": {
    "enterprise_name":"Kirupa waste paper mart",
    "owner_name":"",
    "contact_number":"9840832787"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2491550000,13.0029600000 ]
    },
    "properties": {
    "enterprise_name":"Maduraveeran waste paper mart",
    "owner_name":"Pandian",
    "contact_number":"9942935462"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104333333,13.0033200000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"Murugesh",
    "contact_number":"9884939688"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2201150000,13.0036450000 ]
    },
    "properties": {
    "enterprise_name":"SMS treaders",
    "owner_name":"Sahadevan",
    "contact_number":"9841093637"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2094883333,13.0037916667 ]
    },
    "properties": {
    "enterprise_name":"Raja mohamad",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2439683333,13.0038033333 ]
    },
    "properties": {
    "enterprise_name":"Venkateswara waste paper mart",
    "owner_name":"Kalyanasundharam",
    "contact_number":"8883739344"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2724469000,13.0038488000 ]
    },
    "properties": {
    "enterprise_name":"Sairabanu waste paper mart",
    "owner_name":"Samsudheen",
    "contact_number":"9094297260"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2569200000,13.0039083333 ]
    },
    "properties": {
    "enterprise_name":"Thiyagarajan & son waste paper mart",
    "owner_name":"",
    "contact_number":"9840323323"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2200833333,13.0039666667 ]
    },
    "properties": {
    "enterprise_name":"Punitha saveriyar waste paper",
    "owner_name":"Kumar",
    "contact_number":"9551507013"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2487533333,13.0042933333 ]
    },
    "properties": {
    "enterprise_name":"Jeeyam waste paper mart",
    "owner_name":"Mohammed ali",
    "contact_number":"9444072166"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2621350000,13.0043250000 ]
    },
    "properties": {
    "enterprise_name":"Sreedhar waste paper mart",
    "owner_name":"",
    "contact_number":"9941471025"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2435133333,13.0043666667 ]
    },
    "properties": {
    "enterprise_name":"Guru waste paper mart",
    "owner_name":"G murali",
    "contact_number":"9976767587"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2530568000,13.0056785000 ]
    },
    "properties": {
    "enterprise_name":"Thiyagarajan and sons",
    "owner_name":"Velmurugan",
    "contact_number":"9884447771"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2484526000,13.0063912000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2071645000,13.0064180000 ]
    },
    "properties": {
    "enterprise_name":"Kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"9884567851"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2584420000,13.0074677000 ]
    },
    "properties": {
    "enterprise_name":"Chitti waste paper mart",
    "owner_name":"",
    "contact_number":"9841800156"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2477666667,13.0078900000 ]
    },
    "properties": {
    "enterprise_name":"Grcwas",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2636616667,13.0087300000 ]
    },
    "properties": {
    "enterprise_name":"Madhavan waste paper mart",
    "owner_name":"Madhavan",
    "contact_number":"9840551325"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2281230000,13.0105943000 ]
    },
    "properties": {
    "enterprise_name":"Ems waste paper mart",
    "owner_name":"Vainaperumal",
    "contact_number":"9094997543"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2281230000,13.0105943000 ]
    },
    "properties": {
    "enterprise_name":"Pavithra waste paper mart",
    "owner_name":"Velmurugan",
    "contact_number":"9092555754"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2281230000,13.0105943000 ]
    },
    "properties": {
    "enterprise_name":"Mohan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2493383333,13.0116300000 ]
    },
    "properties": {
    "enterprise_name":"Sr waste paper mart",
    "owner_name":"Rajasekar",
    "contact_number":"9941069121"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2263250000,13.0120733333 ]
    },
    "properties": {
    "enterprise_name":"Rizwan waste paper mart",
    "owner_name":"Rizwan",
    "contact_number":"9600139456"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2489994000,13.0121371000 ]
    },
    "properties": {
    "enterprise_name":"Balamurugan waste paper mart",
    "owner_name":"Balamurugan",
    "contact_number":"9841885838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1663558700,13.0122971600 ]
    },
    "properties": {
    "enterprise_name":"Tirumal waste paper mart",
    "owner_name":"Tirumal",
    "contact_number":"9940024935"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1718324800,13.0131593600 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi enter prices",
    "owner_name":"Thamari perumal",
    "contact_number":"9176574837"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2320100000,13.0131750000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Lingam",
    "contact_number":"8754551533"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1941221000,13.0132844700 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9940366375"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1735904000,13.0134416700 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Bala",
    "contact_number":"9941607026"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2253083333,13.0134733333 ]
    },
    "properties": {
    "enterprise_name":"Erwin waste paper mart",
    "owner_name":"Sundar",
    "contact_number":"8190086314"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2313166667,13.0146633333 ]
    },
    "properties": {
    "enterprise_name":"EMS waste paper mart",
    "owner_name":"Vainaperumal",
    "contact_number":"9094997543"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2461033333,13.0151933333 ]
    },
    "properties": {
    "enterprise_name":"Selvi ammal waste paper mart",
    "owner_name":"Mari",
    "contact_number":"9176522878"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2417156000,13.0153776000 ]
    },
    "properties": {
    "enterprise_name":"Mathina waste paper mart",
    "owner_name":"",
    "contact_number":"9952977839"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2453150000,13.0157033333 ]
    },
    "properties": {
    "enterprise_name":"Sri narayana waste paper mart",
    "owner_name":"",
    "contact_number":"9444019219"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2464763000,13.0158330000 ]
    },
    "properties": {
    "enterprise_name":"Sivakumar waste paper mart",
    "owner_name":"",
    "contact_number":"9444576977"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2259283333,13.0162600000 ]
    },
    "properties": {
    "enterprise_name":"Kk waste paper mart",
    "owner_name":"Karunanithi",
    "contact_number":"9941404097"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1783284400,13.0163187500 ]
    },
    "properties": {
    "enterprise_name":"Muni eswaran waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9840297241"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1783130800,13.0163267500 ]
    },
    "properties": {
    "enterprise_name":"Munieswar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2031071000,13.0163642000 ]
    },
    "properties": {
    "enterprise_name":"Mjj traders",
    "owner_name":"Manidurai",
    "contact_number":"9444487123"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1902069700,13.0164359200 ]
    },
    "properties": {
    "enterprise_name":"Irumbu kadai",
    "owner_name":"Dhurai",
    "contact_number":"9840548479"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2449650000,13.0170933333 ]
    },
    "properties": {
    "enterprise_name":"Sree murugan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9941588551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2047136000,13.0171963000 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9841709292"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432000000,13.0173150000 ]
    },
    "properties": {
    "enterprise_name":"darman waste paper mart",
    "owner_name":"P darman",
    "contact_number":"9840167795"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2035718000,13.0178347000 ]
    },
    "properties": {
    "enterprise_name":"Marry waste paper mart",
    "owner_name":"James",
    "contact_number":"7667701621"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2040087000,13.0179215000 ]
    },
    "properties": {
    "enterprise_name":"Sr traders",
    "owner_name":"S.lingam",
    "contact_number":"9791020798"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2122681000,13.0181677000 ]
    },
    "properties": {
    "enterprise_name":"Muthuvel waste paper mart",
    "owner_name":"Muthuvel",
    "contact_number":"24962212"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2073831000,13.0182591000 ]
    },
    "properties": {
    "enterprise_name":"Nambi waste paper mart",
    "owner_name":"Nambi",
    "contact_number":"9600453267"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1714423200,13.0183161800 ]
    },
    "properties": {
    "enterprise_name":"Anthony Selvan waste paper mart",
    "owner_name":"Thamarai perumal",
    "contact_number":"9629157741"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2769011400,13.0183847000 ]
    },
    "properties": {
    "enterprise_name":"Prathiksha waste paper mart",
    "owner_name":"Selvaganapathy",
    "contact_number":"044-252467"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2596400000,13.0183850000 ]
    },
    "properties": {
    "enterprise_name":"Bharathi waste paper mart",
    "owner_name":"prasanth",
    "contact_number":"9840557665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2447386000,13.0184361000 ]
    },
    "properties": {
    "enterprise_name":"Mahalingam waste paper",
    "owner_name":"Mahalingaraja",
    "contact_number":"9444470809"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2608455000,13.0186218000 ]
    },
    "properties": {
    "enterprise_name":"Sri rakshni waste paper mart",
    "owner_name":"K. Srinivasan",
    "contact_number":"9840736795"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2769470000,13.0186310000 ]
    },
    "properties": {
    "enterprise_name":"Raj kumar",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2600283333,13.0187983333 ]
    },
    "properties": {
    "enterprise_name":"s rakshana",
    "owner_name":"srinivasan ",
    "contact_number":"9840736795"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1687399300,13.0188665400 ]
    },
    "properties": {
    "enterprise_name":"P.M.S",
    "owner_name":"",
    "contact_number":"9941227405"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2033325000,13.0190890000 ]
    },
    "properties": {
    "enterprise_name":"Senthil murugan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9940411730"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2002520000,13.0194350000 ]
    },
    "properties": {
    "enterprise_name":"Sree suyambu lingam traders",
    "owner_name":"Kathir",
    "contact_number":"9941085185"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2614916667,13.0195166667 ]
    },
    "properties": {
    "enterprise_name":"albert waste paper mart",
    "owner_name":"Albert",
    "contact_number":"9787173407"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2021565000,13.0196645000 ]
    },
    "properties": {
    "enterprise_name":"Chellathai traders",
    "owner_name":"Arul",
    "contact_number":"9885022081"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2008416000,13.0196797000 ]
    },
    "properties": {
    "enterprise_name":"Darshini waste paper mart",
    "owner_name":"C.dhanasignh",
    "contact_number":"9842983105"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2014806000,13.0199145000 ]
    },
    "properties": {
    "enterprise_name":"Pavithra waste paper mart",
    "owner_name":"Sakthivel",
    "contact_number":"9444844739"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2456933333,13.0199383333 ]
    },
    "properties": {
    "enterprise_name":"Sivakumar waste paper mart",
    "owner_name":"",
    "contact_number":"9444576977"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2062834000,13.0199918000 ]
    },
    "properties": {
    "enterprise_name":"Iyyappan waste paper mart",
    "owner_name":"Iyyappan",
    "contact_number":"9094133630"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2275556000,13.0199962000 ]
    },
    "properties": {
    "enterprise_name":"Muthuraman waste paper mart",
    "owner_name":"Muthuraman",
    "contact_number":"9841877046"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2035303000,13.0200098000 ]
    },
    "properties": {
    "enterprise_name":"Reshma waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"8938227497"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2772199200,13.0200688800 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"Murugan",
    "contact_number":"9841784703"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2050633000,13.0201018000 ]
    },
    "properties": {
    "enterprise_name":"Maruthi waste paper mart",
    "owner_name":"Murugesan",
    "contact_number":"9176338525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2529680000,13.0202250000 ]
    },
    "properties": {
    "enterprise_name":"Mohanadevi waste paper mart",
    "owner_name":"Gopi",
    "contact_number":"044421195"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2063784000,13.0202402000 ]
    },
    "properties": {
    "enterprise_name":"Jagan waste mart",
    "owner_name":"C.jagan",
    "contact_number":"9841352954"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2020149000,13.0203431000 ]
    },
    "properties": {
    "enterprise_name":"Pechiyammal waste paper mart",
    "owner_name":"Selvan",
    "contact_number":"9444145058"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2142062300,13.0204027000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Lingusamy",
    "contact_number":"9884074357"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2591750000,13.0204166667 ]
    },
    "properties": {
    "enterprise_name":"mk traders",
    "owner_name":"",
    "contact_number":"9444038423"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2623633333,13.0206583333 ]
    },
    "properties": {
    "enterprise_name":"sathiya waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2626033333,13.0207333333 ]
    },
    "properties": {
    "enterprise_name":"h m m waste paper mart",
    "owner_name":"",
    "contact_number":"9840412969"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625433333,13.0207900000 ]
    },
    "properties": {
    "enterprise_name":"Bharathi waste paper mart",
    "owner_name":"bharath",
    "contact_number":"9840557665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2038729000,13.0208381000 ]
    },
    "properties": {
    "enterprise_name":"Azeez waste paper mart",
    "owner_name":"Azeez",
    "contact_number":"9566154228"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2597550000,13.0210150000 ]
    },
    "properties": {
    "enterprise_name":"akash waste paper Mart",
    "owner_name":"pandiyan",
    "contact_number":"9884202132"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2064863000,13.0212803000 ]
    },
    "properties": {
    "enterprise_name":"Manikandan waste paper mart",
    "owner_name":"Manikandan",
    "contact_number":"9941835606"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2596166667,13.0214250000 ]
    },
    "properties": {
    "enterprise_name":"muthuramman waste paper Mart",
    "owner_name":"Suresh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2191441000,13.0214616100 ]
    },
    "properties": {
    "enterprise_name":"KR waste paper mart",
    "owner_name":"Justin",
    "contact_number":"9841652094"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2058038000,13.0215010000 ]
    },
    "properties": {
    "enterprise_name":"Rajagopal waste paper mart",
    "owner_name":"Rajagopal",
    "contact_number":"9841547670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2161104700,13.0216447500 ]
    },
    "properties": {
    "enterprise_name":"Sheik waste paper mart",
    "owner_name":"Sheik",
    "contact_number":"9841088323"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2178750800,13.0216869200 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"",
    "contact_number":"9765377816"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1664435800,13.0217193300 ]
    },
    "properties": {
    "enterprise_name":"Muthumadaswammi waste paper mart",
    "owner_name":"",
    "contact_number":"9500018000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032945000,13.0217527000 ]
    },
    "properties": {
    "enterprise_name":"Santhos waste paper mart",
    "owner_name":"Santhos",
    "contact_number":"9884537200"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2593783333,13.0218716667 ]
    },
    "properties": {
    "enterprise_name":"adithan wast paper mart",
    "owner_name":"adithan",
    "contact_number":"9841661366"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032257000,13.0223679000 ]
    },
    "properties": {
    "enterprise_name":"Sri Balaji waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9500140806"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2595913400,13.0223774600 ]
    },
    "properties": {
    "enterprise_name":"Athithyan waste mart",
    "owner_name":"Athithan",
    "contact_number":"9841661366"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1683111500,13.0226545400 ]
    },
    "properties": {
    "enterprise_name":"Jaya waste paper mart",
    "owner_name":"Sagayaraj",
    "contact_number":"9840184654"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2153452200,13.0227476700 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Mahesh",
    "contact_number":"9791090680"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1571516667,13.0227583333 ]
    },
    "properties": {
    "enterprise_name":"Raja waste paper mart",
    "owner_name":"Raja",
    "contact_number":"8056102975"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2602916667,13.0228133333 ]
    },
    "properties": {
    "enterprise_name":"mkbrother",
    "owner_name":"",
    "contact_number":"9962055752"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2531024500,13.0228800400 ]
    },
    "properties": {
    "enterprise_name":"Rajastha traders",
    "owner_name":"Raja",
    "contact_number":"9962835090"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2603911000,13.0229214000 ]
    },
    "properties": {
    "enterprise_name":"M. K. Brothers waste paper mart",
    "owner_name":"Nanavel",
    "contact_number":"9962055752"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2164586900,13.0230027500 ]
    },
    "properties": {
    "enterprise_name":"MSM traders",
    "owner_name":"Murgan",
    "contact_number":"9841264631"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2064020000,13.0230821000 ]
    },
    "properties": {
    "enterprise_name":"Puduvai anthoniar waste paper mart",
    "owner_name":"David",
    "contact_number":"9941411735"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2154560400,13.0231527000 ]
    },
    "properties": {
    "enterprise_name":"Muthuraman waste paper mart",
    "owner_name":"Permual",
    "contact_number":"9629381970"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2598698000,13.0233163100 ]
    },
    "properties": {
    "enterprise_name":"Mk traders",
    "owner_name":"K selvam",
    "contact_number":"944403842"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2396633333,13.0236733333 ]
    },
    "properties": {
    "enterprise_name":"Unknown",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1708072200,13.0238462900 ]
    },
    "properties": {
    "enterprise_name":"Test",
    "owner_name":"",
    "contact_number":"35367899"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1707542200,13.0239756100 ]
    },
    "properties": {
    "enterprise_name":"Ganesh",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2127756000,13.0241596100 ]
    },
    "properties": {
    "enterprise_name":"Gopal waste paper mart",
    "owner_name":"",
    "contact_number":"9380647563"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2408850000,13.0242200000 ]
    },
    "properties": {
    "enterprise_name":"Manikandan waste paper mart",
    "owner_name":"",
    "contact_number":"7299678873"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2713330000,13.0243233000 ]
    },
    "properties": {
    "enterprise_name":"Thirumani waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"9841266756"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2582216100,13.0243380500 ]
    },
    "properties": {
    "enterprise_name":"Bathra kalliamman waste paper mart",
    "owner_name":"Vaithiyanathan",
    "contact_number":"9884587668"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2576350000,13.0245433333 ]
    },
    "properties": {
    "enterprise_name":"ayyanarappan waste paper mart",
    "owner_name":"",
    "contact_number":"9176332644"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2581154000,13.0246040000 ]
    },
    "properties": {
    "enterprise_name":"Iyanarappan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9176332644"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2591716667,13.0247416667 ]
    },
    "properties": {
    "enterprise_name":"pathrakali Amman waste paper Mart",
    "owner_name":"",
    "contact_number":"9841537660"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2202633333,13.0247600000 ]
    },
    "properties": {
    "enterprise_name":"thanga Mani waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2176890500,13.0247811000 ]
    },
    "properties": {
    "enterprise_name":"Bismi waste paper mart",
    "owner_name":"Abdul",
    "contact_number":"9840272052"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2590283333,13.0247883333 ]
    },
    "properties": {
    "enterprise_name":"sivachandra waste paper Mart",
    "owner_name":"thanga Pandian",
    "contact_number":"9710968192"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2403000000,13.0248716667 ]
    },
    "properties": {
    "enterprise_name":"No name ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2615216667,13.0251516667 ]
    },
    "properties": {
    "enterprise_name":"nms traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2037350000,13.0252210000 ]
    },
    "properties": {
    "enterprise_name":"Mkb waste paper mart",
    "owner_name":"Karna",
    "contact_number":"9380216543"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2036860000,13.0252332000 ]
    },
    "properties": {
    "enterprise_name":"Sree Kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"9941977659"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2124579800,13.0256622100 ]
    },
    "properties": {
    "enterprise_name":"Siva waste paper mart",
    "owner_name":"Siva",
    "contact_number":"9600117298"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2216880700,13.0256809200 ]
    },
    "properties": {
    "enterprise_name":"Ganagaraj",
    "owner_name":"Ganagaraj",
    "contact_number":"9710585505"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2152573100,13.0257005100 ]
    },
    "properties": {
    "enterprise_name":"Anand waste paper mart",
    "owner_name":"Anand",
    "contact_number":"9840394903"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2678491000,13.0259717000 ]
    },
    "properties": {
    "enterprise_name":"Parveen waste paper",
    "owner_name":"Anwar",
    "contact_number":"9841527292\n"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2240350000,13.0261966667 ]
    },
    "properties": {
    "enterprise_name":"sudhan traders",
    "owner_name":"",
    "contact_number":"9941222499"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741200000,13.0262078000 ]
    },
    "properties": {
    "enterprise_name":"Welcome waste paper mart",
    "owner_name":"Jaheer",
    "contact_number":"8148636343"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2217763500,13.0263285700 ]
    },
    "properties": {
    "enterprise_name":"Shop closed",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2241914400,13.0263635500 ]
    },
    "properties": {
    "enterprise_name":"Sudhan waste paper mart",
    "owner_name":"Kathiresan",
    "contact_number":"9941222499"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1553183333,13.0264866667 ]
    },
    "properties": {
    "enterprise_name":"Jb waste paper mart",
    "owner_name":"",
    "contact_number":"9444345751"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2196433333,13.0266683333 ]
    },
    "properties": {
    "enterprise_name":"Murgan waste paper mart",
    "owner_name":"adithan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2111168600,13.0268048500 ]
    },
    "properties": {
    "enterprise_name":"Pandiyan waste paper mart",
    "owner_name":"Pandiyan",
    "contact_number":"9444202854"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2111155500,13.0268314800 ]
    },
    "properties": {
    "enterprise_name":"Pandiya waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9444202854"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657140000,13.0269050000 ]
    },
    "properties": {
    "enterprise_name":"Ravi waste paper mart",
    "owner_name":"Ravi",
    "contact_number":"9444434772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1832866667,13.0270916667 ]
    },
    "properties": {
    "enterprise_name":"Mkp waste paper mart",
    "owner_name":"Kesavan",
    "contact_number":"8056233557"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1909183333,13.0271466667 ]
    },
    "properties": {
    "enterprise_name":"Balu waste paper mart",
    "owner_name":"Balu",
    "contact_number":"9851627395"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2166748900,13.0271751500 ]
    },
    "properties": {
    "enterprise_name":"Sri kulasai muthuraman waste paper mart",
    "owner_name":"",
    "contact_number":"9566020197"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2267616667,13.0271766667 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1611583333,13.0272600000 ]
    },
    "properties": {
    "enterprise_name":"Bija waste paper mart",
    "owner_name":"",
    "contact_number":"9003029995"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2173744000,13.0272619400 ]
    },
    "properties": {
    "enterprise_name":"Saravana trades",
    "owner_name":"Arumugam pandi",
    "contact_number":"9940430386"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195494300,13.0274859900 ]
    },
    "properties": {
    "enterprise_name":"Michael waste paper mart",
    "owner_name":"Michael",
    "contact_number":"9677262758"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2095290400,13.0275235400 ]
    },
    "properties": {
    "enterprise_name":"Swetha waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9841753874"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2174252700,13.0276572500 ]
    },
    "properties": {
    "enterprise_name":"Dorma lingam",
    "owner_name":"Lingam",
    "contact_number":"7299279210"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2723673000,13.0277546000 ]
    },
    "properties": {
    "enterprise_name":"Sri Elumalaiyan waste paper mart",
    "owner_name":"Geevarathinam",
    "contact_number":"9940421430,9941328074"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1911113000,13.0278045000 ]
    },
    "properties": {
    "enterprise_name":"Venugopal waste paper mart",
    "owner_name":"Gopal",
    "contact_number":"9840625134"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2578723200,13.0278127300 ]
    },
    "properties": {
    "enterprise_name":"City waste paper mart",
    "owner_name":"Firthouse",
    "contact_number":"9841800156"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2713548000,13.0278166000 ]
    },
    "properties": {
    "enterprise_name":"Maharaja waste paper mart",
    "owner_name":"Maharaja",
    "contact_number":"9445228784"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2577150000,13.0278350000 ]
    },
    "properties": {
    "enterprise_name":"city waste paper mart",
    "owner_name":"farthyuse",
    "contact_number":"9841800156"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1567416667,13.0282783333 ]
    },
    "properties": {
    "enterprise_name":"Srinivasan waste paper mart",
    "owner_name":"",
    "contact_number":"7299448313"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2199516667,13.0283900000 ]
    },
    "properties": {
    "enterprise_name":"ramesh waste paper mart",
    "owner_name":"ramesh",
    "contact_number":"9841122874"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2273666667,13.0284016667 ]
    },
    "properties": {
    "enterprise_name":"Arasan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2234934000,13.0284252400 ]
    },
    "properties": {
    "enterprise_name":"M.S.M waste paper mart",
    "owner_name":"",
    "contact_number":"9841264631"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2327433333,13.0284816667 ]
    },
    "properties": {
    "enterprise_name":"survesh wara waste paper mart",
    "owner_name":"chendru pandiyan",
    "contact_number":"9962115278"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1781149000,13.0284896000 ]
    },
    "properties": {
    "enterprise_name":"Vignesh waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2228734800,13.0285287200 ]
    },
    "properties": {
    "enterprise_name":"Agastin waste paper mart",
    "owner_name":"Kani",
    "contact_number":"9486330036"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2200801200,13.0286702900 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2201390800,13.0287166800 ]
    },
    "properties": {
    "enterprise_name":"Muthu waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9710026411"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1761722000,13.0289077000 ]
    },
    "properties": {
    "enterprise_name":"Sami waste paper mart",
    "owner_name":"Saminathan",
    "contact_number":"23610555"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2720931900,13.0289126900 ]
    },
    "properties": {
    "enterprise_name":"Yasmath waste mart",
    "owner_name":"Yasmath",
    "contact_number":"9962123773"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2167170500,13.0290018600 ]
    },
    "properties": {
    "enterprise_name":"Prakash waste paper mart",
    "owner_name":"Prakash",
    "contact_number":"9884602211"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2044613900,13.0290116800 ]
    },
    "properties": {
    "enterprise_name":"Muthuraman stores",
    "owner_name":"Velu Kumar",
    "contact_number":"9962176847"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2344833333,13.0292983333 ]
    },
    "properties": {
    "enterprise_name":"iron scrab store",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1566616667,13.0293133333 ]
    },
    "properties": {
    "enterprise_name":"Kamaraj waste paper mart",
    "owner_name":"Kamaraj",
    "contact_number":"7502041424"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2608701000,13.0293235000 ]
    },
    "properties": {
    "enterprise_name":"Meenachi waste paper mart",
    "owner_name":"Kathiresan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2640541800,13.0293613200 ]
    },
    "properties": {
    "enterprise_name":"Sri kamatchi Amman old paper mart",
    "owner_name":"Devanathan",
    "contact_number":"9841293444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2120577100,13.0293665700 ]
    },
    "properties": {
    "enterprise_name":"Panner Selvam waste paper mart",
    "owner_name":"",
    "contact_number":"9841631666"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2639405000,13.0293947000 ]
    },
    "properties": {
    "enterprise_name":"Sri Kailash waste paper mart",
    "owner_name":"Kailashwaran",
    "contact_number":"9940348587"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2646374000,13.0293988000 ]
    },
    "properties": {
    "enterprise_name":"NFJ waste paper mart",
    "owner_name":"Syed abbas",
    "contact_number":"9840445090"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1729112000,13.0294041000 ]
    },
    "properties": {
    "enterprise_name":"VinAyaga waste paper mart",
    "owner_name":"Palaniraman",
    "contact_number":"8938657187"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2116757900,13.0294566100 ]
    },
    "properties": {
    "enterprise_name":"Kandhaswamy waste paper mart",
    "owner_name":"",
    "contact_number":"9941830676"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2606900000,13.0294833333 ]
    },
    "properties": {
    "enterprise_name":"m k brother",
    "owner_name":"nana vel",
    "contact_number":"9941217935"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2646433333,13.0294983333 ]
    },
    "properties": {
    "enterprise_name":"nfj waste paper mart",
    "owner_name":"saiyad abas",
    "contact_number":"9840445090"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2469766667,13.0295033333 ]
    },
    "properties": {
    "enterprise_name":"muthukumar",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2677556000,13.0295077200 ]
    },
    "properties": {
    "enterprise_name":"Parasakthi waste mart",
    "owner_name":"Sivakumar",
    "contact_number":"94451006160"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2143330600,13.0295867400 ]
    },
    "properties": {
    "enterprise_name":"Om sakthi waste paper mart",
    "owner_name":"Muthu raja",
    "contact_number":"9941267378"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2330066667,13.0296000000 ]
    },
    "properties": {
    "enterprise_name":"chin raj",
    "owner_name":"",
    "contact_number":"9840633381"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2303993000,13.0296217000 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2041316800,13.0297325700 ]
    },
    "properties": {
    "enterprise_name":"Jayaraj waste paper mart",
    "owner_name":"Jayaraj",
    "contact_number":"9380398495"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2034547900,13.0298588300 ]
    },
    "properties": {
    "enterprise_name":"S p waste paper mart",
    "owner_name":"Balan",
    "contact_number":"9488156457"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1742950000,13.0299583333 ]
    },
    "properties": {
    "enterprise_name":"Muthu waste paper mart",
    "owner_name":"Mutharaman",
    "contact_number":"9655290604"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2274442000,13.0301483000 ]
    },
    "properties": {
    "enterprise_name":"Kamala waste paper mart",
    "owner_name":"Rajendharan",
    "contact_number":"9884022028"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2455750000,13.0301666667 ]
    },
    "properties": {
    "enterprise_name":"sree athinamalai iyyanar waste Paper mart",
    "owner_name":"raja sekaran",
    "contact_number":"9840411328"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1713129000,13.0302062000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2202636800,13.0302124600 ]
    },
    "properties": {
    "enterprise_name":"Dhivakar",
    "owner_name":"",
    "contact_number":"9841505585"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2339733333,13.0302150000 ]
    },
    "properties": {
    "enterprise_name":"k s m waste paper paper Mart",
    "owner_name":"faruque",
    "contact_number":"7299958786"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2270850000,13.0302183333 ]
    },
    "properties": {
    "enterprise_name":"kamala waste paper Mart",
    "owner_name":"Murali",
    "contact_number":"9941850183"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2287400000,13.0302233333 ]
    },
    "properties": {
    "enterprise_name":"muthu Kumar store",
    "owner_name":"manner",
    "contact_number":"9840625628"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2202500000,13.0302366667 ]
    },
    "properties": {
    "enterprise_name":"sA  waste paper mart",
    "owner_name":"",
    "contact_number":"9841505585"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2339983333,13.0302833333 ]
    },
    "properties": {
    "enterprise_name":"nellai waste paper mart",
    "owner_name":"",
    "contact_number":"98840540425"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2594716667,13.0303066667 ]
    },
    "properties": {
    "enterprise_name":"nazeer waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9003233577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2280166667,13.0303533333 ]
    },
    "properties": {
    "enterprise_name":"ak waste paper mart",
    "owner_name":"jabastin",
    "contact_number":"7598115047"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2306783333,13.0303800000 ]
    },
    "properties": {
    "enterprise_name":"Kamachiamman waste paper mart",
    "owner_name":"Srivansan",
    "contact_number":"9888421632"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2018213200,13.0303969100 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi's waste paper mart",
    "owner_name":"Paramasivan",
    "contact_number":"9099191037"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1779177000,13.0304191000 ]
    },
    "properties": {
    "enterprise_name":"Jayanthan waste paper mart",
    "owner_name":"",
    "contact_number":"9840733862"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2602060000,13.0304218000 ]
    },
    "properties": {
    "enterprise_name":"Nashir waste paper mart",
    "owner_name":"Nashir",
    "contact_number":"9003233577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1931844200,13.0304396000 ]
    },
    "properties": {
    "enterprise_name":"Muthu waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9840859365"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2297983333,13.0304966667 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"",
    "contact_number":"9094505666"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1561683333,13.0305066667 ]
    },
    "properties": {
    "enterprise_name":"Ruban waste paper mart",
    "owner_name":"Ruban",
    "contact_number":"9884858770"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1935499500,13.0305076800 ]
    },
    "properties": {
    "enterprise_name":"Senthilnathan Waste paper mart",
    "owner_name":"Narayanan",
    "contact_number":"9840781999"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2329600000,13.0305650000 ]
    },
    "properties": {
    "enterprise_name":"padama waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2409483333,13.0305816667 ]
    },
    "properties": {
    "enterprise_name":"parwathi waste paper mart",
    "owner_name":"Nantha kumar",
    "contact_number":"7305052017"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1932602000,13.0306246000 ]
    },
    "properties": {
    "enterprise_name":"Senthilnathan waste paper mart",
    "owner_name":"Senthilnathan",
    "contact_number":"9851637387"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2064652100,13.0307757500 ]
    },
    "properties": {
    "enterprise_name":"Antony waste paper mart",
    "owner_name":"Vinitha",
    "contact_number":"9171470428"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2696080000,13.0308040000 ]
    },
    "properties": {
    "enterprise_name":"Sadick waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2215828700,13.0308657000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi vel waste paper mart",
    "owner_name":"Sendhil",
    "contact_number":"9444251172"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2211766667,13.0308883333 ]
    },
    "properties": {
    "enterprise_name":"sakthi Vel waste paper mart",
    "owner_name":"Krishna",
    "contact_number":"8056250202"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2412783333,13.0308950000 ]
    },
    "properties": {
    "enterprise_name":"v. m mutharaman waste paper mart",
    "owner_name":"vanaraj",
    "contact_number":"9962282911"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2358050000,13.0309083333 ]
    },
    "properties": {
    "enterprise_name":"g p traders",
    "owner_name":"Palani",
    "contact_number":"9940333124"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1972815100,13.0309259900 ]
    },
    "properties": {
    "enterprise_name":"Selva kannie shop2",
    "owner_name":"Selva kannie",
    "contact_number":"9841090633"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2250666667,13.0309966667 ]
    },
    "properties": {
    "enterprise_name":"om Shakthi waste paper mart",
    "owner_name":"",
    "contact_number":"994118998"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2049553400,13.0310520700 ]
    },
    "properties": {
    "enterprise_name":"Guna sekar waste paper mart",
    "owner_name":"Guna",
    "contact_number":"8056286411"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2354200000,13.0310866667 ]
    },
    "properties": {
    "enterprise_name":"nisha",
    "owner_name":"yakar salim",
    "contact_number":"9884782492"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1955407000,13.0310904000 ]
    },
    "properties": {
    "enterprise_name":"Jaya waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"9159369677"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1982267000,13.0311106000 ]
    },
    "properties": {
    "enterprise_name":"Selva kannie waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9841090633"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1636483333,13.0311633333 ]
    },
    "properties": {
    "enterprise_name":"Sankara waste paper mart",
    "owner_name":"Sankaran",
    "contact_number":"996290021"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2328842000,13.0313101700 ]
    },
    "properties": {
    "enterprise_name":"Chlamal waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1989571900,13.0313780800 ]
    },
    "properties": {
    "enterprise_name":"Selvaraj waste paper mart",
    "owner_name":"Selva raja",
    "contact_number":"9710844323"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2010280200,13.0314822900 ]
    },
    "properties": {
    "enterprise_name":"Kumarappan waste paper mart",
    "owner_name":"",
    "contact_number":"9444134689"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2287333333,13.0314883333 ]
    },
    "properties": {
    "enterprise_name":"Vel murgan Waste paper Mart",
    "owner_name":"subramani",
    "contact_number":"8610249124"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2031333200,13.0314928900 ]
    },
    "properties": {
    "enterprise_name":"Leo waste paper mart",
    "owner_name":"Stella",
    "contact_number":"7397288156"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2600966667,13.0315016667 ]
    },
    "properties": {
    "enterprise_name":"welcome waste paper mart",
    "owner_name":"",
    "contact_number":"24616429"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1997091200,13.0315295200 ]
    },
    "properties": {
    "enterprise_name":"TSR WASTE PAPER MART",
    "owner_name":"Raja guru",
    "contact_number":"8122863261"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2000963100,13.0315330000 ]
    },
    "properties": {
    "enterprise_name":"Ramesh waste paper mart",
    "owner_name":"Ramesh",
    "contact_number":"9840183455"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1882550000,13.0315522000 ]
    },
    "properties": {
    "enterprise_name":"Thirupathi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2327916667,13.0315616667 ]
    },
    "properties": {
    "enterprise_name":"chellamal waste paper mart",
    "owner_name":"Jhon Ravi",
    "contact_number":"9444302886"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2217033333,13.0315816667 ]
    },
    "properties": {
    "enterprise_name":"thir murugan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2453150000,13.0316116667 ]
    },
    "properties": {
    "enterprise_name":"r s ramesh waste paper mart",
    "owner_name":"",
    "contact_number":"9941404097"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2704947000,13.0316215000 ]
    },
    "properties": {
    "enterprise_name":"Sri mailai waste paper mart",
    "owner_name":"Karmegam",
    "contact_number":"9840017360,9952076681"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1896363000,13.0316510000 ]
    },
    "properties": {
    "enterprise_name":"Mtrs  waste paper mart",
    "owner_name":"Marimuthu",
    "contact_number":"8124361940"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1735322000,13.0316662000 ]
    },
    "properties": {
    "enterprise_name":"Nandhakumar waste paper mart",
    "owner_name":"Nandhakumar",
    "contact_number":"9566020154"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2603968800,13.0317597600 ]
    },
    "properties": {
    "enterprise_name":"MMS waste mart",
    "owner_name":"Suresh",
    "contact_number":"9941642525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2641850500,13.0318097000 ]
    },
    "properties": {
    "enterprise_name":"Rjp waste mart",
    "owner_name":"Riaz",
    "contact_number":"9884033192"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2369966667,13.0318266667 ]
    },
    "properties": {
    "enterprise_name":"vignesh  waste paper Mart",
    "owner_name":"vasu dhevan",
    "contact_number":"9840157845"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1913200600,13.0321083700 ]
    },
    "properties": {
    "enterprise_name":"Revathi waste paper mart",
    "owner_name":"Chendur pandi",
    "contact_number":"7401118694"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2303183333,13.0322583333 ]
    },
    "properties": {
    "enterprise_name":"venkatesh wara waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1913333333,13.0322766667 ]
    },
    "properties": {
    "enterprise_name":"P.s Murugan",
    "owner_name":"",
    "contact_number":"9176648331"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1717897000,13.0323685000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2030606300,13.0323980800 ]
    },
    "properties": {
    "enterprise_name":"Thangaya waste paper mart",
    "owner_name":"",
    "contact_number":"9841217640"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2182213900,13.0325937800 ]
    },
    "properties": {
    "enterprise_name":"Selvam",
    "owner_name":"Raja",
    "contact_number":"4428890124"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2328750000,13.0326316667 ]
    },
    "properties": {
    "enterprise_name":"sree bala Murgan",
    "owner_name":"anand",
    "contact_number":"9840389971"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1758432000,13.0328704000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1758382000,13.0328715000 ]
    },
    "properties": {
    "enterprise_name":"Baskar waste paper mart",
    "owner_name":"Baskar",
    "contact_number":"9961773254"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2218333333,13.0329866667 ]
    },
    "properties": {
    "enterprise_name":"maharaja waste paper Mart",
    "owner_name":"Siva",
    "contact_number":"9941159683"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2408866667,13.0331650000 ]
    },
    "properties": {
    "enterprise_name":"boyace waste paper mart",
    "owner_name":"boyce",
    "contact_number":"9841613944"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2342733333,13.0333733333 ]
    },
    "properties": {
    "enterprise_name":"muthara Amman waste paper mart",
    "owner_name":"",
    "contact_number":"9444042670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2550082700,13.0333809200 ]
    },
    "properties": {
    "enterprise_name":"Nisha waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1611000000,13.0334100000 ]
    },
    "properties": {
    "enterprise_name":"Punitha Anthony waste paper mart",
    "owner_name":"",
    "contact_number":"9444587446"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2416650000,13.0334400000 ]
    },
    "properties": {
    "enterprise_name":"selva raj waste paper mart",
    "owner_name":"Ramesh",
    "contact_number":"7200497034"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2528500000,13.0336250000 ]
    },
    "properties": {
    "enterprise_name":"Sri vignesh traders",
    "owner_name":"satha nantham",
    "contact_number":"9941912242"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2497533333,13.0336583333 ]
    },
    "properties": {
    "enterprise_name":"regaboth waste paper mart",
    "owner_name":"ponraj",
    "contact_number":"7401280471"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2500716667,13.0336900000 ]
    },
    "properties": {
    "enterprise_name":"VIJAY waste paper mart",
    "owner_name":"",
    "contact_number":"9841206422"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2586566667,13.0337250000 ]
    },
    "properties": {
    "enterprise_name":"parvathy waste paper mart",
    "owner_name":"selva Kumar",
    "contact_number":"9444140670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2582166667,13.0337800000 ]
    },
    "properties": {
    "enterprise_name":"s s waste paper Mart",
    "owner_name":"",
    "contact_number":"9710254930"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2496000000,13.0338516667 ]
    },
    "properties": {
    "enterprise_name":"pennacir waste paper mart",
    "owner_name":"",
    "contact_number":"9941277609"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1868418000,13.0339039000 ]
    },
    "properties": {
    "enterprise_name":"Sree Amman waste paper",
    "owner_name":"S jaya kumar",
    "contact_number":"9677010079"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1740350000,13.0339875000 ]
    },
    "properties": {
    "enterprise_name":"raja waste paper mart",
    "owner_name":"Raja",
    "contact_number":"9840732296"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1852397000,13.0342344000 ]
    },
    "properties": {
    "enterprise_name":"Guru waste paper mart",
    "owner_name":"P subaramani",
    "contact_number":"7299329663"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1977644600,13.0342373200 ]
    },
    "properties": {
    "enterprise_name":"Umar waste paper mart",
    "owner_name":"",
    "contact_number":"8124976548"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1719824000,13.0343058000 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Anthony",
    "contact_number":"9840125678"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2654950000,13.0343366667 ]
    },
    "properties": {
    "enterprise_name":"k k enterprise",
    "owner_name":"sami",
    "contact_number":"24663385"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1978815600,13.0343873100 ]
    },
    "properties": {
    "enterprise_name":"Siluvi thangam waste mart",
    "owner_name":"Siluvi thangam",
    "contact_number":"9841856261"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1715677000,13.0343924000 ]
    },
    "properties": {
    "enterprise_name":"Ganavel waste paper mart",
    "owner_name":"Ganavel",
    "contact_number":"9935617732"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1719083000,13.0344466000 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"",
    "contact_number":"7845523531"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2653359000,13.0345996000 ]
    },
    "properties": {
    "enterprise_name":"K k enterprises",
    "owner_name":"Samy",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1706683333,13.0346200000 ]
    },
    "properties": {
    "enterprise_name":"Ganapathi waste paper mart",
    "owner_name":"Vignesh",
    "contact_number":"9840627895"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1963390800,13.0347200400 ]
    },
    "properties": {
    "enterprise_name":"Muthiya waste paper mart",
    "owner_name":"Anandan",
    "contact_number":"9994400126"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2586233333,13.0347750000 ]
    },
    "properties": {
    "enterprise_name":"Bharathi waste paper mart",
    "owner_name":"saravanan",
    "contact_number":"9566925050"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2503166667,13.0349083333 ]
    },
    "properties": {
    "enterprise_name":"surya waste paper mart",
    "owner_name":"sanjay",
    "contact_number":"9952024665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1624987000,13.0349269000 ]
    },
    "properties": {
    "enterprise_name":"Senthil waste paper mart",
    "owner_name":"",
    "contact_number":"9981625432"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1870987000,13.0349307000 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9841260136"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2334207800,13.0350417400 ]
    },
    "properties": {
    "enterprise_name":"Wilson waste paper mart",
    "owner_name":"Wilson",
    "contact_number":"9843657123"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1978437100,13.0351170200 ]
    },
    "properties": {
    "enterprise_name":"Bala Murugan waste paper mart",
    "owner_name":"Babu",
    "contact_number":"9791187096"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2425433333,13.0351450000 ]
    },
    "properties": {
    "enterprise_name":"bala Kumar waste paper mart",
    "owner_name":"marriyapan",
    "contact_number":"9884542181"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2425366667,13.0351600000 ]
    },
    "properties": {
    "enterprise_name":"Kamachi",
    "owner_name":"Kamachi",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2541519000,13.0351763000 ]
    },
    "properties": {
    "enterprise_name":"Murthy and son jeeva waste paper mart",
    "owner_name":"Jeevan",
    "contact_number":"9840075110"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2668916667,13.0351783333 ]
    },
    "properties": {
    "enterprise_name":"sumbu lingam waste paper mart",
    "owner_name":"uday kumar",
    "contact_number":"9841302835"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2545330000,13.0352018000 ]
    },
    "properties": {
    "enterprise_name":"Sri muthuraman waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657266667,13.0352366667 ]
    },
    "properties": {
    "enterprise_name":"arunachalam waste paper mart",
    "owner_name":"arunachalam",
    "contact_number":"971050008"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2385576700,13.0352682500 ]
    },
    "properties": {
    "enterprise_name":"Vijay lingam",
    "owner_name":"Vijay lingam",
    "contact_number":"9710671862"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2338863500,13.0353944500 ]
    },
    "properties": {
    "enterprise_name":"Murgan waste paper mart",
    "owner_name":"Murgan",
    "contact_number":"9710220128"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1743247000,13.0355149000 ]
    },
    "properties": {
    "enterprise_name":"Senthilkumar waste paper mart",
    "owner_name":"Senthilkumar",
    "contact_number":"9840655771"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1654347000,13.0355287000 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1684683333,13.0356116667 ]
    },
    "properties": {
    "enterprise_name":"Senthil waste paper mart",
    "owner_name":"",
    "contact_number":"9847652397"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1636850000,13.0356212000 ]
    },
    "properties": {
    "enterprise_name":"Sree mutharaman waste paper mart",
    "owner_name":"Iyyappan",
    "contact_number":"8681560161"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1630165000,13.0357924000 ]
    },
    "properties": {
    "enterprise_name":"Srk was",
    "owner_name":"",
    "contact_number":"9600558505"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1723463000,13.0359566000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"9710873396"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2383803300,13.0361405800 ]
    },
    "properties": {
    "enterprise_name":"Mohandas das waste paper mart",
    "owner_name":"",
    "contact_number":"7418126470"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1859956000,13.0363253000 ]
    },
    "properties": {
    "enterprise_name":"Vengatesan waste paper mart",
    "owner_name":"Vengatesan",
    "contact_number":"8754422744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2688903900,13.0363352900 ]
    },
    "properties": {
    "enterprise_name":"Surjith paper mart",
    "owner_name":"Raj",
    "contact_number":"9840509269"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1863233333,13.0363433333 ]
    },
    "properties": {
    "enterprise_name":"Sree kannan waste paper mart",
    "owner_name":"Perumal",
    "contact_number":"8754422744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2710791000,13.0364422000 ]
    },
    "properties": {
    "enterprise_name":"Shruthi metals and waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2011203400,13.0364734200 ]
    },
    "properties": {
    "enterprise_name":"Nms waste mart",
    "owner_name":"Velu",
    "contact_number":"44266547890"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2653581000,13.0365728000 ]
    },
    "properties": {
    "enterprise_name":"Sri suyambu lingam waste paper mart",
    "owner_name":"Suyambulingam",
    "contact_number":"9841302835"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890316667,13.0366066667 ]
    },
    "properties": {
    "enterprise_name":"Sree murugan enterprise",
    "owner_name":"Murugan",
    "contact_number":"9677297869"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1960400000,13.0366733333 ]
    },
    "properties": {
    "enterprise_name":"Thangaraja waste paper mart",
    "owner_name":"Thangaraja",
    "contact_number":"9844847955"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1911231800,13.0367117200 ]
    },
    "properties": {
    "enterprise_name":"Muthu lakshmi waste paper mart",
    "owner_name":"Chellamani",
    "contact_number":"9841931878"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2229752000,13.0368334000 ]
    },
    "properties": {
    "enterprise_name":"S r waste paper mart",
    "owner_name":"",
    "contact_number":"9840300149"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1646387000,13.0369318000 ]
    },
    "properties": {
    "enterprise_name":"Ganesh waste paper mart",
    "owner_name":"Ganeshan",
    "contact_number":"9840546237"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2162654800,13.0369481100 ]
    },
    "properties": {
    "enterprise_name":"Thangam waste paper mart",
    "owner_name":"Venkatesan",
    "contact_number":"98965432190"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1898670000,13.0369738000 ]
    },
    "properties": {
    "enterprise_name":"Alponse waste paper mart",
    "owner_name":"Stephan",
    "contact_number":"9941187699"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1841935000,13.0370416000 ]
    },
    "properties": {
    "enterprise_name":"Marry annai waste paper mart",
    "owner_name":"Jhon",
    "contact_number":"9940492868"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2223504000,13.0371678000 ]
    },
    "properties": {
    "enterprise_name":"Prasana waste paper mart",
    "owner_name":"S.palani",
    "contact_number":"9841933375"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2440050000,13.0374666667 ]
    },
    "properties": {
    "enterprise_name":"SMS waste paper mart",
    "owner_name":"lingam",
    "contact_number":"7338922824"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1785503000,13.0375219000 ]
    },
    "properties": {
    "enterprise_name":"Saravana wpm",
    "owner_name":"N raja",
    "contact_number":"9600107035"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1607216667,13.0378883333 ]
    },
    "properties": {
    "enterprise_name":"Nm traders",
    "owner_name":"Magesh",
    "contact_number":"8050233557"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2448216667,13.0378900000 ]
    },
    "properties": {
    "enterprise_name":"Suresh waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9884821924"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1805783333,13.0379033333 ]
    },
    "properties": {
    "enterprise_name":"Sangeetha waste paper mart",
    "owner_name":"Subramani",
    "contact_number":"9003097375"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2164788900,13.0379143100 ]
    },
    "properties": {
    "enterprise_name":"MSA water paper mart",
    "owner_name":"Chendur Pandi",
    "contact_number":"9840062444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1894474000,13.0379388000 ]
    },
    "properties": {
    "enterprise_name":"J keerthana traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2719883333,13.0379966667 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper Mart",
    "owner_name":"D.Kumar",
    "contact_number":"9840498909"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1647719000,13.0380302000 ]
    },
    "properties": {
    "enterprise_name":"Senthil waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2712112500,13.0384590000 ]
    },
    "properties": {
    "enterprise_name":"Devi waste mart",
    "owner_name":"Rajesh",
    "contact_number":"97100264111"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2424816667,13.0385366667 ]
    },
    "properties": {
    "enterprise_name":"devi",
    "owner_name":"devi",
    "contact_number":"9444463400"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2738479000,13.0386664000 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2644583333,13.0388250000 ]
    },
    "properties": {
    "enterprise_name":"nava Shakthi waste paper mart",
    "owner_name":"",
    "contact_number":"9840630601"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2569042000,13.0389254200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737218200,13.0390586100 ]
    },
    "properties": {
    "enterprise_name":"Devis waste mart",
    "owner_name":"Balu",
    "contact_number":"04424987000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1859133333,13.0390716667 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2699939000,13.0390774000 ]
    },
    "properties": {
    "enterprise_name":"KVM Mahalakshmi waste paper mart",
    "owner_name":"Manickam",
    "contact_number":"9840086605"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1878433333,13.0390866667 ]
    },
    "properties": {
    "enterprise_name":"Vellai ammal waste paper mart",
    "owner_name":"Kumaran",
    "contact_number":"9381862092"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1796130000,13.0391370000 ]
    },
    "properties": {
    "enterprise_name":"Daniel waste paper mart",
    "owner_name":"Daniel",
    "contact_number":"9941070818"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2646083333,13.0391800000 ]
    },
    "properties": {
    "enterprise_name":"arshad waste paper mart",
    "owner_name":"",
    "contact_number":"24984363"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2646416667,13.0391816667 ]
    },
    "properties": {
    "enterprise_name":"MMS waste paper mart",
    "owner_name":"mahindran",
    "contact_number":"9710157888"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1605300000,13.0392583333 ]
    },
    "properties": {
    "enterprise_name":"Friend waste paper mart",
    "owner_name":"Jagadeesh",
    "contact_number":"7288984156"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1859700000,13.0393116667 ]
    },
    "properties": {
    "enterprise_name":"Surya waste paper mart",
    "owner_name":"",
    "contact_number":"9840838718"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2501616667,13.0397316667 ]
    },
    "properties": {
    "enterprise_name":"alai Amman waste paper mart",
    "owner_name":"kannan",
    "contact_number":"7871882229"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2665395000,13.0397329000 ]
    },
    "properties": {
    "enterprise_name":"Bairose",
    "owner_name":"Bairose",
    "contact_number":"9941123062"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741307100,13.0397775000 ]
    },
    "properties": {
    "enterprise_name":"Welcome waste mart",
    "owner_name":"Ashrab ali \n9941277644",
    "contact_number":"04422313467"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2638439100,13.0398065600 ]
    },
    "properties": {
    "enterprise_name":"Arumugam",
    "owner_name":"Arumugam",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2666939200,13.0398184300 ]
    },
    "properties": {
    "enterprise_name":"Ethiraj ",
    "owner_name":"Ethiraj",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1632199000,13.0398807900 ]
    },
    "properties": {
    "enterprise_name":"Pon kumar",
    "owner_name":"Ponkumar",
    "contact_number":"4433567890"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2492766667,13.0398933333 ]
    },
    "properties": {
    "enterprise_name":"maruthi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2516983333,13.0399216667 ]
    },
    "properties": {
    "enterprise_name":"air rahman waste paper mart",
    "owner_name":"tamim ansari",
    "contact_number":"9962117227"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1902283333,13.0399816667 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"9940429729"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657479000,13.0399851000 ]
    },
    "properties": {
    "enterprise_name":"Sri Kumaran traders",
    "owner_name":"Kumaran",
    "contact_number":"97100264111"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1784512000,13.0400420000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"9715110015"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2712748700,13.0400561100 ]
    },
    "properties": {
    "enterprise_name":"Mahesh waste paper mart",
    "owner_name":"Magesh",
    "contact_number":"9384862953"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1562566667,13.0401450000 ]
    },
    "properties": {
    "enterprise_name":"Senthil waste paper mart",
    "owner_name":"",
    "contact_number":"9841480482"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2742615000,13.0401503000 ]
    },
    "properties": {
    "enterprise_name":"Golden waste paper mart",
    "owner_name":"Raja",
    "contact_number":"9941563407"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2294305100,13.0401559100 ]
    },
    "properties": {
    "enterprise_name":"A to z waste paper mart",
    "owner_name":"Madhan",
    "contact_number":"9788891051"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1516570000,13.0401832000 ]
    },
    "properties": {
    "enterprise_name":"Anthony waste paper mart",
    "owner_name":"David",
    "contact_number":"9840655880"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2497966667,13.0403616667 ]
    },
    "properties": {
    "enterprise_name":"sree sathya Sai waste paper mart",
    "owner_name":"sathish",
    "contact_number":"7299540682"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2276972000,13.0404782000 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Mayil vagan",
    "contact_number":"7418385756"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2680976100,13.0405211400 ]
    },
    "properties": {
    "enterprise_name":"R.R.waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"7708060273"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2248875000,13.0406101000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9884562562"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2489433333,13.0406883333 ]
    },
    "properties": {
    "enterprise_name":"a s m waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1566466667,13.0409266667 ]
    },
    "properties": {
    "enterprise_name":"Sm waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9789825358"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2088656900,13.0409378000 ]
    },
    "properties": {
    "enterprise_name":"Anandan paper mart",
    "owner_name":"Anandan",
    "contact_number":"044 299654876"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1741861000,13.0409823000 ]
    },
    "properties": {
    "enterprise_name":"Vignesh waste paper mart",
    "owner_name":"Vignesh",
    "contact_number":"9566732958"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2651382000,13.0410300000 ]
    },
    "properties": {
    "enterprise_name":"Venkateshwara waste paper mart",
    "owner_name":"Venkat",
    "contact_number":"97102 54923"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1516850000,13.0416200000 ]
    },
    "properties": {
    "enterprise_name":"Kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"23610554"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068304200,13.0416355600 ]
    },
    "properties": {
    "enterprise_name":"T.R.S waste paper mart\nJeevanandham road,\nKk nagar",
    "owner_name":"Palani",
    "contact_number":"9884146556"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2362176600,13.0418031800 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste paper mart",
    "owner_name":"Linga",
    "contact_number":"9444818838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1669169800,13.0418634000 ]
    },
    "properties": {
    "enterprise_name":"Subash waste paper mart",
    "owner_name":"Siva balan",
    "contact_number":"9940408219"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1656248600,13.0420001000 ]
    },
    "properties": {
    "enterprise_name":"Saravan waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2270317000,13.0420152000 ]
    },
    "properties": {
    "enterprise_name":"Vijayakumar waste paper mart",
    "owner_name":"Vijaykumar",
    "contact_number":"9003216084"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1626918100,13.0423276900 ]
    },
    "properties": {
    "enterprise_name":"Iyyanar traders",
    "owner_name":"V. Arumugam",
    "contact_number":"044 4380 6372"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2233674000,13.0423678000 ]
    },
    "properties": {
    "enterprise_name":"Mohammed bhai",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2728966667,13.0423900000 ]
    },
    "properties": {
    "enterprise_name":"raja traders",
    "owner_name":"Raja",
    "contact_number":"9841717244"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2182328200,13.0424505600 ]
    },
    "properties": {
    "enterprise_name":"Siva waste paper mart",
    "owner_name":"Siva raman",
    "contact_number":"9941330360"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1909133333,13.0425700000 ]
    },
    "properties": {
    "enterprise_name":"Ulaga ratchagar waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9043403578"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2734133333,13.0425816667 ]
    },
    "properties": {
    "enterprise_name":"murugadoss waste paper mart",
    "owner_name":"murugadoss",
    "contact_number":"044-24985126"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2022750000,13.0430150000 ]
    },
    "properties": {
    "enterprise_name":"Pudhuvai anthoniyar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2030550000,13.0430733333 ]
    },
    "properties": {
    "enterprise_name":"Saa waste paper mart",
    "owner_name":"Rajeshkumar",
    "contact_number":"9840401132"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1767847700,13.0430963900 ]
    },
    "properties": {
    "enterprise_name":"Balaji komathi waste paper mart",
    "owner_name":"Karthudhuari",
    "contact_number":"9444092812"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2464250000,13.0431100000 ]
    },
    "properties": {
    "enterprise_name":"t r p waste paper mart",
    "owner_name":"dinesh",
    "contact_number":"9841494966"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2022792600,13.0431130900 ]
    },
    "properties": {
    "enterprise_name":"Tms waste paper mart",
    "owner_name":"Dms kovil raj",
    "contact_number":"98843 41884"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1790236100,13.0431137500 ]
    },
    "properties": {
    "enterprise_name":"Lingashwar waste paper mart",
    "owner_name":"Linga",
    "contact_number":"8939528728"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2027314500,13.0431608400 ]
    },
    "properties": {
    "enterprise_name":"S.A.A waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"98404 01132"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1505466667,13.0434383333 ]
    },
    "properties": {
    "enterprise_name":"Vms waste paper mart",
    "owner_name":"",
    "contact_number":"9941176523"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1644072800,13.0434663800 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"M. Balaji",
    "contact_number":"99413 52596"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2752421900,13.0435288100 ]
    },
    "properties": {
    "enterprise_name":"Annamalai waste paper mart",
    "owner_name":"Shankar Ganesh",
    "contact_number":"9884839333"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2755090000,13.0435450000 ]
    },
    "properties": {
    "enterprise_name":"Annamalaiyar waste paper mart",
    "owner_name":"selvam",
    "contact_number":"044-242376"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2528166667,13.0438716667 ]
    },
    "properties": {
    "enterprise_name":"Mani waste paper mart",
    "owner_name":"SATHISH",
    "contact_number":"9841058803"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2372044500,13.0438776800 ]
    },
    "properties": {
    "enterprise_name":"Naresh waste paper mart",
    "owner_name":"Naresh",
    "contact_number":"7708536545"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2262041000,13.0440723000 ]
    },
    "properties": {
    "enterprise_name":"Awwamma waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1957783333,13.0441033333 ]
    },
    "properties": {
    "enterprise_name":"Spm waste paper mart",
    "owner_name":"",
    "contact_number":"9841524420"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1958481500,13.0441785500 ]
    },
    "properties": {
    "enterprise_name":"Spm waste mart",
    "owner_name":"Muthu kumar",
    "contact_number":"9841524420"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2012121200,13.0442897800 ]
    },
    "properties": {
    "enterprise_name":"Vel raj",
    "owner_name":"Vel raj",
    "contact_number":"95664557891"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1990183333,13.0442950000 ]
    },
    "properties": {
    "enterprise_name":"Reshma waste paper mart",
    "owner_name":"",
    "contact_number":"8939227498"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2384335500,13.0443516600 ]
    },
    "properties": {
    "enterprise_name":"Kumbathran waste paper mart",
    "owner_name":"Kumbathran",
    "contact_number":"9840539049"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1503016667,13.0445150000 ]
    },
    "properties": {
    "enterprise_name":"Permual waste paper mart",
    "owner_name":"Perumal",
    "contact_number":"9840733862"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1850122300,13.0445741100 ]
    },
    "properties": {
    "enterprise_name":"Annai lingaswary waste Paper mart",
    "owner_name":"Ganapathy",
    "contact_number":"9500111380"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1552400000,13.0447416667 ]
    },
    "properties": {
    "enterprise_name":"Thiyagarayan waste paper mart",
    "owner_name":"Thiyagarayan",
    "contact_number":"9840655235"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1928366667,13.0448016667 ]
    },
    "properties": {
    "enterprise_name":"Arulmigu muthumari Amman waste paper mart",
    "owner_name":"",
    "contact_number":"9841768343"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2357216300,13.0448722400 ]
    },
    "properties": {
    "enterprise_name":"Mani rajan",
    "owner_name":"Mani rajan",
    "contact_number":"9789054812"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2675683333,13.0449966667 ]
    },
    "properties": {
    "enterprise_name":"kani waste paper mart",
    "owner_name":"selva raj",
    "contact_number":"9710515515"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2720550000,13.0450290000 ]
    },
    "properties": {
    "enterprise_name":"RAVI WASTE PAPER MART",
    "owner_name":"k.pari",
    "contact_number":"9840186873"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1648375900,13.0451492800 ]
    },
    "properties": {
    "enterprise_name":"Jaya kumar koni kadi - processor",
    "owner_name":"Jayakumar",
    "contact_number":"9444706173"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1608160800,13.0451684600 ]
    },
    "properties": {
    "enterprise_name":"Nisha waste paper mart",
    "owner_name":"Raju",
    "contact_number":"9551891970"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1637119300,13.0453617100 ]
    },
    "properties": {
    "enterprise_name":"Kannan .b",
    "owner_name":"Baskar",
    "contact_number":"9791136720"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2227023000,13.0454171000 ]
    },
    "properties": {
    "enterprise_name":"Gowtham waste paper mart",
    "owner_name":"Prabhakar",
    "contact_number":"9941832011"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1639598700,13.0460441300 ]
    },
    "properties": {
    "enterprise_name":"Ramesh",
    "owner_name":"",
    "contact_number":"841678909"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2301818400,13.0464301400 ]
    },
    "properties": {
    "enterprise_name":"S p s WASTE PAPER mart",
    "owner_name":"Suresh Kumar",
    "contact_number":"8678910234"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2767810700,13.0465628300 ]
    },
    "properties": {
    "enterprise_name":"Thirumal traders",
    "owner_name":"Mugesh",
    "contact_number":"9500150405"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1926226000,13.0466670000 ]
    },
    "properties": {
    "enterprise_name":"Muthumariyaman waste paper mart",
    "owner_name":"Magesh",
    "contact_number":"9500172310"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1546263000,13.0466689000 ]
    },
    "properties": {
    "enterprise_name":"Sudala muthu traders",
    "owner_name":"Esakaimuthu",
    "contact_number":"9710588095"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2184872500,13.0467522800 ]
    },
    "properties": {
    "enterprise_name":"Sarvana waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9841037802"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2259126600,13.0467572500 ]
    },
    "properties": {
    "enterprise_name":"Jaffar waste paper mart",
    "owner_name":"Jaffar",
    "contact_number":"9962143265"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2603416667,13.0467900000 ]
    },
    "properties": {
    "enterprise_name":"well come waste paper mart",
    "owner_name":"sehak mohammed",
    "contact_number":"9789833253"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2583966667,13.0468183333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"Kumar",
    "contact_number":"9790469383"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1689777200,13.0468898900 ]
    },
    "properties": {
    "enterprise_name":"Santhi waste paper mart",
    "owner_name":"John",
    "contact_number":"9790715646"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1839002900,13.0469684000 ]
    },
    "properties": {
    "enterprise_name":"Devaraj waste paper mart",
    "owner_name":"Devaraj",
    "contact_number":"9841458275"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2213409800,13.0470635100 ]
    },
    "properties": {
    "enterprise_name":"Babu waste paper mart",
    "owner_name":"Babu",
    "contact_number":"8839285510"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1993300000,13.0471433333 ]
    },
    "properties": {
    "enterprise_name":"P rajagopal waste paper mart",
    "owner_name":"Rajagopal",
    "contact_number":"9841547670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1674193900,13.0473224800 ]
    },
    "properties": {
    "enterprise_name":"J.J waste paper mart",
    "owner_name":"Jayapal",
    "contact_number":"729916672"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2299469700,13.0474568600 ]
    },
    "properties": {
    "enterprise_name":"R J waste paper mart",
    "owner_name":"Mohamed",
    "contact_number":"9940599360"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2697066667,13.0474900000 ]
    },
    "properties": {
    "enterprise_name":"martin waste paper mart",
    "owner_name":"deva Nathan",
    "contact_number":"9176120612"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2689916667,13.0475383333 ]
    },
    "properties": {
    "enterprise_name":"t k waste paper Mart",
    "owner_name":"Karthick",
    "contact_number":"9940151755"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2021516667,13.0478533333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1546200000,13.0479816667 ]
    },
    "properties": {
    "enterprise_name":"Sk waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2442083333,13.0480533333 ]
    },
    "properties": {
    "enterprise_name":"singara Vel waste paper mart",
    "owner_name":"singara vel",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2235916000,13.0481465000 ]
    },
    "properties": {
    "enterprise_name":"Avvama waste paper mart",
    "owner_name":"Prabhakaran",
    "contact_number":"9884953105"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2459150000,13.0482433333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":"9789036659"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2459133333,13.0482450000 ]
    },
    "properties": {
    "enterprise_name":"rajandran waste paper mart",
    "owner_name":"rajandran",
    "contact_number":"8939221871"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1654219000,13.0482645600 ]
    },
    "properties": {
    "enterprise_name":"Gnanam",
    "owner_name":"Gnan sekar",
    "contact_number":"9965023899"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1838632000,13.0483132100 ]
    },
    "properties": {
    "enterprise_name":"V v old waste paper mart",
    "owner_name":"Vetri velan",
    "contact_number":"9840253728"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1778268300,13.0484693700 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi waste paper mart",
    "owner_name":"Murali",
    "contact_number":"9176161310"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1769654300,13.0485657200 ]
    },
    "properties": {
    "enterprise_name":"Selvi waste paper mart",
    "owner_name":"Munniswamy",
    "contact_number":"044 55342837"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2359966667,13.0486433333 ]
    },
    "properties": {
    "enterprise_name":"chitra waste paper mart",
    "owner_name":"narayana samy",
    "contact_number":"9840652149"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2598816667,13.0487716667 ]
    },
    "properties": {
    "enterprise_name":"vinayaga waste paper Mart",
    "owner_name":"raju",
    "contact_number":"9941479413"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2598633333,13.0488133333 ]
    },
    "properties": {
    "enterprise_name":"t j waste paper mart",
    "owner_name":"aynar",
    "contact_number":"9566955446"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2188840900,13.0488550000 ]
    },
    "properties": {
    "enterprise_name":"Siva lingam waste paper mart",
    "owner_name":"Siva lingam",
    "contact_number":"9600004860"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2173723900,13.0488829100 ]
    },
    "properties": {
    "enterprise_name":"Nisha waste paper mart",
    "owner_name":"P Vijaya kumar",
    "contact_number":"9941416688"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1760514200,13.0489488500 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Wilson",
    "contact_number":"8938008678"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2186385500,13.0490227000 ]
    },
    "properties": {
    "enterprise_name":"Jj",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2146238000,13.0490312100 ]
    },
    "properties": {
    "enterprise_name":"Vkr waste paper mart",
    "owner_name":"Siva",
    "contact_number":"48591244"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2020466667,13.0491366667 ]
    },
    "properties": {
    "enterprise_name":"akash waste paper mart",
    "owner_name":"kamel raj",
    "contact_number":"7458339363"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1739149400,13.0493047200 ]
    },
    "properties": {
    "enterprise_name":"Prem Kumar waste paper mart",
    "owner_name":"Prem Kumar",
    "contact_number":"7660856224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2689616667,13.0493250000 ]
    },
    "properties": {
    "enterprise_name":"elahi waste paper Mart",
    "owner_name":"abubacukar",
    "contact_number":"9841330846"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2687383333,13.0495033333 ]
    },
    "properties": {
    "enterprise_name":"lakshmi bottle store",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1695923500,13.0495170800 ]
    },
    "properties": {
    "enterprise_name":"Krishna",
    "owner_name":"",
    "contact_number":"99656800116"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2746980900,13.0497625900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2746605800,13.0497904800 ]
    },
    "properties": {
    "enterprise_name":"Alaudeen waste paper mart",
    "owner_name":"Mohdeen",
    "contact_number":"9789853945"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2351983333,13.0498000000 ]
    },
    "properties": {
    "enterprise_name":"pavithara waste paper mart",
    "owner_name":"sowanthar raj",
    "contact_number":"9941484612"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1677369200,13.0498002400 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste mart",
    "owner_name":"Sekar &\nSivan arul",
    "contact_number":"8015994525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1642466500,13.0498038700 ]
    },
    "properties": {
    "enterprise_name":"Raju waste pvt ltd",
    "owner_name":"Raju",
    "contact_number":"9941265794"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2746228100,13.0499020900 ]
    },
    "properties": {
    "enterprise_name":"S.Alaudeen waste paper mart",
    "owner_name":"Mohdeen",
    "contact_number":"9789853945"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1813916600,13.0499621500 ]
    },
    "properties": {
    "enterprise_name":"Angel waste paper mart",
    "owner_name":"Joshep gunaselan",
    "contact_number":"9841793789"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2653700000,13.0499916667 ]
    },
    "properties": {
    "enterprise_name":"Shakthi vell waste paper mart",
    "owner_name":"Shakthi vel",
    "contact_number":"9941841995"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2502450000,13.0500083333 ]
    },
    "properties": {
    "enterprise_name":"r p waste paper mart",
    "owner_name":"ramesh",
    "contact_number":"9382102350"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2751207700,13.0500678200 ]
    },
    "properties": {
    "enterprise_name":"M.K.Brothers waste paper mart",
    "owner_name":"",
    "contact_number":"7338941850"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1668259700,13.0500697800 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Raja",
    "contact_number":"9840946647"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2148323500,13.0501464700 ]
    },
    "properties": {
    "enterprise_name":"Rs ganabathi waste paper mart",
    "owner_name":"Ganapathi",
    "contact_number":"9884862636"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1793169200,13.0501923400 ]
    },
    "properties": {
    "enterprise_name":"S M waste paper mart",
    "owner_name":"Selvaraj",
    "contact_number":"8300641395"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1793619100,13.0502100600 ]
    },
    "properties": {
    "enterprise_name":"Nesam waste paper mart",
    "owner_name":"Raman",
    "contact_number":"9841530843"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1628611800,13.0502273400 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste mart",
    "owner_name":"Kannan",
    "contact_number":"9840819761"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2745575900,13.0503259400 ]
    },
    "properties": {
    "enterprise_name":"Amman waste paper mart",
    "owner_name":"Murugan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2505033333,13.0503550000 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"jaba",
    "contact_number":"9941934974"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1959501000,13.0503792000 ]
    },
    "properties": {
    "enterprise_name":"Senthil murugan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9941411731"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1904706000,13.0504484000 ]
    },
    "properties": {
    "enterprise_name":"A m chandran waste paper mart",
    "owner_name":"Chandran",
    "contact_number":"9840273673"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2198668600,13.0504965300 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"7985252707"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2364934000,13.0505057000 ]
    },
    "properties": {
    "enterprise_name":"Angalamman waste paper mart",
    "owner_name":"Vellikannan",
    "contact_number":"9677292312"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2735934000,13.0506234200 ]
    },
    "properties": {
    "enterprise_name":"Sekar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2023350000,13.0506816667 ]
    },
    "properties": {
    "enterprise_name":"papa",
    "owner_name":"rajan",
    "contact_number":"9941363664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1828886100,13.0507420300 ]
    },
    "properties": {
    "enterprise_name":"Kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"9710665543"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1897731000,13.0507777000 ]
    },
    "properties": {
    "enterprise_name":"Sathis waste paper mart",
    "owner_name":"Sathis",
    "contact_number":"9952076681"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2497600000,13.0507950000 ]
    },
    "properties": {
    "enterprise_name":"rs plastic",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2479133333,13.0507966667 ]
    },
    "properties": {
    "enterprise_name":"a s m waste paper mart",
    "owner_name":"kamal battah",
    "contact_number":"9789892513"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2217141800,13.0508415700 ]
    },
    "properties": {
    "enterprise_name":"T s waste paper mart",
    "owner_name":"Anbu",
    "contact_number":"9841868646"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1876433000,13.0508551000 ]
    },
    "properties": {
    "enterprise_name":"David waste paper mart",
    "owner_name":"David",
    "contact_number":"9841700292"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613883333,13.0511116667 ]
    },
    "properties": {
    "enterprise_name":"karaneeswari waste paper Mart",
    "owner_name":"viswanathan",
    "contact_number":"9677242505"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2273474300,13.0511639600 ]
    },
    "properties": {
    "enterprise_name":"R Vijaya waste paper mart",
    "owner_name":"Thirunelveli",
    "contact_number":"8655282191"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1991366667,13.0511800000 ]
    },
    "properties": {
    "enterprise_name":"anthoniyar waste paper mart",
    "owner_name":"anthoniyar",
    "contact_number":"9444359266"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2224901000,13.0512090000 ]
    },
    "properties": {
    "enterprise_name":"Nellai waste paper mart",
    "owner_name":"Ram",
    "contact_number":"9540144305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2627783333,13.0512650000 ]
    },
    "properties": {
    "enterprise_name":"b m s waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2311166000,13.0513348300 ]
    },
    "properties": {
    "enterprise_name":"Ram waste paper mart",
    "owner_name":"Martin",
    "contact_number":"9840922711"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2749630200,13.0513521600 ]
    },
    "properties": {
    "enterprise_name":"Sandhanam",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1965716667,13.0513950000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2608933333,13.0514016667 ]
    },
    "properties": {
    "enterprise_name":"annai waste paper Mart",
    "owner_name":"",
    "contact_number":"9840624518"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1697001500,13.0514736900 ]
    },
    "properties": {
    "enterprise_name":"Thanga pandi waste paper marr",
    "owner_name":"T. Sekar",
    "contact_number":"9841338921"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1894238000,13.0515976000 ]
    },
    "properties": {
    "enterprise_name":"Udhaya waste paper mart",
    "owner_name":"Udhaya",
    "contact_number":"9940493748"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613983333,13.0516916667 ]
    },
    "properties": {
    "enterprise_name":"a s waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2286171000,13.0517053000 ]
    },
    "properties": {
    "enterprise_name":"JP waste paper mart",
    "owner_name":"Dillubabu",
    "contact_number":"7344451436"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2357786000,13.0519524000 ]
    },
    "properties": {
    "enterprise_name":"Iyyappan waste paper mart",
    "owner_name":"Nayanasekar",
    "contact_number":"9094133635"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2285849100,13.0519644700 ]
    },
    "properties": {
    "enterprise_name":"Nimi waste paper mart",
    "owner_name":"Stephen",
    "contact_number":"9841206346"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2787100000,13.0520316667 ]
    },
    "properties": {
    "enterprise_name":"Kamachi waste paper mart",
    "owner_name":"Rajendran",
    "contact_number":"8939561411"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1995750000,13.0521683333 ]
    },
    "properties": {
    "enterprise_name":"muthu waste paper mart",
    "owner_name":"muthu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2390945000,13.0522962000 ]
    },
    "properties": {
    "enterprise_name":"Mariyappan waste paper mart",
    "owner_name":"Mariyappan",
    "contact_number":"9094714292"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2261798800,13.0524553700 ]
    },
    "properties": {
    "enterprise_name":"Mayal waste paper mart",
    "owner_name":"",
    "contact_number":"9841023145"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2314338600,13.0525266300 ]
    },
    "properties": {
    "enterprise_name":"Shri murgan trades",
    "owner_name":"Arul",
    "contact_number":"9381026652"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2693183333,13.0526550000 ]
    },
    "properties": {
    "enterprise_name":"royal waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2242888000,13.0528003000 ]
    },
    "properties": {
    "enterprise_name":"Ganesh waste paper mart",
    "owner_name":"Ganesh",
    "contact_number":"8535739965"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2155156400,13.0528986600 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper mart",
    "owner_name":"Athiraj",
    "contact_number":"7358236322"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1896615000,13.0529521000 ]
    },
    "properties": {
    "enterprise_name":"Gopal waste paper mart",
    "owner_name":"Gopal",
    "contact_number":"8939561413"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2382305000,13.0529724000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2699395000,13.0531027000 ]
    },
    "properties": {
    "enterprise_name":"Seri Ravi waste paper mart",
    "owner_name":"Ravi",
    "contact_number":"9940273673"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2316814500,13.0532523100 ]
    },
    "properties": {
    "enterprise_name":"Shanthi waste paper mart",
    "owner_name":"Raj Kumar",
    "contact_number":"9444364560"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2797791000,13.0535051000 ]
    },
    "properties": {
    "enterprise_name":"Pandi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2373275000,13.0535617000 ]
    },
    "properties": {
    "enterprise_name":"Matten waste paper mart",
    "owner_name":"",
    "contact_number":"9884417655"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2305756400,13.0536311500 ]
    },
    "properties": {
    "enterprise_name":"J N waste paper mart",
    "owner_name":"Ali",
    "contact_number":"9941014336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1695740700,13.0536729500 ]
    },
    "properties": {
    "enterprise_name":"Raju durai",
    "owner_name":"Raja durai",
    "contact_number":"9942345678"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2710033333,13.0537116667 ]
    },
    "properties": {
    "enterprise_name":"Mani waste paper mart",
    "owner_name":"murali",
    "contact_number":"9551373332"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1915876000,13.0538716000 ]
    },
    "properties": {
    "enterprise_name":"Senthil Kumar",
    "owner_name":"",
    "contact_number":"9940614499"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2377150000,13.0539500000 ]
    },
    "properties": {
    "enterprise_name":"Sree Mahalakshmi waste paper mart",
    "owner_name":"Thangaraj",
    "contact_number":"9176338525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2788877000,13.0539940000 ]
    },
    "properties": {
    "enterprise_name":"Saji waste paper mart",
    "owner_name":"",
    "contact_number":"9841700292"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890872900,13.0541118700 ]
    },
    "properties": {
    "enterprise_name":"Kesavan waste paper mart",
    "owner_name":"",
    "contact_number":"9989054832"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1706878300,13.0541466000 ]
    },
    "properties": {
    "enterprise_name":"Sudakar waste paper mart",
    "owner_name":"Sudakar",
    "contact_number":"9551889393"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2137498600,13.0543082800 ]
    },
    "properties": {
    "enterprise_name":"Joseph waste paper mart",
    "owner_name":"Joseph",
    "contact_number":"9962256519"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2645400000,13.0544433333 ]
    },
    "properties": {
    "enterprise_name":"santhosh waste paper mart",
    "owner_name":"",
    "contact_number":"9941975855"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2643116667,13.0546633333 ]
    },
    "properties": {
    "enterprise_name":"royal bottle store",
    "owner_name":"Palani",
    "contact_number":"9444949593"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2159606000,13.0547378200 ]
    },
    "properties": {
    "enterprise_name":"Noorulla waste paper mart",
    "owner_name":"Noorulla",
    "contact_number":"9840382836"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2692427000,13.0548564000 ]
    },
    "properties": {
    "enterprise_name":"Sapiya waste paper mart",
    "owner_name":"",
    "contact_number":"9444284168"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1633113900,13.0549344100 ]
    },
    "properties": {
    "enterprise_name":"Narayana waste paper mart",
    "owner_name":"Ramesh pandiyan",
    "contact_number":"9941639866"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2729528000,13.0549435000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1658763300,13.0549469800 ]
    },
    "properties": {
    "enterprise_name":"Nagaraj",
    "owner_name":"",
    "contact_number":"9865948881"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1659125000,13.0550126900 ]
    },
    "properties": {
    "enterprise_name":"Ramu",
    "owner_name":"Ramu",
    "contact_number":"7299527683"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2204466667,13.0551483333 ]
    },
    "properties": {
    "enterprise_name":"Suresh babu waste paper Mart",
    "owner_name":"karthick",
    "contact_number":"9003024265"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1879862000,13.0552644000 ]
    },
    "properties": {
    "enterprise_name":"Rajesh waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1664745000,13.0555450500 ]
    },
    "properties": {
    "enterprise_name":"P. Pathma old iron mart",
    "owner_name":"A. Pappu durai",
    "contact_number":"9941497391"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2258616667,13.0556283333 ]
    },
    "properties": {
    "enterprise_name":"nesam waste paper Mart",
    "owner_name":"bala",
    "contact_number":"9941419502"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2197216667,13.0556516667 ]
    },
    "properties": {
    "enterprise_name":"m j waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2704459000,13.0557208000 ]
    },
    "properties": {
    "enterprise_name":"Surya waste paper mart ",
    "owner_name":"",
    "contact_number":"9444284168"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1936966667,13.0557216667 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2163687300,13.0558175300 ]
    },
    "properties": {
    "enterprise_name":"Nisha waste paper mart",
    "owner_name":"Shanmugam",
    "contact_number":"9841905505"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2533500000,13.0559083333 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Nithya nandham",
    "contact_number":"98411 74065"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2543816667,13.0559766667 ]
    },
    "properties": {
    "enterprise_name":"Star waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2758518000,13.0560742000 ]
    },
    "properties": {
    "enterprise_name":"Velu waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"9840539049"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1958118100,13.0561189600 ]
    },
    "properties": {
    "enterprise_name":"Sri Parvathi waste paper mart",
    "owner_name":"Sai",
    "contact_number":"9841714807"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2531050000,13.0561466667 ]
    },
    "properties": {
    "enterprise_name":"Balamurugan waste paper mart",
    "owner_name":"Mariyappan",
    "contact_number":"9840332444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741233333,13.0561850000 ]
    },
    "properties": {
    "enterprise_name":"Azeez waste paper mart",
    "owner_name":"",
    "contact_number":"4428442349"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1705714200,13.0562107600 ]
    },
    "properties": {
    "enterprise_name":"Viswnanthan",
    "owner_name":"Viswanathan",
    "contact_number":"9941927745"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1808284500,13.0562547200 ]
    },
    "properties": {
    "enterprise_name":"JJ plastics",
    "owner_name":"Martin",
    "contact_number":"4424797199"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1779856600,13.0563124200 ]
    },
    "properties": {
    "enterprise_name":"Murugesan waste paper mart",
    "owner_name":"Mayakrishnan",
    "contact_number":"9043195412"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2556550000,13.0563716667 ]
    },
    "properties": {
    "enterprise_name":"Thuraimurugan ",
    "owner_name":"Thuraimurugan",
    "contact_number":"9840112550"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2290150000,13.0566800000 ]
    },
    "properties": {
    "enterprise_name":"perumal",
    "owner_name":"",
    "contact_number":"7299866255"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1814388300,13.0566926400 ]
    },
    "properties": {
    "enterprise_name":"M.R. waste paper mart",
    "owner_name":"Mari",
    "contact_number":"9444357183"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1943720400,13.0567411200 ]
    },
    "properties": {
    "enterprise_name":"Ganesh waste paper mart",
    "owner_name":"Ganesh",
    "contact_number":"9840127548"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2080683333,13.0568566667 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2002900000,13.0569166667 ]
    },
    "properties": {
    "enterprise_name":"pown raj",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1834650500,13.0569772500 ]
    },
    "properties": {
    "enterprise_name":"Shanmuga sundaram",
    "owner_name":"Shanmuga sundaram",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2762066667,13.0570266667 ]
    },
    "properties": {
    "enterprise_name":"Ravi waste paper mart",
    "owner_name":"Ravi",
    "contact_number":"9566038741"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2073216667,13.0571500000 ]
    },
    "properties": {
    "enterprise_name":"sathish waste paper Mart",
    "owner_name":"SATHISH",
    "contact_number":"9841647212"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2721433333,13.0572433333 ]
    },
    "properties": {
    "enterprise_name":"Fathima waste paper mart",
    "owner_name":"",
    "contact_number":"9962487624"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2239916667,13.0573433333 ]
    },
    "properties": {
    "enterprise_name":"john waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2220516667,13.0575533333 ]
    },
    "properties": {
    "enterprise_name":"a s m",
    "owner_name":"mohammed yusaf",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1970531500,13.0575992500 ]
    },
    "properties": {
    "enterprise_name":"Vjtha waste paper mart",
    "owner_name":"",
    "contact_number":"9841076542"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2046566667,13.0576100000 ]
    },
    "properties": {
    "enterprise_name":"murgan waste paper Mart",
    "owner_name":"Ramesh",
    "contact_number":"9176199191"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2016166667,13.0576766667 ]
    },
    "properties": {
    "enterprise_name":"dharma raj waste paper mart",
    "owner_name":"dharma raj",
    "contact_number":"9791123458"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2720043000,13.0578369000 ]
    },
    "properties": {
    "enterprise_name":"Vengatesan waste paper mart",
    "owner_name":"Vengatesan",
    "contact_number":"9841325861"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2385816667,13.0578650000 ]
    },
    "properties": {
    "enterprise_name":"Sri srinivasa traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1636187500,13.0579850700 ]
    },
    "properties": {
    "enterprise_name":"Mali matha irumbu kadi",
    "owner_name":"Michel",
    "contact_number":"9786082173"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2389198000,13.0579913000 ]
    },
    "properties": {
    "enterprise_name":"Selvin waste paper mart",
    "owner_name":"Selvin",
    "contact_number":"9380216545"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2390311000,13.0580075000 ]
    },
    "properties": {
    "enterprise_name":"Maruthi waste paper mart",
    "owner_name":"Murugesan",
    "contact_number":"28174300"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1847506800,13.0580485300 ]
    },
    "properties": {
    "enterprise_name":"Jj plastic",
    "owner_name":",",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2304583333,13.0582716667 ]
    },
    "properties": {
    "enterprise_name":"Abdul bhai",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2241766667,13.0584050000 ]
    },
    "properties": {
    "enterprise_name":"sathish waste paper Mart",
    "owner_name":"",
    "contact_number":"9444506575"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2134034500,13.0584152900 ]
    },
    "properties": {
    "enterprise_name":"Anthonyiar waste paper mart",
    "owner_name":"Balan",
    "contact_number":"8122563218"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2164193100,13.0584788300 ]
    },
    "properties": {
    "enterprise_name":"Anthonyiar waste paper mart",
    "owner_name":"Joseph",
    "contact_number":"9710265610"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2151270100,13.0584840400 ]
    },
    "properties": {
    "enterprise_name":"Amman waste paper mart",
    "owner_name":"Chelladurai durai",
    "contact_number":"8148081737"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2248716667,13.0586066667 ]
    },
    "properties": {
    "enterprise_name":"sekar waste paper Mart",
    "owner_name":"Chandra sekaran",
    "contact_number":"9840352125"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1756127700,13.0587723900 ]
    },
    "properties": {
    "enterprise_name":"ASR waste paper mart",
    "owner_name":"Jagadhish",
    "contact_number":"9677240372"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741911000,13.0589254000 ]
    },
    "properties": {
    "enterprise_name":"Sri Jeyam waste paper mart",
    "owner_name":"K.selvanathan",
    "contact_number":"4428529297"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1801145700,13.0590769400 ]
    },
    "properties": {
    "enterprise_name":"Arul kumar bottle traders",
    "owner_name":"Arul kumar",
    "contact_number":"9962490133"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1655550600,13.0590795200 ]
    },
    "properties": {
    "enterprise_name":"Krishna waste paper mart",
    "owner_name":"E.kumar",
    "contact_number":"9841121823"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2443483333,13.0591150000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1663476300,13.0592360600 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9865652661"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2219183333,13.0592400000 ]
    },
    "properties": {
    "enterprise_name":"kavitha waste paper Mart",
    "owner_name":"margatha vel",
    "contact_number":"9840809419"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2233633333,13.0593466667 ]
    },
    "properties": {
    "enterprise_name":"roshan waste paper Mart",
    "owner_name":"velu",
    "contact_number":"9382320337"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1967728300,13.0594965100 ]
    },
    "properties": {
    "enterprise_name":"S kavin waste paper mart",
    "owner_name":"Raja",
    "contact_number":"9962459718"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1639143800,13.0595864800 ]
    },
    "properties": {
    "enterprise_name":"Matha plastic",
    "owner_name":"Christopher",
    "contact_number":"9994544002"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1658441200,13.0596937300 ]
    },
    "properties": {
    "enterprise_name":"Moorthi traders",
    "owner_name":"A. Dakshna moorthy",
    "contact_number":"99416 09536"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2470294000,13.0597181000 ]
    },
    "properties": {
    "enterprise_name":"Jacob waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2468283333,13.0597733333 ]
    },
    "properties": {
    "enterprise_name":"Rajagoball",
    "owner_name":"Rajagoball",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2302633333,13.0598300000 ]
    },
    "properties": {
    "enterprise_name":"sekar waste paper Mart",
    "owner_name":"kumara vel",
    "contact_number":"9841114773"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1769375800,13.0599980200 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"9710912503"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2434496000,13.0603486000 ]
    },
    "properties": {
    "enterprise_name":"Asraf waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2013783333,13.0605216667 ]
    },
    "properties": {
    "enterprise_name":"bala murgan",
    "owner_name":"Murgan",
    "contact_number":"9382729688"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2420502000,13.0605671000 ]
    },
    "properties": {
    "enterprise_name":"Muthukumar waste paper mart",
    "owner_name":"Muthukumaran",
    "contact_number":"9840574436"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1822101500,13.0606829100 ]
    },
    "properties": {
    "enterprise_name":"Nelasekar",
    "owner_name":"Neelasekar",
    "contact_number":"9710281762"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2300366667,13.0608016667 ]
    },
    "properties": {
    "enterprise_name":"kannan waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2113906000,13.0608539000 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"Naresh",
    "contact_number":"7252682999"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1963746000,13.0608656500 ]
    },
    "properties": {
    "enterprise_name":"Kavin waste paper mart",
    "owner_name":"Mahesh",
    "contact_number":"9841888551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1979169900,13.0609133200 ]
    },
    "properties": {
    "enterprise_name":"Kavin waste paper mart",
    "owner_name":"Mahesh",
    "contact_number":"9841888551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1797622500,13.0609410400 ]
    },
    "properties": {
    "enterprise_name":"Suman",
    "owner_name":"Suman",
    "contact_number":"9969416546"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1782505800,13.0610971100 ]
    },
    "properties": {
    "enterprise_name":"Ezumali koni kadi",
    "owner_name":"Ezumalai",
    "contact_number":"9150029071"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2410666667,13.0611000000 ]
    },
    "properties": {
    "enterprise_name":"Iyan waste paper mart",
    "owner_name":"Ravi",
    "contact_number":"9840264002"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2088747300,13.0613066100 ]
    },
    "properties": {
    "enterprise_name":"Parvathi waste paper mart",
    "owner_name":"Parvathi",
    "contact_number":"9003239154"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2464262400,13.0613282700 ]
    },
    "properties": {
    "enterprise_name":"Ganesh",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2409988000,13.0613739000 ]
    },
    "properties": {
    "enterprise_name":"Muthukumaran waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9840574436"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1993483900,13.0613899900 ]
    },
    "properties": {
    "enterprise_name":"Karthika waste paper mart",
    "owner_name":"",
    "contact_number":"9642789710"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1851346400,13.0615652100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1639233800,13.0617432300 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi traders",
    "owner_name":"Sivakumar",
    "contact_number":"9840408918"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2371850000,13.0618322000 ]
    },
    "properties": {
    "enterprise_name":"Mkb waste paper mart",
    "owner_name":"Karna",
    "contact_number":"9380216548"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1926610000,13.0619033100 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Ramachandran",
    "contact_number":"9551141441"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1782008600,13.0619214300 ]
    },
    "properties": {
    "enterprise_name":"Shanthi waste paper mart",
    "owner_name":"",
    "contact_number":"4422368890"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1924385400,13.0619794200 ]
    },
    "properties": {
    "enterprise_name":"Annai waste papers",
    "owner_name":"Ramachandran",
    "contact_number":"9551141441"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1925764700,13.0619873700 ]
    },
    "properties": {
    "enterprise_name":"Raja Lakshmi",
    "owner_name":"Raja",
    "contact_number":"9841561697"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432596000,13.0620095000 ]
    },
    "properties": {
    "enterprise_name":"Bhuvaneshwari waste paper mart",
    "owner_name":"Kumaresan",
    "contact_number":"9790916040"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1793940000,13.0620198600 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper",
    "owner_name":"Ganabathi",
    "contact_number":"99405350531"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2205777000,13.0620730000 ]
    },
    "properties": {
    "enterprise_name":"Shanthi waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"7397229846"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1770577900,13.0620937900 ]
    },
    "properties": {
    "enterprise_name":"Bismi waste paper mart",
    "owner_name":"Jabbar",
    "contact_number":"9841391474"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Sree karthikeyan traders",
    "owner_name":"Kumaran",
    "contact_number":"9444285145"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Sree muthuraman waste paper mart",
    "owner_name":"Balakrishanan",
    "contact_number":"8148705179"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Ponambalam waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Sree murugan traders",
    "owner_name":"Murugan",
    "contact_number":"9003297342"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Magesh waste paper mart",
    "owner_name":"Somakumar",
    "contact_number":"9841965830"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Keerthana waste paper mart",
    "owner_name":"Chandrasekhar",
    "contact_number":"8939802029"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Sathis waste paper mart",
    "owner_name":"Sathish",
    "contact_number":"9791046225"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Omkara waste paper mart",
    "owner_name":"Madhavan",
    "contact_number":"9444429993"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2195961000,13.0621842000 ]
    },
    "properties": {
    "enterprise_name":"Mohan bhai waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2194177000,13.0622382000 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"",
    "contact_number":"9414499049"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2194177000,13.0622382000 ]
    },
    "properties": {
    "enterprise_name":"Karthik waste paper mart",
    "owner_name":"Kalyanasundaram",
    "contact_number":"8883739355"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2194177000,13.0622382000 ]
    },
    "properties": {
    "enterprise_name":"Lk waste paper mart",
    "owner_name":"Murali",
    "contact_number":"9976767583"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2194177000,13.0622382000 ]
    },
    "properties": {
    "enterprise_name":"Karunanidi waste paper mart",
    "owner_name":"Karunanidhi",
    "contact_number":"9941404096"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2719583333,13.0622383333 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart ",
    "owner_name":"",
    "contact_number":"8939561412"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2317150000,13.0622866667 ]
    },
    "properties": {
    "enterprise_name":"punitha anthoniyar waste paper Mart",
    "owner_name":"David",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2196243400,13.0623365200 ]
    },
    "properties": {
    "enterprise_name":"Bala murgan",
    "owner_name":"",
    "contact_number":"7378837712"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1660861100,13.0624135000 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"",
    "contact_number":"9945634789"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1821064100,13.0624665000 ]
    },
    "properties": {
    "enterprise_name":"J.J waste paper mart",
    "owner_name":"Saundrapandiyan",
    "contact_number":"9940434507"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2443000000,13.0624750000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9841784703"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1825976400,13.0625029300 ]
    },
    "properties": {
    "enterprise_name":"Parithirudham waste paper mart",
    "owner_name":"John",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432116667,13.0625850000 ]
    },
    "properties": {
    "enterprise_name":"Munesh",
    "owner_name":"Munesh",
    "contact_number":"9962878578"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2431185000,13.0625900000 ]
    },
    "properties": {
    "enterprise_name":"Chinnadurai waste paper mart",
    "owner_name":"Chinnadurai",
    "contact_number":"9962878578"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2782033333,13.0627300000 ]
    },
    "properties": {
    "enterprise_name":"Sri muthumariamman waste paper mart",
    "owner_name":"Velmurugan",
    "contact_number":"9952076681"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2310500000,13.0629166667 ]
    },
    "properties": {
    "enterprise_name":"ramesh waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2143749500,13.0630756900 ]
    },
    "properties": {
    "enterprise_name":"Kanappan waste paper mart",
    "owner_name":"Kanappan",
    "contact_number":"9262672822"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1760817000,13.0631090800 ]
    },
    "properties": {
    "enterprise_name":"Suyambulingam",
    "owner_name":"Suyambudhurai",
    "contact_number":"9380971456"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1748356500,13.0631671000 ]
    },
    "properties": {
    "enterprise_name":"Sivasakthi waste paper mart",
    "owner_name":"Paramasivam",
    "contact_number":"9003289274"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2128825300,13.0632754300 ]
    },
    "properties": {
    "enterprise_name":"M s k Deen",
    "owner_name":"Selvan",
    "contact_number":"9444766386"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2429086000,13.0636065000 ]
    },
    "properties": {
    "enterprise_name":"Maharaja waste paper mart",
    "owner_name":"S natrajan",
    "contact_number":"9282137971"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2130871200,13.0637150800 ]
    },
    "properties": {
    "enterprise_name":"Sri Muthu malai waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9841363861"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1942035000,13.0641829800 ]
    },
    "properties": {
    "enterprise_name":"JJ waste paper shop",
    "owner_name":"Jagadesh",
    "contact_number":"9551882521"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1853745300,13.0643923300 ]
    },
    "properties": {
    "enterprise_name":"Sri Murugan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2091703900,13.0643942500 ]
    },
    "properties": {
    "enterprise_name":"Ramadev waste paper mart",
    "owner_name":"Ramadev",
    "contact_number":"9710619755"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1965909600,13.0644614400 ]
    },
    "properties": {
    "enterprise_name":"S.A.K.Waste paper shop",
    "owner_name":"Ayub khan",
    "contact_number":"9444048385"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1965218500,13.0645151900 ]
    },
    "properties": {
    "enterprise_name":"S k b waste paper mart",
    "owner_name":"Ayyakanu",
    "contact_number":"9444124527"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2137092800,13.0649655100 ]
    },
    "properties": {
    "enterprise_name":"Waste paper mart",
    "owner_name":"Raju",
    "contact_number":"9444037408"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2760684000,13.0650139000 ]
    },
    "properties": {
    "enterprise_name":"Pandiyan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2257233333,13.0652416667 ]
    },
    "properties": {
    "enterprise_name":"nilabar nisha waste paper Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2731427000,13.0654558000 ]
    },
    "properties": {
    "enterprise_name":"pandiyan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2722975000,13.0655981000 ]
    },
    "properties": {
    "enterprise_name":"Pms waste paper mart",
    "owner_name":"",
    "contact_number":"9789837077"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2265133333,13.0657200000 ]
    },
    "properties": {
    "enterprise_name":"navas waste paper Mart",
    "owner_name":"Abdul",
    "contact_number":"9880139519"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1963819400,13.0658894700 ]
    },
    "properties": {
    "enterprise_name":"Friends traders",
    "owner_name":"Duraimanikkam",
    "contact_number":"9841623534"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2233716667,13.0662550000 ]
    },
    "properties": {
    "enterprise_name":"shanthi waste paper Mart",
    "owner_name":"dharma raj",
    "contact_number":"9444646172"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2536483333,13.0662850000 ]
    },
    "properties": {
    "enterprise_name":"Maharaja waste paper",
    "owner_name":"Nadaraj",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1795224400,13.0663029900 ]
    },
    "properties": {
    "enterprise_name":"V.Rogith",
    "owner_name":"Kumar",
    "contact_number":"9710977027"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2139923000,13.0663226600 ]
    },
    "properties": {
    "enterprise_name":"V s waste paper mart",
    "owner_name":"Velu",
    "contact_number":"9941052540"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2103983200,13.0663759400 ]
    },
    "properties": {
    "enterprise_name":"Jasmine waste paper mart",
    "owner_name":"Mohamed",
    "contact_number":"99403561510"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2122982200,13.0663890400 ]
    },
    "properties": {
    "enterprise_name":"Muthu Teresa waste paper mart",
    "owner_name":"Jaya raj",
    "contact_number":"7544366788"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2619657100,13.0666638100 ]
    },
    "properties": {
    "enterprise_name":"South kovam road Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2125119900,13.0667855000 ]
    },
    "properties": {
    "enterprise_name":"Mythile metal mart",
    "owner_name":"Mano",
    "contact_number":"9791472327"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1847458500,13.0672370900 ]
    },
    "properties": {
    "enterprise_name":"SRM",
    "owner_name":"",
    "contact_number":"9176574534"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1807484100,13.0673785000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1729964000,13.0675826900 ]
    },
    "properties": {
    "enterprise_name":"Prem waste paper mart",
    "owner_name":"Prem",
    "contact_number":"9841641658"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2672652100,13.0677418100 ]
    },
    "properties": {
    "enterprise_name":"South kovam river road ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2174083333,13.0677833333 ]
    },
    "properties": {
    "enterprise_name":"a m waste paper mart",
    "owner_name":"",
    "contact_number":"9444185206"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2175216667,13.0680300000 ]
    },
    "properties": {
    "enterprise_name":"ss traders",
    "owner_name":"shakul amir",
    "contact_number":"9940308215"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2124694500,13.0681727300 ]
    },
    "properties": {
    "enterprise_name":"S v waste paper mart",
    "owner_name":"",
    "contact_number":"9940307400"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2197300000,13.0685433333 ]
    },
    "properties": {
    "enterprise_name":"madha waste paper mart",
    "owner_name":"joshep kathiravan",
    "contact_number":"9566063775"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1612397400,13.0685464400 ]
    },
    "properties": {
    "enterprise_name":"Balaji Waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9810088196"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2234200000,13.0687150000 ]
    },
    "properties": {
    "enterprise_name":"Divya waste paper Mart",
    "owner_name":"Senthil",
    "contact_number":"9941212224"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2105854000,13.0688439400 ]
    },
    "properties": {
    "enterprise_name":"Swamy waste paper mart",
    "owner_name":"Bommai swamy",
    "contact_number":"9791068694"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2680294000,13.0692189000 ]
    },
    "properties": {
    "enterprise_name":"Jagadesh waste paper mart",
    "owner_name":"Jagadesh",
    "contact_number":"9840285163"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974935900,13.0696959600 ]
    },
    "properties": {
    "enterprise_name":"S G waste paper mart",
    "owner_name":"Kesavan",
    "contact_number":"9386589314"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974549400,13.0698838400 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9841623534"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1975012700,13.0699726000 ]
    },
    "properties": {
    "enterprise_name":"Narayanan waste paper mart",
    "owner_name":"",
    "contact_number":"9941198132"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2694466667,13.0699983333 ]
    },
    "properties": {
    "enterprise_name":"Logesh waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974639600,13.0699984400 ]
    },
    "properties": {
    "enterprise_name":"Swamy waste paper mart",
    "owner_name":"",
    "contact_number":"9600027554"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2694183500,13.0700190000 ]
    },
    "properties": {
    "enterprise_name":"Chindhabai waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2395886000,13.0703499000 ]
    },
    "properties": {
    "enterprise_name":"M M s waste paper mart",
    "owner_name":"Mari",
    "contact_number":"9789824094"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2079140800,13.0705168000 ]
    },
    "properties": {
    "enterprise_name":"Kali Muthu waste paper mart",
    "owner_name":"Kalimuthu",
    "contact_number":"9176014951"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2643867000,13.0705753000 ]
    },
    "properties": {
    "enterprise_name":"Subbarayan St Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2703551700,13.0706313200 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2141719500,13.0706712900 ]
    },
    "properties": {
    "enterprise_name":"Tms waste paper mart",
    "owner_name":"",
    "contact_number":"9846744214"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2639070000,13.0707907000 ]
    },
    "properties": {
    "enterprise_name":"Adham waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2142904900,13.0708583100 ]
    },
    "properties": {
    "enterprise_name":"Saraswathi waste paper mart",
    "owner_name":"Selvi",
    "contact_number":"9952242703"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2670592800,13.0708768100 ]
    },
    "properties": {
    "enterprise_name":"Narayanan naicken St Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2680315200,13.0709109100 ]
    },
    "properties": {
    "enterprise_name":"Mohideen",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2701133333,13.0709183333 ]
    },
    "properties": {
    "enterprise_name":"Velan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2690773800,13.0709650900 ]
    },
    "properties": {
    "enterprise_name":"Kaka waste paper mart",
    "owner_name":"Ibrahim",
    "contact_number":"9004361600"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2103748600,13.0709906100 ]
    },
    "properties": {
    "enterprise_name":"V s waste paper mart",
    "owner_name":"Elango",
    "contact_number":"9710616270"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2691114000,13.0710011700 ]
    },
    "properties": {
    "enterprise_name":"Kaka waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2690983333,13.0710166667 ]
    },
    "properties": {
    "enterprise_name":"Ganapathi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2678359000,13.0713094000 ]
    },
    "properties": {
    "enterprise_name":"Velan waste paper mart",
    "owner_name":"Kirubakaran",
    "contact_number":"9087800012"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2181766667,13.0713566667 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"murganantham",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2132407400,13.0714227300 ]
    },
    "properties": {
    "enterprise_name":"Selva permual waste paper mart",
    "owner_name":"Selva raman",
    "contact_number":"7010185749"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625852900,13.0714639900 ]
    },
    "properties": {
    "enterprise_name":"Siyali St Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1667835500,13.0717066600 ]
    },
    "properties": {
    "enterprise_name":"Guru deva waste paper mart",
    "owner_name":"Deva",
    "contact_number":"8438956524"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2639881000,13.0717100400 ]
    },
    "properties": {
    "enterprise_name":"Velayathum chetty St Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2143500400,13.0717336400 ]
    },
    "properties": {
    "enterprise_name":"Jeeva waste paper mart",
    "owner_name":"Jeeva",
    "contact_number":"8363689921"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2634014700,13.0718501800 ]
    },
    "properties": {
    "enterprise_name":"Thiruvengadam St Pudupet",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2708675200,13.0721749400 ]
    },
    "properties": {
    "enterprise_name":"Babu",
    "owner_name":"Babu",
    "contact_number":"9566078719"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2705194000,13.0721921000 ]
    },
    "properties": {
    "enterprise_name":"Aravind waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2652694800,13.0721932200 ]
    },
    "properties": {
    "enterprise_name":"Ayyaiswamy St Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2683357300,13.0722723900 ]
    },
    "properties": {
    "enterprise_name":"Mahalaxmi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1878830100,13.0723230000 ]
    },
    "properties": {
    "enterprise_name":"Murgan waste paper mart",
    "owner_name":"",
    "contact_number":"9444651986"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2155038800,13.0724172300 ]
    },
    "properties": {
    "enterprise_name":"Durai",
    "owner_name":"",
    "contact_number":"9864421234"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2083047300,13.0727917600 ]
    },
    "properties": {
    "enterprise_name":"Sathish waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2084202900,13.0727961600 ]
    },
    "properties": {
    "enterprise_name":"Vetri vel waste paper mart",
    "owner_name":"Vetri",
    "contact_number":"9940476858"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2438251000,13.0728895600 ]
    },
    "properties": {
    "enterprise_name":"Divya bottle merchant",
    "owner_name":"Sakthivel",
    "contact_number":"7299290290"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2663381400,13.0729362900 ]
    },
    "properties": {
    "enterprise_name":"Lang's garden road Pudupet ch2",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1994059300,13.0729517700 ]
    },
    "properties": {
    "enterprise_name":"Vanaja waste paper mart 7",
    "owner_name":"Jaikumar",
    "contact_number":"9941830619"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1796416667,13.0730283333 ]
    },
    "properties": {
    "enterprise_name":"Sivan waste paper mart",
    "owner_name":"Siva",
    "contact_number":"9710820853"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2072761500,13.0730452300 ]
    },
    "properties": {
    "enterprise_name":"G B R  waste Paper mart",
    "owner_name":"Ganesh",
    "contact_number":"7541533740"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974445000,13.0734947000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman",
    "owner_name":"Duraipandiyan",
    "contact_number":"9940554665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2438009500,13.0735329200 ]
    },
    "properties": {
    "enterprise_name":"Durga waste paper mart",
    "owner_name":"Eswari",
    "contact_number":"9710069261"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2196016667,13.0736033333 ]
    },
    "properties": {
    "enterprise_name":"Shakthi vel waste paper mart",
    "owner_name":"Shakthi vel",
    "contact_number":"9710639031"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2158629000,13.0737882300 ]
    },
    "properties": {
    "enterprise_name":"Murgan paper mart",
    "owner_name":"Murgan",
    "contact_number":"8220046100"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032313600,13.0738773000 ]
    },
    "properties": {
    "enterprise_name":"Thava muthu waste paper mart",
    "owner_name":"Thava muthu",
    "contact_number":"8056170725"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2029977400,13.0738783200 ]
    },
    "properties": {
    "enterprise_name":"G B R scrap mart",
    "owner_name":"Babu",
    "contact_number":"9710307996"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2001136000,13.0739356000 ]
    },
    "properties": {
    "enterprise_name":"Sri vasan waste paper mart",
    "owner_name":"Vasan",
    "contact_number":"7396018723"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2199383333,13.0739433333 ]
    },
    "properties": {
    "enterprise_name":"as Naveen waste paper mart",
    "owner_name":"",
    "contact_number":"9840153689"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2199383333,13.0739466667 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"danuskodi",
    "contact_number":"9841655009"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2019096200,13.0739671100 ]
    },
    "properties": {
    "enterprise_name":"No name",
    "owner_name":"",
    "contact_number":"8664678884"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1846616667,13.0743383333 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan treaders",
    "owner_name":"Rajalingam",
    "contact_number":"9941337775"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1774950000,13.0747983333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559755700,13.0748021400 ]
    },
    "properties": {
    "enterprise_name":"Jai bottle store",
    "owner_name":"Rajaram",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2575440400,13.0750607600 ]
    },
    "properties": {
    "enterprise_name":"Lavanya waste paper mart",
    "owner_name":"Pathpanapan",
    "contact_number":"9941523711"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2258583333,13.0754416667 ]
    },
    "properties": {
    "enterprise_name":"Thiru murgan waste paper mart",
    "owner_name":"Ramu",
    "contact_number":"9551392444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2253928000,13.0754837000 ]
    },
    "properties": {
    "enterprise_name":"Vikarm waste paper mart",
    "owner_name":"Vikram",
    "contact_number":"9952861145"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068383100,13.0755468700 ]
    },
    "properties": {
    "enterprise_name":"Pupandi waste paper mart",
    "owner_name":"Pupandi",
    "contact_number":"8056232999"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1635591000,13.0756441300 ]
    },
    "properties": {
    "enterprise_name":"Vel murgan waste paper mart",
    "owner_name":"Thisitha",
    "contact_number":"9043952252"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2035974400,13.0756696600 ]
    },
    "properties": {
    "enterprise_name":"Vimala waste paper mart",
    "owner_name":"Vimal",
    "contact_number":"9655432186"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1984905000,13.0756705000 ]
    },
    "properties": {
    "enterprise_name":"Karthick waste paper mart",
    "owner_name":"",
    "contact_number":"9941133592"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2550765300,13.0758404300 ]
    },
    "properties": {
    "enterprise_name":"Suresh waste paper mart",
    "owner_name":"Jayaram",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2283616667,13.0758900000 ]
    },
    "properties": {
    "enterprise_name":"Baalu waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9382806143"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2699474600,13.0759655200 ]
    },
    "properties": {
    "enterprise_name":"Rayal traders",
    "owner_name":"Samath",
    "contact_number":"9884428540"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1676602900,13.0762161000 ]
    },
    "properties": {
    "enterprise_name":"Shanmugam waste paper mart",
    "owner_name":"Shanmugam",
    "contact_number":"8612573896"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2266846000,13.0762841000 ]
    },
    "properties": {
    "enterprise_name":"Ganesh waste paper mart",
    "owner_name":"Ganesh",
    "contact_number":"8056255347"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068716200,13.0764886800 ]
    },
    "properties": {
    "enterprise_name":"P s Raj traders",
    "owner_name":"Permual",
    "contact_number":"9952023619"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2038964000,13.0773708000 ]
    },
    "properties": {
    "enterprise_name":"Vanaja waste paper mart",
    "owner_name":"ShanmuGam",
    "contact_number":"9940621897"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2301733333,13.0775250000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9840565457"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1850533333,13.0779266667 ]
    },
    "properties": {
    "enterprise_name":"Parama guru waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2302716667,13.0786216667 ]
    },
    "properties": {
    "enterprise_name":"Yathara waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2703396500,13.0787282600 ]
    },
    "properties": {
    "enterprise_name":"Shop closed",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1880250000,13.0791300000 ]
    },
    "properties": {
    "enterprise_name":"Uma waste paper mart",
    "owner_name":"Lingaraj",
    "contact_number":"9841545414"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2041599000,13.0791906600 ]
    },
    "properties": {
    "enterprise_name":"Govinda waste paper mart",
    "owner_name":"Govinda",
    "contact_number":"8146200196"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1857900000,13.0792450000 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2141603500,13.0793306300 ]
    },
    "properties": {
    "enterprise_name":"Golden waste paper mart",
    "owner_name":"Guna",
    "contact_number":"9941181230"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1803700000,13.0795200000 ]
    },
    "properties": {
    "enterprise_name":"Murugalakshmy waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9380338737"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2147719000,13.0796262400 ]
    },
    "properties": {
    "enterprise_name":"Saranya waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9941780013"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2015477000,13.0799817000 ]
    },
    "properties": {
    "enterprise_name":"Punitha anthoniyar waste paper mart",
    "owner_name":"",
    "contact_number":"9940690980"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2305916667,13.0807950000 ]
    },
    "properties": {
    "enterprise_name":"Sri Balaji waste paper mart",
    "owner_name":"Sundar",
    "contact_number":"9840465468"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2269833333,13.0808166667 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper mart",
    "owner_name":"",
    "contact_number":"9841547690"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2228033333,13.0808883333 ]
    },
    "properties": {
    "enterprise_name":"K pv waste paper mart",
    "owner_name":"Permaul",
    "contact_number":"9952961145"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1908850000,13.0811083333 ]
    },
    "properties": {
    "enterprise_name":"Sivanandam",
    "owner_name":"Sivanandam",
    "contact_number":"9710083125"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1669579800,13.0811119700 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9444757126"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1862116667,13.0812433333 ]
    },
    "properties": {
    "enterprise_name":"Eam waste paper mart",
    "owner_name":"Pandiyan",
    "contact_number":"9246096990"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2519292600,13.0813625900 ]
    },
    "properties": {
    "enterprise_name":"Thangam waste paper mart",
    "owner_name":"Panirselvan",
    "contact_number":"9841339797"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1768333333,13.0814416667 ]
    },
    "properties": {
    "enterprise_name":"Mary annai waste paper mart",
    "owner_name":"A.suresh",
    "contact_number":"8939206171"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1803283333,13.0814516667 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Anand",
    "contact_number":"7871588672"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2150746600,13.0816192300 ]
    },
    "properties": {
    "enterprise_name":"Sri karmagam waste paper mart",
    "owner_name":"Megam",
    "contact_number":"044-26284920"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511031900,13.0820629000 ]
    },
    "properties": {
    "enterprise_name":"Thangam waste paper mart",
    "owner_name":"Jayachadhran",
    "contact_number":"9841468538"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1858783333,13.0820766667 ]
    },
    "properties": {
    "enterprise_name":"Saraswathy waste paper mart",
    "owner_name":"Dharchanamoorthy",
    "contact_number":"9940516661"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1916483333,13.0821033333 ]
    },
    "properties": {
    "enterprise_name":"Thilaga waste paper mart",
    "owner_name":"Ponpandi",
    "contact_number":"9941956022"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2123063800,13.0822605500 ]
    },
    "properties": {
    "enterprise_name":"Sri Devi waste paper mart",
    "owner_name":"Arumugam",
    "contact_number":"9444496621"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2498016500,13.0823528000 ]
    },
    "properties": {
    "enterprise_name":"Eegel waste paper mart",
    "owner_name":"Sathish",
    "contact_number":"9500157188"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1810750000,13.0824750000 ]
    },
    "properties": {
    "enterprise_name":"Thiruchandur Murugan waste paper mart",
    "owner_name":"Gurunathan",
    "contact_number":"9840502689"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2128190600,13.0825058300 ]
    },
    "properties": {
    "enterprise_name":"India waste paper mart",
    "owner_name":"Mariappan",
    "contact_number":"9940882116"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1865650000,13.0825650000 ]
    },
    "properties": {
    "enterprise_name":"TMS waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9841565585"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2786486000,13.0826267000 ]
    },
    "properties": {
    "enterprise_name":"Saravanan Papper.",
    "owner_name":"Saravanan",
    "contact_number":"9884043071"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1918100000,13.0826700000 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9884679553"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1663016667,13.0832950000 ]
    },
    "properties": {
    "enterprise_name":"Selvin waste paper mart",
    "owner_name":"A.Soundar",
    "contact_number":"9710912483"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1826450000,13.0833916667 ]
    },
    "properties": {
    "enterprise_name":"Nathish waste paper mart",
    "owner_name":"Vinoth",
    "contact_number":"9941612624"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2054876000,13.0833977000 ]
    },
    "properties": {
    "enterprise_name":"David waste paper mart",
    "owner_name":"David",
    "contact_number":"26223366"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1668766667,13.0834250000 ]
    },
    "properties": {
    "enterprise_name":"Dharmaraj",
    "owner_name":"Dharmaraj",
    "contact_number":"9042454756"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1796383333,13.0834333333 ]
    },
    "properties": {
    "enterprise_name":"Saraswathy waste paper mart",
    "owner_name":"Ganesan",
    "contact_number":"9095583221"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1919783333,13.0834683333 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Balpandi",
    "contact_number":"9710914934"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1717633333,13.0836883333 ]
    },
    "properties": {
    "enterprise_name":"Suganthi waste paper",
    "owner_name":"Suyamburaj",
    "contact_number":"9551678467"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2395854200,13.0837766200 ]
    },
    "properties": {
    "enterprise_name":"Periya swammi waste paper mart",
    "owner_name":"",
    "contact_number":"9884581601"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1689116667,13.0838733333 ]
    },
    "properties": {
    "enterprise_name":"Sathya waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1864550000,13.0839250000 ]
    },
    "properties": {
    "enterprise_name":"Raj Kumar",
    "owner_name":"Raj kumar",
    "contact_number":"9447573431"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2270569000,13.0840722000 ]
    },
    "properties": {
    "enterprise_name":"Sri venkateswara waste paper mart",
    "owner_name":"Gopal",
    "contact_number":"9840317457"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1931683333,13.0844850000 ]
    },
    "properties": {
    "enterprise_name":"Isai selvam",
    "owner_name":"Ganesh",
    "contact_number":"9710302032"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1991445000,13.0846018000 ]
    },
    "properties": {
    "enterprise_name":"Shri periyanavar waste",
    "owner_name":"Ramu",
    "contact_number":"9841909565"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1711233333,13.0847683333 ]
    },
    "properties": {
    "enterprise_name":"Krishnan waste paper mart",
    "owner_name":"Krishnan",
    "contact_number":"9710151444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2003333000,13.0847747000 ]
    },
    "properties": {
    "enterprise_name":"SMT waste paper mart",
    "owner_name":"Sukumar",
    "contact_number":"9677270261"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2546442200,13.0848924200 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan bottle store",
    "owner_name":"Murugan",
    "contact_number":"996278148"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2791050000,13.0849216000 ]
    },
    "properties": {
    "enterprise_name":"Vel Murugan traders.",
    "owner_name":"Prakash",
    "contact_number":"9790895399"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1824166667,13.0850166667 ]
    },
    "properties": {
    "enterprise_name":"VTR waste paper mart",
    "owner_name":"V.Thangaraj",
    "contact_number":"9941191146"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1639000000,13.0850266667 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"",
    "contact_number":"9445175420"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1691841400,13.0850864700 ]
    },
    "properties": {
    "enterprise_name":"Kesavan waste paper mart",
    "owner_name":"Kesavan",
    "contact_number":"9861238147"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1640107900,13.0850890000 ]
    },
    "properties": {
    "enterprise_name":"Murgan waste paper mart",
    "owner_name":"Murgan",
    "contact_number":"9444280859"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1908716667,13.0851133333 ]
    },
    "properties": {
    "enterprise_name":"Valarum traders waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9600475675"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1692116667,13.0851433333 ]
    },
    "properties": {
    "enterprise_name":"Muthuvel waste paper mart",
    "owner_name":"Muthuvel",
    "contact_number":"9710218739"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1889183333,13.0853033333 ]
    },
    "properties": {
    "enterprise_name":"Vijayalakshmy waste paper mart",
    "owner_name":"Chandra segar",
    "contact_number":"9952895255"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1997757000,13.0853510000 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan waste",
    "owner_name":"Murugan",
    "contact_number":"9884406664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2499612300,13.0853820400 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Manogar",
    "contact_number":"9094566598"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1691600000,13.0854316667 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan treaders",
    "owner_name":"Kumar",
    "contact_number":"9941337775"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1690382600,13.0855704400 ]
    },
    "properties": {
    "enterprise_name":"Vel murgan waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9941337775"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1949866667,13.0857016667 ]
    },
    "properties": {
    "enterprise_name":"David waste paper mart",
    "owner_name":"Ponpandi",
    "contact_number":"9710007387"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1745600000,13.0857383333 ]
    },
    "properties": {
    "enterprise_name":"Balamurugan waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9710484807"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2210250000,13.0857900000 ]
    },
    "properties": {
    "enterprise_name":"P rajagopal waste paper mart",
    "owner_name":"Rajagopal",
    "contact_number":"9841547670"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1848133333,13.0859083333 ]
    },
    "properties": {
    "enterprise_name":"Arokia annnai waste paper mart",
    "owner_name":"Stephen",
    "contact_number":"4426562910"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1913183333,13.0862383333 ]
    },
    "properties": {
    "enterprise_name":"SMT waste paper mart",
    "owner_name":"Tamilan",
    "contact_number":"8015580556"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1766416667,13.0866216667 ]
    },
    "properties": {
    "enterprise_name":"Vengadeshwara waste paper mart",
    "owner_name":"Jhon",
    "contact_number":"9962616627"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1925816667,13.0867016667 ]
    },
    "properties": {
    "enterprise_name":"SMT waste paper mart",
    "owner_name":"Samy kannu",
    "contact_number":"8015580556"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2593909300,13.0867969100 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657611700,13.0868996000 ]
    },
    "properties": {
    "enterprise_name":"Bhavani waste paper mart",
    "owner_name":"Kandhan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1610850000,13.0873650000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Vijayakumar",
    "contact_number":"8760637606"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1914550000,13.0874683333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"Sasikumar",
    "contact_number":"9790780230"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1914116667,13.0876300000 ]
    },
    "properties": {
    "enterprise_name":"Muthukumar waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9710635959"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2416597200,13.0879661800 ]
    },
    "properties": {
    "enterprise_name":"Mariyappan iron shop",
    "owner_name":"Mariyappan",
    "contact_number":"9176666801"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1946966667,13.0880016667 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Krishna Kumar",
    "contact_number":"7397347893"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1958900000,13.0881083333 ]
    },
    "properties": {
    "enterprise_name":"Lavanya waste paper mart",
    "owner_name":"E.pattu raja",
    "contact_number":"9380653898"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2656601100,13.0882331200 ]
    },
    "properties": {
    "enterprise_name":"Baskar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1607683333,13.0883383333 ]
    },
    "properties": {
    "enterprise_name":"G.muthukumar waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"7502390040"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2251450000,13.0884833333 ]
    },
    "properties": {
    "enterprise_name":"Selvi waste paper mart",
    "owner_name":"Sathis",
    "contact_number":"9791905821"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2500679000,13.0885712000 ]
    },
    "properties": {
    "enterprise_name":"Mks waste paper mart",
    "owner_name":"Saravana",
    "contact_number":"8056233558"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2238866667,13.0886283333 ]
    },
    "properties": {
    "enterprise_name":"Chandran waste paper",
    "owner_name":"Chandran",
    "contact_number":"9884441567"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511033333,13.0887050000 ]
    },
    "properties": {
    "enterprise_name":"Ganapathi waste paper mart",
    "owner_name":"Palani",
    "contact_number":"9840286014"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2658633700,13.0887207000 ]
    },
    "properties": {
    "enterprise_name":"Waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2314221000,13.0888660000 ]
    },
    "properties": {
    "enterprise_name":"Mks waste paper mart",
    "owner_name":"",
    "contact_number":"9884387595"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1950900000,13.0889000000 ]
    },
    "properties": {
    "enterprise_name":"Uma waste paper mart",
    "owner_name":"Lingaraj",
    "contact_number":"9710676860"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1971900000,13.0889533333 ]
    },
    "properties": {
    "enterprise_name":"Kandan",
    "owner_name":"Kandan",
    "contact_number":"9840728585"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2504655300,13.0892988700 ]
    },
    "properties": {
    "enterprise_name":"Shop closed",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2552127000,13.0893208000 ]
    },
    "properties": {
    "enterprise_name":"David waste paper mart",
    "owner_name":"David",
    "contact_number":"9791020798"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1818300000,13.0897100000 ]
    },
    "properties": {
    "enterprise_name":"Selvam Mari waste paper mart",
    "owner_name":"Vel",
    "contact_number":"9841939491"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1819450000,13.0897533333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1899666667,13.0901016667 ]
    },
    "properties": {
    "enterprise_name":"Tvs waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1880433333,13.0901300000 ]
    },
    "properties": {
    "enterprise_name":"David treaders",
    "owner_name":"",
    "contact_number":"9381144331"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1811066667,13.0901966667 ]
    },
    "properties": {
    "enterprise_name":"Pechiamman waste paper mart",
    "owner_name":"Pechimuthu",
    "contact_number":"9841480029"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2540916667,13.0902483333 ]
    },
    "properties": {
    "enterprise_name":"Balu waste paper mart",
    "owner_name":"Balu",
    "contact_number":"9444229276"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2548116000,13.0903479000 ]
    },
    "properties": {
    "enterprise_name":"Mani waste paper mart",
    "owner_name":"Manikandan",
    "contact_number":"9840261445"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2184156000,13.0903571000 ]
    },
    "properties": {
    "enterprise_name":"Sree Kannan waste paper mart",
    "owner_name":"Vignesh",
    "contact_number":"044 26280423"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1973050000,13.0904733333 ]
    },
    "properties": {
    "enterprise_name":"V.m.a.",
    "owner_name":"",
    "contact_number":"9941781077"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2602539000,13.0904773000 ]
    },
    "properties": {
    "enterprise_name":"Sree murugan waste paper mart",
    "owner_name":"Murugan ",
    "contact_number":"8939603356"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2589638000,13.0905209000 ]
    },
    "properties": {
    "enterprise_name":"Vinayakar waste paper mart",
    "owner_name":"",
    "contact_number":"9941121893"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1795116667,13.0906166667 ]
    },
    "properties": {
    "enterprise_name":"Saravanan paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9841178989"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2025395000,13.0907470000 ]
    },
    "properties": {
    "enterprise_name":"Kannan waste paper mart",
    "owner_name":"Ravi",
    "contact_number":"9840607715"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2542081000,13.0913277000 ]
    },
    "properties": {
    "enterprise_name":"Vel waste paper mart",
    "owner_name":"Velu",
    "contact_number":"9841352955"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1844016667,13.0913600000 ]
    },
    "properties": {
    "enterprise_name":"New mutharamman waste paper mart",
    "owner_name":"Vijay Kumar",
    "contact_number":"9941702931"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2028239000,13.0917738000 ]
    },
    "properties": {
    "enterprise_name":"Good luck waste paper mart",
    "owner_name":"Martin",
    "contact_number":"9094911450"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1885733333,13.0919116667 ]
    },
    "properties": {
    "enterprise_name":"Pachammal waste paper mart",
    "owner_name":"Babu",
    "contact_number":"9444180766"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2904847600,13.0920868800 ]
    },
    "properties": {
    "enterprise_name":"Seiyadhu waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104027600,13.0922271700 ]
    },
    "properties": {
    "enterprise_name":"Sri kannan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"044 26280423"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1770916667,13.0922316667 ]
    },
    "properties": {
    "enterprise_name":"Kathireson",
    "owner_name":"Kathireson",
    "contact_number":"9597546143"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2536549000,13.0922443000 ]
    },
    "properties": {
    "enterprise_name":"Meiyappan waste paper mart",
    "owner_name":"Meiyappan",
    "contact_number":"9840733982"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2905330100,13.0922537300 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"Chindrasekar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2905875200,13.0923057800 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"Chendrasekar",
    "contact_number":"9840642175"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2509093800,13.0925004900 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2509998700,13.0925214800 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Sekar",
    "contact_number":"9789029006"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2545379000,13.0925432000 ]
    },
    "properties": {
    "enterprise_name":"Joseph waste paper mart",
    "owner_name":"",
    "contact_number":"8056233557"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2508413000,13.0926155000 ]
    },
    "properties": {
    "enterprise_name":"Madha waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9941588571"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2271633000,13.0926209000 ]
    },
    "properties": {
    "enterprise_name":"Mr waste paper mart",
    "owner_name":"Antony",
    "contact_number":"7550285052"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2025654000,13.0927336000 ]
    },
    "properties": {
    "enterprise_name":"Raja waste paper mart",
    "owner_name":"Raja",
    "contact_number":"7397410325"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2554693000,13.0929660000 ]
    },
    "properties": {
    "enterprise_name":"Vicky waste paper mart",
    "owner_name":"",
    "contact_number":"9941186314"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2534669000,13.0929733000 ]
    },
    "properties": {
    "enterprise_name":"Mks waste paper mart",
    "owner_name":"Padaiselvan",
    "contact_number":"9444145085"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2889540600,13.0931416500 ]
    },
    "properties": {
    "enterprise_name":"Prakash",
    "owner_name":"Prakash",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1772116667,13.0933233333 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9094069001"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2312547300,13.0934264800 ]
    },
    "properties": {
    "enterprise_name":"Murugan old waste Paper mart",
    "owner_name":"Elango",
    "contact_number":"9841634017"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1767316667,13.0934500000 ]
    },
    "properties": {
    "enterprise_name":"Muthukumar waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9444301773"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2434949000,13.0934659000 ]
    },
    "properties": {
    "enterprise_name":"Mohammed waste papermart",
    "owner_name":"Abdul",
    "contact_number":"9941302781"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2845430000,13.0936697100 ]
    },
    "properties": {
    "enterprise_name":"Anbu waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2885133100,13.0937196900 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2169704400,13.0937906000 ]
    },
    "properties": {
    "enterprise_name":"Kuppsamay waste paper mart",
    "owner_name":"Kuppsamay",
    "contact_number":"978056612"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1777416667,13.0939083333 ]
    },
    "properties": {
    "enterprise_name":"Bosh",
    "owner_name":"Bosh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2314094000,13.0941796000 ]
    },
    "properties": {
    "enterprise_name":"Cvr",
    "owner_name":"Karthick",
    "contact_number":"7418126471"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1887433333,13.0941816667 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1812816667,13.0945350000 ]
    },
    "properties": {
    "enterprise_name":"Kss waste paper mart",
    "owner_name":"Jeeva",
    "contact_number":"9003190196"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2434082000,13.0945385000 ]
    },
    "properties": {
    "enterprise_name":"Michael waste paper mart",
    "owner_name":"Arokiadhas",
    "contact_number":"9092156771"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1985172000,13.0946939000 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi waste paper mart",
    "owner_name":"",
    "contact_number":"9841087382"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2819166400,13.0948845800 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Kannan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1547133333,13.0949700000 ]
    },
    "properties": {
    "enterprise_name":"Kanniyamman waste paper mart",
    "owner_name":"Balakrishnan",
    "contact_number":"8675888611"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1573066667,13.0949966667 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman plastic",
    "owner_name":"Murugan",
    "contact_number":"8056056071"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2771101400,13.0950013200 ]
    },
    "properties": {
    "enterprise_name":"SP paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1887550000,13.0950500000 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste paper mart",
    "owner_name":"Sellathurai",
    "contact_number":"9710647665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2893968000,13.0951000800 ]
    },
    "properties": {
    "enterprise_name":"Jai traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2012704300,13.0951468600 ]
    },
    "properties": {
    "enterprise_name":"Sri Venkateswara waste paper mart",
    "owner_name":"Venkatesh",
    "contact_number":"9840115546"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1573716667,13.0951500000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman iron shop",
    "owner_name":"Balu",
    "contact_number":"8056056071"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2910234100,13.0952217000 ]
    },
    "properties": {
    "enterprise_name":"Yuvraj",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1586300000,13.0955200000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":"7010166523"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1524116667,13.0955750000 ]
    },
    "properties": {
    "enterprise_name":"Thillai waste paper mart",
    "owner_name":"Thillai",
    "contact_number":"9884894476"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2012792200,13.0956434100 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi waste paper mart",
    "owner_name":"S E muthu",
    "contact_number":"9841087382"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1874750000,13.0957483333 ]
    },
    "properties": {
    "enterprise_name":"Sri Sri Lakshmi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2382255900,13.0959558100 ]
    },
    "properties": {
    "enterprise_name":"Ayyanar waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"9444405657"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1868733333,13.0960150000 ]
    },
    "properties": {
    "enterprise_name":"Mogan waste paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2262440800,13.0962664900 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":" Chandra Sekhar",
    "contact_number":"9941809841"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2214610900,13.0962726600 ]
    },
    "properties": {
    "enterprise_name":"Jb waste paper",
    "owner_name":"",
    "contact_number":"23612307"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1846033333,13.0963033333 ]
    },
    "properties": {
    "enterprise_name":"Samy kani waste paper mart",
    "owner_name":"Mani",
    "contact_number":"9761040889"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2842772000,13.0963374000 ]
    },
    "properties": {
    "enterprise_name":"Thameen Ansari",
    "owner_name":"Thameen",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2193864600,13.0963820300 ]
    },
    "properties": {
    "enterprise_name":"Punitha anthoniyar waste paper mart",
    "owner_name":"",
    "contact_number":"9840469856"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2369068500,13.0964003500 ]
    },
    "properties": {
    "enterprise_name":"Srinivasa waste paper mart",
    "owner_name":"Murali",
    "contact_number":"97108894499"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2265052000,13.0964385000 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"Sekar",
    "contact_number":"9941809841"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2367092600,13.0964387100 ]
    },
    "properties": {
    "enterprise_name":"Srinivasa waste paper mart",
    "owner_name":"Paramesvaran",
    "contact_number":"9710899409"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2370132400,13.0964525600 ]
    },
    "properties": {
    "enterprise_name":"Srivasann waste paper mart ",
    "owner_name":"",
    "contact_number":"9444232329"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2281051800,13.0966149300 ]
    },
    "properties": {
    "enterprise_name":"S.A.S WASTE PAPER MART",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2832757400,13.0966421400 ]
    },
    "properties": {
    "enterprise_name":"Nithiyanandhan",
    "owner_name":"Nithiyanandhan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2348605000,13.0966534000 ]
    },
    "properties": {
    "enterprise_name":"Rks waste paper mart",
    "owner_name":"Joseph",
    "contact_number":"9629178525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2319275400,13.0967515000 ]
    },
    "properties": {
    "enterprise_name":"SAS waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"9444546410"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2343404000,13.0967656000 ]
    },
    "properties": {
    "enterprise_name":"Metro waste paper mart",
    "owner_name":"Ramesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2339438500,13.0968364300 ]
    },
    "properties": {
    "enterprise_name":"Metro waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"8608092958"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1763783333,13.0968883333 ]
    },
    "properties": {
    "enterprise_name":"Selva Kumar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2318453000,13.0969019000 ]
    },
    "properties": {
    "enterprise_name":"S A S waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"26440910"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1823400000,13.0969483333 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart. 2/1",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2016665000,13.0969544100 ]
    },
    "properties": {
    "enterprise_name":"Anitha waste paper mart",
    "owner_name":"Baskar",
    "contact_number":"9841631384"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2015967100,13.0969632300 ]
    },
    "properties": {
    "enterprise_name":"Anitha waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"98416 31384"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2913433700,13.0969801400 ]
    },
    "properties": {
    "enterprise_name":"Ravi traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2475014000,13.0971865000 ]
    },
    "properties": {
    "enterprise_name":"Reshma waste paper mart",
    "owner_name":"",
    "contact_number":"8939227498"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1793316667,13.0971983333 ]
    },
    "properties": {
    "enterprise_name":"Selva Rani waste paper mart",
    "owner_name":"Natraja",
    "contact_number":"9266646829"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2291371100,13.0973120800 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"Murugan",
    "contact_number":"9941929643"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2266776000,13.0973144000 ]
    },
    "properties": {
    "enterprise_name":"Sree balaji waste paper mart ",
    "owner_name":"Kumar",
    "contact_number":"9841480312"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1813500000,13.0973866667 ]
    },
    "properties": {
    "enterprise_name":"Dhanalakshmi waste paper mart",
    "owner_name":"Mahesh",
    "contact_number":"9710778822"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2576762000,13.0974608000 ]
    },
    "properties": {
    "enterprise_name":"sree balaji waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"24962272"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1852583333,13.0974683333 ]
    },
    "properties": {
    "enterprise_name":"Saravanan waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"950067360"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2575428000,13.0974728000 ]
    },
    "properties": {
    "enterprise_name":"Padmanaban waste paper mart",
    "owner_name":"Padmanaban",
    "contact_number":"8964321456"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1856566667,13.0974833333 ]
    },
    "properties": {
    "enterprise_name":"Ponmani waste paper mart",
    "owner_name":"Ponmani",
    "contact_number":"9790725043"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2908293200,13.0975157100 ]
    },
    "properties": {
    "enterprise_name":"Razeek",
    "owner_name":"Razeek",
    "contact_number":"9176282640"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2411552800,13.0975456600 ]
    },
    "properties": {
    "enterprise_name":"Ram waste paper mart",
    "owner_name":"Ram",
    "contact_number":"9381173778"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2532760000,13.0976453000 ]
    },
    "properties": {
    "enterprise_name":"Elumalai waste paper Mart",
    "owner_name":"",
    "contact_number":"9941195867"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2540199000,13.0976548000 ]
    },
    "properties": {
    "enterprise_name":"Vasudevan waste paper mart",
    "owner_name":"Vasudevan",
    "contact_number":"9840586723"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2885752300,13.0976567500 ]
    },
    "properties": {
    "enterprise_name":"Sermenraj",
    "owner_name":"Sermenraj",
    "contact_number":"9941273600"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2845130300,13.0976605500 ]
    },
    "properties": {
    "enterprise_name":"Sakthi traders",
    "owner_name":"Rupan",
    "contact_number":"9940507143"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2845116800,13.0976796100 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2861530200,13.0976913500 ]
    },
    "properties": {
    "enterprise_name":"Purudha waste paper mart",
    "owner_name":"Umar",
    "contact_number":"7395935864"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2849616400,13.0977257600 ]
    },
    "properties": {
    "enterprise_name":"Gopi",
    "owner_name":"Gopi",
    "contact_number":"9444456751"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1781716667,13.0977683333 ]
    },
    "properties": {
    "enterprise_name":"Uma waste paper mart",
    "owner_name":"Beeman",
    "contact_number":"9710505201"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2886326000,13.0977815500 ]
    },
    "properties": {
    "enterprise_name":"Sermanraj",
    "owner_name":"Sermanraj",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2845506900,13.0978081500 ]
    },
    "properties": {
    "enterprise_name":"Rupan",
    "owner_name":"Rupan",
    "contact_number":"8939223485"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2834974200,13.0978306500 ]
    },
    "properties": {
    "enterprise_name":"Karpaga vinayaga waste paper mart",
    "owner_name":"Kanthan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1899916667,13.0978650000 ]
    },
    "properties": {
    "enterprise_name":"Murugeson waste paper",
    "owner_name":"Murugeson",
    "contact_number":"9003035829"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2834028700,13.0979402800 ]
    },
    "properties": {
    "enterprise_name":"Karpaga Vinayagar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1592750000,13.0980233333 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Sugumar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1593800000,13.0980383333 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Sugumar",
    "contact_number":"9942104494"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2824800200,13.0982852900 ]
    },
    "properties": {
    "enterprise_name":"Mani",
    "owner_name":"Mani",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2347136300,13.0983017100 ]
    },
    "properties": {
    "enterprise_name":"Brama Sakthi waste paper mart",
    "owner_name":"",
    "contact_number":"044 2345678901"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2807038500,13.0983662900 ]
    },
    "properties": {
    "enterprise_name":"Rajeshwari waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2810688600,13.0983918800 ]
    },
    "properties": {
    "enterprise_name":"Nivedha waste paper mart",
    "owner_name":"Murugan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2925923200,13.0984940300 ]
    },
    "properties": {
    "enterprise_name":"Bismi waste paper mart",
    "owner_name":"Yousuf",
    "contact_number":"9941885710"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2280719300,13.0985192000 ]
    },
    "properties": {
    "enterprise_name":"Bismilla waste paper mart",
    "owner_name":"Rehman ",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2581035000,13.0985238000 ]
    },
    "properties": {
    "enterprise_name":"Maruthi waste paper mart",
    "owner_name":"C.murugasen",
    "contact_number":"28174305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1755750000,13.0985666667 ]
    },
    "properties": {
    "enterprise_name":"Vengadeshwara waste paper mart",
    "owner_name":"Senthil",
    "contact_number":"9840588556"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2593140000,13.0986444000 ]
    },
    "properties": {
    "enterprise_name":"Mbk waste paper mart",
    "owner_name":"Karna",
    "contact_number":"9380216547"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2908226800,13.0986804600 ]
    },
    "properties": {
    "enterprise_name":"M.G.R waste paper mart",
    "owner_name":"Pandiyan",
    "contact_number":"9940274066"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2518215000,13.0987041000 ]
    },
    "properties": {
    "enterprise_name":"Parthiban waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2571299000,13.0987449000 ]
    },
    "properties": {
    "enterprise_name":"Selvin waste paper mart",
    "owner_name":"Selvin",
    "contact_number":"9176338425"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1869616667,13.0987583333 ]
    },
    "properties": {
    "enterprise_name":"Rishwana plastic",
    "owner_name":"Samadhani",
    "contact_number":"9710215643"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2261540000,13.0987592000 ]
    },
    "properties": {
    "enterprise_name":"Ks waste paper mart",
    "owner_name":"Vinoth",
    "contact_number":"9941837629"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2896209600,13.0988706200 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2563153000,13.0989337000 ]
    },
    "properties": {
    "enterprise_name":"Samuvel waste paper mart",
    "owner_name":"",
    "contact_number":"8056277336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2645770000,13.0989626000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"",
    "contact_number":"9840385260"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2645398000,13.0989738000 ]
    },
    "properties": {
    "enterprise_name":"Kathiravan",
    "owner_name":"",
    "contact_number":"9840236212"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2396887500,13.0990012900 ]
    },
    "properties": {
    "enterprise_name":"Ruban waste paper mart",
    "owner_name":"Ruban",
    "contact_number":"9884723516"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1795200000,13.0990933333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1794457700,13.0991881200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1599500000,13.0992750000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Ramesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2907487500,13.0994437000 ]
    },
    "properties": {
    "enterprise_name":"Rajendran",
    "owner_name":"Rajendran",
    "contact_number":"9840168368"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2424165100,13.0994547300 ]
    },
    "properties": {
    "enterprise_name":"Sangeetha waste paper mart",
    "owner_name":"Bharath",
    "contact_number":"9677013639"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2836102400,13.0996744100 ]
    },
    "properties": {
    "enterprise_name":"Udhaya Kumar waste paper mart",
    "owner_name":"Ramesh",
    "contact_number":"9884523711"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1748100000,13.0997350000 ]
    },
    "properties": {
    "enterprise_name":"Devaraj waste paper mart",
    "owner_name":"Devaraj",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2895271400,13.1000034500 ]
    },
    "properties": {
    "enterprise_name":"Saravanan",
    "owner_name":"Saravanan",
    "contact_number":"8608013723"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1796650000,13.1003100000 ]
    },
    "properties": {
    "enterprise_name":"Anand",
    "owner_name":"Lakshman",
    "contact_number":"9003247021"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1735466667,13.1003216667 ]
    },
    "properties": {
    "enterprise_name":"Kadharboi",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1708733333,13.1004083333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2902274500,13.1006789200 ]
    },
    "properties": {
    "enterprise_name":"Maheshwari waste paper mart",
    "owner_name":"Selvaraj",
    "contact_number":"9444160187"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2086374800,13.1008634600 ]
    },
    "properties": {
    "enterprise_name":"Kaliappan",
    "owner_name":"Kaliappan",
    "contact_number":"9840942946"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2086186300,13.1009102500 ]
    },
    "properties": {
    "enterprise_name":"Raja waste Paper mart",
    "owner_name":"Raja",
    "contact_number":"9043633736"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2086640900,13.1009157300 ]
    },
    "properties": {
    "enterprise_name":"Babu",
    "owner_name":"Babu",
    "contact_number":"7358770529"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1722500000,13.1011583333 ]
    },
    "properties": {
    "enterprise_name":"Saravanan waste paper mart",
    "owner_name":"Sambath",
    "contact_number":"9940456634"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1635500000,13.1012116667 ]
    },
    "properties": {
    "enterprise_name":"Siva treaders",
    "owner_name":"Antony",
    "contact_number":"9025279591"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1759616667,13.1015550000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1594166667,13.1015900000 ]
    },
    "properties": {
    "enterprise_name":"Velram waste paper mart",
    "owner_name":"Velram",
    "contact_number":"9514425972"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2049235700,13.1017203900 ]
    },
    "properties": {
    "enterprise_name":"Anand nadar",
    "owner_name":"Anand nadar",
    "contact_number":"9941534615"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032838900,13.1017506700 ]
    },
    "properties": {
    "enterprise_name":"Selva",
    "owner_name":"",
    "contact_number":"44265487910"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032151800,13.1017721500 ]
    },
    "properties": {
    "enterprise_name":"Siva balamurugan waste paper mart",
    "owner_name":"Nandha kumar",
    "contact_number":"94444 96945"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2673850000,13.1020000000 ]
    },
    "properties": {
    "enterprise_name":"Mari waste paper mart",
    "owner_name":"Mari",
    "contact_number":"9176522870"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2055750600,13.1021832900 ]
    },
    "properties": {
    "enterprise_name":"Rajesh",
    "owner_name":"Rajesh",
    "contact_number":"9962107444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2639701000,13.1021934000 ]
    },
    "properties": {
    "enterprise_name":"Sankar waste paper mart",
    "owner_name":"Sankar",
    "contact_number":"9962918004"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1705400000,13.1022800000 ]
    },
    "properties": {
    "enterprise_name":"Paramaguru iron shop",
    "owner_name":"Pichai",
    "contact_number":"9952031730"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2634300000,13.1023233333 ]
    },
    "properties": {
    "enterprise_name":"Balan waste paper mart",
    "owner_name":"Balan",
    "contact_number":"9841488195"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2615300000,13.1025833333 ]
    },
    "properties": {
    "enterprise_name":"Sree palani andavar waste paper mart",
    "owner_name":"Arumugam",
    "contact_number":"8754633618"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2114315400,13.1025855100 ]
    },
    "properties": {
    "enterprise_name":"114/34, Malini waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9841754581"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2842310700,13.1028048200 ]
    },
    "properties": {
    "enterprise_name":"Murugan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2632283333,13.1029733333 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Sekar",
    "contact_number":"9952977840"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2491982000,13.1029897000 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Mayil vaganan",
    "contact_number":"7418385756"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2492343000,13.1030077000 ]
    },
    "properties": {
    "enterprise_name":"Dharmar waste paper mart",
    "owner_name":"Dharmar",
    "contact_number":"9840167794"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2487177000,13.1032385000 ]
    },
    "properties": {
    "enterprise_name":"Sri vinayagar waste paper mart",
    "owner_name":"Sathis",
    "contact_number":"9884447772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1726200000,13.1032416667 ]
    },
    "properties": {
    "enterprise_name":"Arasur muthal waste paper mart",
    "owner_name":"Mahalingam",
    "contact_number":"9962373969"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1775066667,13.1033066667 ]
    },
    "properties": {
    "enterprise_name":"Velmurugan waste paper mart",
    "owner_name":"Velmurugan",
    "contact_number":"9791097305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2843932400,13.1039241200 ]
    },
    "properties": {
    "enterprise_name":"Bismi paper mart",
    "owner_name":"Usupu",
    "contact_number":"9941885710"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1790650000,13.1042850000 ]
    },
    "properties": {
    "enterprise_name":"Selvan waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1539850000,13.1042950000 ]
    },
    "properties": {
    "enterprise_name":"NR waste paper mart",
    "owner_name":"Nagaraj",
    "contact_number":"9841314685"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1792183333,13.1043350000 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"9551627733"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1712233333,13.1043850000 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9384621113"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2506476200,13.1044629700 ]
    },
    "properties": {
    "enterprise_name":"Meenakshi waste paper mart",
    "owner_name":"Naresh",
    "contact_number":"7708536545"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2618900000,13.1046700000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9842776361"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2476752300,13.1048799700 ]
    },
    "properties": {
    "enterprise_name":"Kuppsamay waste paper mart",
    "owner_name":"Kuppsamay",
    "contact_number":"7418357561"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1539466667,13.1049200000 ]
    },
    "properties": {
    "enterprise_name":"Jero Shalini waste paper mart",
    "owner_name":"Prabhu dheva",
    "contact_number":"9894229468"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2604880000,13.1050714000 ]
    },
    "properties": {
    "enterprise_name":"Darmar waste paper mart",
    "owner_name":"Darmar",
    "contact_number":"9840167785"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2431353000,13.1052592000 ]
    },
    "properties": {
    "enterprise_name":"R n j waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9842776368"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2450085000,13.1053107000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"7667701621"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2096251600,13.1055681900 ]
    },
    "properties": {
    "enterprise_name":"David waste paper mart",
    "owner_name":"Muthu raj",
    "contact_number":"9344102020"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2654466667,13.1058700000 ]
    },
    "properties": {
    "enterprise_name":"Vasanthi waste paper mart",
    "owner_name":"Rajesh",
    "contact_number":"9884447771"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2438066000,13.1058785000 ]
    },
    "properties": {
    "enterprise_name":"Sree Kannan waste paper mart",
    "owner_name":"",
    "contact_number":"9941977658"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2611883333,13.1059050000 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":"9941588564"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2032617300,13.1064061200 ]
    },
    "properties": {
    "enterprise_name":"E.s waste paper mart",
    "owner_name":"Kamal",
    "contact_number":"9840391447"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1512466667,13.1064283333 ]
    },
    "properties": {
    "enterprise_name":"G.muthukumar waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9677226333"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1797750000,13.1064383333 ]
    },
    "properties": {
    "enterprise_name":"Vanaja",
    "owner_name":"Vanaja",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2081097300,13.1064648100 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi waste paper mart",
    "owner_name":"Saravanan",
    "contact_number":"9941064449"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1856800000,13.1065816667 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2671383333,13.1066900000 ]
    },
    "properties": {
    "enterprise_name":"Marson waste papermart",
    "owner_name":"Perakman",
    "contact_number":"9444072277"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2102044500,13.1067922700 ]
    },
    "properties": {
    "enterprise_name":"Sathish waste paper mart",
    "owner_name":"Rajan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2007466500,13.1071887100 ]
    },
    "properties": {
    "enterprise_name":"Mohammed",
    "owner_name":"",
    "contact_number":"98956789011"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2935781000,13.1072862000 ]
    },
    "properties": {
    "enterprise_name":"Srinivasan paper.",
    "owner_name":"Mani",
    "contact_number":"9514172426"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1833816667,13.1075416667 ]
    },
    "properties": {
    "enterprise_name":"Daniel ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2062858700,13.1077885400 ]
    },
    "properties": {
    "enterprise_name":"146/2 Mutharamman waste paper mart",
    "owner_name":"Guna",
    "contact_number":"9940557562"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1993114600,13.1078441000 ]
    },
    "properties": {
    "enterprise_name":"Nainar Mohammed",
    "owner_name":"",
    "contact_number":"8807395221"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2323266667,13.1081216667 ]
    },
    "properties": {
    "enterprise_name":"Ganapathi waste paper mart",
    "owner_name":"",
    "contact_number":"9556602041"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2039989100,13.1083162700 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste mart",
    "owner_name":"Kumar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1804933333,13.1085400000 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste paper mart",
    "owner_name":"",
    "contact_number":"9840464989"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2022564300,13.1087235200 ]
    },
    "properties": {
    "enterprise_name":"Sathish ac scrap",
    "owner_name":"Sathish",
    "contact_number":"8939125657"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2352883333,13.1088966667 ]
    },
    "properties": {
    "enterprise_name":"Fathima waste paper mart",
    "owner_name":"",
    "contact_number":"8566732957"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2001522300,13.1090050900 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9444727436"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511653700,13.1090298900 ]
    },
    "properties": {
    "enterprise_name":"Sundar waste paper mart",
    "owner_name":"Sundar",
    "contact_number":"9884140706"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2335916667,13.1090850000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"",
    "contact_number":"9941628712"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2519213400,13.1093235000 ]
    },
    "properties": {
    "enterprise_name":"Guru Moorthy waste paper mart",
    "owner_name":"",
    "contact_number":"8552448566"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2376016667,13.1093550000 ]
    },
    "properties": {
    "enterprise_name":"Jaganathan waste paper mart",
    "owner_name":"",
    "contact_number":"9840567329"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2364366667,13.1095050000 ]
    },
    "properties": {
    "enterprise_name":"Loganathan waste paper mart",
    "owner_name":"",
    "contact_number":"9488570002"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2588579400,13.1097408300 ]
    },
    "properties": {
    "enterprise_name":"S10",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2612597100,13.1098660900 ]
    },
    "properties": {
    "enterprise_name":"S8",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2329016667,13.1101416667 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"",
    "contact_number":"9840766543"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625364400,13.1105125200 ]
    },
    "properties": {
    "enterprise_name":"S7",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2823206700,13.1105252200 ]
    },
    "properties": {
    "enterprise_name":"Anandan traders\nM.man",
    "owner_name":"Anandan",
    "contact_number":"9884087668"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2484597900,13.1105377700 ]
    },
    "properties": {
    "enterprise_name":"Vel murgan waste paper mart",
    "owner_name":"Velu",
    "contact_number":"9600504586"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2494358100,13.1106366100 ]
    },
    "properties": {
    "enterprise_name":"Indria Devi waste paper mart",
    "owner_name":"Devi",
    "contact_number":"9150575250"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2475334700,13.1106607400 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"Sakthi",
    "contact_number":"9944779447"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2317016667,13.1107200000 ]
    },
    "properties": {
    "enterprise_name":"Saravana waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2832263500,13.1107333200 ]
    },
    "properties": {
    "enterprise_name":"Ya",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2366400000,13.1109450000 ]
    },
    "properties": {
    "enterprise_name":"Kumaran waste paper mart",
    "owner_name":"",
    "contact_number":"9840285145"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2494032600,13.1110794700 ]
    },
    "properties": {
    "enterprise_name":"Permual waste paper mart",
    "owner_name":"",
    "contact_number":"7636778450"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2132574000,13.1111199000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart ",
    "owner_name":"Karthigeyen",
    "contact_number":"9171688992"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2437798800,13.1112899300 ]
    },
    "properties": {
    "enterprise_name":"Raja Govinda waste paper mart",
    "owner_name":"",
    "contact_number":"8610926892"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2565292900,13.1113694500 ]
    },
    "properties": {
    "enterprise_name":"S9",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1816750000,13.1113933333 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"Priyan",
    "contact_number":"9884149475"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2344050000,13.1114300000 ]
    },
    "properties": {
    "enterprise_name":"Veeraragavar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2893059100,13.1114566300 ]
    },
    "properties": {
    "enterprise_name":"Bosskar waste paper mart",
    "owner_name":"Bosskar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2826380200,13.1117736000 ]
    },
    "properties": {
    "enterprise_name":"Ashoka plastic",
    "owner_name":"Ashok",
    "contact_number":"9444138981"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2330183333,13.1119033333 ]
    },
    "properties": {
    "enterprise_name":"Nandhu waste paper mart",
    "owner_name":"Nandhakumar",
    "contact_number":"9941678534"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2381476800,13.1119828700 ]
    },
    "properties": {
    "enterprise_name":"Poornima waste paper mart",
    "owner_name":"Babu",
    "contact_number":"9383143309"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2436074700,13.1121389700 ]
    },
    "properties": {
    "enterprise_name":"Selva Raj waste paper mart",
    "owner_name":"Selvaraj",
    "contact_number":"7094251636"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2346183333,13.1121483333 ]
    },
    "properties": {
    "enterprise_name":"Manthara Murthy waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2472418300,13.1122475300 ]
    },
    "properties": {
    "enterprise_name":"K Palani waste paper mart",
    "owner_name":"Palani",
    "contact_number":"9841933750"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1806133333,13.1124100000 ]
    },
    "properties": {
    "enterprise_name":"Arthi waste paper mart",
    "owner_name":"",
    "contact_number":"9841838759"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2379995000,13.1127127000 ]
    },
    "properties": {
    "enterprise_name":"Jeyasili waste paper mart ",
    "owner_name":"1",
    "contact_number":"9444232310"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1503166667,13.1128500000 ]
    },
    "properties": {
    "enterprise_name":"Ravi Kumar",
    "owner_name":"Ravi Kumar",
    "contact_number":"7092021322"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2369447500,13.1128659500 ]
    },
    "properties": {
    "enterprise_name":"Prem waste paper mart ",
    "owner_name":"",
    "contact_number":"23613250"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2777190000,13.1128797000 ]
    },
    "properties": {
    "enterprise_name":"Siva",
    "owner_name":"",
    "contact_number":"7299901763"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2778782600,13.1129367900 ]
    },
    "properties": {
    "enterprise_name":"Said basha",
    "owner_name":"Basha",
    "contact_number":"044 25982809"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2788634400,13.1129689300 ]
    },
    "properties": {
    "enterprise_name":"Bathusha",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2869220000,13.1130215000 ]
    },
    "properties": {
    "enterprise_name":"Usman traders.",
    "owner_name":"Usman",
    "contact_number":"9840416383"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2778501300,13.1130654500 ]
    },
    "properties": {
    "enterprise_name":"Gouse bai",
    "owner_name":"Gose",
    "contact_number":"8754576959"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2128428000,13.1131143000 ]
    },
    "properties": {
    "enterprise_name":"Rms waste paper mart ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2789207300,13.1131256600 ]
    },
    "properties": {
    "enterprise_name":"Mandhara morrthy store",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2489458900,13.1132113100 ]
    },
    "properties": {
    "enterprise_name":"Chandra babu waste paper mart",
    "owner_name":"Chandra",
    "contact_number":"8989802029"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2567492900,13.1132340900 ]
    },
    "properties": {
    "enterprise_name":"Annapurani waste mart",
    "owner_name":"",
    "contact_number":"9941377084"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2567826400,13.1132491500 ]
    },
    "properties": {
    "enterprise_name":"Anna Poornima waste paper mart",
    "owner_name":"",
    "contact_number":"9914234679"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1520500000,13.1133733333 ]
    },
    "properties": {
    "enterprise_name":"Rajan waste paper mart",
    "owner_name":"Rajan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2404981600,13.1133993300 ]
    },
    "properties": {
    "enterprise_name":"Sai waste paper mart",
    "owner_name":"",
    "contact_number":"9442093376"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2870511000,13.1134700000 ]
    },
    "properties": {
    "enterprise_name":"Muthu waste paper.",
    "owner_name":"Muthu Kumar",
    "contact_number":"9940024972"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1525283333,13.1136066667 ]
    },
    "properties": {
    "enterprise_name":"Venugoball",
    "owner_name":"Venugoball",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2557452900,13.1137312000 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga Paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2556153800,13.1137924900 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"",
    "contact_number":"9641777155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2586017600,13.1139782800 ]
    },
    "properties": {
    "enterprise_name":"Balajai. Waste paper mart",
    "owner_name":"",
    "contact_number":"7416737888"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2344600000,13.1140366667 ]
    },
    "properties": {
    "enterprise_name":"Kavitha waste paper mart",
    "owner_name":"Suresh",
    "contact_number":"9941694732"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2794690000,13.1140668500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2368364000,13.1141608000 ]
    },
    "properties": {
    "enterprise_name":"NBS wastepaper mart",
    "owner_name":"Subu",
    "contact_number":"9566020143"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068042000,13.1141661000 ]
    },
    "properties": {
    "enterprise_name":"Ganesan waste paper mart ",
    "owner_name":"Durai ",
    "contact_number":"7418126429"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2875896000,13.1142447000 ]
    },
    "properties": {
    "enterprise_name":"Dolphin traders.",
    "owner_name":"Srinivasan.",
    "contact_number":"8438495059"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2562946300,13.1144347400 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste paper mart",
    "owner_name":"",
    "contact_number":"9566125557"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2858666000,13.1145746900 ]
    },
    "properties": {
    "enterprise_name":"Eswari waste paper mart",
    "owner_name":"Balasubramanian",
    "contact_number":"9884228349"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1858716667,13.1147800000 ]
    },
    "properties": {
    "enterprise_name":"Vigneshwary waste paper mart",
    "owner_name":"Muthukumar",
    "contact_number":"9094597559"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1543983333,13.1147850000 ]
    },
    "properties": {
    "enterprise_name":"Pushbam",
    "owner_name":"Pushbam",
    "contact_number":"7418168893"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2594387100,13.1149746700 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2579284400,13.1149987600 ]
    },
    "properties": {
    "enterprise_name":"Seathu waste paper mart",
    "owner_name":"",
    "contact_number":"8417636776"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2850005900,13.1151089500 ]
    },
    "properties": {
    "enterprise_name":"R.R waste paper mart",
    "owner_name":"Ramaraj",
    "contact_number":"9840373025"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1918083333,13.1152766667 ]
    },
    "properties": {
    "enterprise_name":"Vigneshwary waste paper mart",
    "owner_name":"",
    "contact_number":"9840441060"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2806097900,13.1153098100 ]
    },
    "properties": {
    "enterprise_name":"Balaji",
    "owner_name":"Balaji",
    "contact_number":"9710817172"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2810688700,13.1153897100 ]
    },
    "properties": {
    "enterprise_name":"Sundar waste paper mart",
    "owner_name":"Selvam and sundar",
    "contact_number":"9962617874"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2799831400,13.1154003200 ]
    },
    "properties": {
    "enterprise_name":"Karthik waste paper mart",
    "owner_name":"Narayanan",
    "contact_number":"9176822789"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2800008900,13.1154285300 ]
    },
    "properties": {
    "enterprise_name":"Karthik waste paper",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2472170600,13.1154289500 ]
    },
    "properties": {
    "enterprise_name":"Ganeshan waste paper mart",
    "owner_name":"Ganeshesan",
    "contact_number":"9775044112"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2914486900,13.1154730400 ]
    },
    "properties": {
    "enterprise_name":"A.raja lingam",
    "owner_name":"Rajendran",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2482452000,13.1156129900 ]
    },
    "properties": {
    "enterprise_name":"Karthick waste",
    "owner_name":"",
    "contact_number":"9862626771"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2067168000,13.1156177000 ]
    },
    "properties": {
    "enterprise_name":"Sri balaji waste paper mart ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1883283333,13.1157583333 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste paper mart",
    "owner_name":"",
    "contact_number":"9840228939"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1654500000,13.1158566667 ]
    },
    "properties": {
    "enterprise_name":"Saravanan waste paper",
    "owner_name":"Saravanan",
    "contact_number":"7358755843"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2861412000,13.1159372000 ]
    },
    "properties": {
    "enterprise_name":"Gunaseker traders.",
    "owner_name":"Gunaseker",
    "contact_number":"9840214365"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2860856000,13.1159714200 ]
    },
    "properties": {
    "enterprise_name":"Gunasekar",
    "owner_name":"Gunasekar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2860708500,13.1159990800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2428718600,13.1160317000 ]
    },
    "properties": {
    "enterprise_name":"Siva sakthi waste paper mart",
    "owner_name":"Sakthi",
    "contact_number":"9554309749"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2111556000,13.1160443000 ]
    },
    "properties": {
    "enterprise_name":"Vks waste paper mart ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2808242700,13.1161151700 ]
    },
    "properties": {
    "enterprise_name":"Jothi",
    "owner_name":"Jothi",
    "contact_number":"9444671208"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1611750000,13.1161600000 ]
    },
    "properties": {
    "enterprise_name":"Thillai waste paper mart",
    "owner_name":"Segar",
    "contact_number":"8056270728"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2111116000,13.1161612000 ]
    },
    "properties": {
    "enterprise_name":"Arasu waste paper mart ",
    "owner_name":"Nandha kumar",
    "contact_number":"9941133593"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2361500000,13.1163687000 ]
    },
    "properties": {
    "enterprise_name":"Ram waste paper mart ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1522383333,13.1165950000 ]
    },
    "properties": {
    "enterprise_name":"Gousalya waste paper mart",
    "owner_name":"Murukesa pandiyan",
    "contact_number":"9877285308"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2392532000,13.1168485000 ]
    },
    "properties": {
    "enterprise_name":"Annadh wastepaper mart",
    "owner_name":"Anandh",
    "contact_number":"9380553556"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2775426800,13.1169902200 ]
    },
    "properties": {
    "enterprise_name":"Vignesh bottle store",
    "owner_name":"Vignesh",
    "contact_number":"9941099170"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1561233333,13.1170333333 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Natrajan",
    "contact_number":"9884576437"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2624264200,13.1170469600 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2460677600,13.1171914900 ]
    },
    "properties": {
    "enterprise_name":"Mohan waste paper mart",
    "owner_name":"Mohan",
    "contact_number":"9841653974"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1689683333,13.1171950000 ]
    },
    "properties": {
    "enterprise_name":"Viji waste paper mart",
    "owner_name":"Muthukkannan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2362039600,13.1172834500 ]
    },
    "properties": {
    "enterprise_name":"VMS waste paper mart ",
    "owner_name":"Vineeth",
    "contact_number":"8939201520"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2783285400,13.1174841200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2882516900,13.1176491000 ]
    },
    "properties": {
    "enterprise_name":"Surya",
    "owner_name":"Surya",
    "contact_number":"9789824020"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2106897000,13.1178767000 ]
    },
    "properties": {
    "enterprise_name":"Pavithra waste paper mart ",
    "owner_name":"Ram ",
    "contact_number":"9956321089"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2380696000,13.1178858000 ]
    },
    "properties": {
    "enterprise_name":"Suresh waste paper mart ",
    "owner_name":"Suresh ",
    "contact_number":"9941835212"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2890984000,13.1179237000 ]
    },
    "properties": {
    "enterprise_name":"Tamil Selvi waste Papper",
    "owner_name":"Manohar.",
    "contact_number":"7332456724"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2869336000,13.1181359000 ]
    },
    "properties": {
    "enterprise_name":"Gracy waste paper.",
    "owner_name":"Ponraj.",
    "contact_number":"9884109895"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2486422800,13.1184210800 ]
    },
    "properties": {
    "enterprise_name":"Siva Balan waste paper mart",
    "owner_name":"",
    "contact_number":"9714156891"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1589000000,13.1184466667 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Ganesan",
    "contact_number":"9444225440"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1447683333,13.1185300000 ]
    },
    "properties": {
    "enterprise_name":"TKR waste paper mart",
    "owner_name":"Ramesh Kumar",
    "contact_number":"9710019797"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2084545000,13.1185609000 ]
    },
    "properties": {
    "enterprise_name":"Selvi waste paper mart ",
    "owner_name":"Muthu pandi ",
    "contact_number":"9790963528"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2036714000,13.1185838000 ]
    },
    "properties": {
    "enterprise_name":"Svs waste  paper mart ",
    "owner_name":"Murugan ",
    "contact_number":"9841480610"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2784384300,13.1186588100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2783095900,13.1187251200 ]
    },
    "properties": {
    "enterprise_name":"Babu",
    "owner_name":"Babu",
    "contact_number":"9094660552"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2425695900,13.1188814600 ]
    },
    "properties": {
    "enterprise_name":"Kalaiamman waste paper mart",
    "owner_name":"Mano raj",
    "contact_number":"9940309592"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2487435100,13.1189706600 ]
    },
    "properties": {
    "enterprise_name":"S M S waste paper mart",
    "owner_name":"",
    "contact_number":"9840663882"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1540416667,13.1190183333 ]
    },
    "properties": {
    "enterprise_name":"Rajasigam waste paper mart",
    "owner_name":"",
    "contact_number":"7200209335"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1541733333,13.1190766667 ]
    },
    "properties": {
    "enterprise_name":"Rajasigam waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2873413600,13.1191006300 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"984130 2284"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2082927000,13.1191153000 ]
    },
    "properties": {
    "enterprise_name":"Raj waste paper mart ",
    "owner_name":"",
    "contact_number":"9884120875"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2886674400,13.1191467700 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1966291200,13.1191815800 ]
    },
    "properties": {
    "enterprise_name":"Vishwa waste paper mart",
    "owner_name":"",
    "contact_number":"9841939798"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2874298000,13.1191926000 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper.",
    "owner_name":"Murugan.",
    "contact_number":"9941302284"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1578466667,13.1191933333 ]
    },
    "properties": {
    "enterprise_name":"Uma waste paper mart",
    "owner_name":"Linga raj",
    "contact_number":"9841545414"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2057418000,13.1192000000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart ",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2805532100,13.1192169800 ]
    },
    "properties": {
    "enterprise_name":"It",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1966000000,13.1192216667 ]
    },
    "properties": {
    "enterprise_name":"Vishwa waste paper mart",
    "owner_name":"Thangaraj",
    "contact_number":"9841939798"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1939795100,13.1193452700 ]
    },
    "properties": {
    "enterprise_name":"Sri Murugan irumbu kadi",
    "owner_name":"Rajesh",
    "contact_number":"9962725353"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1939666667,13.1194150000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Veera dhurai",
    "contact_number":"9962725353"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1891890700,13.1194507500 ]
    },
    "properties": {
    "enterprise_name":"Anushka waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9094452699"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2478868800,13.1194801800 ]
    },
    "properties": {
    "enterprise_name":"Guru waste paper mart",
    "owner_name":"",
    "contact_number":"9625441155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2462809000,13.1195372100 ]
    },
    "properties": {
    "enterprise_name":"Renuka waste paper mart",
    "owner_name":"Sathish",
    "contact_number":"9555313954"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2891602700,13.1197447000 ]
    },
    "properties": {
    "enterprise_name":"Tamil selvi waste mart",
    "owner_name":"Vijayan",
    "contact_number":"044 3765438790"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2364956000,13.1199575000 ]
    },
    "properties": {
    "enterprise_name":"SME waste paper mart ",
    "owner_name":"David ",
    "contact_number":"7010120743"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2277050000,13.1199616667 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2283996000,13.1201108000 ]
    },
    "properties": {
    "enterprise_name":"Manila Perumal traders",
    "owner_name":"Selva Kumar.",
    "contact_number":"9840366088"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2458932700,13.1201228800 ]
    },
    "properties": {
    "enterprise_name":"Palani murgan waste paper mart",
    "owner_name":"Murgan",
    "contact_number":"7412654221"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1554650000,13.1201466667 ]
    },
    "properties": {
    "enterprise_name":"Raj Kumar waste paper mart",
    "owner_name":"Rajalingam",
    "contact_number":"9841518218"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2766335100,13.1201607400 ]
    },
    "properties": {
    "enterprise_name":"Valli waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2286733333,13.1203016667 ]
    },
    "properties": {
    "enterprise_name":"Bala ganapathi waste paper mart",
    "owner_name":"",
    "contact_number":"9940273673"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2454425100,13.1205302900 ]
    },
    "properties": {
    "enterprise_name":"Mohamed waste paper mart",
    "owner_name":"Hussain",
    "contact_number":"9940430896"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2878822000,13.1205845600 ]
    },
    "properties": {
    "enterprise_name":"Loganathan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2343662000,13.1206594000 ]
    },
    "properties": {
    "enterprise_name":"Sri balaji waste paper mart ",
    "owner_name":"Balaji ",
    "contact_number":"9756231504"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1683283333,13.1206983333 ]
    },
    "properties": {
    "enterprise_name":"Viji treaders",
    "owner_name":"",
    "contact_number":"7811005566"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2465683800,13.1214306000 ]
    },
    "properties": {
    "enterprise_name":"Selvaraj waste paper mart",
    "owner_name":"Selvaraj",
    "contact_number":"9952742588"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2841633333,13.1214600000 ]
    },
    "properties": {
    "enterprise_name":"Balaji waste paper mart",
    "owner_name":"Balaji",
    "contact_number":"9094892007"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1584766667,13.1214883333 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Kumareson",
    "contact_number":"9941082215"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2386096000,13.1215378000 ]
    },
    "properties": {
    "enterprise_name":"Hari waste paper mart ",
    "owner_name":"Raja",
    "contact_number":"9876541230"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1586733333,13.1216700000 ]
    },
    "properties": {
    "enterprise_name":"Bathmanathan",
    "owner_name":"Bathmanathan",
    "contact_number":"9840331850"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2360250000,13.1217155000 ]
    },
    "properties": {
    "enterprise_name":"Punitha anthoniyar waste paper mart ",
    "owner_name":"Peter",
    "contact_number":"7373181787"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2038537000,13.1217699000 ]
    },
    "properties": {
    "enterprise_name":"Karthick waste paper mart ",
    "owner_name":"",
    "contact_number":"23456874"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2363987000,13.1218187000 ]
    },
    "properties": {
    "enterprise_name":"Daya waste Papper",
    "owner_name":"Daya.",
    "contact_number":"9940090403"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2934468400,13.1218399600 ]
    },
    "properties": {
    "enterprise_name":"Kumar",
    "owner_name":"Kumar",
    "contact_number":"98955677891"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2933064800,13.1218411200 ]
    },
    "properties": {
    "enterprise_name":"Velu traders",
    "owner_name":"Velu",
    "contact_number":"9841836614"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2432788300,13.1219248500 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"9994136461"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2222280000,13.1219407000 ]
    },
    "properties": {
    "enterprise_name":"Pavithira waste paper mart ",
    "owner_name":"praveen ",
    "contact_number":"7299315960"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2658193500,13.1219520500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657426200,13.1219767000 ]
    },
    "properties": {
    "enterprise_name":"Ganesan",
    "owner_name":"Ganesan",
    "contact_number":"7299483926"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2327893000,13.1223421000 ]
    },
    "properties": {
    "enterprise_name":"Sivanesh waste paper mart ",
    "owner_name":"Ganesh",
    "contact_number":"7418226473"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2845197000,13.1223815000 ]
    },
    "properties": {
    "enterprise_name":"Jyothi waste paper mart",
    "owner_name":"Jyothi",
    "contact_number":"9940581870"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2272616667,13.1224466667 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper mart",
    "owner_name":"Selvam",
    "contact_number":"8939561412"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1680933333,13.1225116667 ]
    },
    "properties": {
    "enterprise_name":"Raj waste paper mart",
    "owner_name":"Sella kani",
    "contact_number":"8778655213"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2092464000,13.1226112000 ]
    },
    "properties": {
    "enterprise_name":"Vk waste paper mart ",
    "owner_name":"Kamalakannan",
    "contact_number":"9841583628"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2635003300,13.1228422500 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga Paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1516350000,13.1228816667 ]
    },
    "properties": {
    "enterprise_name":"Sangar waste paper mart",
    "owner_name":"Sangar",
    "contact_number":"9884243175"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2336199500,13.1229469000 ]
    },
    "properties": {
    "enterprise_name":"Annadh waste paper mart ",
    "owner_name":"Kumar",
    "contact_number":"8122361842"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2871128000,13.1230923000 ]
    },
    "properties": {
    "enterprise_name":"Lingam bottle paper store",
    "owner_name":"Gunasekhar",
    "contact_number":"9380611143"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2309633333,13.1231250000 ]
    },
    "properties": {
    "enterprise_name":"Jayaseli waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"7200686400"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2242222000,13.1231933000 ]
    },
    "properties": {
    "enterprise_name":"Mandira moorthy waste paper.",
    "owner_name":"Selvam.",
    "contact_number":"9444046638"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2775002100,13.1234452900 ]
    },
    "properties": {
    "enterprise_name":"Saravana traders",
    "owner_name":"Saravanan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1555950000,13.1236383333 ]
    },
    "properties": {
    "enterprise_name":"Raghu",
    "owner_name":"Raghu",
    "contact_number":"9840296585"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2115304000,13.1237058000 ]
    },
    "properties": {
    "enterprise_name":"Mk waste paper mart ",
    "owner_name":"Kannan",
    "contact_number":"9790971430"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2326450000,13.1237314000 ]
    },
    "properties": {
    "enterprise_name":"Karthik waste paper mart ",
    "owner_name":"",
    "contact_number":"9884700563"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2235291000,13.1237712000 ]
    },
    "properties": {
    "enterprise_name":"Ramaraj waste paper mart ",
    "owner_name":"Ram",
    "contact_number":"8015120153"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2221889000,13.1237957000 ]
    },
    "properties": {
    "enterprise_name":"Perumal waste paper mart ",
    "owner_name":"Karupuswamy",
    "contact_number":"9962406032"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2852297700,13.1238306000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2476107100,13.1240640800 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"9940784855"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2141350000,13.1240786000 ]
    },
    "properties": {
    "enterprise_name":"Vinoth waste paper mart ",
    "owner_name":"",
    "contact_number":"9730852741"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2276389000,13.1240980000 ]
    },
    "properties": {
    "enterprise_name":"Senthil waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2142072000,13.1241015000 ]
    },
    "properties": {
    "enterprise_name":"S jothi waste paper mart ",
    "owner_name":"Muthuraman",
    "contact_number":"7415614306"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1598583333,13.1243150000 ]
    },
    "properties": {
    "enterprise_name":"Kumar waste paper mart",
    "owner_name":"Kumar",
    "contact_number":"7449004979"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2251150000,13.1245300000 ]
    },
    "properties": {
    "enterprise_name":"Sree mahalakshmi waste paper mart",
    "owner_name":"Thangaraj",
    "contact_number":"9094714292"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2054651000,13.1245379000 ]
    },
    "properties": {
    "enterprise_name":"Vinayagam waste paper mart ",
    "owner_name":"Vinayagam",
    "contact_number":"9941863254"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2472986000,13.1245416000 ]
    },
    "properties": {
    "enterprise_name":"Sethu raman waste paper",
    "owner_name":"SethuRaman",
    "contact_number":"9710658626"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2476399000,13.1245441000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper",
    "owner_name":"Murugan",
    "contact_number":"9940784859"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2738267000,13.1250427900 ]
    },
    "properties": {
    "enterprise_name":"Manoj traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2740887500,13.1251215100 ]
    },
    "properties": {
    "enterprise_name":"Monoj traders",
    "owner_name":"Muthuraj",
    "contact_number":"9038279009"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2167094000,13.1252390000 ]
    },
    "properties": {
    "enterprise_name":"Aarasu Kalai waste paper mart",
    "owner_name":"Kalivannan",
    "contact_number":"9962436288"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2657710400,13.1252674800 ]
    },
    "properties": {
    "enterprise_name":"Siva Sakthi waste paper mart",
    "owner_name":"Arul",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1676616667,13.1254000000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2290233333,13.1255033333 ]
    },
    "properties": {
    "enterprise_name":"Sree jagan waste paper mart",
    "owner_name":"K.selvanathan",
    "contact_number":"28529298"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2780454300,13.1255873600 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2422973100,13.1258090800 ]
    },
    "properties": {
    "enterprise_name":"T.n. waste paper mart",
    "owner_name":"Shanmugam",
    "contact_number":"9841356861"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2283133333,13.1259633333 ]
    },
    "properties": {
    "enterprise_name":"Surya waste paper mart",
    "owner_name":"Surya",
    "contact_number":"9444284168"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2235933333,13.1265550000 ]
    },
    "properties": {
    "enterprise_name":"Maradhu waste paper mart",
    "owner_name":"Marudhu",
    "contact_number":"9094133635"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1629966667,13.1269250000 ]
    },
    "properties": {
    "enterprise_name":"Kasi waste paper mart",
    "owner_name":"Kasi",
    "contact_number":"9551025494"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2292357000,13.1272025000 ]
    },
    "properties": {
    "enterprise_name":"Lordhu waste Papper mart",
    "owner_name":"Thangaraj.",
    "contact_number":"9940403277"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2720437500,13.1273776800 ]
    },
    "properties": {
    "enterprise_name":"Anandhi waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2785367100,13.1274720800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2787385300,13.1275725200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2699516000,13.1277824400 ]
    },
    "properties": {
    "enterprise_name":"Siva waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1588083333,13.1280633333 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2166651000,13.1280710000 ]
    },
    "properties": {
    "enterprise_name":"Sm waste paper mart",
    "owner_name":"",
    "contact_number":"9092291719"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2498276600,13.1281973500 ]
    },
    "properties": {
    "enterprise_name":"I",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2737939900,13.1282202300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2498984200,13.1282556300 ]
    },
    "properties": {
    "enterprise_name":"Rahman waste paper mart",
    "owner_name":"Rahman",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2315147000,13.1285035000 ]
    },
    "properties": {
    "enterprise_name":"Jenifer traders",
    "owner_name":"Royapan",
    "contact_number":"9500007376"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2397212200,13.1285320400 ]
    },
    "properties": {
    "enterprise_name":"Muthu lingam waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9710648913"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2318213000,13.1287567000 ]
    },
    "properties": {
    "enterprise_name":"Amudha waste paper",
    "owner_name":"Govindraj",
    "contact_number":"7373302550"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2016103600,13.1289006000 ]
    },
    "properties": {
    "enterprise_name":"Uma waste paper mart",
    "owner_name":"Anandhakumar",
    "contact_number":"9710331183"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2245591700,13.1289456700 ]
    },
    "properties": {
    "enterprise_name":"Thana Lakshmi traders",
    "owner_name":"Raju",
    "contact_number":"999419958"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1701216667,13.1292416667 ]
    },
    "properties": {
    "enterprise_name":"Selvakumar",
    "owner_name":"Selvakumar",
    "contact_number":"9176743140"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1699924900,13.1293801700 ]
    },
    "properties": {
    "enterprise_name":"Indu waste paper mart",
    "owner_name":"Muthu Kumar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2272479500,13.1300917000 ]
    },
    "properties": {
    "enterprise_name":"Esthak",
    "owner_name":"",
    "contact_number":"9042643944"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2093108000,13.1301579000 ]
    },
    "properties": {
    "enterprise_name":"Pechiamman paper shop",
    "owner_name":"Mariyappan",
    "contact_number":"9566002763"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2082483400,13.1301616300 ]
    },
    "properties": {
    "enterprise_name":"Muthubarathi waste paper shop",
    "owner_name":"Velu",
    "contact_number":"9677189207"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2294823000,13.1303245400 ]
    },
    "properties": {
    "enterprise_name":"M pal raj",
    "owner_name":"Paul raj",
    "contact_number":"9884142982"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2792400000,13.1303659200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2663731100,13.1304596600 ]
    },
    "properties": {
    "enterprise_name":"MM waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2019453900,13.1307009900 ]
    },
    "properties": {
    "enterprise_name":"Sakthi waste paper mart",
    "owner_name":"Kartheesan",
    "contact_number":"9962452997"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2119549500,13.1308696100 ]
    },
    "properties": {
    "enterprise_name":"Jayabharath waste paper shop",
    "owner_name":"",
    "contact_number":"9444788554"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2034640000,13.1310677000 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste paper shop",
    "owner_name":"",
    "contact_number":"8056085338"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2601968300,13.1311482000 ]
    },
    "properties": {
    "enterprise_name":"Baskar",
    "owner_name":"Baskar",
    "contact_number":"9444129665"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2894193000,13.1311951000 ]
    },
    "properties": {
    "enterprise_name":"Selvaraj paper mart",
    "owner_name":"Selvaraj",
    "contact_number":"9444230821"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2861072000,13.1312180000 ]
    },
    "properties": {
    "enterprise_name":"Saravanan waste Papper mart",
    "owner_name":"Rajan",
    "contact_number":"9841240710"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2895881200,13.1312545300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2007817100,13.1314914900 ]
    },
    "properties": {
    "enterprise_name":"Jayasakthi old scrab shop",
    "owner_name":"Sivaselvam",
    "contact_number":"9444831401"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1992850500,13.1319886900 ]
    },
    "properties": {
    "enterprise_name":"Ravi kumar",
    "owner_name":"Ravi Kumar",
    "contact_number":"9566262881"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1981526900,13.1320154100 ]
    },
    "properties": {
    "enterprise_name":"Lingeshwaran waste paper mart",
    "owner_name":"Lingesh",
    "contact_number":"9840125835"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2587240600,13.1320254700 ]
    },
    "properties": {
    "enterprise_name":"Ashok waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1703433333,13.1320350000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"K.murugan",
    "contact_number":"9994660807"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2588855900,13.1321292400 ]
    },
    "properties": {
    "enterprise_name":"Geetha Paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1655483333,13.1321733333 ]
    },
    "properties": {
    "enterprise_name":"Srimathi waste paper mart",
    "owner_name":"Murugan",
    "contact_number":"8939473686"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1718066667,13.1321933333 ]
    },
    "properties": {
    "enterprise_name":"Muthukumar",
    "owner_name":"Muthukumar",
    "contact_number":"9176191674"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2910046200,13.1322655800 ]
    },
    "properties": {
    "enterprise_name":"T",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1760700000,13.1322833333 ]
    },
    "properties": {
    "enterprise_name":"Bathma Nathan waste paper mart",
    "owner_name":"Bathmanathan",
    "contact_number":"9710783525"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2910669000,13.1323559000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2634677200,13.1325637400 ]
    },
    "properties": {
    "enterprise_name":"VRS waste paper mart",
    "owner_name":"Seladhurai",
    "contact_number":"9444418991"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2904917000,13.1325815000 ]
    },
    "properties": {
    "enterprise_name":"Arun waste Papper mart",
    "owner_name":"Arun",
    "contact_number":"9941430249"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2121049200,13.1326149600 ]
    },
    "properties": {
    "enterprise_name":"Pk waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2121304000,13.1326285700 ]
    },
    "properties": {
    "enterprise_name":"Annai old scrab shop",
    "owner_name":"Felix",
    "contact_number":"9094708090"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1734066667,13.1327200000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Balakrishnan",
    "contact_number":"9677180101"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2605633100,13.1328648600 ]
    },
    "properties": {
    "enterprise_name":"Goudhami waste paper mart",
    "owner_name":"Sankar",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2594843100,13.1329577200 ]
    },
    "properties": {
    "enterprise_name":"Nandhini waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"9940309860"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1733816667,13.1331500000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste paper mart",
    "owner_name":"",
    "contact_number":"9952950171"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2629008900,13.1331638600 ]
    },
    "properties": {
    "enterprise_name":"Chetty waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2242725800,13.1332556800 ]
    },
    "properties": {
    "enterprise_name":"SMT waste paper",
    "owner_name":"Kumar",
    "contact_number":"9884374242"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2300135500,13.1332690400 ]
    },
    "properties": {
    "enterprise_name":"Waste mart",
    "owner_name":"",
    "contact_number":"9841830226"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2953677000,13.1334785000 ]
    },
    "properties": {
    "enterprise_name":"Ganeshen waste Papper mart.",
    "owner_name":"Ganesan",
    "contact_number":"7305266346"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2535529300,13.1335950400 ]
    },
    "properties": {
    "enterprise_name":"Rakesh waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1998160000,13.1335977900 ]
    },
    "properties": {
    "enterprise_name":"Karthi waste paper mart",
    "owner_name":"Karthi",
    "contact_number":"9865114058"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1935789000,13.1336844900 ]
    },
    "properties": {
    "enterprise_name":"Vel murugan waste paper mart",
    "owner_name":"Narayanan",
    "contact_number":"9962494188"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1583166667,13.1340783333 ]
    },
    "properties": {
    "enterprise_name":"Srimathi waste paper mart",
    "owner_name":"Jayagobal",
    "contact_number":"9486587811"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1725050000,13.1342783333 ]
    },
    "properties": {
    "enterprise_name":"Ns ",
    "owner_name":"",
    "contact_number":"8526110977"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1583933333,13.1344650000 ]
    },
    "properties": {
    "enterprise_name":"Soyambulingam waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1730483333,13.1345133333 ]
    },
    "properties": {
    "enterprise_name":"JS  iron shop",
    "owner_name":"Selva",
    "contact_number":"9842187965"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2096764900,13.1345161600 ]
    },
    "properties": {
    "enterprise_name":"Manthira moorthy waste paper mart\nWd 26",
    "owner_name":"Balan",
    "contact_number":"7639191446"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2927473000,13.1348066700 ]
    },
    "properties": {
    "enterprise_name":"Sakthi paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1661783333,13.1348400000 ]
    },
    "properties": {
    "enterprise_name":"Umarnath waste paper mart",
    "owner_name":"Umarnath",
    "contact_number":"9962717522"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2303468800,13.1349695900 ]
    },
    "properties": {
    "enterprise_name":"Jaya bharathiyar waste paper mart",
    "owner_name":"Balan",
    "contact_number":"9710251816"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2062791200,13.1350385100 ]
    },
    "properties": {
    "enterprise_name":"Alagar stores",
    "owner_name":"Ponlingam",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2865934000,13.1350973300 ]
    },
    "properties": {
    "enterprise_name":"Arul Raj paper mart",
    "owner_name":"Arul",
    "contact_number":"9094057379"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2865408000,13.1351282000 ]
    },
    "properties": {
    "enterprise_name":"Arulraj waste Papper mart",
    "owner_name":"Arulraj",
    "contact_number":"9094057379"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2946712000,13.1352334000 ]
    },
    "properties": {
    "enterprise_name":"Mathi waste paper",
    "owner_name":"Mathi",
    "contact_number":"9941247321"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2946712000,13.1352334000 ]
    },
    "properties": {
    "enterprise_name":"Durairaj traders",
    "owner_name":"Durairaj",
    "contact_number":"7332145677"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2249561000,13.1352838200 ]
    },
    "properties": {
    "enterprise_name":"Periyandavar waste paper mart",
    "owner_name":"Sukumar",
    "contact_number":"9003189353"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2383701000,13.1353734000 ]
    },
    "properties": {
    "enterprise_name":"Muniyasamy traders",
    "owner_name":"Muniyasamy",
    "contact_number":"9884282009"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2384179700,13.1354746600 ]
    },
    "properties": {
    "enterprise_name":"Prabhu waste paper mart",
    "owner_name":"Prabu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1905136800,13.1355201200 ]
    },
    "properties": {
    "enterprise_name":"Tiruvenkadam",
    "owner_name":"",
    "contact_number":"9876543210"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2190417200,13.1356674500 ]
    },
    "properties": {
    "enterprise_name":"Dass",
    "owner_name":"",
    "contact_number":"9941821023"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1702700000,13.1358883333 ]
    },
    "properties": {
    "enterprise_name":"Kamaraj waste paper mart",
    "owner_name":"Kamaraj",
    "contact_number":"8056005078"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2573227900,13.1361952500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2572324700,13.1362633700 ]
    },
    "properties": {
    "enterprise_name":"I",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2576962500,13.1363484400 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2974574000,13.1364076000 ]
    },
    "properties": {
    "enterprise_name":"Vadivelu Papper mart.",
    "owner_name":"Vadivelu",
    "contact_number":"8056298315"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2548438600,13.1365251300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559914300,13.1365467800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"Rajesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2559205000,13.1366768300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2549536400,13.1367076300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2513212400,13.1367362300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2512236400,13.1367392500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1601583333,13.1369100000 ]
    },
    "properties": {
    "enterprise_name":"Yapiya waste paper mart",
    "owner_name":"Hansari",
    "contact_number":"9941415363"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1762950000,13.1369116667 ]
    },
    "properties": {
    "enterprise_name":"Vgn treaders",
    "owner_name":"",
    "contact_number":"9043208334"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2932463500,13.1369400600 ]
    },
    "properties": {
    "enterprise_name":"Yr",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2011717000,13.1369934600 ]
    },
    "properties": {
    "enterprise_name":"Murugantham",
    "owner_name":"Murugantham",
    "contact_number":"9787046104"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2478424700,13.1373883200 ]
    },
    "properties": {
    "enterprise_name":"Sankarshwari waste Mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2933131000,13.1375426000 ]
    },
    "properties": {
    "enterprise_name":"Jenitha traders",
    "owner_name":"",
    "contact_number":"7358472481"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2017295300,13.1376964000 ]
    },
    "properties": {
    "enterprise_name":"Jaya Kani waste paper mart",
    "owner_name":"Kannan",
    "contact_number":"98401278901"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2560447800,13.1377443900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2558389200,13.1377833200 ]
    },
    "properties": {
    "enterprise_name":"SRK waste paper mart",
    "owner_name":"Murugan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2934119300,13.1379496500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2960643000,13.1381362000 ]
    },
    "properties": {
    "enterprise_name":"Jeiselan waste Papper mart.",
    "owner_name":"Santhanakumar",
    "contact_number":"8939199854"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2457983800,13.1383022800 ]
    },
    "properties": {
    "enterprise_name":"Thangam traders",
    "owner_name":"",
    "contact_number":"9840347788"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2453552600,13.1386635300 ]
    },
    "properties": {
    "enterprise_name":"Aisha waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2441607600,13.1386824800 ]
    },
    "properties": {
    "enterprise_name":"Aiyyapa paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2066702800,13.1389324900 ]
    },
    "properties": {
    "enterprise_name":"Jaya traders",
    "owner_name":"Baskar",
    "contact_number":"9962230719"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2065380500,13.1390108300 ]
    },
    "properties": {
    "enterprise_name":"Jaya traders",
    "owner_name":"Ragu",
    "contact_number":"9962230719"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2065663700,13.1390871000 ]
    },
    "properties": {
    "enterprise_name":"Jaya traders",
    "owner_name":"",
    "contact_number":"9962230719"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1882189000,13.1391535300 ]
    },
    "properties": {
    "enterprise_name":"Iron shop",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2058091800,13.1391795600 ]
    },
    "properties": {
    "enterprise_name":"Mandhariya morrthy waste mart",
    "owner_name":"",
    "contact_number":"9941150341"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2424112400,13.1398206100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1709816667,13.1400266667 ]
    },
    "properties": {
    "enterprise_name":"Annai waste paper mart",
    "owner_name":"Sivalingam",
    "contact_number":"9790326124"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2449212500,13.1408520000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2450145800,13.1408866600 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1823806300,13.1412005700 ]
    },
    "properties": {
    "enterprise_name":"Vanaras traders",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1822267700,13.1412677500 ]
    },
    "properties": {
    "enterprise_name":"Vanaja traders",
    "owner_name":"Venkat",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2944875700,13.1414564200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2943862000,13.1414942000 ]
    },
    "properties": {
    "enterprise_name":"John waste paper mart",
    "owner_name":"Jhon",
    "contact_number":"9941428455"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 79.9002052300,13.1433330200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2572103600,13.1434307000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2494304400,13.1436120700 ]
    },
    "properties": {
    "enterprise_name":"Vnr waste paper mart",
    "owner_name":"Muthu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2495605400,13.1436380400 ]
    },
    "properties": {
    "enterprise_name":"Vnr waste mart",
    "owner_name":"Raj",
    "contact_number":"9840278336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2495005000,13.1436765900 ]
    },
    "properties": {
    "enterprise_name":"Vnr waste paper mart",
    "owner_name":"Ranjith",
    "contact_number":"9840278336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2495050000,13.1437363000 ]
    },
    "properties": {
    "enterprise_name":"V.N.R waste paper mart.",
    "owner_name":"Selvam",
    "contact_number":"9840278336"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2540012600,13.1438583300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2540510200,13.1439591500 ]
    },
    "properties": {
    "enterprise_name":"Raja waste paper mart",
    "owner_name":"Raja",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2541430800,13.1440442600 ]
    },
    "properties": {
    "enterprise_name":"Annai waste mart",
    "owner_name":"Mariappan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2204544000,13.1441683000 ]
    },
    "properties": {
    "enterprise_name":"Samy waste paper mart",
    "owner_name":"Samy",
    "contact_number":"9884282009"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2497683600,13.1444838800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":"9600120173"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2497221000,13.1445174400 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2325749300,13.1451159400 ]
    },
    "properties": {
    "enterprise_name":"It",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2325605000,13.1451621000 ]
    },
    "properties": {
    "enterprise_name":"C.R.waste Papper.",
    "owner_name":"Rajendran.",
    "contact_number":"9597449120"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2453529600,13.1454792100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1886918200,13.1461288400 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1887199000,13.1461359300 ]
    },
    "properties": {
    "enterprise_name":"Suresh",
    "owner_name":"Suresh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2296406500,13.1461437400 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1886383400,13.1461536300 ]
    },
    "properties": {
    "enterprise_name":"Natarajan",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3012883000,13.1461721000 ]
    },
    "properties": {
    "enterprise_name":"Antonysamy waste shop",
    "owner_name":"Antonysamy",
    "contact_number":"9566084487"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2955547900,13.1462773900 ]
    },
    "properties": {
    "enterprise_name":"Egneshwaran waste paper mart",
    "owner_name":"Jhonson",
    "contact_number":"9841972173"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2315965600,13.1467206200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2317160800,13.1467678700 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2945255000,13.1470853000 ]
    },
    "properties": {
    "enterprise_name":"Yesu attai shop",
    "owner_name":"Yesubalan",
    "contact_number":"9455487834"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2978056000,13.1471091000 ]
    },
    "properties": {
    "enterprise_name":"Periyasamy waste shop",
    "owner_name":"Periyasamy",
    "contact_number":"9382886268"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2957776600,13.1474181800 ]
    },
    "properties": {
    "enterprise_name":"Prabakaran waste paper mart",
    "owner_name":"Prabakaran",
    "contact_number":"9952980638"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2313469000,13.1474942000 ]
    },
    "properties": {
    "enterprise_name":"Mesiya traders",
    "owner_name":"Jhon",
    "contact_number":"9884125551"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2311517000,13.1477345000 ]
    },
    "properties": {
    "enterprise_name":"C.s. Mithra traders.",
    "owner_name":"Chandran",
    "contact_number":"9941883084"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2153605000,13.1477737200 ]
    },
    "properties": {
    "enterprise_name":"Srp waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2927704800,13.1479226000 ]
    },
    "properties": {
    "enterprise_name":"R.Mallika waste paper mart",
    "owner_name":"Murugavel",
    "contact_number":"7295370740"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2041269600,13.1480749200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2040386700,13.1481459700 ]
    },
    "properties": {
    "enterprise_name":"Mahalaxmi waste paper mart",
    "owner_name":"Raj",
    "contact_number":"8189894086"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2039792600,13.1483028200 ]
    },
    "properties": {
    "enterprise_name":"Kailasam",
    "owner_name":"Kailasam",
    "contact_number":"9876543210"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2040002600,13.1485152600 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":"8765432109"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2941959200,13.1487895700 ]
    },
    "properties": {
    "enterprise_name":"Daniel waste shop",
    "owner_name":"Daniel",
    "contact_number":"9841522758"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2951091200,13.1498310400 ]
    },
    "properties": {
    "enterprise_name":"Ganeshan waste paper shop",
    "owner_name":"Ganeshan",
    "contact_number":"8148999427"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2981710000,13.1498941000 ]
    },
    "properties": {
    "enterprise_name":"Illaiya waste shop",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2042450300,13.1499090400 ]
    },
    "properties": {
    "enterprise_name":"Ajor waste paper mart",
    "owner_name":"Monogran",
    "contact_number":"9500158565"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2040982900,13.1499145100 ]
    },
    "properties": {
    "enterprise_name":"A.N.D waste paper mart",
    "owner_name":"",
    "contact_number":"9500158565"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2951380200,13.1499683700 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2945006500,13.1499800900 ]
    },
    "properties": {
    "enterprise_name":"Jayapaul waste paper shop",
    "owner_name":"Jayapal",
    "contact_number":"9941707234"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2043725300,13.1500927100 ]
    },
    "properties": {
    "enterprise_name":"Ajr waste paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2992410000,13.1503263000 ]
    },
    "properties": {
    "enterprise_name":"Linga waste shop",
    "owner_name":"Vetrivel",
    "contact_number":"9444210938"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2288201000,13.1505435000 ]
    },
    "properties": {
    "enterprise_name":"Chairman waste paper mart.",
    "owner_name":"Kannan",
    "contact_number":"8189972540"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2946705800,13.1506088800 ]
    },
    "properties": {
    "enterprise_name":"Balaji steels",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2968137900,13.1509567200 ]
    },
    "properties": {
    "enterprise_name":"Dhiviya waste paper mart",
    "owner_name":"",
    "contact_number":"9444688605"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2306612000,13.1510551000 ]
    },
    "properties": {
    "enterprise_name":"Om Chairman old paper shop",
    "owner_name":"K.Kannan",
    "contact_number":"8189972540"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2306612000,13.1510551000 ]
    },
    "properties": {
    "enterprise_name":"Selvam waste paper shop",
    "owner_name":"Selvam",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3023330000,13.1511998000 ]
    },
    "properties": {
    "enterprise_name":"Ravichendiren waste shops",
    "owner_name":"K.Ravichendiren",
    "contact_number":"9841887130"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2955401200,13.1516092900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2954906900,13.1516330400 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga waste paper mart",
    "owner_name":"Muthuraj",
    "contact_number":"9551034890"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2295172000,13.1516388000 ]
    },
    "properties": {
    "enterprise_name":"Amman waste Papper",
    "owner_name":"Selvam.",
    "contact_number":"9841965713"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2107645300,13.1516413500 ]
    },
    "properties": {
    "enterprise_name":"Kumar",
    "owner_name":"Kumar",
    "contact_number":"9995476880"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2986849900,13.1523016800 ]
    },
    "properties": {
    "enterprise_name":"Vetri waste paper mart",
    "owner_name":"Vetrivel",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3007017000,13.1523807000 ]
    },
    "properties": {
    "enterprise_name":"Madha waste shops",
    "owner_name":"Suresh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2279237000,13.1527020000 ]
    },
    "properties": {
    "enterprise_name":"Maseya waste Papper mart.",
    "owner_name":"Murthy",
    "contact_number":"9043333321"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2279265900,13.1528589900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2978726600,13.1530253200 ]
    },
    "properties": {
    "enterprise_name":"Manogaran waste shops",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2280312800,13.1530790000 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2977435000,13.1531346000 ]
    },
    "properties": {
    "enterprise_name":"Pushpapriyan paper shop",
    "owner_name":"Chidambaram",
    "contact_number":"9840400737"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3040743000,13.1541163000 ]
    },
    "properties": {
    "enterprise_name":"Sairam iron shop",
    "owner_name":"Antony",
    "contact_number":"9840896202"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2988504000,13.1542747000 ]
    },
    "properties": {
    "enterprise_name":"Vanaja mutharamman old paper waste shop",
    "owner_name":"Solaiyappan",
    "contact_number":"9940584983"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2308068000,13.1545061000 ]
    },
    "properties": {
    "enterprise_name":"Madasamy shop",
    "owner_name":"Madasamy",
    "contact_number":"9940131838"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1953141900,13.1546574300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1952448000,13.1546867700 ]
    },
    "properties": {
    "enterprise_name":"Lingam",
    "owner_name":"Lingam",
    "contact_number":"9884757325"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2976475000,13.1547028000 ]
    },
    "properties": {
    "enterprise_name":"Bharath waste paper shops",
    "owner_name":"Krishnan",
    "contact_number":"9176071816"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2976705400,13.1549139000 ]
    },
    "properties": {
    "enterprise_name":"Amman paper mart",
    "owner_name":"Marimuthu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2308726000,13.1549866000 ]
    },
    "properties": {
    "enterprise_name":"Vellaisamy waste shop",
    "owner_name":"Vellaisamy",
    "contact_number":"7418208200"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2984869000,13.1553818000 ]
    },
    "properties": {
    "enterprise_name":"Thalavai madha paper shop",
    "owner_name":"Esakimuthu",
    "contact_number":"9840415419"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2045840600,13.1564096500 ]
    },
    "properties": {
    "enterprise_name":"Devi karu Mari Amman waste mart",
    "owner_name":"Kaliappan",
    "contact_number":"9444384879"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2479884000,13.1566181000 ]
    },
    "properties": {
    "enterprise_name":"Thangam waste Papper",
    "owner_name":"Thangam",
    "contact_number":"9840414724"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2978609000,13.1572782000 ]
    },
    "properties": {
    "enterprise_name":"Ganeshan waste shops",
    "owner_name":"Yogeshwaran",
    "contact_number":"7871245295"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2971091000,13.1581282000 ]
    },
    "properties": {
    "enterprise_name":"R.R.S Papers",
    "owner_name":"Rajasekar",
    "contact_number":"9884274305"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3028640000,13.1586112000 ]
    },
    "properties": {
    "enterprise_name":"Vinayaga paper Mart",
    "owner_name":"Thangamariyappan",
    "contact_number":"9840042002"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2577451000,13.1591273000 ]
    },
    "properties": {
    "enterprise_name":"Old Papper shop",
    "owner_name":"Alaguraja.",
    "contact_number":"9543051955"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3046572000,13.1592710000 ]
    },
    "properties": {
    "enterprise_name":"Govindasamy waste shop",
    "owner_name":"Govindasamy",
    "contact_number":"9840305435"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3054672000,13.1598702000 ]
    },
    "properties": {
    "enterprise_name":"Selvan waste mart",
    "owner_name":"Selvam",
    "contact_number":"8015878998"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3031514000,13.1601721000 ]
    },
    "properties": {
    "enterprise_name":"Jagdesh paper mart",
    "owner_name":"Jagdesh",
    "contact_number":"9790874664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2521700000,13.1610528000 ]
    },
    "properties": {
    "enterprise_name":"Vasanth paper mart",
    "owner_name":"Selva",
    "contact_number":"8122047834"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2627629100,13.1623684600 ]
    },
    "properties": {
    "enterprise_name":"A.s waste paper mart",
    "owner_name":"Appu",
    "contact_number":"9380001772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2626769800,13.1623795400 ]
    },
    "properties": {
    "enterprise_name":"A.S. waste paper mart",
    "owner_name":"Appu",
    "contact_number":"9380001772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2626873000,13.1624216500 ]
    },
    "properties": {
    "enterprise_name":"A.S. waste paper mart",
    "owner_name":"Appu",
    "contact_number":"9380003372"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2624847400,13.1624318900 ]
    },
    "properties": {
    "enterprise_name":"As waste paper",
    "owner_name":"",
    "contact_number":"9380001772"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2558454900,13.1639590500 ]
    },
    "properties": {
    "enterprise_name":"Iyyapa",
    "owner_name":"Ramesh",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2558490100,13.1641007700 ]
    },
    "properties": {
    "enterprise_name":"Iyyapa traders",
    "owner_name":"Ravi raja",
    "contact_number":"9940109656"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2904896300,13.1641292200 ]
    },
    "properties": {
    "enterprise_name":"Paul Raj paper mart",
    "owner_name":"Paul raj",
    "contact_number":"94445873213"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2068190000,13.1643719100 ]
    },
    "properties": {
    "enterprise_name":"Suambu lingam waste mart",
    "owner_name":"Muthu kumar",
    "contact_number":"9444205786"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2594450800,13.1655138400 ]
    },
    "properties": {
    "enterprise_name":"Muthu",
    "owner_name":"Muthu",
    "contact_number":"9962907755"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2924733600,13.1662882200 ]
    },
    "properties": {
    "enterprise_name":"S.m. ponraj",
    "owner_name":"Ponraj",
    "contact_number":"9840576716"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2923054900,13.1663769300 ]
    },
    "properties": {
    "enterprise_name":"Srinivasan",
    "owner_name":"Srinivasan",
    "contact_number":"9566048781"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2362800300,13.1673050100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2347261400,13.1681608900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2349390000,13.1681681800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"Mari muthu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2348786000,13.1682408500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2922616100,13.1683892900 ]
    },
    "properties": {
    "enterprise_name":"Thanga pandi",
    "owner_name":"Thanga pandi",
    "contact_number":"9940077650"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2931238600,13.1686620400 ]
    },
    "properties": {
    "enterprise_name":"Murugan traders",
    "owner_name":"Murugan",
    "contact_number":"9841143410"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2928951400,13.1686626300 ]
    },
    "properties": {
    "enterprise_name":"Senthur waste paper mart",
    "owner_name":"Philips",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2929360700,13.1686732900 ]
    },
    "properties": {
    "enterprise_name":"Kathir",
    "owner_name":"Kathir",
    "contact_number":"9380082040"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2392966300,13.1686931800 ]
    },
    "properties": {
    "enterprise_name":"Velayutham permal paper mart",
    "owner_name":"Vinoth",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2392321300,13.1686965800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2392937600,13.1688264700 ]
    },
    "properties": {
    "enterprise_name":"Velayutham permal paper mart",
    "owner_name":"",
    "contact_number":"9176372563"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2012523100,13.1690519400 ]
    },
    "properties": {
    "enterprise_name":"Parvathi waste paper mart",
    "owner_name":"Bose",
    "contact_number":"7871212216"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1927427500,13.1690812300 ]
    },
    "properties": {
    "enterprise_name":"Lingam waste mart",
    "owner_name":"Issaki muthu",
    "contact_number":"9445342160"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2916706500,13.1690844300 ]
    },
    "properties": {
    "enterprise_name":"Sudala muthu",
    "owner_name":"Sudala muthu",
    "contact_number":"8939083970"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2215002000,13.1691461000 ]
    },
    "properties": {
    "enterprise_name":"Vinayagar waste Papper mart",
    "owner_name":"Pradeep perumal",
    "contact_number":"9043338939"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2394150500,13.1702518500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2613817100,13.1708435300 ]
    },
    "properties": {
    "enterprise_name":"Devadas waste mart",
    "owner_name":"Devadas",
    "contact_number":"860806779"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2566701500,13.1708931200 ]
    },
    "properties": {
    "enterprise_name":"S.s mart",
    "owner_name":"Jagan",
    "contact_number":"99976543210"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2566291200,13.1711669700 ]
    },
    "properties": {
    "enterprise_name":"Murugesh",
    "owner_name":"Murugesh",
    "contact_number":"8939154504"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2569570700,13.1712159700 ]
    },
    "properties": {
    "enterprise_name":"Punitha metals",
    "owner_name":"Arul doss",
    "contact_number":"9444782179"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2568833100,13.1713048000 ]
    },
    "properties": {
    "enterprise_name":"It",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1987666600,13.1714103200 ]
    },
    "properties": {
    "enterprise_name":"Mallika waste paper mart",
    "owner_name":"Boomi nathan",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1986956900,13.1715067400 ]
    },
    "properties": {
    "enterprise_name":"Velayutham waste paper mart",
    "owner_name":"Velayutham",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2569691500,13.1721467600 ]
    },
    "properties": {
    "enterprise_name":"Mani",
    "owner_name":"Mani",
    "contact_number":"917642558"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2566616500,13.1721811400 ]
    },
    "properties": {
    "enterprise_name":"Mani",
    "owner_name":"Mani",
    "contact_number":"917642558"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2569438600,13.1723004200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1977930400,13.1734792800 ]
    },
    "properties": {
    "enterprise_name":"Manikandan waste paper mart",
    "owner_name":"Dilip kumar",
    "contact_number":"7358598727"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2013817900,13.1738266000 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"Muthu",
    "contact_number":"81224890895"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2517282900,13.1740472800 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman traders",
    "owner_name":"Gopal",
    "contact_number":"9094005757"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2518528200,13.1741299900 ]
    },
    "properties": {
    "enterprise_name":"Mutharaman trader",
    "owner_name":"Gopal",
    "contact_number":"9444192744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2487613300,13.1745542900 ]
    },
    "properties": {
    "enterprise_name":"Saravanan traders",
    "owner_name":"",
    "contact_number":"9940485442"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2517710300,13.1746299300 ]
    },
    "properties": {
    "enterprise_name":"Shop closed",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2014909800,13.1749480800 ]
    },
    "properties": {
    "enterprise_name":"Mutharamman waste paper mart",
    "owner_name":"",
    "contact_number":"8122489095"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2880024800,13.1751835300 ]
    },
    "properties": {
    "enterprise_name":"Nachi muthu",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2933077900,13.1755327900 ]
    },
    "properties": {
    "enterprise_name":"Maha Lakshmi plastic",
    "owner_name":"Jaya raj",
    "contact_number":"941874288"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2993388900,13.1766536800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2995123000,13.1767699000 ]
    },
    "properties": {
    "enterprise_name":"Balachendiren waste shop",
    "owner_name":"Balachendiren",
    "contact_number":"9840405442"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2023153700,13.1769825500 ]
    },
    "properties": {
    "enterprise_name":"Ayyanar waste paper mart",
    "owner_name":"Muthitya",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2971392000,13.1784435000 ]
    },
    "properties": {
    "enterprise_name":"Citizen old iron shop",
    "owner_name":"S.T.palani raja",
    "contact_number":"9789513594"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3006945000,13.1784714000 ]
    },
    "properties": {
    "enterprise_name":"Vinayagam paper mart",
    "owner_name":"Arumugam",
    "contact_number":"9444995779"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2970186000,13.1794247000 ]
    },
    "properties": {
    "enterprise_name":"Kumar",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2982478000,13.1797240000 ]
    },
    "properties": {
    "enterprise_name":"S.L.M traders",
    "owner_name":"Madasamy",
    "contact_number":"9840318784"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3016536000,13.1803496000 ]
    },
    "properties": {
    "enterprise_name":"Alex paper mart",
    "owner_name":"Kurush antony",
    "contact_number":"7200721610"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2984660200,13.1810141200 ]
    },
    "properties": {
    "enterprise_name":"J. raj",
    "owner_name":"J.raj",
    "contact_number":"9840229536"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3005450000,13.1826075900 ]
    },
    "properties": {
    "enterprise_name":"Koman s.m",
    "owner_name":"Koman",
    "contact_number":"9042732289"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2343856200,13.1828014200 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3013038300,13.1833628800 ]
    },
    "properties": {
    "enterprise_name":"Ganesan",
    "owner_name":"Ganesan",
    "contact_number":"9597093422"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2033070300,13.1834520400 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":"7358287063"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3084674000,13.1840844000 ]
    },
    "properties": {
    "enterprise_name":"Muthaiya waste shop",
    "owner_name":"Muthaiya",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3086208000,13.1845247000 ]
    },
    "properties": {
    "enterprise_name":"Ayya metels ",
    "owner_name":"R.Agilan",
    "contact_number":"9445276330"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3149465000,13.1846965000 ]
    },
    "properties": {
    "enterprise_name":"Naganathan waste shops",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3149465000,13.1846965000 ]
    },
    "properties": {
    "enterprise_name":"A.M.P traders",
    "owner_name":"Kumar",
    "contact_number":"9444715805"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1949952500,13.1864246900 ]
    },
    "properties": {
    "enterprise_name":"Rajesh",
    "owner_name":"Vadivelu",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3067963000,13.1865527000 ]
    },
    "properties": {
    "enterprise_name":"Archana paper mart",
    "owner_name":"Ganapathi",
    "contact_number":"9566173403"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1919879600,13.1875884800 ]
    },
    "properties": {
    "enterprise_name":"Lakshmi waste paper mart",
    "owner_name":"Kasi",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3103424000,13.1899911000 ]
    },
    "properties": {
    "enterprise_name":"Gopal waste shop",
    "owner_name":"Gopal",
    "contact_number":"9944289074"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2697770300,13.1902940900 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3138245000,13.1908360000 ]
    },
    "properties": {
    "enterprise_name":"Kanni iron shop",
    "owner_name":"G.Kannan",
    "contact_number":"9965643654"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3108471000,13.1920110000 ]
    },
    "properties": {
    "enterprise_name":"Kathiresan paper mart",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3095250000,13.1921697000 ]
    },
    "properties": {
    "enterprise_name":"Madha paper mart",
    "owner_name":"Robinson",
    "contact_number":"9840661469"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1717517100,13.1944093400 ]
    },
    "properties": {
    "enterprise_name":"Anand",
    "owner_name":"",
    "contact_number":"9710022011"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1643754100,13.1958375700 ]
    },
    "properties": {
    "enterprise_name":"Jagannathan",
    "owner_name":"Jagannatan",
    "contact_number":"9382731514"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1650619500,13.1961019500 ]
    },
    "properties": {
    "enterprise_name":"Yegapa raj",
    "owner_name":"Yagapa raj",
    "contact_number":"7871275620"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1798272600,13.1973355100 ]
    },
    "properties": {
    "enterprise_name":"Srinivasan",
    "owner_name":"Srinivasan",
    "contact_number":"9710815365"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1578507500,13.1973756900 ]
    },
    "properties": {
    "enterprise_name":"Kumar",
    "owner_name":"Kuma r",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1885365900,13.1974909800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1951248900,13.2030062600 ]
    },
    "properties": {
    "enterprise_name":"Parvathi waste mart",
    "owner_name":"Ganaraj",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2702130000,13.2050453800 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3186993000,13.2127654000 ]
    },
    "properties": {
    "enterprise_name":"Panjangam waste shops",
    "owner_name":"Panjangam",
    "contact_number":"9952730196"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3204254000,13.2162255000 ]
    },
    "properties": {
    "enterprise_name":"Murugan waste mart",
    "owner_name":"Pream",
    "contact_number":"9498055883"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2731544100,13.2211509300 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.3211365000,13.2246231000 ]
    },
    "properties": {
    "enterprise_name":"SNG enterprises",
    "owner_name":"Sagaya raj",
    "contact_number":"9952917686"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1655874400,13.2274584700 ]
    },
    "properties": {
    "enterprise_name":"Purushoth",
    "owner_name":"Purushoth",
    "contact_number":"7601954273"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2738459800,13.2302799500 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2738789200,13.2303127600 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2741305600,13.2305278100 ]
    },
    "properties": {
    "enterprise_name":"",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1626458500,13.2345946700 ]
    },
    "properties": {
    "enterprise_name":"Jay ganesh",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1612291100,13.2380383200 ]
    },
    "properties": {
    "enterprise_name":"Ramesh",
    "owner_name":"Ramesh",
    "contact_number":"9442434469"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1592637600,13.2439593400 ]
    },
    "properties": {
    "enterprise_name":"Rajesh",
    "owner_name":"",
    "contact_number":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1588501300,13.2449124100 ]
    },
    "properties": {
    "enterprise_name":"Raj",
    "owner_name":"Raj",
    "contact_number":"9786777680"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1555582500,13.2519873600 ]
    },
    "properties": {
    "enterprise_name":"Mayakannan",
    "owner_name":"Mayakannan",
    "contact_number":"966645673"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2750806000,13.2600034100 ]
    },
    "properties": {
    "enterprise_name":"Murugan paper mart",
    "owner_name":"",
    "contact_number":"9444240097"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2572164500,13.2810361800 ]
    },
    "properties": {
    "enterprise_name":"Hi",
    "owner_name":"",
    "contact_number":""
    }
  }
]
}


export default kabadiwallas;