import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Table } from 'reactstrap';
import { Link } from 'react-router';
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import Loader from 'react-loader-spinner'
import kcLogo from '../images/kc1.png';
import '../index.css';

class Metal extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      kwData: {},
      mmData: {},
      wpData: {},
      buttonValue: "Volume",
      kwPrice: {},
      mmPrice: {},
      wpPrice: {},
      kwRevenue: {},
      mmRevenue: {},
      wpRevenue: {},
      loadingVolume: true,
      loadingPrice: true,
      loadingRevenue: true
    };
    this.setState({ buttonValue: "Volume" })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //API call - VOLUME
    this.setState({ loadingVolume: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/volume/', {
      params: {
        material: 'Metal',
        out: (this.state.buttonValue).toLowerCase()
      }
    }).then(res => {
      this.setState({
        loadingVolume: false,
        kwData: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmData: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpData: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));

    //API call - PRICE
    this.setState({ loadingPrice: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/buy_sell/', {
      params: {
        material: 'Metal'
      }
    }).then(res => {
      this.setState({
        loadingPrice: false,
        kwPrice: {
          labels: res.data.data.kw.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.kw.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.kw.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        mmPrice: {
          labels: res.data.data.mm.keys,
          datasets: [
            {
              label: 'Buying Pricing',
              data: res.data.data.mm.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.mm.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        wpPrice: {
          labels: res.data.data.wp.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.wp.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.wp.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        }
       })
    }).catch(err => console.log(err));

    //API call - REVENUE
    this.setState({ loadingRevenue: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/revenue/', {
      params: {
        material: 'Metal'
      }
    }).then(res => {
      this.setState({
        loadingRevenue: false,
        kwRevenue: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmRevenue: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpRevenue: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));
  }

  //Navbar Toggle
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  //Dropdown button toggle
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  
  //View port width calculator
  calculateWidth() {
    if (window.innerWidth <= 600) { /* mobile */
      return 300;
    } else if (window.innerWidth >= 1100) { /* Desktop */
      return 150;
    } else {
      return 250; /* Tablet */
    }
  }

  //Render Waste Picker data
  renderWastePickerData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.wpData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L0 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: '600'
                        },
                          ticks: {
                            fontSize: 12,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: 'Source Sans Pro',
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === 'Price') {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.wpPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                  tooltips: {
                    mode: 'x-axis'
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Price',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true,
                      ticks: {
                        fontSize: 12,
                        beginAtZero: true,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      stacked: true
                    }]
                  }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.wpRevenue}
            // width={800}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L0 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: '600'
                      },
                        ticks: {
                          fontSize: 12,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: 'Source Sans Pro',
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  // Render Kabadiwalla data
  renderKabadiwallaData() {
    if(this.state.buttonValue === "Volume") {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.kwData}
              // width={800}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L1 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        },
                        tooltips: {
                          enabled: true,
                          fontSize: 12,
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif",
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === "Price") {
      if(this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.kwPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.kwRevenue}
            // width={800}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L1 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif",
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  //Render Middleman Data
  renderMiddlemanData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.mmData}
              // width={800}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L2 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Volume',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                          fontSize: 8,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          beginAtZero:true,
                          min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === 'Price') {
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.mmPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.mmRevenue}
            // width={800}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L2 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        beginAtZero:true,
                        min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  renderDropdownButton() {
    if(this.state.loadingVolume == false && this.state.loadingPrice == false) {
      return (
        <ButtonDropdown className="btn center" style={{ marginTop: 20, marginBottom: 20 }} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle className="dropDownButtonStyle" caret>
            {this.state.buttonValue}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Volume"})}>Volume</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Price"})}>Price</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Revenue"})}>Revenue</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loader
            type="TailSpin"
            color="#39A300"
            height="40"
            width="40"
          />
        </div>
      )
    }
  }

  renderMaterialTaxonomy() {
      return (
        <div className="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Material Taxonomy</h3>
          </div>
          <div style={{ paddingTop: 10 }}>
            <div style={{ paddingLeft: 15 }}>
              <h5>Aluminium</h5> 
            </div>
            <p className="descriptionStyle">
              The primary subcategories of aluminium are AA or aluminium alloy, BC or beer can, BB or bus body, CC or cable channel, SR or steel-reinforced, nagam (“strips”, aluminium alloyed with Zinc), getti (“chunks”), and one set of items referred to simply as aluminium, which is typically material recovered from tin foil containers and other packaging material. All the subcategories are relatively highly priced. The cheapest item is packaging aluminium, which L1s sell at 15 INR per kilogram. On the other extreme, aluminium vessels are sold for double that amount. Middlemen segregate some of the L1s subcategories even further. For example, what L1s refer to as getti, they segregate into MA or Motor Aluminium and MAI or Motor Aluminium with Iron.
            </p>
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Table bordered className="tabelTextStyle" style={{ marginTop: 15 }}>
              <thead>
                <tr>
                  <th colspan="2">Waste-picker</th>
                  <th colspan="2">Kabadiwalla</th>
                  <th colspan="2">Middleman</th>
                  <th colspan="2">Processor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><b>Material Name</b></td>
                  <td><b>Price</b></td>
                  <td><b>Material Name</b></td>
                  <td><b>Price</b></td>
                  <td><b>Material Name</b></td>
                  <td><b>Price</b></td>
                  <td><b>Material Name</b></td>
                  <td><b>Price</b></td>
                </tr>
                <tr>
                  <td rowspan="11">Aluminium</td>
                  <td rowspan="11">60-100</td>
                </tr>
                <tr>
                  <td>Aluminium</td>
                  <td>100</td>
                  <td>Aluminium</td>
                  <td>110</td>
                  <td rowspan="21">Alloys and Metals</td>
                  <td rowspan="21">Varies</td>
                </tr>
                <tr>
                  <td>Naagam</td>
                  <td>80</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>CC</td>
                  <td>110</td>
                  <td>CC</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>BC</td>
                  <td>65</td>
                  <td>Coke</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Bus Body</td>
                  <td>-</td>
                  <td>BB</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>AA</td>
                  <td>95</td>
                  <td>AA</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td rowspan="3">Getti</td>
                  <td rowspan="3">-</td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>MAI</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>SR</td>
                  <td>70-80</td>
                  <td>SR</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td>Naagam</td>
                  <td>60</td>
                  <td>Naagam</td>
                  <td>80</td>
                  <td>Naagam</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td>CC</td>
                  <td>90</td>
                  <td>CC</td>
                  <td>110</td>
                  <td>CC</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td>BC</td>
                  <td>60</td>
                  <td>BC</td>
                  <td>65</td>
                  <td>Coke</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td>Bus Body</td>
                  <td>-</td>
                  <td>Bus Body</td>
                  <td>-</td>
                  <td>BB</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td>AA</td>
                  <td>80</td>
                  <td>AA</td>
                  <td>95</td>
                  <td>AA</td>
                  <td>-</td>
                </tr>

                <tr>
                  <td rowspan="3">Getti</td>
                  <td rowspan="3">-</td>
                  <td rowspan="3">Getti</td>
                  <td rowspan="3">-</td>
                </tr>
                <tr>
                  <td>MA</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>MAI</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td >SR</td>
                  <td>65-70</td>
                  <td>SR</td>
                  <td>70-80</td>
                  <td >SR</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Computer</td>
                  <td>-</td>
                  <td>Aluminium PC</td>
                  <td>96</td>
                  <td>Aluminium PC</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Thool</td>
                  <td>-</td>
                  <td>Aluminium</td>
                  <td>73</td>
                  <td>Aluminium</td>
                  <td>-</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div style={{ paddingTop: 10 }}>
          <div style={{ paddingLeft: 15 }}>
            <h5>Copper</h5> 
          </div>
          <p className="descriptionStyle">Copper is one of the most expensive materials handled in the informal waste ecosystem, but it is also quite rare and found only in small quantities. Copper is therefore segregated to a fine degree with CAC (Copper Alloy Conductor), AC Copper, TOC/NC (TIn Overcoated Copper/Nickel-plated Copper), OC (Oxidised Copper), and RMC Copper (Rigid Metal Conduit Copper) being the major categories. CAC, which is stripped copper wiring, is considered to be best quality of copper scrap and commands the highest sale price. AC Copper, which is copper tubing and copper parts from air-conditioning units, is considered to be of second-grade quality. OC, which includes copper items that have been exposed to air and thus oxidised, is of the lowest quality as its conductivity reduces due to oxidation.</p>
          <div style={{ paddingLeft: 15, paddingRight: 15 }}>
            <Table bordered className="tabelTextStyle" style={{ marginTop: 15 }}>
            <thead>
              <tr>
                <th colspan="2">Waste-picker</th>
                <th colspan="2">Kabadiwalla</th>
                <th colspan="2">Middleman</th>
                <th colspan="2">Processor</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
                <td><b>Material Name</b></td>
                <td><b>Price</b></td>
              </tr>
              <tr>
                <td rowspan="5">Copper</td>
                <td rowspan="5">300-370</td>
              </tr>
              <tr>
                <td>CAC</td>
                <td>320</td>
                <td>CAC/Copper Wire</td>
                <td>350-380</td>
                <td rowspan="10">Alloy and Metal Products</td>
                <td rowspan="10">Varies</td>
              </tr>
              <tr>
                <td>AC</td>
                <td>320</td>
                <td>AC</td>
                <td>350</td>
              </tr>
              <tr>
                <td>TOC</td>
                <td>-</td>
                <td>TOC</td>
                <td>350</td>
              </tr>
              <tr>
                <td>OC</td>
                <td>280</td>
                <td>OC</td>
                <td>380</td>
              </tr>
              <tr>
                <td>CAC</td>
                <td>300</td>
                <td>CAC</td>
                <td>320</td>
                <td>CAC/Copper Wire</td>
                <td>350-380</td>
              </tr>
              <tr>
                <td>AC</td>
                <td>280</td>
                <td>AC</td>
                <td>320</td>
                <td>AC</td>
                <td>350</td>
              </tr>
              <tr>
                <td>OC</td>
                <td>260</td>
                <td>OC</td>
                <td>280</td>
                <td>OC</td>
                <td>380</td>
              </tr>
              <tr>
                <td>Mixy Copper</td>
                <td>-</td>
                <td>Mixy Copper</td>
                <td>-</td>
                <td>R-Mixy Copper</td>
                <td>370</td>
              </tr>
              <tr>
                <td>Getti</td>
                <td>-</td>
                <td>Getti</td>
                <td>-</td>
                <td>GC/Getti Copper</td>
                <td>380</td>
              </tr>
            </tbody>
          </Table>
          </div>
        </div>
      </div>
      )
  }

  renderDataAnalyticsContent() {
    if(this.state.loadingVolume == false && this.state.buttonValue === 'Volume') {
      return (
        <div>
          <h5>Volume</h5>
          <p className="descriptionStyleMaterialTaxonomy">Iron is the most recovered material in the city’s waste ecosystem, and is attributable to its widespread use in the consumer market, while copper is the second highest recovered material at the L0 level. At the L1 and L2 levels, commercial sourcing from smaller retailers and recovery of higher value metals from household furniture and appliances is carried out.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Price') {
      return (
        <div>
          <h5>Price</h5>
          <p className="descriptionStyleMaterialTaxonomy">While L0s are usually able to recover only low-value metals such as foil-grade aluminium and tin, metals are a high-margin material for L1. L1s make, on average, between Rs. 10 - 30 per kg of metal sold, compared to the Rs 1 - 5 per kg for many other materials. Segregation of metals at the L2 level ensure that higher margins can be achieved. Iron from commercial sources, which are of higher quality, and rolling steel are the materials offering the largest margins for L2 enterprises. </p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Revenue') {
      return (
        <div>
          <h5>Revenue</h5>
          <p className="descriptionStyleMaterialTaxonomy">Across different stakeholder levels, iron generates the highest revenue due to its high volumes. Other higher volume materials generating significant revenue include commercial steel and tin. </p>
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronMetal">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Metal</h1>
            Unlike other material categories, the classification system followed for metal scraps closely mirrors formal nomenclature. L0s typically recovery low-value metals such as foil-grade aluminium and tin, while L1s and L2 procure higher value metals directly from households and commercial sources. Reprocessing and remanufacturing operations for metals are largely located outside Chennai city limits given the strict environmental compliance requirements and prohibitive real estate costs.
          </div>
        </div>
        {/* LCA */}
        <div class="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Life Cycle Analysis</h3>
          </div>
          <p className="descriptionStyle">At the L0 level, the most highly recovered scraps are low-value metals such as aluminium foil, tin, and iron. L1 enterprises mostly function as aggregators. However, unlike other material categories, L1 enterprises may carry out processing of items from domestic sources such as household appliances and furniture to extract their metal components. This material is sold to L2 enterprises who segregate it to a fine degree based on standardized formal classification according to nature of alloy type. This material is sold to processors who are mostly located in other districts of Tamil Nadu or in other states.</p>
        </div>
        {/* Material Taxnomy */}
        {this.renderMaterialTaxonomy()}
        {/* Data Analytics */}
        <div className="descriptionViewStyle" style={{ paddingLeft: 15, paddingRight: 15 }}>
          {
            this.state.loadingVolume == false ? (
              <div>
                <h3>Data Analytics</h3> 
                {this.renderDataAnalyticsContent()}
              </div>
            ) : null
          }
          {/* Dropdown Button */}
          {this.renderDropdownButton()}

          {this.renderWastePickerData()}
          {this.renderKabadiwallaData()}
          {this.renderMiddlemanData()}
        </div>
      </div>
    )
  }
}

export default Metal;
