var middleman = {
   "type": "FeatureCollection",
   "features": [
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2718583300,13.0424500000 ]
    },
    "properties": {
    "shop_name":"Aysha traders",
    "contact":"4424983652"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2739668700,13.0451693400 ]
    },
    "properties": {
    "shop_name":"Rahmath nisha steel traders",
    "contact":"Khadar 9962443131"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2775021400,13.0180481100 ]
    },
    "properties": {
    "shop_name":"Suganya bottles",
    "contact":"9789054813"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2683716700,13.0625133300 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2513172800,13.0490871200 ]
    },
    "properties": {
    "shop_name":"Sri Devi plastic",
    "contact":"9383335050"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511633300,13.0490550000 ]
    },
    "properties": {
    "shop_name":"Raja store",
    "contact":"938015733"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2245489800,12.9862694600 ]
    },
    "properties": {
    "shop_name":"Kala waste paper mart",
    "contact":"9791156523"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511100000,12.9805016700 ]
    },
    "properties": {
    "shop_name":"Akshaya waste paper mart",
    "contact":"98426 28212\n99622 22398\n86672 13331"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2736172900,13.0333731400 ]
    },
    "properties": {
    "shop_name":"Ar traders\n s.ansari",
    "contact":"9884714347"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2451990300,12.9739502900 ]
    },
    "properties": {
    "shop_name":"Balsundaram waste paper mart",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2554200000,12.9876366700 ]
    },
    "properties": {
    "shop_name":"Gomathi waste paper stores",
    "contact":"044 24402554"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2433233300,13.1290733300 ]
    },
    "properties": {
    "shop_name":"Raja store",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2427023800,13.1295141700 ]
    },
    "properties": {
    "shop_name":"Mithili steels",
    "contact":"442552010"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2456826000,13.1383020300 ]
    },
    "properties": {
    "shop_name":"Thangam paziya irumbu kadai",
    "contact":"9840347788"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2385100000,13.1220783300 ]
    },
    "properties": {
    "shop_name":"Madha papers",
    "contact":"044 26720066\n 99625 10662"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2601183300,13.0303766700 ]
    },
    "properties": {
    "shop_name":"S.P.W. paper",
    "contact":"9003233577"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2461658600,13.1291183900 ]
    },
    "properties": {
    "shop_name":"Palani andavar traders",
    "contact":"9940157758"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2421516700,13.1303100000 ]
    },
    "properties": {
    "shop_name":"Om sakthi",
    "contact":"9840380076"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2297728200,13.0305028900 ]
    },
    "properties": {
    "shop_name":"Saravanan paper mart, GS scrap metals",
    "contact":"9094505666 & 8428341840"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2489990500,13.0406757100 ]
    },
    "properties": {
    "shop_name":"As metal",
    "contact":"9444273703"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2724616700,13.0423466700 ]
    },
    "properties": {
    "shop_name":"Raja traders",
    "contact":"9444355284"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2463566700,13.0417816700 ]
    },
    "properties": {
    "shop_name":"Farooq enter price",
    "contact":"9840067184"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2806247700,13.0964226800 ]
    },
    "properties": {
    "shop_name":"Ravi bharathi &co",
    "contact":"4425211633"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2509433300,13.0924833300 ]
    },
    "properties": {
    "shop_name":"Madha wast paper store",
    "contact":"9789029006\n9444639313\n7299974169"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2471420900,13.1070025900 ]
    },
    "properties": {
    "shop_name":"Thirumal murugan traders",
    "contact":"9840170620 97911 03259"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2576945700,13.0975492000 ]
    },
    "properties": {
    "shop_name":"Padmanaba waste mart",
    "contact":"044 26622907"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2365950000,13.0965100000 ]
    },
    "properties": {
    "shop_name":"Sri Srinivasa waste paper",
    "contact":"9710899409"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2271533300,13.0935166700 ]
    },
    "properties": {
    "shop_name":"Battle godown",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2086900000,13.1008966700 ]
    },
    "properties": {
    "shop_name":"Sivaprakas paper mart",
    "contact":"9840942946"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1658384300,13.0596495800 ]
    },
    "properties": {
    "shop_name":"Moorthy traders",
    "contact":"9941609536"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1633844700,13.0401124400 ]
    },
    "properties": {
    "shop_name":"Sudhakar w.mart",
    "contact":"9094710710"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890616700,13.0784450000 ]
    },
    "properties": {
    "shop_name":"SMT",
    "contact":"044 26158338"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1883694400,13.0759151000 ]
    },
    "properties": {
    "shop_name":"Kottalam traders",
    "contact":"9840266628, 9791064447"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1651333300,13.0460800000 ]
    },
    "properties": {
    "shop_name":"Shanmuga treads",
    "contact":"9094719931"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1500054400,13.0480025900 ]
    },
    "properties": {
    "shop_name":"Arthur Durga traders",
    "contact":"9840324995/8144004579"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1518784800,13.0514698300 ]
    },
    "properties": {
    "shop_name":"Naveen traders",
    "contact":"9566184489"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1531953300,13.0560950400 ]
    },
    "properties": {
    "shop_name":"Ajb traders",
    "contact":"9941244257"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1495822200,13.0480509000 ]
    },
    "properties": {
    "shop_name":"Jaya Surya traders",
    "contact":"9444100664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1886233300,13.0741633300 ]
    },
    "properties": {
    "shop_name":"Anitha plastic",
    "contact":"9842391132"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2536883300,13.0924633300 ]
    },
    "properties": {
    "shop_name":"Sai abc traders",
    "contact":"9962910021\n9840123289"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1513566700,13.0516383300 ]
    },
    "properties": {
    "shop_name":"Kumar thurai",
    "contact":"9941712176\n9841388487"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1500883300,13.0544683300 ]
    },
    "properties": {
    "shop_name":"Praga yuga Enterprise",
    "contact":"9884121822"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1336533300,13.0602233300 ]
    },
    "properties": {
    "shop_name":"Ramkumar Enterprise",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1822966700,13.0608166700 ]
    },
    "properties": {
    "shop_name":"JRM Enterprise",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1914963300,13.0820161600 ]
    },
    "properties": {
    "shop_name":"Velmurugan traders",
    "contact":"9941337775"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1238337300,13.0539891400 ]
    },
    "properties": {
    "shop_name":"St velankanni Madhavi traders",
    "contact":"988466533"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1209033300,13.0646450000 ]
    },
    "properties": {
    "shop_name":"Harris traders",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1544816700,13.0539083300 ]
    },
    "properties": {
    "shop_name":"Sri velmuruga waste mart",
    "contact":"8939193211"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1828650000,13.0664083300 ]
    },
    "properties": {
    "shop_name":"Balaji traders",
    "contact":"9884229155"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1329833300,13.0641050000 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1329921800,13.0641315100 ]
    },
    "properties": {
    "shop_name":"Sac bottles",
    "contact":"9840444008"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1344189800,13.0629494800 ]
    },
    "properties": {
    "shop_name":"Priya irumbu kadi",
    "contact":"949803112"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1914783300,13.0820600000 ]
    },
    "properties": {
    "shop_name":"Vijayan& brothers",
    "contact":"9677297088"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1294700000,13.0646250000 ]
    },
    "properties": {
    "shop_name":"Deepika traders",
    "contact":"9789155269\n9094468853"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2614862700,13.1082155200 ]
    },
    "properties": {
    "shop_name":"Abdul",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2579771500,13.1148800700 ]
    },
    "properties": {
    "shop_name":"Daily thanthi",
    "contact":"9884646866"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2605655000,13.1077313500 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2580306200,13.1097897900 ]
    },
    "properties": {
    "shop_name":"Andal traders",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890983300,13.0315100000 ]
    },
    "properties": {
    "shop_name":"Fenix plastic & paper mart",
    "contact":"9444045313\n9445098425\n04424745312"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2282213300,13.1528188800 ]
    },
    "properties": {
    "shop_name":"Bhairavi recycling",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2444344900,13.1291629600 ]
    },
    "properties": {
    "shop_name":"Sun shine plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2940035000,13.1332901100 ]
    },
    "properties": {
    "shop_name":"Venkateswara metal mart",
    "contact":"9840048235"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2963527500,13.1556362100 ]
    },
    "properties": {
    "shop_name":"STK traders",
    "contact":"9840476037"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2622833300,13.1005350000 ]
    },
    "properties": {
    "shop_name":"Shree traders",
    "contact":"9444105869\n9600090007"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1928725400,13.1165910400 ]
    },
    "properties": {
    "shop_name":"Thangaraj",
    "contact":"9444174402"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1462575800,13.2001898500 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1952710600,13.2038938000 ]
    },
    "properties": {
    "shop_name":"Arihant reprocess industries",
    "contact":"9600095285"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2393499100,13.1703374600 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2557144800,13.1643850000 ]
    },
    "properties": {
    "shop_name":"Sri iyyapa traders",
    "contact":"9444252416"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2633134600,13.1705622900 ]
    },
    "properties": {
    "shop_name":"Sri annai plastics,",
    "contact":"9840407355"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1533409800,13.1982599200 ]
    },
    "properties": {
    "shop_name":"Prem",
    "contact":"9176229348"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1460709200,13.2000673300 ]
    },
    "properties": {
    "shop_name":"Selvaraj pet company",
    "contact":"8072939298"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1771093600,13.2009824300 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1649816900,13.2306918900 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1628794900,13.2381909100 ]
    },
    "properties": {
    "shop_name":"Siva \nPlastic and iron",
    "contact":"9940549599"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1534781700,13.1981783300 ]
    },
    "properties": {
    "shop_name":"Giri\n raja plasric",
    "contact":"9176229348"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1773754700,13.2003967800 ]
    },
    "properties": {
    "shop_name":"Naf traders",
    "contact":"044 26418272"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2242263800,13.1748797200 ]
    },
    "properties": {
    "shop_name":"S.A.S plastic",
    "contact":"9840569949"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2251820400,13.1746897100 ]
    },
    "properties": {
    "shop_name":"Bala Murugan plastic",
    "contact":"7299443055"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2263508400,13.1763361100 ]
    },
    "properties": {
    "shop_name":"Star plastics",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2215432200,13.1687434400 ]
    },
    "properties": {
    "shop_name":"Plastic Chennai",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2213788800,13.1772699100 ]
    },
    "properties": {
    "shop_name":"Sun plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2212533300,13.1974950000 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2375335000,13.1900698000 ]
    },
    "properties": {
    "shop_name":"Sakshetha plastic",
    "contact":"7373880882"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2260507000,13.1768747000 ]
    },
    "properties": {
    "shop_name":"Iqbal plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2584420000,13.1345721000 ]
    },
    "properties": {
    "shop_name":"Mahalaxmi metal",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2277289100,13.1528474300 ]
    },
    "properties": {
    "shop_name":"Jothi plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2337964000,13.1873252000 ]
    },
    "properties": {
    "shop_name":"Optumas plastic",
    "contact":"8072681620"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2337964000,13.1873252000 ]
    },
    "properties": {
    "shop_name":"Dharsha enterprise",
    "contact":"8015497000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2258200700,13.1756157500 ]
    },
    "properties": {
    "shop_name":"Kavya plastics",
    "contact":"9840424245"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2266232700,13.1770627600 ]
    },
    "properties": {
    "shop_name":"Priyanka plastics",
    "contact":"9087010952"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2217645500,13.1687889300 ]
    },
    "properties": {
    "shop_name":"Plastic cover traders",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2213651600,13.1773603800 ]
    },
    "properties": {
    "shop_name":"Sun plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2217880300,13.1743267000 ]
    },
    "properties": {
    "shop_name":"All plastic cover",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2539733300,13.1649416700 ]
    },
    "properties": {
    "shop_name":"Sree mandra moorthi treaders",
    "contact":"9840507450"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2398116700,13.1703666700 ]
    },
    "properties": {
    "shop_name":"Sree annai plastic",
    "contact":"7358096860"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2398200000,13.1703150000 ]
    },
    "properties": {
    "shop_name":"Vijay plastic",
    "contact":"7358096860"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2458975000,13.1291083000 ]
    },
    "properties": {
    "shop_name":"Sun shine plastic",
    "contact":"9840740712"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1929563500,13.1167548300 ]
    },
    "properties": {
    "shop_name":"Sunil Raj plastic",
    "contact":"7904331880"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1912734000,13.1200669000 ]
    },
    "properties": {
    "shop_name":"S.A.S plastic",
    "contact":"9791524274"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2545000000,13.1140566700 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2066488900,13.1828688500 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1951550700,13.2068231300 ]
    },
    "properties": {
    "shop_name":"Just plastic",
    "contact":"8508265243"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1424850000,13.0613850000 ]
    },
    "properties": {
    "shop_name":"Ss plastic",
    "contact":"944451743"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2923400000,13.1662183300 ]
    },
    "properties": {
    "shop_name":"SM ponraj waste mart",
    "contact":"9840576716"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.0802316700,13.0459050000 ]
    },
    "properties": {
    "shop_name":"Shri vari",
    "contact":"9383200826"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 79.9618904500,13.0218402300 ]
    },
    "properties": {
    "shop_name":"Jesuva plastics",
    "contact":"9655694296"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2489455200,12.9600076300 ]
    },
    "properties": {
    "shop_name":"KNR traders",
    "contact":"9444111889"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2478003400,12.9629248700 ]
    },
    "properties": {
    "shop_name":"MTR traders",
    "contact":"9884430020\n9444742077"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2285639500,12.9170341200 ]
    },
    "properties": {
    "shop_name":"Friends scrab dealers",
    "contact":"9380663629"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2293913200,12.9159096100 ]
    },
    "properties": {
    "shop_name":"Balan trader's",
    "contact":"9788969788"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1042957000,12.8711492000 ]
    },
    "properties": {
    "shop_name":"Suresh pet.",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1676610100,12.9647574200 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2223233200,12.8478764000 ]
    },
    "properties": {
    "shop_name":"Punitha mickel athithoothar traders",
    "contact":"9444317490\n9094092960"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1997787600,12.8741217800 ]
    },
    "properties": {
    "shop_name":"Saviar traders",
    "contact":"9962021321"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1409309000,12.9125119000 ]
    },
    "properties": {
    "shop_name":"M.P.M traders",
    "contact":"8825889354"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1433811000,12.9257943000 ]
    },
    "properties": {
    "shop_name":"Michel traders",
    "contact":"9445700000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1461850000,13.0361516700 ]
    },
    "properties": {
    "shop_name":"Murugan waste paper mart",
    "contact":"9841491501"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2252259000,13.0318712000 ]
    },
    "properties": {
    "shop_name":"G.p.plastic.",
    "contact":"9840446688"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2104495000,13.0096539000 ]
    },
    "properties": {
    "shop_name":"R.K.m glass works",
    "contact":"9152192243"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1533066700,13.0562350000 ]
    },
    "properties": {
    "shop_name":"AJP plastic",
    "contact":"9941244257"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1408719000,12.8923617000 ]
    },
    "properties": {
    "shop_name":"Maharaja traders",
    "contact":"9444102965"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1618630900,12.8829452100 ]
    },
    "properties": {
    "shop_name":"Sri Easwari Traders",
    "contact":"9840322235"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2115325000,13.0160221000 ]
    },
    "properties": {
    "shop_name":"Annai traders.",
    "contact":"9941835079"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1712006700,12.8832599500 ]
    },
    "properties": {
    "shop_name":"Antony metal scrabs",
    "contact":"9443393991"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1781341700,12.9795098300 ]
    },
    "properties": {
    "shop_name":"M.M.S plastics",
    "contact":"9710648193"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1799545400,12.9568180200 ]
    },
    "properties": {
    "shop_name":"New Hivaa Traders",
    "contact":"9884303214"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2493449600,12.9726209800 ]
    },
    "properties": {
    "shop_name":"Thangam enterprises",
    "contact":"9884491604"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2415179000,12.9543292100 ]
    },
    "properties": {
    "shop_name":"SMK traders",
    "contact":"9941008272"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1477482900,12.9682366100 ]
    },
    "properties": {
    "shop_name":"M.S. waste paper traders",
    "contact":"9840302914"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1451657700,12.9686053900 ]
    },
    "properties": {
    "shop_name":"Ashwin waste paper mart",
    "contact":"9176543420"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2332804000,12.9104622000 ]
    },
    "properties": {
    "shop_name":"LkR enterprises.",
    "contact":"9940400944"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2494206000,12.9634903000 ]
    },
    "properties": {
    "shop_name":"Bright plastic.",
    "contact":"9840033832"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2471469000,12.9634935000 ]
    },
    "properties": {
    "shop_name":"Nathan trader's.",
    "contact":"9.8416200199444005E+19"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2406701000,12.9676632000 ]
    },
    "properties": {
    "shop_name":"AVM plastic.",
    "contact":"9941358787"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2331277000,12.9104547000 ]
    },
    "properties": {
    "shop_name":"Balan traders.",
    "contact":"9042751889"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2378715500,12.9326192300 ]
    },
    "properties": {
    "shop_name":"Raja waste scrap",
    "contact":"917000000000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2336750100,12.9352585700 ]
    },
    "properties": {
    "shop_name":"Mageswari traders",
    "contact":"9941355744"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2375638200,12.9811854600 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2374122100,12.9810994500 ]
    },
    "properties": {
    "shop_name":"Bottle mart",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2056743100,12.9401190400 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974598100,12.9253296600 ]
    },
    "properties": {
    "shop_name":"Kr waste paper mart",
    "contact":"9500008123"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2041960400,12.9370530900 ]
    },
    "properties": {
    "shop_name":"Sumathi waste paper mart",
    "contact":"9443442000"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1579072600,13.0556976200 ]
    },
    "properties": {
    "shop_name":"Muthu waste mart",
    "contact":"9444100664"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1496998200,13.0495104400 ]
    },
    "properties": {
    "shop_name":"Vel metal mart",
    "contact":"9094944122"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1613297200,13.0823490700 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2363255000,12.9416094200 ]
    },
    "properties": {
    "shop_name":"All scraps",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2369313400,12.9430839400 ]
    },
    "properties": {
    "shop_name":"Punitha innaciar traders",
    "contact":"9962345451"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2593758000,12.9816294000 ]
    },
    "properties": {
    "shop_name":"Nathan traders.",
    "contact":"9444080386"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1893981600,13.0315642200 ]
    },
    "properties": {
    "shop_name":"Prabakaran iron traders",
    "contact":"9841240114"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1890167000,13.0316271600 ]
    },
    "properties": {
    "shop_name":"Fonix plastics",
    "contact":"044-24745312"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1405123200,13.0237132000 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1263061300,13.0015086400 ]
    },
    "properties": {
    "shop_name":"NA",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1864631000,13.0331442500 ]
    },
    "properties": {
    "shop_name":"GV entrpeises",
    "contact":"9884602111"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1198174700,12.9772502300 ]
    },
    "properties": {
    "shop_name":"Raj cover company",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1200975700,12.9781375100 ]
    },
    "properties": {
    "shop_name":"Card board company",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1201870300,12.9786831100 ]
    },
    "properties": {
    "shop_name":"Black plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2063843200,13.1391451800 ]
    },
    "properties": {
    "shop_name":"Jaya traders",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1856815500,13.0339653600 ]
    },
    "properties": {
    "shop_name":"Thangapandi traders",
    "contact":"9884753963"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1957011000,12.9819741000 ]
    },
    "properties": {
    "shop_name":"Annai plastic.",
    "contact":"9380245009"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1343875000,12.9986393000 ]
    },
    "properties": {
    "shop_name":"Anisha plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2673695000,13.0759753000 ]
    },
    "properties": {
    "shop_name":"Srinivasa Papper stores.",
    "contact":"4428454300"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2646589000,13.0697649000 ]
    },
    "properties": {
    "shop_name":"Bismillah plastic.",
    "contact":"7348524362"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2625015000,13.1008448000 ]
    },
    "properties": {
    "shop_name":"Shree traders.",
    "contact":"9444105869"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2640167000,13.0703238000 ]
    },
    "properties": {
    "shop_name":"K.v. traders.",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2629229000,13.1012307000 ]
    },
    "properties": {
    "shop_name":"Shifa steels.",
    "contact":"9841132676"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1684273100,13.0398253700 ]
    },
    "properties": {
    "shop_name":"Lalith metal scraps",
    "contact":"9884234374\n9884179938"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1731635100,13.0551314700 ]
    },
    "properties": {
    "shop_name":"Grace Metals",
    "contact":"9444108346"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1490398700,13.0245346200 ]
    },
    "properties": {
    "shop_name":"IFS Steels",
    "contact":"9840490372"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1852146600,13.0336534800 ]
    },
    "properties": {
    "shop_name":"Ravi metal scaps",
    "contact":"9941246884"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1851688200,13.0336065300 ]
    },
    "properties": {
    "shop_name":"Antony metals",
    "contact":"9444381030"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2511801000,13.0919894000 ]
    },
    "properties": {
    "shop_name":"Madha waste Papper mart.",
    "contact":"9789029006"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2476301000,13.0951633000 ]
    },
    "properties": {
    "shop_name":"Solai trader's.",
    "contact":"9840933489"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1974570200,13.1967726100 ]
    },
    "properties": {
    "shop_name":"Kamal international",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1940207600,13.1973011000 ]
    },
    "properties": {
    "shop_name":"Balaji plastic",
    "contact":"NA"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1719328800,13.0714425900 ]
    },
    "properties": {
    "shop_name":"Yasotha Traders",
    "contact":"9841172400"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1861085100,13.0639173600 ]
    },
    "properties": {
    "shop_name":"Nellai traders",
    "contact":"9840322239"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1871935300,13.0671989100 ]
    },
    "properties": {
    "shop_name":"Arulrooban traders",
    "contact":"9381918381"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1824295500,13.0657860200 ]
    },
    "properties": {
    "shop_name":"Annai Velankanni traders",
    "contact":"9381918381"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1854557500,13.0641949300 ]
    },
    "properties": {
    "shop_name":"Divya traders",
    "contact":"9941212200"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1877621000,13.0392380700 ]
    },
    "properties": {
    "shop_name":"Rajapandi traders",
    "contact":"7845483662"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1922415300,13.0414806500 ]
    },
    "properties": {
    "shop_name":"Suresh traders",
    "contact":"9381026508"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1767650100,13.0483357600 ]
    },
    "properties": {
    "shop_name":"Deepak industries",
    "contact":"9094780554"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1830564600,13.0506567800 ]
    },
    "properties": {
    "shop_name":"Saravanan traders",
    "contact":"9500111381"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2519485000,13.0913849000 ]
    },
    "properties": {
    "shop_name":"Indhu plastic.",
    "contact":"9943942826."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2512586000,13.0920580000 ]
    },
    "properties": {
    "shop_name":"M.A Kumar traders.",
    "contact":"9444871778"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2773002000,13.0179846000 ]
    },
    "properties": {
    "shop_name":"J.kumar bottle.",
    "contact":"9080352905"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1845423,13.0349196 ]
    },
    "properties": {
    "shop_name":"Thangapandi trader's",
    "contact":"9840436870"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.1852462,13.0335304 ]
    },
    "properties": {
    "shop_name":"July plastic.",
    "contact":"9444544645"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2444236,13.1291062 ]
    },
    "properties": {
    "shop_name":"Sun shine plastic.",
    "contact":"9840740712."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2424126,13.1279072 ]
    },
    "properties": {
    "shop_name":"Saravanan trader's",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2424558,13.1294681 ]
    },
    "properties": {
    "shop_name":"Maithili Stells.",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.27235861,13.12655915 ]
    },
    "properties": {
    "shop_name":"Paper mart",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.27340887,13.12683072 ]
    },
    "properties": {
    "shop_name":"Krishna traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.27357258,13.1272833 ]
    },
    "properties": {
    "shop_name":"Akalila waste paper mart",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26675486,13.13011189 ]
    },
    "properties": {
    "shop_name":"Rajesh trader's",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.19769833333334,13.125016666666665 ]
    },
    "properties": {
    "shop_name":"Bhavani plastic.",
    "contact":"9600134444"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.19779333333332,13.125291666666666 ]
    },
    "properties": {
    "shop_name":"Annai traders.",
    "contact":"9840614949"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2252323,13.0320954 ]
    },
    "properties": {
    "shop_name":"Gopal plastic.",
    "contact":"044-24891192,24748148."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.244852,13.1294248 ]
    },
    "properties": {
    "shop_name":"Usha steel traders.",
    "contact":"9600085151"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2116382,13.1220259 ]
    },
    "properties": {
    "shop_name":"G.s plastic.",
    "contact":"9444046638"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2138831,13.122412 ]
    },
    "properties": {
    "shop_name":"Jothi plastic.",
    "contact":"99417-02006."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.295615,13.176349999999998 ]
    },
    "properties": {
    "shop_name":"Anandhi treaders",
    "contact":"044 2590 4259"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29337833333334,13.172044999999999 ]
    },
    "properties": {
    "shop_name":"Velaa enterprises",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.28909,13.163426666666664 ]
    },
    "properties": {
    "shop_name":"Sponge item",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.289195,13.164183333333336 ]
    },
    "properties": {
    "shop_name":"Ajin plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2239402,13.1336152 ]
    },
    "properties": {
    "shop_name":"MPs bottle.",
    "contact":"9444218804"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2244621,13.1362032 ]
    },
    "properties": {
    "shop_name":"Suresh plastic.",
    "contact":"9790401349"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2347978,13.1429878 ]
    },
    "properties": {
    "shop_name":"Madha waste Papper.",
    "contact":"9962510662"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.28575500000001,13.122755 ]
    },
    "properties": {
    "shop_name":"",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.292005,13.131131666666667 ]
    },
    "properties": {
    "shop_name":"Lakshmi treaders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.20279666666667,13.017826666666668 ]
    },
    "properties": {
    "shop_name":"Kulasai mutharamman waste paper mart",
    "contact":"9551908079"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.20789833333333,13.0051 ]
    },
    "properties": {
    "shop_name":"SB metals ",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17119666666667,13.139963333333332 ]
    },
    "properties": {
    "shop_name":"Svv plastic",
    "contact":"7845103629"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.18230333333332,13.136240000000003 ]
    },
    "properties": {
    "shop_name":"Sreenivasa treaders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.181135,13.132678333333335 ]
    },
    "properties": {
    "shop_name":"Maharaja plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17888333333333,13.139141666666667 ]
    },
    "properties": {
    "shop_name":"Plastic MM",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17981666666667,13.135545000000002 ]
    },
    "properties": {
    "shop_name":"Sreenivasa treaders ",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.18702666666667,13.138981666666666 ]
    },
    "properties": {
    "shop_name":"Ss treaders",
    "contact":"9710498855"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.21018166666666,13.154828333333334 ]
    },
    "properties": {
    "shop_name":"Priya traders",
    "contact":"9444285163"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.25100437,13.09258928 ]
    },
    "properties": {
    "shop_name":"",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.25610686,13.13802974 ]
    },
    "properties": {
    "shop_name":"Krishna traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.25838542,13.1432694 ]
    },
    "properties": {
    "shop_name":"Raju traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.25797583,13.14147353 ]
    },
    "properties": {
    "shop_name":"",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.24352682,13.12931761 ]
    },
    "properties": {
    "shop_name":"Vinoth steel",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.24240516,13.14146096 ]
    },
    "properties": {
    "shop_name":"Walt traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.24236947,13.14174635 ]
    },
    "properties": {
    "shop_name":"Alpha polimer",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.25786531,13.11496425 ]
    },
    "properties": {
    "shop_name":"",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26046031,13.10817306 ]
    },
    "properties": {
    "shop_name":"Royal enterprise",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.24344151,13.12946252 ]
    },
    "properties": {
    "shop_name":"Steel traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.24443632,13.12932994 ]
    },
    "properties": {
    "shop_name":"Sivan plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29611,13.176115000000001 ]
    },
    "properties": {
    "shop_name":"S.J.treaders",
    "contact":"9551778803"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.28916,13.162745 ]
    },
    "properties": {
    "shop_name":"K.m.p.treaders",
    "contact":"7299002249"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29328666666667,13.173688333333333 ]
    },
    "properties": {
    "shop_name":"Murugan treaders",
    "contact":"9940227765"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2771562,13.1239733 ]
    },
    "properties": {
    "shop_name":"Siva plastic",
    "contact":"9841088819."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2768763,13.1245579 ]
    },
    "properties": {
    "shop_name":"Harish Narayana steel.",
    "contact":"9444375582."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2769052,13.1244492 ]
    },
    "properties": {
    "shop_name":"Rajayam trader s plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.20327666666667,13.021848333333333 ]
    },
    "properties": {
    "shop_name":"Sri mutharamman treaders",
    "contact":"9444708079\n9710388985"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29241333333333,13.177069999999999 ]
    },
    "properties": {
    "shop_name":"Arun paper",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29005166666667,13.170546666666667 ]
    },
    "properties": {
    "shop_name":"Plastic godown",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29235333333332,13.177038333333334 ]
    },
    "properties": {
    "shop_name":"STR treaders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29325166666666,13.168773333333336 ]
    },
    "properties": {
    "shop_name":"Murugan treaders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.30090333333332,13.178665 ]
    },
    "properties": {
    "shop_name":"Vinayaga paper mart",
    "contact":"9444995779"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.20871333333334,13.004936666666667 ]
    },
    "properties": {
    "shop_name":"Times steel",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2239402,13.1336152 ]
    },
    "properties": {
    "shop_name":"MPs bottle.",
    "contact":"9444218804"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2768763,13.1245579 ]
    },
    "properties": {
    "shop_name":"P.S.D Traders. (iron)",
    "contact":"9382808769"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2661705,13.1302911 ]
    },
    "properties": {
    "shop_name":"Meera steel traders",
    "contact":"9884479902."
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17746722,13.05729015 ]
    },
    "properties": {
    "shop_name":"St. Xavier's Plastics",
    "contact":"9841715807"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17737262,13.05737492 ]
    },
    "properties": {
    "shop_name":"G.P.D bottles",
    "contact":"8939519104\n9092107301"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17574715,13.05451032 ]
    },
    "properties": {
    "shop_name":" Ramesh traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.18701371,13.067525 ]
    },
    "properties": {
    "shop_name":"Aaa.Bee.Traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17159565,13.05501378 ]
    },
    "properties": {
    "shop_name":"J.J. Plastics",
    "contact":"9444347093"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.16338208,13.0399851 ]
    },
    "properties": {
    "shop_name":"Main road shop plastics",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.16505567,13.0462748 ]
    },
    "properties": {
    "shop_name":"Shanmuga Traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.17569688,13.05447318 ]
    },
    "properties": {
    "shop_name":"Ramesh bottles",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.18538351,13.06417377 ]
    },
    "properties": {
    "shop_name":"Locked main shop",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.18010073,13.03833789 ]
    },
    "properties": {
    "shop_name":"Maharaja traders",
    "contact":"9840613724"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29648999999999,13.135179999999998 ]
    },
    "properties": {
    "shop_name":"Saravana steels",
    "contact":"9840889703"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.31573,13.183654999999998 ]
    },
    "properties": {
    "shop_name":"Madha waste paper mart",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.297355,13.145618333333335 ]
    },
    "properties": {
    "shop_name":"Nadarajan treaders",
    "contact":"04425991404"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.30361500000001,13.16043 ]
    },
    "properties": {
    "shop_name":"Golden waste paper",
    "contact":"9380560039"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29493833333332,13.141436666666667 ]
    },
    "properties": {
    "shop_name":"John britto plastic",
    "contact":"9941128464"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.20267833333334,12.998721666666665 ]
    },
    "properties": {
    "shop_name":"P.J.N. treaders",
    "contact":"04422332475"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.28767500000001,13.137123333333333 ]
    },
    "properties": {
    "shop_name":"Durairaj",
    "contact":"9381801727"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29046333333334,13.138358333333334 ]
    },
    "properties": {
    "shop_name":"SAM treaders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.287595,13.137663333333334 ]
    },
    "properties": {
    "shop_name":"Steel sumangali steels",
    "contact":"9710590011"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.29334999999999,13.137471666666666 ]
    },
    "properties": {
    "shop_name":"Dhurai treaders",
    "contact":"9381801727"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26480432,13.10214969 ]
    },
    "properties": {
    "shop_name":"",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26556539,13.10074769 ]
    },
    "properties": {
    "shop_name":"SKS steel traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26411316,13.10218108 ]
    },
    "properties": {
    "shop_name":"Vasantham plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26381447,13.1013878 ]
    },
    "properties": {
    "shop_name":"Andal plastic",
    "contact":"8939294528"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26317932,13.10178728 ]
    },
    "properties": {
    "shop_name":"Zm plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26288226,13.10099679 ]
    },
    "properties": {
    "shop_name":"Sm Plastic traders",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26279105,13.10077775 ]
    },
    "properties": {
    "shop_name":"Famous pack plastic",
    "contact":""
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.26322511,13.1034867 ]
    },
    "properties": {
    "shop_name":"Frisky traders",
    "contact":"8072070001"
    }
  },
  {
    "type": "Feature",
    "geometry": {
       "type": "Point",
       "coordinates":  [ 80.2291721,13.1345555 ]
    },
    "properties": {
    "shop_name":"Palani andavar trader's",
    "contact":"72"
    }
  }
]
}

export default middleman;
