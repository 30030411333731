import React, {Component} from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown } from 'reactstrap';
import { Link } from 'react-router';
import axios from 'axios';
import {Bar} from 'react-chartjs-2';
import kcLogo from '../images/kc1.png';
import plasticLCA from '../images/plasticLCA.jpg';
import HDPE from '../images/HDPE.jpg';
import LDPE from '../images/LDPE.jpg';
import PP from '../images/PP.jpg';
import PVC from '../images/PVC.jpg';
import Loader from 'react-loader-spinner'
import '../index.css';

class Plastic extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      kwData: {},
      mmData: {},
      wpData: {},
      buttonValue: "Volume",
      kwPrice: {},
      mmPrice: {},
      wpPrice: {},
      kwRevenue: {},
      mmRevenue: {},
      wpRevenue: {},
      loadingVolume: true,
      loadingPrice: true,
      loadingRevenue: true
    };
    this.setState({ buttonValue: "Volume" })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    //API call - VOLUME
    this.setState({ loadingVolume: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/volume/', {
      params: {
        material: 'Plastic',
        out: (this.state.buttonValue).toLowerCase()
      }
    }).then(res => {
      this.setState({
        loadingVolume: false,
        kwData: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmData: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpData: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Volume',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
     })
    }).catch(err => console.log(err));

    //API call - PRICE
    this.setState({ loadingPrice: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/buy_sell/', {
      params: {
        material: 'Plastic'
      }
    }).then(res => {
      this.setState({
        loadingPrice: false,
        kwPrice: {
          labels: res.data.data.kw.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.kw.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.kw.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        mmPrice: {
          labels: res.data.data.mm.keys,
          datasets: [
            {
              label: 'Buying Pricing',
              data: res.data.data.mm.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.mm.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        },
        wpPrice: {
          labels: res.data.data.wp.keys,
          datasets: [
            {
              label: 'Buying Price',
              data: res.data.data.wp.buy_avg,
              backgroundColor: 'rgba(68, 179, 7, 1)'
            },
            {
              label: 'Selling Price',
              data: res.data.data.wp.sell_avg,
              backgroundColor: '#575757'
            }
          ]
        }
      })
    }).catch(err => console.log(err));

    //API call - REVENUE
    this.setState({ loadingRevenue: true });
    axios.get( 'https://cors-anywhere.herokuapp.com/' + 'https://gpsdd-report-api.kcdev.xyz/revenue/', {
      params: {
        material: 'Plastic'
      }
    }).then(res => {
      this.setState({
        loadingRevenue: false,
        kwRevenue: {
          labels: res.data.data.kw.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.kw.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        mmRevenue: {
          labels: res.data.data.mm.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.mm.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        },
        wpRevenue: {
          labels: res.data.data.wp.keys,
          datasets: [{
            label: 'Revenue',
            data: res.data.data.wp.values,
            backgroundColor: 'rgba(68, 179, 7, 1)'
          }]
        }
      })
    }).catch(err => console.log(err));
  }

  //Navbar Toggle
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  //Dropdown button toggle
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  
  //View port width calculator
  calculateWidth() {
    if (window.innerWidth <= 600) { /* mobile */
      return 300;
    } else if (window.innerWidth >= 1100) { /* Desktop */
      return 150;
    } else {
      return 250; /* Tablet */
    }
  }

  //Render Waste Picker data
  renderWastePickerData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.wpData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L0 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: '600'
                        },
                          ticks: {
                            fontSize: 12,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: 'Source Sans Pro',
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if (this.state.buttonValue === 'Price'){
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.wpPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                  tooltips: {
                    mode: 'x-axis'
                  },
                  scales: {
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Price',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 12,
                        beginAtZero: true,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      }
                    }],
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                    }]
                  }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.wpRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L0 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: '600'
                      },
                        ticks: {
                          fontSize: 12,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: 'Source Sans Pro',
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  // Render Kabadiwalla data
  renderKabadiwallaData() {
    if(this.state.buttonValue === "Volume") {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.kwData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L1 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                      xAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Materials',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif"
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                      yAxes: [{
                        scaleLabel: {
                          display: true,
                          labelString: 'Volume',
                          fontSize: 14,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          fontWeight: 600
                        },
                        tooltips: {
                          enabled: true,
                          fontSize: 12,
                          fontFamily: "'Source Sans Pro', sans-serif"
                        },
                          ticks: {
                              fontSize: 8,
                              fontColor: '#151515',
                              fontFamily: "'Source Sans Pro', sans-serif",
                              beginAtZero:true,
                              min: 0
                          },
                          gridLines: {
                            color: '#ccc',
                            borderDash: [1, 3],
                          }
                      }],
                  }
              }}
            />
          </div>
        )
      }
    } else if(this.state.buttonValue === "Price") {
      if(this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.kwPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.kwRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L1 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif"
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                        ticks: {
                            fontSize: 8,
                            fontColor: '#151515',
                            fontFamily: "'Source Sans Pro', sans-serif",
                            beginAtZero:true,
                            min: 0
                        },
                        gridLines: {
                          color: '#ccc',
                          borderDash: [1, 3],
                        }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  //Render Middleman Data
  renderMiddlemanData() {
    if(this.state.buttonValue === 'Volume') {
      if(this.state.loadingVolume == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              data={this.state.mmData}
              height={this.calculateWidth()}
              options={{
                  maintainAspectRatio: true,
                  title: {
                    display: true,
                    text: 'L2 Aggregator',
                    fontSize: 20,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  legend: {
                    position: 'top',
                    labels: {
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  },
                  tooltips: {
                    enabled: true,
                    fontSize: 12,
                    fontFamily: "'Source Sans Pro', sans-serif"
                  },
                  scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Volume',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                          fontSize: 8,
                          fontColor: '#151515',
                          fontFamily: "'Source Sans Pro', sans-serif",
                          beginAtZero:true,
                          min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                  }
              }}
            />
          </div>
        )
      }
    } else if(this.state.buttonValue === 'Price'){
      if (this.state.loadingPrice == false) {
        return (
          <div style={{ marginBottom: 50 }}>
            <Bar
              height={this.calculateWidth()}
              data={this.state.mmPrice}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    fontWeight: 600
                  }
                },
                tooltips: {
                  mode: 'x-axis'
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Price',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    },
                    // stacked: true,
                    ticks: {
                      fontSize: 12,
                      beginAtZero: true,
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }],
                  xAxes: [{
                    scaleLabel: {
                      display: true,
                      labelString: 'Materials',
                      fontSize: 14,
                      fontColor: '#151515',
                      fontFamily: "'Source Sans Pro', sans-serif"
                    }
                  }]
                }
              }}
            />
          </div>
        )
      }
    } else {
      return (
        <div style={{ marginBottom: 50 }}>
          <Bar
            data={this.state.mmRevenue}
            height={this.calculateWidth()}
            options={{
                maintainAspectRatio: true,
                title: {
                  display: true,
                  text: 'L2 Aggregator',
                  fontSize: 20,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                legend: {
                  position: 'top',
                  labels: {
                    fontSize: 14,
                    fontColor: '#151515',
                    fontFamily: "'Source Sans Pro', sans-serif"
                  }
                },
                tooltips: {
                  enabled: true,
                  fontSize: 12,
                  fontFamily: "'Source Sans Pro', sans-serif"
                },
                scales: {
                    xAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Materials',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                    yAxes: [{
                      scaleLabel: {
                        display: true,
                        labelString: 'Revenue',
                        fontSize: 14,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        fontWeight: 600
                      },
                      tooltips: {
                        enabled: true,
                        fontSize: 12,
                        fontFamily: "'Source Sans Pro', sans-serif"
                      },
                      ticks: {
                        fontSize: 8,
                        fontColor: '#151515',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        beginAtZero:true,
                        min: 0
                      },
                      gridLines: {
                        color: '#ccc',
                        borderDash: [1, 3],
                      }
                    }],
                }
            }}
          />
        </div>
      )
    }
  }

  renderDropdownButton() {
    if(this.state.loadingVolume == false && this.state.loadingPrice == false) {
      return (
        <ButtonDropdown className="btn center" style={{ marginTop: 20, marginBottom: 20 }} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
          <DropdownToggle className="dropDownButtonStyle" caret>
            {this.state.buttonValue}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Volume"})}>Volume</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Price"})}>Price</DropdownItem>
            <DropdownItem className="btnOptions dropDownMenuItemStyle" onClick={() => this.setState({ buttonValue: "Revenue"})}>Revenue</DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )
    } else {
      return (
        <div style={{ textAlign: 'center' }}>
          <Loader
             type="TailSpin"
             color="#39A300"
             height="40"
             width="40"
          />
        </div>
      )
    }
  }

  renderMaterialTaxonomy() {
      return (
        <div class="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Material Taxonomy</h3>
          </div>
          <p class="descriptionStyle">Plastic is the most complex and diverse material handled by the informal waste ecosystem. There are more than a hundred different types of plastics identified by the informal sector stakeholders, though only a few are available in large volumes. The informal sector has devised a number of methods and terminologies to identify various polymer types and their process grades, which is usually an indication of the melt flow index of the material.</p>
          {/* HDPE */}
          <div>
            <div style={{ paddingLeft: 15 }}>
              <h5>High Density Polyethylene (HDPE)</h5> 
            </div>
            <p class="descriptionStyle">HDPE is a very complex scrap material given the wide range of colours and process grades of this material. As with most scrap material, HDPE is collected, along with other materials, at the L0 level as a mixed aggregate known as <i>masala</i>. At the L1 level, HDPE is recovered from <i>masala</i> in the form of <i>bommai</i>. Beginning at the L2 level, HDPE materials begins undergoing significant segregation based on process grade by which they were manufactured in the first place - either blow grade HDPE or injection grade HDPE - and also based on colour and sometimes based on brand.</p>
            <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
              <img src={HDPE} alt="HDPE Material Taxnomy" class="img-responsive" height={'auto'} width={'100%'} className="center" />
            </div>
          </div>
          {/* LDPE */}
          <div>
            <div style={{ paddingLeft: 15 }}>
              <h5>Low Density Polyethylene (LDPE)</h5> 
            </div>
            <p class="descriptionStyle">As with most scrap material, LDPE is collected, along with other materials at the L0 level as a mixed aggregate known as <i>masala</i>. LDPE bags and covers, one of the more easily identified LDPE scrap materials, are segregated into the “milk cover” category at the L0 level if a sufficient quantity is collected. LDPE is one of the few materials that is segregated from <i>masala</i> at the L1 level due to its high sale price. Beginning at the L2 level, LDPE begins undergoing further segregation based on process grade - either blow grade or injection grade - and based on colour. After the processor level, these finely segregated LDPE scrap materials are subjected to size reduction to produce flakes initially, and then processed further to produce pellets for each process grade and colour. </p>
            <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
              <img src={LDPE} alt="HDPE Material Taxnomy" class="img-responsive" height={'auto'} width={'100%'} className="center" />
            </div>
          </div>
          {/* PP */}
          <div>
            <div style={{ paddingLeft: 15 }}>
              <h5>Polypropylene (PP)</h5> 
            </div>
            <p class="descriptionStyle">PP is among the most widely used materials in the Chennai consumer market and comes in a variety of process grades, colours and forms. As a result, PP are the most highly recovered material amongst all plastic scrap materials. At L1 level, similar to HDPE, PP is aggregated and sold as <i>bommai</i> from the <i>masala</i> mix to the L2s. At L2 level, PP is typically segregated into 3 process grades - medium (flow) injection grade, high (flow) injection grade, and blow grade.</p>
            <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
              <img src={PP} alt="HDPE Material Taxnomy" class="img-responsive" height={'auto'} width={'100%'} className="center" />
            </div>
          </div>
          {/* PVC */}
          <div>
            <div style={{ paddingLeft: 15 }}>
              <h5>Polyvinyl Chloride (PVC)</h5> 
            </div>
            <p class="descriptionStyle">The majority of PVC scrap material is collected and stored, along with other materials, by L0 stakeholders as <i>masala</i>. At the L1 level, L1 stakeholders segregate PVC scraps from this mix and aggregate them separately. But further segregation of PVC does not happen given the labour intensive nature of the process and their lack of knowledge of various process grades of PVC. At the L2 stakeholder level, PVC begins to undergo extensive segregation based on colour, process grade, and additives (eg. plasticizers). The large proportion of PVC scraps are unplasticized extrusion grade material, primarily in black and grey colours.</p>
            <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
              <img src={PVC} alt="HDPE Material Taxnomy" class="img-responsive" height={'auto'} width={'100%'} className="center" />
            </div>
          </div>
        </div>
      )
  }

  renderDataAnalyticsContent() {
    if(this.state.loadingVolume == false && this.state.buttonValue === 'Volume') {
      return (
        <div>
          <h5>Volume</h5>
          <p className="descriptionStyleMaterialTaxonomy">At the L0 stakeholder level, <i>bommai</i>, a mixed aggregate of PP and HDPE scraps, is the most recovered material. PET, which is easily identifiable and widely available, sees the second highest volume at the L0 level. Beginning at the L1 level, rudimentary segregation of <i>bommai</i> results in a number of polymer fractions being separated from the mixture. High value polymers, such as PVC, LDPE, HIPS, and ABS, and easy to identify PET are the first to be recovered from this mixture. At the L2 level, finer segregation of mixed plastics becomes prevalent. Recovery of PP, PET, HIPS, ABS, and PVC increase manifold compared to L0 and L1 stages.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Price') {
      return (
        <div>
          <h5>Price</h5>
          <p className="descriptionStyleMaterialTaxonomy">At the L0 and L1 enterprise level, prices are determined by the relative content of plastic in a mixed aggregate. As the material is further segregated, material prices begin varying based on polymer type, process grade, market demand for certain colour, presence of contaminants and degree of weathering of the materials.</p>
        </div>
      )
    } else if (this.state.loadingVolume == false && this.state.buttonValue === 'Revenue') {
      return (
        <div>
          <h5>Revenue</h5>
          <p className="descriptionStyleMaterialTaxonomy">PET and <i>bommai</i> generate the most revenue across all stakeholder levels given their large volumes. PP, LDPE, and PVC are also materials that generate significant revenue given their high price.</p>
        </div>
      )
    }
  }

  render () {
    return (
      <div>
        <Navbar className="navbarStyle" light expand="lg">
          <NavbarBrand href="/" className="navbarHeadingStyle">
            <img top src={kcLogo} alt="Kabadiwalla Connect" height={55}  />
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto">
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/">About</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Stakeholders">Stakeholders</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" to="/Materials">Materials</Link></NavLink>
              </NavItem>
              <NavItem>
                <NavLink><Link className="navbarTextStyle" href="https://wiki.kabadiwallaconnect.in/index.php/Special:UserLogin">Wiki</Link></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
        {/* Jumbotron Hero */}
        <div class="containerJumbotronPlastic">
          <div class="imgContainer" />
          <div class="centered descriptionViewStyle">
            <h1 className="jumbotronHeader">Plastic</h1>
            The post-consumer plastics market is characterized by a highly stratified structure with stakeholders who specialize in functions such as aggregation, segregation, trading, processing based on polymer type and process grade. Plastic segregation and processing is labor-intensive and requires specialized training for the workers to ensure correct identification of the materials. Stakeholders who are able to provide higher volumes, uniform quality receive better prices.
          </div>
        </div>
        {/* LCA */}
        <div class="descriptionViewStyle">
          <div style={{ paddingLeft: 15 }}>
            <h3>Life Cycle Analysis</h3>
          </div>
          <p className="descriptionStyle">Value addition to the plastic scraps happens in the form of size reduction and reprocessing. Size reduction is carried out through grinding to produce regrinds, flakes or chips. Based on market demand and the availability of the technical capabilities, these may be further washed before being sold to downstream reprocessors. Some difficult to process materials such as PVC (Polyvinyl Chloride) or low-volume materials such as SAN (Styrene-acrylonitrile) are usually sold as regrinds, while high-volume in demand materials are made into reprocessed plastic granules (RPG).</p>
          <div style={{ marginTop: 20, marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
            <img src={plasticLCA} alt="Plastic LCA" class="img-responsive" height={'auto'} width={'100%'} className="center" />
          </div>
        </div>
        {/* Material Taxonomy */}
        {this.renderMaterialTaxonomy()}
        {/* Data Analytics */}
        <div className="descriptionViewStyle" style={{ paddingLeft: 15, paddingRight: 15 }}>
          {
            this.state.loadingVolume == false ? (
              <div>
                <h3>Data Analytics</h3> 
                {this.renderDataAnalyticsContent()}
            </div>
            ) : null
          }
          {/* Dropdown Button */}
          {this.renderDropdownButton()}

          {this.renderWastePickerData()}
          {this.renderKabadiwallaData()}
          {this.renderMiddlemanData()}
        </div>
      </div>
    )
  }
}

export default Plastic;
